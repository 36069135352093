import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { ActionButton } from '../../../globalComponents/Buttons/ActionButton/ActionButton';
import HaloSkeleton from '../../../globalComponents/Skeletons/HaloSkeleton';

function CSModalFooter ({ 
    ptGoalObjId, 
    closeModal, 
    mode,
    goBackFunc,
    resetModal, 
    isLoading,
    isSettingSchedule
}) {
  const theme = useTheme();


  return (
    <Box className='flex-row' justifyContent={'flex-end'} p={2} gap={1}>
      {ptGoalObjId && (
        <HaloSkeleton loading={isLoading}>
          <ActionButton color="inherit" onClick={goBackFunc}>
            Back
          </ActionButton>
        </HaloSkeleton>
      )}
      {mode === 'scheduling' && (
        <HaloSkeleton loading={isLoading}>
          <ActionButton onClick={resetModal} color="inherit">
            Cancel
          </ActionButton>
        </HaloSkeleton>
      )}
      <HaloSkeleton loading={isLoading}>
        <ActionButton 
          variant='contained'
          color='primary'
          size='small'
          type='submit'
          loading={isSettingSchedule}
        >
            Confirm
        </ActionButton>
      </HaloSkeleton>
      
    </Box>
  );
};

export default CSModalFooter;
