



export const getTextRule = (variant) => {

    let rule = {}

    switch (variant) {
        case 'email':
            rule.pattern = {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Email is not properly formatted'
            }
            break;
        case 'password-sign-up':
            rule.minLength = {
                value: 8,
                message: 'Password must be at least 8 characters long'
            }
            break;
        default:
            break;
    }

    return rule
}
