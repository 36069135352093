import { useTheme } from "@emotion/react";
import { Box, InputAdornment, Typography } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import IPCEntry from "./IPCEntry";
import DefaultNoRowsOverlay from '../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay'
import { ActionButton } from "../../../../globalComponents/Buttons/ActionButton/ActionButton";
import { HaloDatePicker } from "../../../../globalComponents/ReactHookFormFields/DateFields/HaloDatePicker";
import { HaloNumberField } from "../../../../globalComponents/ReactHookFormFields/NumberFields/HaloNumberField";
import { HaloTextField } from "../../../../globalComponents/ReactHookFormFields";
import HaloSelectField from "../../../../globalComponents/ReactHookFormFields/SelectFields/HaloSelectField";

export default function IPCConsult({ipcHistory, control, handleSubmit, submitIPC}){

    const theme = useTheme()

    return(
        <>
            <Box className='flex-col' gap={'22px'}>
                <Typography sx={{...theme.typography.subtitle2, fontWeight:600}}>
                    Interprofessional Consult
                </Typography>
                <Box className='flex-col' gap={2} component={'form'} noValidate onSubmit={handleSubmit(submitIPC)}>
                    <Box className='flex-col' gap={3} sx={{...theme.standardBox}} p={2} pt={3} pb={3} >
                        <Box className='flex-row' gap={2}>
                            <HaloDatePicker 
                                label='Encounter Date'
                                name='encounter_date'
                                required
                                control={control}
                            />
                            <HaloNumberField
                                label={'Encounter Duration'}
                                name='duration'
                                required
                                InputProps={{
                                    endAdornment: <InputAdornment position='start'>min</InputAdornment>
                                }}
                                control={control}
                            />
                        </Box>
                        <HaloTextField
                            label={'Provider'} 
                            name={'provider'} 
                            control={control}
                            required
                        />
                        <HaloSelectField 
                            label="Communication method"
                            name='communication_method'
                            required
                            options={[
                                {val: 'email', label: 'Email'},
                                {val: 'phone', label: 'Phone'},
                                {val: 'ehr/emr', label: 'EHR/EMR'},
                            ]}
                            control={control}
                        />
                        <HaloTextField
                            label={'Details'} 
                            name={'details'}
                            control={control}
                            required
                            multiline
                            minRows={3}
                        />
                    </Box>

                    <Box>
                    <ActionButton
                        type='submit'
                        sx={{width:120}}
                        variant='contained'>
                            Submit
                    </ActionButton>

                    </Box>
                </Box>
            </Box>
            <Typography variant="subtitle2" sx={{ fontWeight:600}}>
                IPC History
            </Typography>
            <Box sx={{...theme.standardBox}} p={2}>
            {ipcHistory.length > 0?
                <Timeline
                sx={{
                    p:0,
                    m:0,
                    [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0, },
                }}>
                    {ipcHistory?.map((entry, index) =>{
                        return(
                            <IPCEntry key={index} entry={entry}/>
                        )
                    })}
                </Timeline>
                :
                <DefaultNoRowsOverlay message="No past IPC"/>
                }
            </Box>
            
        </>
    )

}


