import { useTheme } from "@emotion/react";
import HeaderFrameLayout from "../../../../../globalComponents/Headers/HeaderFrames/FrameLayout/FrameLayout";
import dayjs from "dayjs";
import CaseStatusChip from "../../../../../rosterManagement/utils/StatusChips/CaseStatusChip";
import { stringToSentenceCase } from "../../../../../../utils/stringUtils/sentenceCase";
import {useParams} from "react-router-dom"





export default function QAClinicianFrame({ encounter, trackedDuration, qaObj }){

    const { patient} = encounter || {}
    const theme = useTheme()

    const dateSubmitted = encounter?.date_submitted || encounter?.submitted_at || ''
    const duration = encounter?.time_spent_reviewing_entered || encounter?.general?.time_spent_reviewing_entered_in_minutes || ''
    const treatingProvider = encounter?.treating_provider || encounter?.general?.treating_provider || ''

    const firstRow = [ 
        { label:'Submitted', value: dateSubmitted ? dayjs(dateSubmitted).format('LL') : '-' }, 
        { label:'Self reported duration', value: duration ? `${Math.round(duration / 60)} minutes` : '-' }, 
        { label:'Tracked duration', value: trackedDuration ? `${trackedDuration} minutes` : '-' }, 
        { label:'QA Status', value: <CaseStatusChip caseStatus={qaObj?.review_state_string ? stringToSentenceCase(qaObj?.review_state_string) : ''}/>}
    ]
    
    const secondRow = [ 
        { label:'Treating Provider', value: treatingProvider}, 
        { label:'Site', value: patient?.site?.site_name }, 
    ]

    return(
        <HeaderFrameLayout 
            avatarColor={theme.avatar.primary}
            name={encounter?.clinician_name}
            namesArray={encounter?.review_clinicians_names}
            firstRow={firstRow}
            secondRow={secondRow}
        />
    )



}