import { Box, Collapse, DialogTitle, Slider, Switch, TextField, Typography, Divider, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ActionButton } from '../../../../../../../globalComponents/Buttons/ActionButton/ActionButton';
import { useManageCasesMutation } from '../../../../../../rosterAdminAPISlice';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { show400Error } from '../../../../../../../../app/errors/genericErrors';
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from "@emotion/react"


export default function NumCasesDialog({
    open,
    handleClose,
    selected,
}) {

    const theme = useTheme()
    const [manageCases, { isLoading }] = useManageCasesMutation()
    const [numCases, setNumCases] = useState(1)
    const [sendEmail, setSendEmail] = useState(true)
    const [fill, setFill] = useState(false)

    const handleSliderChange = (e, newValue) => setNumCases(newValue)
    const handleInputChange = (e) => {

        const input = e.target.value

        if (input > 20) {
            setNumCases(20)
            return;
        }

        setNumCases(e.target.value === '' ? 0 : Number(e.target.value));

    }

    const handleSubmit = () => {
        manageCases({
            method: 'PUT',
            body: {
                user_ids: selected,
                notify_by_email: sendEmail,
                num_cases: fill ? 'fill' : numCases
            },
            type: 'users'
        }).unwrap()
            .then((res) => {

                const { message, warnings } = res || {}

                if (message) {
                    enqueueSnackbar(message, { variant: 'success' })
                }
                if (warnings) {
                    warnings.forEach(warning => (
                        enqueueSnackbar(warning, { variant: 'warning' })
                    ))
                }
                handleClose()
            })
            .catch((err) => show400Error(err))
    }



    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
            <Box className='flex-row-between-center' p={'8px 16px'}>
                <DialogTitle sx={(theme) => ({ ...theme.typography.subtitle2, p: '4px 0' })}>
                    Assign cases
                </DialogTitle>
                <IconButton onClick={handleClose} size="small" sx={{ height: 32, width: 32 }}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
            <DialogContent sx={{ p: '16px 0px' }}>
                <Box className='flex-row-between-center' p={'4px 16px'}>
                    <DialogContentText color={fill ? 'info' : `${theme.palette.text.disabled}`} sx={(theme) => ({ ...theme.typography.body2 })}>
                        Caseloads will be filled as requested
                    </DialogContentText>
                    <Switch checked={fill} onClick={() => setFill(prev => !prev)} />
                </Box>
                <Collapse in={!fill}>
                    <Box className='flex-col' p={'4px 16px'}>
                        <DialogContentText color={fill ? 'info' : 'inherit'} sx={(theme) => ({ ...theme.typography.body2 })}>
                            Assign case(s) for user(s)
                        </DialogContentText>
                        <Box className='flex-row' gap={2} alignItems={'center'}>
                            <Slider
                                value={numCases} //delay in state being intitialized so a fallback number prevents component from breaking
                                onChange={handleSliderChange}
                                min={1}
                                max={20}
                                disabled={isLoading}
                            // sx={{
                            //     width: '77%'
                            // }}
                            />
                            <TextField
                                value={numCases}
                                onChange={handleInputChange}
                                size="small"
                                variant='outlined'
                                disabled={isLoading}
                                inputProps={{
                                    step: 1,
                                    min: 1,
                                    max: 20,
                                    type: 'number',
                                }}
                                sx={{
                                    '& input[type=number]::-webkit-outer-spin-button': {
                                        'WebkitAppearance': 'none',
                                        margin: 0,
                                    },
                                    '& input[type=number]::-webkit-inner-spin-button': {
                                        'WebkitAppearance': 'none',
                                        margin: 0,
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                    <Divider sx={{ margin: '16px' }} />
                </Collapse>
                <Box className='flex-row-between-center' p={'4px 16px'}>
                    <DialogContentText
                        color={sendEmail ? 'info' : `${theme.palette.text.disabled}`}
                        sx={(theme) => ({ ...theme.typography.body2 })}
                    >
                        Send assignment email notification(s)
                    </DialogContentText>
                    <Switch checked={sendEmail} onClick={() => setSendEmail(prev => !prev)} />
                </Box>
            </DialogContent>

            <DialogActions>
                <ActionButton
                    variant='outlined'
                    onClick={() => {
                        handleClose()
                    }}
                    disabled={isLoading}>
                    Cancel
                </ActionButton>
                <DialogActions>
                    <ActionButton
                        variant='contained'
                        onClick={handleSubmit}
                        loading={isLoading}
                    >
                        Assign
                    </ActionButton>
                </DialogActions>
            </DialogActions>

        </Dialog>
    )
}