import { AlertTitle, Alert } from "@mui/material"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ActionButton } from "../Buttons/ActionButton/ActionButton";
import { useNavigate } from "react-router-dom"


function StaticAlert({ severity, title, msg, optionalCTALink, optionalCTATitle }) {

    const navigate = useNavigate()

    return (

        <Alert
            severity={severity}
            sx={{ borderRadius: '8px', p: '6px 16px' }}
            iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="inherit" />,
            }}
        >
            <AlertTitle>{title}</AlertTitle>
            {msg}
            {optionalCTALink &&
                <ActionButton
                    variant='text'
                    onClick={() => navigate(optionalCTALink)}
                    sx={{ paddingLeft: 0 }}
                >
                    {optionalCTATitle}
                </ActionButton>
            }
        </Alert>
    )
}

export default StaticAlert
