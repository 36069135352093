import { ActionButton } from "../../../../../globalComponents/Buttons/ActionButton/ActionButton";
import HaloSkeleton from "../../../../../globalComponents/Skeletons/HaloSkeleton";
import { Box, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from "react";
import ReassignModal from "./ReassignModal";
import { useTheme } from "@emotion/react";






export default function GradingButtons({
    rejectMenuOpen,
    handleRejectToggle,
    handleDecision,
    anchorRef,
    queryLoading,
    isSubmitting,
    finalFeedback,
    patient,
    currentClinician
}){
    const theme = useTheme()
    const [ modalOpen, setModalOpen] = useState(false)


    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
            handleRejectToggle()
        } else if (event.key === 'Escape') {
            handleRejectToggle()
        }
    };

    const openModal = () => {
        handleRejectToggle()
        setModalOpen(true)
    }

    const closeModal = () => setModalOpen(false)


    return(
        <Box className='flex-row' justifyContent={'flex-end'} p={2} pb={3} gap={1}>
            <HaloSkeleton loading={queryLoading}>
                <ActionButton
                    variant='contained' 
                    color='error' 
                    ref={anchorRef}
                    loading={isSubmitting}
                    sx={{
                        width:96,
                        color: theme.palette.mode === 'dark'? 'black' :'white'
                    }}
                    onClick={handleRejectToggle}
                    endIcon={<KeyboardArrowDownIcon/>}>
                    Reject
                </ActionButton>
                <Popper
                    open={rejectMenuOpen}
                    anchorEl={anchorRef.current}
                    placement="bottom-start"
                    transition
                    disablePortal
                    sx={{zIndex:1}}
                    >
                    {({ TransitionProps, placement }) => (
                        <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                            placement === 'top-start' ? 'right top' : 'right top',
                        }}
                        >
                        <Paper>
                            <ClickAwayListener onClickAway={handleRejectToggle}>
                            <MenuList
                                autoFocusItem={rejectMenuOpen}
                                onKeyDown={handleListKeyDown}
                                >
                                <MenuItem onClick={handleDecision(1)}>Edit and resubmit</MenuItem>
                                {/* {!(caseType === 'Infectious Disease') && <MenuItem onClick={openModal}>Reassign to another clinician</MenuItem>} */}
                            </MenuList>
                            </ClickAwayListener>
                        </Paper>
                        </Grow>
                    )}
                </Popper>
            </HaloSkeleton>
            <HaloSkeleton loading={queryLoading}>
                <ActionButton 
                    variant='contained' 
                    color='success' 
                    onClick={handleDecision(0)}
                    loading={isSubmitting}
                    sx={{width:96}}>
                    Approve
                </ActionButton>
            </HaloSkeleton>
            <ReassignModal 
                open={modalOpen}
                handleClose={closeModal}
                finalFeedback={finalFeedback}
                patient={patient}
                currentClinician={currentClinician}
                handleDecision={handleDecision}
                isSubmitting={isSubmitting}
            />
        </Box>
    )
}