import { useState } from "react"
import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from "@mui/material";
import CreatePatientModal from "../CreatePatientModal/CreatePatientModal";
import SinglePatientAssignmentModal from "../../../../rosterManagement/admin/components/SinglePatientAssignmentModal/SinglePatientAssignmentModal";






export default function CreatePatientButton(){

    const [assignedPatient, setAssignPatient] = useState(false) //used to trigger assignment modal
    const [ open, setOpen ] = useState(false)

    const openAssignment = (patient) => setAssignPatient(patient)
    const closeAssignment = () => setAssignPatient(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)



    return(
        <>
        <SinglePatientAssignmentModal
            open={Boolean(assignedPatient)|| false} 
            handleClose={closeAssignment} 
            patientObj={assignedPatient} />
        <CreatePatientModal
            open={open} 
            handleClose={handleClose} 
            modalMaxWidth={1200} 
            openAssignment={openAssignment}/>
            <Button
                title="Create a patient profile"
                onClick={handleOpen}
                sx={{ borderRadius:'18px' }}
                variant="outlined"
                disableElevation
                startIcon={<AddIcon/>}>
                    Patient
            </Button>
        </>
    )



}





