import { useCallback } from 'react'
import { stringToTitleCase } from '../../../../utils/stringUtils/titleCase'
import { linkedRowProps } from '../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps'
import ExpandingSearchBar from '../../../globalComponents/SearchBars/ExpandingSearchBar'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useGetTrialUserListQuery } from '../../trialAdminAPISlice'
import { selectTrialUserListFilters, setTrialUserFilters } from '../../../admin/adminSlice'
import usePaginatedRowCount from '../../../globalComponents/DataGrid/hooks/commonUseEffects/usePaginatedRowCount'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box, Button } from "@mui/material"
import DefaultNoRowsOverlay from '../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay'
import SingleFilterChip from '../../../globalComponents/Chips/DropDownChip/SingleFilterChip/SingleFilterChip'
import { SubmissionStatusChip } from '../../utils/trialChips'
import { FinalApprovalChip, InitialApprovalChip } from '../../utils/trialChips'
import { OnboardingKeyIcon } from '../../../../assets/icons/OnboardingKeyIcon'
import { useState } from 'react'
import TrialOnboardingModal from "../Modal/TrialOnboardingModal"
import { selectCurrentUser } from '../../../auth/authSlice'
import ChangeReviewerMenu from './ChangeReviewerMenu'
import ExtendTrialMenu from './ExtendTrialMenu'

const columns = [
    {
        field: 'trial_type',
        headerName: 'Trial type',
        flex: 1,

    },
    {
        field: 'first_name',
        headerName: 'Name',
        flex: 1,
        valueGetter: (value, row) => stringToTitleCase(row?.name) || '-'

    },
    {
        field: 'trial_end',
        headerName: 'Trial end',
        flex: 1.5,
        renderCell: (params) => (
            <ExtendTrialMenu params={params} />
        )

    },
    {
        field: 'is_work_completed',
        headerName: 'Submission status',
        sortable: false,
        flex: 1,
        renderCell: ({ value }) => <SubmissionStatusChip status={value} />
    },
    {
        field: 'reviewer_name',
        headerName: 'Reviewer',
        sortable: false,
        flex: 1,
        renderCell: ({ value: reviewer, row }) =>
            <ChangeReviewerMenu
                currentReviewerName={reviewer}
                currentReviewerId={row.reviewer_id}
                trialUserId={row.id}
                isFeedbackStarted={row.is_feedback_started}
            />

    },
    {
        field: 'passed_initial_approval',
        headerName: 'Initial review status',
        sortable: false,
        flex: 1,
        renderCell: ({ value: status, row }) => status !== null ? (<InitialApprovalChip score={row.get_feedback_score_avg} />) : '-'
    },
    {
        field: 'passed_final_approval',
        headerName: 'Final review status',
        sortable: false,
        flex: 1,
        renderCell: ({ value: status, }) => status !== null ? (<FinalApprovalChip status={status} />) : '-'

    },
    {
        field: 'onboard',
        headerName: '',
        flex: 0.5,
        sortable: false,
        renderCell: (params) => {
            const disabled = !params.row.passed_final_approval || !params.row.is_trial_user
            return (
                <Box className='flex-row-flex-end' width={'100%'} height={'100%'} sx={{ alignContent: 'center' }}>
                    <Button
                        disabled={disabled}
                        onClick={(e) => {
                            e.stopPropagation()
                            params.api.getRow(params.id).openModal()
                        }} sx={{ p: 0 }}>
                        <OnboardingKeyIcon color={!disabled ? '#00B295' : 'gray'} />
                    </Button>
                </Box>)
        }
    },

]

const trialReviewFilterOptions = [
    { label: 'To-do', value: 'To-do' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Denied', value: 'Denied' },
]

const sxProps = {
    border: 'none',
    "& .MuiDataGrid-virtualScroller": { overflow: "hidden" },
    '& .MuiDataGrid-columnHeaderTitleContainerContent': { '& .MuiCheckbox-root': { visibility: 'hidden' } },
    '.MuiDataGrid-detailPanel': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)'
    },
    ...linkedRowProps
}

function TrialUserListPanel() {

    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { groups } = useSelector(selectCurrentUser)
    const isTrialAdmin = groups.hasOwnProperty('Trial User | Admin')

    const params = useSelector(selectTrialUserListFilters)

    const { data: trialUsers, isLoading, isFetching } = useGetTrialUserListQuery({
        pagination: params.pagination,
        ordering: params.ordering,
        search: params.search,
        review_state: params.review_state
    })

    const [onboardingModalIsOpen, setOnboardingModalIsOpen] = useState(false)
    const [rowData, setRowData] = useState(null)
    const closeOnboardingModalHandler = () => setOnboardingModalIsOpen(false)


    const rowCountState = usePaginatedRowCount(trialUsers?.count)

    const setParams = useCallback((state) => dispatch(setTrialUserFilters(state)), [dispatch])

    const handleSortChange = (sortModel) => {
        const { field, sort } = sortModel[0] || {}
        let order = field

        if (sort === 'desc') {
            order = '-' + field
        }

        resetPagination()
        setParams({ ordering: order })
    }

    const resetPagination = () => setParams({ pagination: { page: 0, pageSize: 10 } })

    const handlePaginationChange = (pageModel) => setParams({ pagination: pageModel })

    const handleFilterChange = useCallback((filterName) => (value) => {
        setParams({ [filterName]: value })
    }, [setParams])

    const handleRowClick = ({ row }) => navigate(`/admin/trial-users/profile/${row.id}`)


    const handleCellClick = (params, event) => {
        if (params.field === 'onboard' || params.field === 'reviewer_name') {
            event.stopPropagation()
        }
    }

    const enhancedColumns = trialUsers?.results.map((row) => (
        {
            ...row,
            openModal: () => {
                setOnboardingModalIsOpen(true)
                setRowData(row)
            }
        }))

    return (
        <>
            <Box className='flex-row-between'>
                <SingleFilterChip
                    chipLabel='Status'
                    initLabel='To-do'
                    options={trialReviewFilterOptions}
                    filterState={params?.review_state}
                    setFilterState={handleFilterChange('review_state')}
                />
                <ExpandingSearchBar initValue={params.search} parentSetter={handleFilterChange('search')} />
            </Box>
            <Box sx={{ ...theme.standardBox }}>
                <DataGridPro
                    columns={columns}
                    columnVisibilityModel={!isTrialAdmin ? { onboard: false, reviewer_name: false } : {}}
                    rows={enhancedColumns || []}
                    paginationMode='server'
                    sortingMode='server'
                    pagination
                    autoHeight
                    disableColumnMenu
                    rowCount={rowCountState}
                    loading={isLoading || isFetching}
                    pageSizeOptions={[5, 10, 15, 20, 50]}
                    onSortModelChange={handleSortChange}
                    paginationModel={params.pagination}
                    onPaginationModelChange={handlePaginationChange}
                    onRowClick={handleRowClick}
                    onCellClick={handleCellClick}
                    slots={{
                        noRowsOverlay: DefaultNoRowsOverlay,
                    }}
                    slotProps={{
                        noRowsOverlay: { message: 'No pending trial reviews' }
                    }}
                    sx={sxProps}
                />
            </Box>
            <TrialOnboardingModal rowData={rowData} open={onboardingModalIsOpen} handleClose={closeOnboardingModalHandler} />
        </>
    )
}

export default TrialUserListPanel
