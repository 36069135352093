import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'



export const getToolbarStatusIcon =(error) =>{
  if(error){
    return <WarningIcon sx={(theme) => ({height:16, width:16, color:theme.palette.error.main})} />
  }
  
  return <CheckCircleIcon sx={(theme) => ({height:16, width:16, color: theme.palette.success.main})} />


}