import { apiSlice } from "../../app/api/apiSlice";
import { manageTrialStaticMeds } from "./trialUserSlice";

export const trialUsersAPISlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ['Trial Dashboard', 'Tutorial Status'] })
    .injectEndpoints({
        endpoints: builder => ({

            //sign up 
            getReferralInfo: builder.query({
                query: (signedPayload) => `trial-users/referral/sign-up/${signedPayload}`
            }),
            signUpTrialUser: builder.mutation({
                query: (payload) =>
                ({
                    url: `trial-users/referral/sign-up`,
                    method: 'POST',
                    body: payload
                })
            }),
            signBAADoc: builder.mutation({
                query: (payload) =>
                ({
                    url: 'trial-users/baa',
                    method: 'POST',
                    body: payload
                })
            }
            ),
            getTutorialStatus: builder.query({
                query: () => ({
                    url: `trial-users/tutorial-status`
                }),
                providesTags:['Tutorial Status']
            }),
            setTutorialStatus: builder.mutation({
                query: (payload) => ({
                    url: 'trial-users/tutorial-status',
                    method: 'PUT',
                    body: payload
                }),
                invalidatesTags:['Tutorial Status']
            }),


            //all encounter related

            getTrialDashboardData: builder.query({
                query: () => `trial-users/dashboard`,
                providesTags: ['Trial Dashboard'],
                keepUnusedDataFor: 1
            }),

            getTrialEncounter: builder.query({
                query: ({ encounterType, trialEncounterId }) => `trial-users/encounter/${encounterType}/${trialEncounterId}`
            }),

            getTrialEncounterSubmittedDetails: builder.query({
                query: ({ encounterType, trialEncounterId }) => `trial-users/encounter-details/${encounterType}/${trialEncounterId}`
            }),

            //Trial user pathology CRUD endpoints
            manageTrialClinicalFindings: builder.mutation({
                query: (payload) => ({
                    url: 'trial-users/pathology/clinical-findings',
                    method: payload.method,
                    body: payload.body
                })
            }),
            updateTrialRecsAndComments: builder.mutation({
                query: (payload) => ({
                    url: 'trial-users/pathology/review',
                    method: 'PUT',
                    body: payload
                })
            }),
            manageTrialResistanceGenes: builder.mutation({
                query: (payload) => ({
                    url: 'trial-users/pathology/resistance-genes',
                    method: payload.method,
                    body: payload.body
                })
            }),
            manageTrialDrugInteractions: builder.mutation({
                query: (payload) => ({
                    url: 'trial-users/pathology/drug-interactions',
                    method: payload.method,
                    body: payload.body
                })
            }),
            manageTrialMedication: builder.mutation({
                query: (payload) => ({
                    url: 'trial-users/pathology/medications',
                    method: payload.method,
                    body: payload.body
                }),
                async onQueryStarted({ method, body }, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        const patchResult = dispatch(manageTrialStaticMeds({ method, data, body }))
                    } catch { }
                },
            }),
            manageTrialICD10Code: builder.mutation({
                query: (payload) => ({
                    url: 'trial-users/pathology/diagnoses',
                    method: payload.method,
                    body: payload.body
                })
            }),
            manageTrialAllergy: builder.mutation({
                query: (payload) => ({
                    url: 'trial-users/pathology/allergies',
                    method: payload.method,
                    body: payload.body
                })
            }),

            //infectious disease CRUD endpoints
            updateTrialInfecDisEncounter: builder.mutation({
                query: (payload) => ({
                    url: 'trial-users/infectious-disease/review',
                    method: 'PUT',
                    body: payload
                })
            }),

            //all encounter submission 
            finalizeTrialEncounter: builder.mutation({
                query: ({ encounterType, trialEncounterId, payload }) => ({
                    url: `trial-users/encounter/finalize/${encounterType}/${trialEncounterId}`,
                    method: 'POST',
                    body: payload
                }),
                invalidatesTags: ['Trial Dashboard']
            })


        })
    })

export const {
    useManageTrialClinicalFindingsMutation, useUpdateTrialRecsAndCommentsMutation, useManageTrialResistanceGenesMutation, useManageTrialDrugInteractionsMutation, useManageTrialMedicationMutation, useManageTrialICD10CodeMutation, useManageTrialAllergyMutation, useSubmitTrialPathologyReviewMutation, useLazyGetReferralInfoQuery, useSignUpTrialUserMutation, useGetTrialDashboardDataQuery, useGetTrialEncounterQuery, useGetTrialEncounterSubmittedDetailsQuery, useFinalizeTrialEncounterMutation, useUpdateTrialInfecDisEncounterMutation, useSignBAADocMutation, useGetTutorialStatusQuery, useSetTutorialStatusMutation
} = trialUsersAPISlice
