import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { TimelineDot } from '@mui/lab';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { useTheme } from '@emotion/react';
import { CGxIcon, PGxIcon, PrescriptionsIcon } from '../../../../../../../../assets/icons/MaterialDesignIcons';
import { purple, cyan } from '@mui/material/colors';

const iconSx = {
    p:1,
    boxShadow:'none', 
    '& svg': {
        height:20,
        width:20
    }
}

export function EntryIcon({ type }) {
    const theme = useTheme();
    const lowerType = type?.toLowerCase() || '';
  
    const entryMap = [
      { key: 'pathology', bg: theme.palette.primary.main, Icon: PrescriptionsIcon },
      { key: 'infectious', bg: theme.palette.warning.light, Icon: CoronavirusOutlinedIcon },
      { key: 'pgx', bg: cyan[500], Icon: PGxIcon },
      { key: 'cgx', bg: purple[300], Icon: CGxIcon },
      { key: 'ipc', bg: theme.palette.success.main, Icon: PhoneIphoneIcon },
    ];
  
    const { bg, Icon } = entryMap.find(({ key }) => lowerType.includes(key)) || { bg: theme.palette.text.secondary, Icon: StickyNote2OutlinedIcon };
  
    return (
      <TimelineDot sx={{ background: bg, ...iconSx }}>
        <Icon sx={{ color: theme.palette.background.paper }} />
      </TimelineDot>
    );
  }