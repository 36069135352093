import { Typography } from "@mui/material";
import dayjs from "dayjs";




export const LabBody = ({ data}) => (
    <>
        <Typography variant='caption' color='text.disabled'>
            Date: {data?.result_date? dayjs(data?.result_date).format('MMMM D, YYYY h:mm A') : '-'}
        </Typography>
        <Typography variant='caption' color='text.disabled'>
            Summary: {data?.summary || '-'}
        </Typography>
    </>
);
