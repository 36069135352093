import CaseAccordion from "../../../../globalComponents/Accordions/CaseAccordion";
import SummaryContainer from "../../../../globalComponents/Containers/SummaryContainer";
import DrugInteractions from "./DrugInteractions/DrugInteractions";
import MedicationNotes from "./MedicationNotes/MedicationNotes";
import MedicationRecs from "./MedicationRecs/MedicationRecs";






export default function Recommendations(props){

    const {medInstructions, general, drugInteractions, med_recs_options, readOnlyView, allowEdits, caseInfo, isLoading, isUnder18, qaObj} = props
    const {field_reviews} = qaObj || {}
    
    const content = [
        <MedicationRecs medInstructions={medInstructions} general={general} med_recs_options={med_recs_options} isUnder18={isUnder18} key={'medInstructions'} qaObj={qaObj} />,
        <MedicationNotes general={general} key={'medNotes'} qaObj={qaObj}/>,
        <DrugInteractions drugInteractions={drugInteractions} key={'medInteractions'}/>
    ]

    const hasQAComments = field_reviews?.["Medication Review"]?.score_percentage < 100 || field_reviews?.["Recommendations & Comments"]?.score_percentage < 100


    return readOnlyView? (

        <SummaryContainer number={3} title='Recommendations' disableEdits={!allowEdits} caseInfo={caseInfo} isLoading={isLoading}>
            {content}
        </SummaryContainer>

    ):(
       <CaseAccordion number={3} title='Recommendations' iconVariant={hasQAComments ? 'error' : null}> 
            {content}
       </CaseAccordion>
    )
}