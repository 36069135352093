import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ManualAssignmentIcon from '../../../../../assets/icons/ManualAssignment';
import BatchDistributionIcon from '../../../../../assets/icons/BatchDistribution';
import { useTheme } from '@emotion/react';

const AsgmtRadio = ({ type, onClick, isActive, isLoading}) => {

    const theme = useTheme()

  return (
    <IconButton
      onClick={onClick}
      sx={{
        borderRadius: 2,
        boxShadow: `inset 0px 0px 0px ${isActive ? '2px ' + theme.palette.primary.main : '1px ' + theme.palette.divider}`,
        boxSizing: 'border-box',
        flex: 1,
        p: '16px 32px',
        alignItems: 'flex-start',
        background: isActive ? theme.palette.primary.shades4 : '',
      }}
    >
      <Box>
        {type === 'manual' ? (
          <ManualAssignmentIcon color={isActive ? 'primary' : theme.palette.action.active} sx={{ fontSize: 56 }} />
        ) : (
          <BatchDistributionIcon color={isActive ? 'primary' : theme.palette.action.active} sx={{ fontSize: 56 }} />
        )}
        <Typography variant="subtitle1" color={theme.palette.text.primary}>
          {type === 'manual' ? 'Manual assignment' : 'Batch distribution'}
        </Typography>
        <Typography variant="body2" color={theme.palette.text.primary}>
          {type === 'manual' ? 'Assign case to a specific clinician' : 'Case will be distributed on the selected date, randomly among eligible clinicians'}
        </Typography>
      </Box>
    </IconButton>
  );
};

export default AsgmtRadio;
