import { InputBase, Paper, Popper } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import React, { useCallback, useLayoutEffect, useState } from "react";
import CustomTextFieldCell from "./CustomTextFieldCell";

//use for cells with longer/multiline content

export default function TextFieldPortalCell(props) {
    const { id, field, value, colDef, hasFocus, row } = props;
    const [anchorEl, setAnchorEl] = useState();
    const [inputRef, setInputRef] = useState(null);
    const apiRef = useGridApiContext();
  
    useLayoutEffect(() => {
      if (hasFocus && inputRef) {
        inputRef.focus();
      }
    }, [hasFocus, inputRef]);
  
    const handleRef = useCallback((el) => {
        if(hasFocus) setAnchorEl(el)
    }, [hasFocus]);
  
    const handleChange = useCallback(
      (event) => {
        const newValue = event.target.value;
        apiRef.current.setEditCellValue( { id, field, value: newValue} );
      },
      [apiRef, field, id],
    );


    if(!hasFocus) {
        return (
            <CustomTextFieldCell  {...props}/>
        )
    }
    

    return (
      <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
        <div
          ref={handleRef}
          style={{
            height: 1,
            width: colDef.computedWidth,
            display: 'block',
            position: 'absolute',
            top: 0,
          }}
        />
        {anchorEl && (
          <Popper open anchorEl={anchorEl} placement="bottom-start">
            <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth, width:colDef.computedWidth }}>
              <InputBase
                multiline
                placeholder="Shift + Enter for new line"
                minRows={6}
                name={field}
                value={value}
                sx={{ textarea: { resize: 'both' }, width: '100%' }}
                onChange={handleChange}
                inputRef={(ref) => setInputRef(ref)}
              />
            </Paper>
          </Popper>
        )}
      </div>
    );
  }


  