import CRUDDataGrid from "../../../../../globalComponents/DataGrid/CRUDDataGrid"
import dayjs from 'dayjs';
import {useManageAllergyMutation} from '../../../../../patients/patientAPISlice'
import CustomTextFieldCell from '../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell'
import { useState } from "react";
import { AllergyIcon } from '../../../../../../assets/icons/MaterialDesignIcons';


export default function Allergies({allergies, readOnly}){

    
    
    let columns = [
        { 
          field: 'allergy_name',
          headerName: 'Allergy',
          flex:1,
          editable:!readOnly,
          headerClassName: readOnly? '' : 'required-col',
          renderEditCell: (params) => (
            <CustomTextFieldCell {...params} />
          ),
        },
        {   
          field: 'date_onset', 
          headerName: 'Date Onset',
          flex:1,
          editable:!readOnly,
          type: 'date',
          valueFormatter: value =>
            value? dayjs(value).format('MM/DD/YYYY') : null
        },
        {   
          field: 'reactions', 
          headerName: 'Reactions',
          flex:1,
          editable:!readOnly, 
        },
      ]

      
    return(
        <CRUDDataGrid
            title={'Allergies'}
            columns={columns} 
            initRows={allergies} 
            mutation={useManageAllergyMutation}
            GridIcon={AllergyIcon}
            fieldToFocus='allergy_name'
            requiredFields={['allergy_name']}
            isReadOnly={readOnly}
            />
    )
}