import Box from "@mui/material/Box";
import DefaultNoRowsOverlay from "../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay";
import { useNavigate } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { linkedRowProps } from "../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";
import { stringToSentenceCase } from "../../../../utils/stringUtils/sentenceCase";
import { AssignmentStatusChipCell } from "../../../globalComponents/DataGrid/subComponents/customCells/AssignmentStatusChipCell";
import { SubmissionStatusChip } from "../../utils/trialChips";


const columns = [
    {
        field: 'patient_name',
        headerName: 'Patient',
        flex: 1,
        maxWidth: 200,
        valueFormatter: (value) => stringToTitleCase(value)
    },
    {
        field: 'referral_link_type',
        headerName: 'Encounter type',
        flex: 1,
        maxWidth: 150,
        renderCell: (params) => (
            <>{params.value.split(' ')[0]}</>
        )
    },
    {
        field: 'col3',
        headerName: 'Submission status',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <SubmissionStatusChip status={row.date_submitted} />

    },
];

export default function TrialDashboardCases({ assignments, Toolbar, isLoading }) {
    const navigate = useNavigate()


    return (
        <Box sx={(theme) => ({ ...theme.standardBox, height: 200 })} >
            <DataGridPro
                sx={{
                    "& .MuiDataGrid-virtualScroller": {
                        overflowX: "hidden",
                        overflowY: assignments?.length === 0 ? 'hidden' : 'auto'
                    },
                    ...linkedRowProps
                }}
                columnHeaderHeight={0}
                columns={columns}
                rows={assignments || []}
                loading={isLoading}
                disableColumnFilter
                disableColumnMenu
                disableColumnResize
                hideFooter
                onRowClick={(params) => {
                    if (params.row.date_submitted) {
                        navigate(`/trial-users/encounter-details/${params.row.referral_link_type}/${params.row.id}`)
                    }
                    else {
                        navigate(`/trial-users/encounter-review/${params.row.referral_link_type}/${params.row.id}`)
                    }
                }}
                slots={{
                    toolbar: Toolbar,
                    noRowsOverlay: DefaultNoRowsOverlay
                }}
                slotProps={{
                    toolbar: {
                        header: 'Assigned encounters',
                        count: assignments?.length,
                        isLoading
                    },
                    noRowsOverlay: {
                        message: 'No assigned encounters'
                    }
                }} />

        </Box>
    )
}
