import React, { useEffect } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';

import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider, } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box';
import { Routes } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SnackbarProvider } from 'notistack';
import SideBar from './app/layout/SideBar/SideBar'
import { initTheme } from './app/theme/theme';
import { useSelector } from 'react-redux';
import { selectScheuleConfirmation } from './features/rosterManagement/rosterSlice';
import { SnackbarCloseButton, StyledMaterialDesignContent } from './app/theme/snackBars';
import { ErrorBoundary } from 'react-error-boundary';
import RenderError from './app/layout/fallbackComponents/RenderError';
import WeeklySummary from './features/rosterManagement/components/WeeklySummary/WeeklySummary';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { OverlayScrollbars } from "overlayscrollbars";
import ScrollToTop from './app/layout/ScrollToTop/ScrollToTop';

//routes
import { dashboardRoutes } from './features/dashboard/dashboardRoutes'
import { authRoutes } from './features/auth/authRoutes'
import { pathologyRoutes } from './features/pathology/pathologyRoutes'
import { patientRoutes } from './features/patients/patientRoutes';
import { infectiousDiseaseRoutes } from './features/infectiousDisease/infectiousDiseaseRoutes'
import { rosterRoutes, rosterAdminRoutes } from './features/rosterManagement/rosterRoutes';
import { profileRoutes } from './features/profile/profileRoutes';
import { adminRoutes } from './features/admin/adminRoutes';
import { trialUserRoutes } from './features/trialUsers/trialUserRoutes';
import { trialAdminRoutes } from './features/trialUsers/trialAdminRoutes'
import { getIsRouteForbidden, selectCurrentUser } from './features/auth/authSlice';
import { useNavigate } from 'react-router-dom'
import { TutorialProvider } from './features/trialUsers/utils/TutorialContext';
import ForbiddenAccess from './features/trialUsers/pages/ForbiddenAccess';


//MUI Pro plan
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

function App() {
  const navigate = useNavigate()

  const schedule = useSelector(selectScheuleConfirmation)
  const user = useSelector(selectCurrentUser)
  const systemPref = useMediaQuery('(prefers-color-scheme:dark)')
  const [darkMode, setDarkMode] = useState(localStorage.getItem('dark_mode') ? JSON.parse(localStorage.getItem('dark_mode')) : systemPref);
  const [navOpen, setOpen] = useState(false)
  const theme = initTheme(darkMode)
  const osInstance = OverlayScrollbars(document.body, { scrollbars: { autoHide: "never", theme: theme.palette.mode === 'dark' ? 'os-theme-light' : 'os-theme-dark' } });

  useEffect(() => {

    osInstance.destroy()
    OverlayScrollbars(document.body, { scrollbars: { autoHide: "never", theme: theme.palette.mode === 'dark' ? 'os-theme-light' : 'os-theme-dark' } });

  }, [theme.palette.mode, osInstance])

  return (
    <>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <TutorialProvider>
          <SnackbarProvider
            maxSnack={1}
            preventDuplicate
            Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
              warning: StyledMaterialDesignContent
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            iconVariant={{
              success: <CheckCircleOutlinedIcon style={{ marginRight: 8 }} />,
              error: <ErrorOutlineOutlinedIcon style={{ marginRight: 8 }} />
            }}
            action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
          >
            <ErrorBoundary FallbackComponent={RenderError}>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box display={'flex'}>
                  <SideBar darkMode={darkMode} setDarkMode={setDarkMode} open={navOpen} setOpen={setOpen} />
                  <Box component="main" flexGrow={1} flex={1} minWidth={0} >
                    {
                      schedule.isConfirmed ?
                        null :
                        <WeeklySummary unconfirmedSchedule={schedule} />
                    }
                    <Routes>
                      {authRoutes}
                      {adminRoutes}
                      {dashboardRoutes}
                      {pathologyRoutes}
                      {patientRoutes}
                      {infectiousDiseaseRoutes}
                      {rosterRoutes}
                      {rosterAdminRoutes}
                      {profileRoutes}
                      {trialUserRoutes}
                      {trialAdminRoutes}
                    </Routes>
                  </Box>
                </Box>
              </LocalizationProvider>
            </ErrorBoundary>
          </SnackbarProvider>
        </TutorialProvider>
      </ThemeProvider>
    </>

  );
}

export default App;
