import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";






export default function FrameRow({items}){

    const theme = useTheme()

    return(
        <Box className='flex-row' gap={3} ml={7} height={44}>
            {
                items?.map((item,indx) =>(
                    <Box className='flex-col' gap={0.4} key={item?.label ?? indx}>
                        <Typography variant="body2" color={theme.palette.text.disabled}>{item?.label}</Typography>
                        {
                            typeof item?.value === 'string' || typeof item?.value === 'number'? 
                                <Typography variant="body2" sx={{...item?.valueSx}}>{item?.value || '-'}</Typography>
                            :
                            item?.value // allows us to pass components as well as strings
                        }
                    </Box>
                ))
            }
        </Box>
    )


}