import { useTheme } from '@emotion/react'
import {ResponsiveBar} from '@nivo/bar'


const themeSx = (theme) =>({
    "axis": {
        "ticks": {
            "text": {
                "fill": theme.palette.text.secondary,
                "outlineWidth": 0,
                "outlineColor": "transparent"
            }
        }
    },
    "legends": {
        "text": {                                        
            "fill": theme.palette.text.primary,
        }
    },
})

const defaultLegend = (legend) => ([
    {   
        ...legend,
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',                
    }
])


function ResponsiveBarChart({
    data, 
    margin, 
    padding, 
    customTooltipFunc,  
    legend, 
    borderRadius=4, 
    enableLabel=true,
    ...otherProps
}) {

    /* 
        data come in an array of objects 
        e.g. [ {x,y}, {x,y} ]
    */
    const theme = useTheme()

    return (
        <ResponsiveBar
            theme={themeSx(theme)}
            data={data}
            keys={['y']}
            indexBy="x"
            margin={margin || {  right: 0, bottom: 30, left: 0 }}
            padding={padding || 0.3}
            valueScale={{type: 'linear' }}
            // indexScale={{type: 'band', round: true}}
            colors={[theme.palette.primary.dark]}
            axisLeft={null}
            labelTextColor={'white'}
            tooltip={customTooltipFunc}
            legends={legend? defaultLegend(legend) : []}
            borderRadius={borderRadius}
            enableLabel={enableLabel}
            {...otherProps}
        />
    )
}

export default ResponsiveBarChart
