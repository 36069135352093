import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectScorePercentage } from "../../../../qualitySlice";
import HaloSkeleton from "../../../../../globalComponents/Skeletons/HaloSkeleton";



const getColor = (scoreStr) => {
    
    let score = typeof scoreStr === 'number'? scoreStr : Number(scoreStr)
    let variant = 'warning.main';
    
    if(score > 90) variant = 'success.main';
    else if(score <= 75) variant = 'error.main';

    return variant;
}


export default function ScoreAndStatus({qaObj, loading}){


    const score = useSelector(selectScorePercentage)
    

    

    return(
        <Box  className='flex-row-between'>
            <HaloSkeleton loading={loading}>
                <Typography variant="subtitle1" color={getColor(score)}>Current score: {score? score + '%' : '-'}</Typography>
            </HaloSkeleton>
        </Box>
    )
}