import { Box, InputAdornment, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { HaloTextField } from "../../../../../globalComponents/ReactHookFormFields/TextFields/HaloTextField"
import { stringToSentenceCase } from "../../../../../../utils/stringUtils/sentenceCase"
import { useEditQAReviewFieldMutation } from "../../../../qualityAPISlice"
import { useRef } from "react"
import { HaloNumberField } from "../../../../../globalComponents/ReactHookFormFields"
import { show400Error } from "../../../../../../app/errors/genericErrors"
import { useWatchScore } from "./useWatchScore"
import {useParams} from "react-router-dom"








export default function GradingSection({section, qualityId, disabled=false}) {

    let {section_name} = section || {}

    const parentRef = useRef(null)
    const [triggerEdit, {isLoading, isFetching}] = useEditQAReviewFieldMutation()
    
    
    const { control, formState:{ isDirty, isValid}, getValues, reset, trigger} = useForm({
        defaultValues:{
            score: section?.score?.toString(),
            feedback: section?.feedback
        },
    })   
    useWatchScore({control, section_name})

    const handleBlur = async (event) => {
        let valid = await trigger()

        //only send requests when section is blurred or values have changed
        if (!event.currentTarget?.contains(event?.relatedTarget) && isDirty && valid) {
            let values = getValues()
            triggerEdit({
                fieldId: section?.id,
                qualityId,
                payload:{
                    ...values
                }
            })
            .unwrap()
            .then(res => {
                // pass the values to update values to "compare"
                reset( {...values}, {keepDirtyValues:true, keepDirty:false, })
            })
            .catch(err => show400Error(err))
        }
    }
    
    return(
        <Box className='flex-col' gap={2} onBlur={(e) => handleBlur(e,section?.id)} ref={parentRef}>
            <Box className='flex-row' alignItems={'center'} gap={1}>
                <HaloNumberField 
                    key={'score_' + section?.id}
                    name='score'
                    control={control}   
                    disabled={disabled}
                    decimalPlace={1}
                    maxValue={Number(section?.max_possible_score)}
                    sx={{width:96}}
                    InputProps={{
                        endAdornment : <InputAdornment position='end'>/{section?.max_possible_score}</InputAdornment>
                    }}
                    required
                    hideHelperText
                />
                <Typography variant="subtitle2">{stringToSentenceCase(section_name) || '-'}</Typography>
            </Box>
            <HaloTextField 
                key={'feedback_' + section?.id}
                name='feedback'
                label={stringToSentenceCase(section_name) + ' feedback'}
                control={control}
                disabled={disabled}
                multiline
                rows={3}
            />
        </Box>
    )
}