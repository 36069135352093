import { Box, Button,Collapse,IconButton,Switch,Typography } from "@mui/material"
import {  getToolbarStatusIcon } from "../../utils/getToolbarStatusIcon";
import  { useEffect, useState} from "react";
import { useErrorTracking, useSelectedRowsCount } from "../../hooks/subscriptions";
import AddIcon from '@mui/icons-material/Add';
import { useRowCount } from "../../hooks/subscriptions/useRowCount";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StyledWarningSwitch from "../../../Switches/StyledWarningSwitch";

function Toggle({
  toggled, 
  setToggle,
  toggleProps, 
  parentObjId,
  isReadOnly
  }){

  const [mutate] = toggleProps?.mutation ? toggleProps?.mutation() : [null];

  //! local state doesn't work as it needs to be ale to close the grid and hide/show text

  const handleToggle = (e) => {

    e.stopPropagation()

    mutate({
      method:'PUT',
      body:{
        id:parentObjId,
        [toggleProps?.mutationKey]: !toggled
      }
    })
    .unwrap()
    .then(res => setToggle(!toggled))

  }


  return(
    <StyledWarningSwitch
        size='small'
        checked={toggled}
        onClick={handleToggle}
        disabled={isReadOnly}
      />
  )

}

const getActionIcon = ({isEmpty, open, isReadOnly}) => {
  let Icon = null

  if (isReadOnly) return Icon;
  else if (isEmpty){
    Icon = AddIcon
  }
  else if (!isEmpty && open){
    Icon = ExpandLessIcon
  }
  else{
    Icon = ExpandMoreIcon
  }

  return <Icon size='small' color='action'/>
}


export default function ToggleToolbar(props) {
  const {
    handleDeleteClick,
    apiRef,
    initRowCount,
    title, 
    toolbarChildren,
    handleAddRow,
    openGrid,
    closeGrid,
    open,
    isReadOnly,
    GridIcon,
    toggleProps,
    parentObjId
  } = props
  
  const rowCount = useRowCount(apiRef, initRowCount)
  const selectedCnt = useSelectedRowsCount(apiRef);
  const [toggled, setToggle] = useState(toggleProps?.value)
  const error = useErrorTracking(apiRef);
  const isEmpty = rowCount === 0;


  useEffect(() => {

    if(isEmpty) closeGrid()

    if (!isEmpty) openGrid()

  }, [isEmpty, isReadOnly, initRowCount])

  useEffect(() => {
    //this is necessary as the values seemed to be getting cached somehow. Will investigate after texas DX
    setToggle(toggleProps?.value) 
  
  }, [toggleProps?.value])

  const handleToolbarClick = () => {

    if (isReadOnly) return null
    else if (isEmpty ){
        handleAddRow()
        openGrid()
    }
    else if(!isEmpty && open){
      closeGrid()
    }
    else{
      openGrid()
    }

  }



  return (
    <Box 
      className='flex-row'
      onClick={handleToolbarClick}
      sx={ (theme) => ({
        p:'8px 16px',
        justifyContent:'space-between',
        borderBottom: open? `1px solid ${theme.palette.divider}` : '' ,
        minHeight:48,
        backgroundColor: theme.palette.primary.shades4,
        '&:hover':{
          backgroundColor: !isReadOnly && theme.palette.action.hover ,
          cursor: !isReadOnly && 'pointer'
        }
      })}>
      {selectedCnt> 0 ?
          <Button
            sx={{ fontSize:16, height:24, alignSelf:'center'}}
            onClick={(e) => {
              e.stopPropagation()
              handleDeleteClick(null)()
            }}>
            Delete {selectedCnt}
          </Button>
        :
        <Box className='flex-col' justifyContent={'center'}>
          <Box className='flex-row' alignItems='center' gap={1}>
            <Box className='flex-row' gap={0.5} alignItems='center'>
              {GridIcon && <GridIcon color='primary' style={{fontSize:22}}/>}
              <Typography variant='body1' color='text.primary' >{title}</Typography>
            </Box>
            {!isReadOnly && getToolbarStatusIcon(error) }
          </Box>
            <Collapse in={toggled}>
              <Typography variant='caption' color='warning.dark'>{toggleProps?.helperText}</Typography>
            </Collapse>
        </Box>
        }
        <Box className='flex-row' gap={1} alignItems='center'>
          {
            (toggleProps && !isReadOnly) &&
            <Toggle 
              toggled={toggled}
              setToggle={setToggle}
              toggleProps={toggleProps}
              parentObjId={parentObjId}
              isReadOnly={isReadOnly}
            />
          }
          {toolbarChildren}
          {
            getActionIcon({isEmpty, open, isReadOnly})
          }
        </Box>
    </Box>
  );
}
