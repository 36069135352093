import { useCallback, useEffect, useRef, useState } from "react"
import { useUploadPatientsMutation } from "../../../../dataIntakeAPISlice"
import { Alert, AlertTitle, Box, Typography } from "@mui/material"
import { CheckCircle } from "@mui/icons-material"
import ErrorIcon from '@mui/icons-material/Error';
import ResponsiveDoughtnutChart from "../../../../../globalComponents/NivoCharts/Doughnut/ResponsiveDoughtnutChart"
import { useTheme } from "@emotion/react"
import CustomNivoTooltip from "../../../../../globalComponents/NivoCharts/Tooltips/CustomNivoTooltip"
import { stringToTitleCase } from "../../../../../../utils/stringUtils/titleCase"
import BulkPatientsToBatchModal from "../../../../../rosterManagement/admin/components/BulkPatientsToBatchModal/BulkPatientsToBatchModal"
import HaloModalStickyFooter from "../../../../../globalComponents/Modals/HaloModalStickyFooter/HaloModalStickyFooter"
import { ActionButton } from "../../../../../globalComponents/Buttons/ActionButton/ActionButton"
import TypographyCard from "../../../../../globalComponents/Cards/TypographyCard/TypographyCard"
import HaloSkeleton from "../../../../../globalComponents/Skeletons/HaloSkeleton"








export default function  Summary({
    file, 
    template, 
    site,
    handleClose,
    type,
    resetState
}){
    const theme = useTheme()
    const [recordId, setRecordId] = useState('')
    const [uploadPatients, {data, isLoading, error }] = useUploadPatientsMutation()
    const effectRan = useRef(false) //pattern for preventing dupe request in dev mode

    useEffect(() => {
        if (effectRan.current === false) {
            uploadPatients({
                template_id: template?.id,
                site: site?.id,
                file: file[0],
                emr_profile: ''
            });
    
            effectRan.current = true; 
        }
    
        return () => {
            effectRan.current = true; 
        }
    }, []);

    const handleCloseAddToBatch = useCallback(() => {
        setRecordId('')
        handleClose()
    })  

    const stats = [
        {
            title:'Site',
            value: site?.site_name
        },
        {
            title: 'Upload type',
            value: stringToTitleCase(template?.template_type?.name)
        },
        {
            title: 'Total scanned',
            value: data?.total,
            endComponent: () => {
                
                const chartData = data?.new || data?.updated ?
                        [
                            {
                                id:0,
                                label:'New',
                                value: data?.new,
                                color: theme.palette.success.main
                            },
                            {
                                id:1,
                                label:'Updated',
                                value: data?.updated ,
                                color: theme.palette.warning.light
                            }
                        ]
                    : 
                        [
                            {
                                id:0,
                                label:'No patients updated/new',
                                value: data?.total,
                                color: theme.palette.grey[300]
                            }
                        ]

                return (
                    <Box height={84} width={84}>
                        <ResponsiveDoughtnutChart 
                            colors={{ datum: 'data.color' }} 
                            tooltip={obj => (
                                <CustomNivoTooltip  //replace with single stat option? or pass all of them in now
                                    stats={chartData}
                                />
                            )}
                            data={chartData}
                        />
                    </Box>
                )
            }
        },
        {
            title: 'Duplicates & errors detected',
            value: data?.potential_dupe_patients?.length + ' dupe(s) & ' + data?.number_of_errors + ' error(s)'
        },
    ]


    return(
        <>

        <Box className='flex-col' gap={2} p={2}>
                <Box className='flex-col-center' alignSelf='center'>
            <HaloSkeleton loading={isLoading}>
                    <>  
                        {
                            error?
                            <ErrorIcon color='error' style={{fontSize:32}}/>
                            :
                            <CheckCircle color="success" style={{fontSize:32}}/>

                        }
                        <Typography variant='h6'>{error? 'Error uploading' : 'Success!'}</Typography>
                    </>
            </HaloSkeleton>
                </Box>
            <Box className='flex-row' gap={1} height={96} >
                {
                    stats?.map(({title, value, endComponent}) => (
                        <TypographyCard 
                            key={title}
                            title={title}
                            value={value}
                            loading={isLoading}
                            endComponent={endComponent}
                        />  
                    ))
                }
            </Box>
            { 
                data?.potential_dupe_patients?.length > 0 && data?.number_of_errors > 0 ?
                    <Alert severity="warning">
                        <AlertTitle>Dupes & errors detected</AlertTitle>
                        <Typography variant="body2">
                            {data?.potential_dupe_patients?.length} duplicate patients were identified and were not uploaded.
                        </Typography>
                        <Typography variant="body2">
                        {data?.number_of_errors} errors across {data?.number_of_rows_with_errors} different rows from the CSV were not uploaded.
                        </Typography>
                    </Alert>
                : data?.potential_dupe_patients?.length > 0 && data?.number_of_errors === 0 ?
                    <Alert severity="warning">
                        <AlertTitle>Dupes detected</AlertTitle>
                        <Typography variant="body2">
                            {data?.potential_dupe_patients?.length} duplicate patients were identified and were not uploaded.
                            </Typography>
                    </Alert>
                : data?.potential_dupe_patients?.length === 0 && data?.number_of_errors > 0 ?
                    <Alert severity="warning">
                        <AlertTitle>Errors detected</AlertTitle>
                        <Typography variant="body2">
                            {data?.number_of_errors} errors across {data?.number_of_rows_with_errors} different rows from the CSV were not uploaded.
                        </Typography>
                    </Alert>
                :
                    null
            }
            <Box className='flex-col' sx={(theme) => ({...theme. standardBox, p:2, gap:1})}>
                <HaloSkeleton loading={isLoading}>
                    <Typography variant="subtitle1" color='text.action'>
                                {data?.new + data?.updated || 0 } patient(s) affected
                    </Typography>
                </HaloSkeleton>
                <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'space-between'}}>
                    {
                        data?.first_30_patients?.slice(0, 30)?.map((patient, indx) => {
                            
                            if (indx === 29 &&  data?.total > 30){
                                return (
                                    <Box sx={{width:'18%', p:0.5}} key={patient.id}>
                                        <Typography variant="body2" color='text.secondary'>+{ data?.total - 29} more</Typography>
                                    </Box>
                                )
                            }

                            return(
                                <Box sx={{width:'18%', p:0.5}} key={patient.id}>
                                    <Typography variant="body2" color='text.secondary'>{stringToTitleCase(patient?.name) }</Typography>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        </Box>

        <HaloModalStickyFooter>
            <Box className='flex-row-between' gap={1} p={2}>
                <ActionButton
                    variant='outlined'
                    onClick={handleClose}>
                    Close
                </ActionButton>
                <Box className='flex-row' gap={1}>
                    <ActionButton 
                        variant='outlined'
                        onClick={resetState}
                        >
                        Add another
                    </ActionButton>
                    {
                        type === 'Patient upload' &&

                        <>
                            <ActionButton
                                variant='contained'
                                onClick={() => setRecordId(data?.intake_record_id)}
                                disabled={isLoading}
                                >
                                Add patients to batch
                            </ActionButton>
                            <BulkPatientsToBatchModal 
                                recordId={recordId}
                                site={site}
                                handleClose={handleCloseAddToBatch}
                            />
                        </>
                    }
                </Box>
            </Box>  
        </HaloModalStickyFooter>   
        </>
    )


}