import HaloModal from "../../../../globalComponents/Modals/HaloModal"
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Checkbox, Skeleton, Divider } from "@mui/material"
import { ActionButton } from "../../../../globalComponents/Buttons/ActionButton/ActionButton"
import { enqueueSnackbar } from "notistack"
import { useTheme } from "@emotion/react"
import BaseSearchBar from "../../../../globalComponents/SearchBars/BaseSearchBar"
import SingleFilterChip from "../../../../globalComponents/Chips/DropDownChip/SingleFilterChip/SingleFilterChip"
import { useState, useEffect, useMemo } from "react"


const TableSkeletonLoader = () => (
    <Box>
        {[...Array(6)].map((el, index) => (
            <Box key={index}>
                <Box display="flex" alignItems="center" mb={1} ml={1}>
                    <Skeleton variant="square" width={24} height={24} sx={{ mr: 2 }} />
                    <Skeleton variant="text" width="80%" height={40} />
                </Box>
                <Divider />
            </Box>
        ))}
    </Box>
);

function ClinicianRosterSettingsModal({ open, handleClose, rowData, userIds, allSettingOptionsQuery, assignedSettingOptionsQuery, settingsMutation, title, settingType, name }) {

    const theme = useTheme()
    const [filter, setFilter] = useState('')
    const [search, setSearch] = useState('')
    const [allSettingOptions, setAllSettingOptions] = useState([])
    const [modifiedSettingOptions, setModifiedSettingOptions] = useState([])

    const { data: initialAssignedOptionsData, isLoading: isInitialAssignedLoading, isFetching: isInitialAssignedFetching } = assignedSettingOptionsQuery(userIds)
    const { data: allSettingOptionsData, isLoading: isAllOptionsLoading, isFetching: isAllOptionsFetching } = allSettingOptionsQuery()
    const [updateSetting, { isLoading: isUpdatingSetting }] = settingsMutation()

    const loading = isInitialAssignedLoading || isInitialAssignedFetching || isAllOptionsLoading || isAllOptionsFetching

    useEffect(() => {
        if (allSettingOptionsData && initialAssignedOptionsData) {
            const allSettingItemsWithStatus = allSettingOptionsData?.map((option) => {
                const assignedUsersCount = initialAssignedOptionsData?.find((assignedOption) => option.id === assignedOption.id)?.assigned_users_count || 0
                const status = getCheckboxStatus(assignedUsersCount, userIds.length)
                return {
                    id: option.id,
                    name: settingType === 'sites' ? option.site_name : option.name,
                    status,
                    initial_status: status
                }
            })
            setAllSettingOptions(allSettingItemsWithStatus)
            setModifiedSettingOptions([])
        }
    }, [allSettingOptionsData, initialAssignedOptionsData, userIds, settingType])

    const getCheckboxStatus = (assignedUsersCount, totalUsersSelected) => {
        if (assignedUsersCount === 0) return 'unchecked'
        if (assignedUsersCount === totalUsersSelected) return 'checked'
        return 'indeterminate'
    }

    const displayedSettingOptions = useMemo(() => {
        return allSettingOptions.filter(option => {
            const matchesFilter =
                (filter === 'checked' && (option.status === 'checked' || option.status === 'indeterminate' || option.wasChecked)) ||
                (filter === 'unchecked' && (option.status === 'unchecked' || option.wasUnchecked)) ||
                filter === '';
            const matchesSearch = option.name.toLowerCase().includes(search.toLowerCase());
            return matchesFilter && matchesSearch;
        });
    }, [allSettingOptions, filter, search]);

    const handleCheckboxChange = (optionId) => {
        setAllSettingOptions(prevOptions => prevOptions.map(option => {
            if (option.id === optionId) {
                const newStatus = option.status === 'checked' ? 'unchecked' : 'checked';
                return {
                    ...option,
                    status: newStatus,
                    wasChecked: option.status === 'checked' || option.status === 'indeterminate',
                    wasUnchecked: option.status === 'unchecked'
                };
            }
            return option;
        }));

        setModifiedSettingOptions(prev => {
            const updatedOption = allSettingOptions.find(p => p.id === optionId)
            const newStatus = updatedOption.status === 'checked' ? 'unchecked' : 'checked'
            const existingIndex = prev.findIndex(p => p.id === optionId)

            if (existingIndex !== -1) {
                if (newStatus === updatedOption.initial_status) {
                    return prev.filter(p => p.id !== optionId)
                }
                return prev.map(p => p.id === optionId ? { ...p, new_status: newStatus } : p)
            }
            return [...prev, { id: optionId, new_status: newStatus }]
        })
    }

    const modifyHandler = () => {
        updateSetting({
            modified_setting_options: modifiedSettingOptions,
            user_ids: userIds
        })
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res, { variant: 'success' })
                handleClose()
            })
    }

    return (
        <HaloModal open={Boolean(open)} title={title} handleClose={handleClose} >
            <Box>
                <Box className='flex-col' p={2} gap={2} >
                    <Typography variant='body2'>{`${title} for ${userIds.length === 1 ? name : `${userIds.length} clinicians`}: `}</Typography>
                    <Box className='flex-row-between'>
                        {settingType !== 'caseTypes' && (
                            <>
                                <SingleFilterChip
                                    chipLabel='Status'
                                    options={[{ label: 'Assigned', value: 'checked' }, { label: 'Unassigned', value: 'unchecked' }]}
                                    filterState={filter}
                                    setFilterState={setFilter}
                                />
                                <BaseSearchBar
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    alwaysShow={true}
                                    handleClear={() => setSearch('')}
                                />
                            </>
                        )}
                    </Box>
                    <TableContainer sx={{ height: '300px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px' }}>
                        {loading ? (
                            <TableSkeletonLoader />
                        ) : (
                            <Table>
                                <TableBody>
                                    {displayedSettingOptions.map((option) => (
                                        <TableRow
                                            key={option.id}
                                            sx={{
                                                '& .MuiTableCell-root': {
                                                    height: '52px',
                                                    padding: '4px 0px 4px 4px',
                                                    background: theme.palette.background.paper
                                                }
                                            }} >
                                            <TableCell align='left'>
                                                <Checkbox
                                                    checked={option.status === 'checked'}
                                                    indeterminate={option.status === 'indeterminate'}
                                                    onChange={() => handleCheckboxChange(option.id)}
                                                />
                                                {option.name}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </Box>
                <Box className='flex-row' gap={1} sx={{ justifyContent: 'flex-end', alignItems: 'center', p: '8px 16px 16px 16px' }}>
                    <ActionButton
                        sx={{ p: '6px 8px', color: `${theme.palette.text.primary}` }}
                        disabled={isUpdatingSetting}
                        onClick={handleClose}>
                        Cancel
                    </ActionButton>
                    <ActionButton
                        disabled={modifiedSettingOptions.length === 0}
                        loading={isUpdatingSetting}
                        variant='contained'
                        onClick={modifyHandler}
                    >
                        Update
                    </ActionButton>
                </Box>
            </Box>
        </HaloModal>
    )
}

export default ClinicianRosterSettingsModal