import { useManageResistanceGeneMutation } from "../../../../pathologyAPISlice"
import CustomTextFieldCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell";
import CRUDDataGrid from "../../../../../globalComponents/DataGrid/CRUDDataGrid";
import { GeneticsIcon } from "../../../../../../assets/icons/MaterialDesignIcons";
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
export default function ResistanceGenes({readOnly, resistanceGenes}) {


  const columns = [
      { field: 'gene',
        headerName: 'Gene',
        flex:1,
        editable: !readOnly,
        headerClassName: readOnly? '' : 'required-col',
        renderEditCell: (params) => (
          <CustomTextFieldCell {...params} />
        ),
      }
    ]
  

    

return (
    <CRUDDataGrid
      title={'Resistance Genes'}
      columns={columns} 
      initRows={resistanceGenes} 
      mutation={useManageResistanceGeneMutation}
      GridIcon={GeneticsIcon}
      fieldToFocus='gene'
      requiredFields={['gene']}
      isReadOnly={readOnly}/>
        
)

}


