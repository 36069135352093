export const stringToSentenceCase = (str) => {
    if (typeof str !== 'string') throw new Error("Input must be a string")

    if (str==='dnf') str = 'did not finish' // get rid of these man 
    else if (str==='no_action') str = 'no action needed' // get rid of these man 

    let words;

    if (str.includes('_')){
         words = str.toLowerCase().split('_')
    }
    else {
        words = str.toLowerCase().split(' ')

    }

    let formattedWords = []

    formattedWords.push(`${words[0][0]?.toUpperCase()}${words[0].slice(1)}`)

    for (let i=1;i<words.length;i++) {
        formattedWords.push(words[i])
    }

    return formattedWords.join(' ')
}
