import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";


//can be used anywhere but has been mainly used with the QuickXFields
export function LabeledBox({label, children, boxWidth,sx, ...otherProps}){

    const theme = useTheme();

    return (
        <Box    
            {...otherProps}
            className='flex-col'  
            width={boxWidth || '100%'} 
            sx={{
                ...sx,
                gap:0.5,
                '.MuiOutlinedInput-input':{
                    p:'0px 0px',             
                },
                '.MuiAutocomplete-root .MuiOutlinedInput-root ':{
                    p:'0px !important',
                    '& .MuiAutocomplete-input':{
                        p:'0px 0px 0px 0px !important'
                    }
                },
                '& .MuiOutlinedInput-root': {   
                    fontSize:'14px',
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent', //theme.palette.divider,
                      background:theme.palette.action.hover
                    },
                    '&:focus-within':{
                        pl:'4px !important',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.main,
                        background: 'transparent'
                    },
                  },
                'input': {
                    ...theme.typography.body2
                }  
            }}>
            <Typography sx={{ ...theme.typography.body3, color: theme.palette.text.disabled }}>
                {label}
            </Typography>
            {children}
        </Box>
    );
}