import { useState, useEffect } from 'react';


export function useRowCount(apiRef, initRowCount) {
    const [rowCount, setCount] = useState(initRowCount)

  useEffect(() => {
    const handleRowCnt = (count) => {
      setCount(count)
    };

    return apiRef.current.subscribeEvent('rowCountChange', handleRowCnt);
  }, [apiRef]);

  return rowCount;
}


