import CaseAccordion from "../../../../globalComponents/Accordions/CaseAccordion";
import SampleComponent from "./SampleComponent/SampleComponent";
import AddIcon from '@mui/icons-material/Add';
import DefaultNoRowsOverlay from "../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay";
import { useEffect, useState } from "react";
import SummaryContainer from "../../../../globalComponents/Containers/SummaryContainer";
import { useSelector } from "react-redux";
import { selectCurrentCase } from "../../../../rosterManagement/rosterSlice";
import { useManageSamplesMutation } from "../../../infectiousDiseaseAPISlice";
import { showGenericValidationError } from "../../../../../app/errors/genericErrors";
import { ActionButton } from "../../../../globalComponents/Buttons/ActionButton/ActionButton";
import QACommentsContainer from "../../../../qualityAssurance/components/QAFeedback/QACommentsContainer";



export default function Samples({ initSamples, sample_menu_options, readOnlyView, allowEdits, caseInfo, isLoading, qaObj }) {

    const encounterId = useSelector(selectCurrentCase).encounterId
    const [samplesState, setSamplesState] = useState(initSamples || [])
    const [manageSample, { isLoading: addingSample, isFetching: fetchingSample }] = useManageSamplesMutation()
    const { field_reviews } = qaObj || {}

    const addEmptyForm = () => {

        manageSample({
            method: 'POST',
            body: {
                encounter: encounterId
            }
        }).then(res => {
            setSamplesState(prev => [...prev, res.data])
        }).catch(err => {
            showGenericValidationError(err)
        })
    }


    useEffect(() => {
        setSamplesState(initSamples)
    }, [initSamples])


    const renderSamples = () => (
        <>
            {
                qaObj &&
                <QACommentsContainer fieldReview={qaObj?.field_reviews["Samples"]} />}
            {samplesState?.length > 0 ?
                samplesState?.map((sample, index) => (
                    <SampleComponent
                        key={sample.id}
                        samplesState={samplesState}
                        sample={sample}
                        sample_menu_options={sample_menu_options}
                        encounterId={encounterId}
                        setSamplesState={setSamplesState} />
                ))
                :
                <DefaultNoRowsOverlay message="No Samples" />}
        </>
    )

    const hasQAComments = field_reviews?.["Samples"]?.score_percentage < 100


    return readOnlyView ?
        (
            <SummaryContainer number={2} title={'Samples'} disableEdits={!allowEdits} caseInfo={caseInfo} isLoading={isLoading}>
                {renderSamples()}
            </SummaryContainer>
        )
        :
        (
            <CaseAccordion number={2} title={'Samples'} iconVariant={hasQAComments ? 'error' : null}>
                {renderSamples()}
                <ActionButton
                    loading={addingSample || fetchingSample}
                    startIcon={<AddIcon />}
                    sx={{ 'span': { mr: 0.5 }, width: 'fit-content', alignItems: 'flex-start' }}
                    onClick={addEmptyForm}
                >
                    Sample
                </ActionButton>
            </CaseAccordion>
        )
}
