import { Box, Divider, Tab } from "@mui/material";
import HaloTabs from "../../../../../globalComponents/Tabs/HaloTabs";
import { useState } from "react";
import HaloTabLabel from "../../../../../globalComponents/Tabs/TabLabels/HaloTabLabel";
import { TitleAndBodyBox } from "../../../../../globalComponents/TextGroups/TitleAndBodyBox";







export function Info({info}){

    const {site_phone, review_interval, auto_signoff, emr, masking_approved} = info || {}
    const [ tabValue, setTabValue ] = useState('General') 

    const handleTabChange = (e, value) => setTabValue(value) 

    return(
        <Box sx={(theme) => ({...theme.standardBox, flex:1})}>
            <HaloTabs value={tabValue} onChange={handleTabChange}>
                <Tab 
                    label={<HaloTabLabel label='General'/>}
                    value='General' />
                <Tab 
                    label={<HaloTabLabel label='Notes'/>}
                    value='Notes' />
            </HaloTabs>
            <Divider/>
            <Box className='flex-row' p={2} gap={3}>
                <Box className='flex-col' gap={2}>
                    <TitleAndBodyBox title='Complexity' body={'-'} />
                    <TitleAndBodyBox title='Phone' body={site_phone}/>
                    <TitleAndBodyBox title='Masking status' body={masking_approved? 'Enabled' : 'Disabled'} />
                </Box>
                <Box className='flex-col' gap={3}>
                    <TitleAndBodyBox title='EMR' body={emr?.name}/>
                    <TitleAndBodyBox title='Allowed review interval' body={review_interval} />
                </Box>
            </Box>
        </Box>
    )
}