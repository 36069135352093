import { useGridApiContext } from '@mui/x-data-grid-pro';
import OutlinedToolbarChip from '../../../../../globalComponents/Chips/OutlinedToolbarChip/OutlinedToolbarChip';
import { useCallback, useState } from 'react';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import StickyHeaderToolbar from '../../../../../globalComponents/DataGrid/subComponents/toolBars/StickyHeaderToolbar';
import UnassignDialog from '../../RosterAdminPanel/RosterGrids/Grids/RMCasesGrid/CaseMgmtToolbar/Dialogs/UnassignDialog';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ReopenDialog from '../../RosterAdminPanel/RosterGrids/Grids/RMCasesGrid/CaseMgmtToolbar/Dialogs/ReopenDialog';


export default function ClinicianCaseMgmtToolbar({
    isSelected,
    selected,
    setSelected,
}) {
    const apiRef = useGridApiContext()
    const [open, setOpen] = useState('')

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen('')
    }
    const handleOpen = (action) => (e) => setOpen(action)


    const showAction = useCallback(() => {
        const rowsMap = apiRef?.current?.getSelectedRows()
        for (let [key, { status }] of rowsMap) {
            if (status === 'assigned' || status === 'in_progress') {
                return 'unassign'
            }
            else if (status === 'completed' || status === 'pending_results') {
                return 'reopen'
            }
        }
        return false
    }, [apiRef])


    return (
        isSelected &&
        <>

            <StickyHeaderToolbar sx={{ height: 52 }}>
                {showAction() === 'reopen' &&
                    <OutlinedToolbarChip
                        onClick={handleOpen('Reopen')}
                        startIcon={<LockOpenIcon sx={{ height: 18, width: 18 }} />}>
                        Reopen
                    </OutlinedToolbarChip>}
                {
                    showAction() === 'unassign' &&
                    <OutlinedToolbarChip
                        onClick={handleOpen('Unassign')}
                        startIcon={<AssignmentReturnOutlinedIcon sx={{ height: 18, width: 18 }} />}
                    >
                        Unassign
                    </OutlinedToolbarChip>
                }
            </StickyHeaderToolbar>
            <UnassignDialog
                open={open === 'Unassign'}
                handleClose={handleClose}
                selected={selected}
                setSelected={setSelected}
            />
            <ReopenDialog
                open={open === 'Reopen'}
                handleClose={handleClose}
                selected={selected}
                setSelected={setSelected}
            />
        </>

    )
}