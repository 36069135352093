import { useTheme } from "@emotion/react"
import { Box, Collapse, Divider, Typography, Avatar } from "@mui/material"
import { SmallHeaderBox, TypographySubtitle } from "../../../globalComponents/SubHeader/components"
import BackButton from "../../../globalComponents/SubHeader/Backbutton"
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase"
import TrialPatientFrame from "./TrialPatientFrame"
import ChiefComplaintAndEHR from "./ChiefComplaintAndEHR"
import { SubHeaderLoader } from "../../../globalComponents/Loaders"
import { CaseHeaderLoader } from "../../../globalComponents/Loaders"
import { createRef, useRef, useState } from "react"
import CollapseHeader from "../../../globalComponents/Headers/HeaderFrames/CollapseHeader"
import { ProceedButton } from "../../../globalComponents/SubHeader/components"
import { useNavigate } from "react-router-dom";
import { useTutorial } from "../../utils/TutorialContext"


function TrialEncounterHeader({ trialPatient, loading, trialEncounterId, encounterType }) {

    const navigate = useNavigate()

    const theme = useTheme()
    const [show, setShow] = useState(true)
    const { refs } = useTutorial()
    const isInfecDis = encounterType.toLowerCase().includes('infectious')



    if (loading) {
        return (
            <>
                <SubHeaderLoader />
                <CaseHeaderLoader />
            </>
        )
    }

    return (
        <>
            <Box height={show ? 196 : 64} width={'100%'} sx={{ transition: 'height 0.3s ease' }}></Box>
            <Box
                sx={{
                    ...theme.stickyHeader,
                    borderBottom: `1px solid ${theme.palette.divider}`
                }}>

                <SmallHeaderBox>
                    <Box className='flex-row-center'>
                        <BackButton />
                        <TypographySubtitle>{stringToTitleCase(trialPatient?.name)}</TypographySubtitle>
                    </Box>
                    <div ref={!isInfecDis ? refs.current[4] : refs.current[3]}>
                        <ProceedButton
                            onClick={() => navigate(`/trial-users/encounter-summary/${encounterType}/${trialEncounterId}`)}
                            text='Proceed to summary' />
                    </div>
                </SmallHeaderBox>

                <CollapseHeader show={show} setShow={setShow}>
                    <Box className='flex-row' sx={{ p: 3, pt: 2, gap: 3, background: theme.palette.background.paper }}>
                        <div ref={refs.current[0]} style={{ width: '40%' }}>
                            <TrialPatientFrame trialPatient={trialPatient} encounterType={encounterType}
                            />
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <ChiefComplaintAndEHR
                            encounterType={encounterType}
                            name={trialPatient?.name.split(' ')[0]}
                            chiefComplaint={trialPatient?.chief_complaint}
                        />
                    </Box>
                </CollapseHeader>
            </Box>
        </>
    )
}

export default TrialEncounterHeader
