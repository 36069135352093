import { DateField } from "@mui/x-date-pickers";
import { useGridApiContext } from "@mui/x-data-grid-pro"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import dayjs from "dayjs";




export default function CustomDateFieldCell(props){
    const { 
        id,
        value: valueProp,
        hasFocus, 
        field,
        row
    } = props

    const [value, setValue] = useState(valueProp)
    const apiRef = useGridApiContext()
    const ref = useRef()
    const error = row?.errors?.includes(field)
    
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus()
        }
    }, [hasFocus])

    const handleValueChange = (newValue) =>{
        apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs:200})
        setValue(newValue)
    }


    return(
        <DateField 
            error={error}
            inputRef={ref}
            autoComplete={field}
            name={field}
            value={value? dayjs(value) : null}
            onChange={handleValueChange}
            sx={{
                width:'100%',
                height:'100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0px', 
                },
                '& fieldset': {
                    border: 'none',
                },
            }}
        />
    )

}