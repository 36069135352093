import CustomTextFieldCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell";
import CRUDDataGrid from "../../../../../globalComponents/DataGrid/CRUDDataGrid";
import { useManageTrialDrugInteractionsMutation } from "../../../../trialUserAPISlice";
import TextFieldPortalCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/TextFieldPortalCell";
import { DrugInteractionIcon } from "../../../../../../assets/icons/MaterialDesignIcons";

export default function TrialDrugInteractions({readOnly, drugInteractions}){

    const columns = [
        {
            field: 'drug1',
            headerName: 'Drugs',
            editable: !readOnly, 
            flex:1,
            headerClassName:'required-col',
            renderEditCell: (params) => (
                <CustomTextFieldCell {...params} />
        ),
        },
        {
            field: 'clinical_relevance', 
            headerName: 'Clinical Relevance',
            editable: !readOnly ,
            flex:1.5,
            headerClassName:'required-col',
            renderEditCell: (params) => (
                <TextFieldPortalCell {...params} />
            )
        },
    ]


    return(
    <CRUDDataGrid
        title={'Drug Interactions'}
        columns={columns} 
        initRows={drugInteractions} 
        mutation={useManageTrialDrugInteractionsMutation}
        GridIcon={DrugInteractionIcon}
        fieldToFocus='drug1'
        requiredFields={['drug1', 'clinical_relevance']}
        isReadOnly={readOnly}
        isTrialReview={true}
    />
    )
}
