
import {cardio} from 'ldrs'
import { useTheme } from '@emotion/react';
import { Box, Typography } from "@mui/material";






export function Loader(){


    const theme = useTheme()

    return(
        <Box height={300} className='flex-row-center' >
            <l-cardio
                size="200" 
                stroke="4" 
                speed="1.8" 
                color={theme.palette.grey[500]}
                /> 
        </Box>
    )


}