import { useCallback, useEffect, useState } from 'react';
import { debounce } from "lodash";
import BaseSearchBar from '../../../../SearchBars/BaseSearchBar';



//Intended to only be used with internal Grid state
export default function GridAPISearchBar({
  apiRef, 
  searchField,
  ...props
}){


  const [search, setSearch] = useState('')

  const handleChange = (e) => setSearch(e.target.value)
  const handleClear = (e) => setSearch('')

  const debouncedSearch = useCallback(
    debounce((value) => {
      apiRef?.current?.setFilterModel( { items: [{ field: searchField, operator: 'contains', value }] })
    }, 300),
    [apiRef]
  );

  useEffect(()=>{
    debouncedSearch(search)
  }, [search, debouncedSearch])

  useEffect(() => {

    if(apiRef) setSearch('')

  }, [apiRef])


  return(
      <BaseSearchBar 
        value={search}
        onChange={handleChange}
        handleClear={handleClear}
        {...props}
      />
      
  )
}