import { useParams } from "react-router-dom";
import { selecteRosterMgmtPreset, selectRosterMgmtDates } from "../../admin/adminSlice";
import BreadCrumbHeader from "../../globalComponents/Headers/BreadCrumbHeader";
import BackButton from "../../globalComponents/SubHeader/Backbutton";
import { useGetSiteAndSettingsQuery } from "../rosterAdminAPISlice";
import { Alert, Box } from "@mui/material";
import SiteInfoAndSettings from "../admin/components/RosterAdminSite/InfoAndSettings/SiteInfoAndSettings";
import RosterDateRangeButton from "../admin/components/RosterAdminPanel/RosterDateRangeButton/RosterDateRangeButton";







export default function RosterMgmtSite() {

    let { siteId } = useParams()
    const { data } = useGetSiteAndSettingsQuery(siteId)

    
    return(
        <>
            <BreadCrumbHeader 
                startCrumb={'Admin'}
                title={data?.info?.site_name}
                crumbs={['Site']}
                startComponent={<BackButton />}
                endComponent={ <RosterDateRangeButton dateSelector={selectRosterMgmtDates} presetSelector={selecteRosterMgmtPreset}/> }
            />
            <Box p={3} gap={1}>
                <Alert severity="warning">Beta - use legacy for user asgmts</Alert>
                <SiteInfoAndSettings data={data}/>
            </Box>
        </>
    )
}