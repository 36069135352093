import { logOut} from '../../features/auth/authSlice'
import { setScheduleConfirmation } from '../../features/rosterManagement/rosterSlice';
import { enqueueSnackbar } from 'notistack';


export const handleMacroExceptions = ({result, api}) => {
    const errStatus = result?.error?.status;
    const errMessage = result?.error?.data?.detail

    switch (errStatus) {
        case 401:
            enqueueSnackbar('Session expired: please re-login', { variant: 'error' });
            api.dispatch(logOut());
            break;
        case 403:
            enqueueSnackbar('Permission error: ' + errMessage, { variant: 'error' });
            break
        case 'FETCH_ERROR':
            enqueueSnackbar('Network error: please check your connection and refresh page', { variant: 'error' });
            break;
        case 'TIMEOUT_ERROR':
            enqueueSnackbar('Timeout error: please check your connection and try again', { variant: 'error' });
            break;
        case 500:
        case 'PARSING_ERROR':
            enqueueSnackbar('Server error: please try again later', { variant: 'error' });
            break;
    }

    if (result?.data?.hasOwnProperty('schedule_unconfirmed')) {
        let { end_date, start_date, point_goal_id } = result.data.schedule_unconfirmed;
        api.dispatch(setScheduleConfirmation({
            isConfirmed: false,
            startDate: start_date,
            endDate: end_date,
            ptGoalObjId: point_goal_id
        }));
    }
};