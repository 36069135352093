
import { TextField } from "@mui/material"
import { useController } from "react-hook-form"
import { IMaskInput } from 'react-imask';
import React from "react";
import { getNumberRule } from "./getNumberRule";
import { getMasking } from "./getMasking";




const MaskingComponent = React.forwardRef(function TextMaskCustom(props, ref) {
  const { 
    onChange,
    variant,
    maxValue, 
    minValue, 
    customMask, 
    suffix, 
    decimalPlace, 
    value,
    ...other 
  } = props;
  
  const { mask, definition } = getMasking({customMask, suffix, variant, maxValue, decimalPlace})

  return (
    <IMaskInput
      {...other}
      // value={value?.toString()}
      // leaving the above comment in so that we don't manually change a field into a string but rather do it in the...
      // ...default values:{} key in the useForm hook. If we chnage it here we will trigger isDirty when no chnages have been made to the input
      value={value}
      mask={mask}
      definitions={definition}
      inputRef={ref}
      min={minValue}
      max={maxValue}
      scale={decimalPlace}
      radix="."
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});
  

export function HaloNumberField({ 
  control, 
  name, 
  label='', 
  required, 
  size='small',
  variant='number', //variant should only be used for preset validation and masking rules
  maxValue=999,
  minValue=0,
  mask,
  decimalPlace=0,
  suffix,
  helperText,
  hideHelperText=false,
  InputProps,
  inputLabelProps,
  onBlur,
  rules = {},
  title,
  sx,
  ...otherProps
}) {

  const { field, formState: { errors }, } = useController({
    name,
    control,
    rules: { 
      required: required? 'This field is required' : false, 
      ...rules,
      ...getNumberRule(variant) //this shows error in UI
    },
  })  


    return(
        <TextField 
          size={size} //app wide default
          placeholder=' -'
          fullWidth
          label={label}
          onChange={field.onChange} 
          onBlur={onBlur || field.onBlur} 
          value={field.value } 
          name={field.name} 
          inputRef={field.ref}
          error={Boolean(errors[name])}
          required={required}
          helperText={hideHelperText? '' :helperText? helperText: errors[name]?.message}
          title={title? field.value : ''}
          InputProps={{
            inputComponent: MaskingComponent,
            inputProps: {
              variant,
              maxValue,
              minValue,
              customMask:mask,
              suffix,
              decimalPlace,
            },
            ...InputProps
          }}
          {...otherProps}
          InputLabelProps={{...inputLabelProps}}
          sx={{
            ...sx,
            "& .MuiInputBase-input": {
              textOverflow: "ellipsis"
            }
          }}
        />
    )

}
