import { useTheme } from "@emotion/react";
import { Box, Skeleton } from "@mui/material";














export default function PatientSummaryLoader(){

    const theme = useTheme()

    return(
        <Box className='flex-col' gap={1} sx={{...theme.standardBox}} height={'fit-content'} width={'100%'} p={2}>
            <Box>
                <Skeleton animation='wave' width={'100%'} height={68}/>
            </Box>
            <Box className='flex-row' gap={3}>
                <Skeleton animation='wave' width={'50%'} height={200}/>
                <Skeleton animation='wave' width={'50%'} height={200}/>
            </Box>
            <Skeleton animation='wave' width={'60%'} height={100}/>
            <Skeleton animation='wave' width={'50%'} height={50}/>
            <Skeleton animation='wave' width={'60%'} height={100}/>
            <Skeleton animation='wave' width={'50%'} height={50}/>
            <Skeleton animation='wave' width={'60%'} height={100}/>
            <Skeleton animation='wave' width={'50%'} height={50}/>
        </Box>
    )
}