import { useState } from "react"
import { useTheme } from "@emotion/react"
import { Box, Divider, Typography, Avatar } from "@mui/material"
import { SmallHeaderBox, TypographySubtitle } from "../../../globalComponents/SubHeader/components"
import BackButton from "../../../globalComponents/SubHeader/Backbutton"
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase"
import TrialPatientFrame from "./TrialPatientFrame"
import ChiefComplaintAndEHR from "./ChiefComplaintAndEHR"
import { SubHeaderLoader } from "../../../globalComponents/Loaders"
import { CaseHeaderLoader } from "../../../globalComponents/Loaders"
import { TitleAndBodyBox } from "../../../globalComponents/TextGroups/TitleAndBodyBox"
import CollapseHeader from "../../../globalComponents/Headers/HeaderFrames/CollapseHeader"
import { SubmissionStatusChip } from "../../utils/trialChips"

function TrialReviewHeader({ trialPatient, loading, score, trialUserName, feedbackSubmitted, encounterType }) {


    const theme = useTheme()
    const [show, setShow] = useState(false)

    if (loading) {
        return (
            <>
                <SubHeaderLoader />
                <CaseHeaderLoader />
            </>
        )
    }


    return (
        <>
            <Box height={show ? 261 : 128} width={'100%'} sx={{ transition: 'height 0.3s ease' }}></Box>
            <Box
                sx={{
                    ...theme.stickyHeader,
                }}>

                <SmallHeaderBox>
                    <Box className='flex-row-center'>
                        <BackButton />
                        <TypographySubtitle>{stringToTitleCase(trialPatient?.name)}</TypographySubtitle>
                    </Box>
                </SmallHeaderBox>

                <SmallHeaderBox justifyContent="flex-start" gap={3}>
                    <Box className='flex-row' gap={'12px'}>
                        <Avatar
                            sx={{
                                bgcolor: theme.palette.success.main,
                                height: 32,
                                width: 32
                            }}>
                            {trialUserName?.slice(0, 1).toUpperCase()}
                        </Avatar>
                        <TitleAndBodyBox title={'Trial clinician'} body={trialUserName} />
                    </Box>
                    <TitleAndBodyBox title={'Score'} body={`${score} / 100`} bodyColor={score > 65 ? theme.palette.success.main : theme.palette.error.main} />
                    <Box className='flex-col' gap={'4px'}>
                        <Typography variant="body2" sx={{ color: theme.palette.text.disabled }}>Feedback status</Typography>
                        <SubmissionStatusChip status={feedbackSubmitted} />
                    </Box>
                    <TitleAndBodyBox title={'Trial type'} body={encounterType}/>
                </SmallHeaderBox>
                <CollapseHeader show={show} setShow={setShow} >
                    <Box className='flex-row' sx={{ p: 3, pt: 2, gap: 3, background: theme.palette.background.paper, borderBottom:1, borderColor:'divider' }}>
                        <TrialPatientFrame trialPatient={trialPatient} encounterType={encounterType} />
                        <Divider orientation="vertical" flexItem />
                        <ChiefComplaintAndEHR
                            name={trialPatient?.name.split(' ')[0]}
                            chiefComplaint={trialPatient?.chief_complaint}
                            encounterType={encounterType}
                        />
                    </Box>
                </CollapseHeader>

            </Box>
        </>
    )
}

export default TrialReviewHeader
