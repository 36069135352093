import { createSlice } from "@reduxjs/toolkit"

const pathologySlice = createSlice({
    name: 'pathology',
    initialState: { 
        activeTab: 'Completed', 
        staticMeds: [],
        pathologyListFilters:  {
            pageNumber:1,
            firstName:'',
            lastName:'',
            site:{site_name: '', id:''},
            dateOfBirth:'',
            currentPathologyClinician:'',
            lastPathologyClinican:'',
            lastReviewDate:''
        }
    },

    reducers: {
        setActiveTab: (state, action) => {
            const { tab } = action.payload
            state.activeTab = tab
        },
        setFindingMetrics: (state, action) => {
            state.currentReview.metrics = action.payload
        },
        setPatientVitals: (state,action) =>{
            state.currentReview.patientVitals = action.payload
        },
        //Pathology List
        setPathologyListFilters: (state, action) =>{
            state.pathologyListFilters = {
                ...state.pathologyListFilters, 
                ...action.payload, 
            };
        },
        setStaticMeds: (state, {payload}) => {
            state.staticMeds = payload
        },
        manageStaticMeds: (state, {payload}) => {

            if(payload.method === 'POST'){ 
                state.staticMeds.push(payload.data)
            }else if(payload.method === 'PUT'){
                const indx = state.staticMeds.findIndex(med => med.id === payload.data.id);
                state.staticMeds[indx] = payload.data
            }else if(payload.method === 'DELETE'){
                state.staticMeds = state.staticMeds.filter(med => !payload.body.ids.includes(med.id))
            }
        }

    },
})
// what we dispatch in component
export const { 
    setActiveTab, 
    setFindingMetrics, 
    setPatientVitals,
    setPathologyListFilters,
    setStaticMeds,
    manageStaticMeds
                    } = pathologySlice.actions


export const selectActiveTab = (state) => state.pathology.activeTab

export const selectMetrics = (state) => state.pathology.currentReview?.metrics
export const selectPatientVitals = (state) => state.pathology?.currentReview?.patientVitals
export const selectLabs = (state) => state.pathology.currentReview?.labs
export const selectPastReviews = (state) => state.pathology.currentReview?.pastReviews
export const selectPathologyListFilters = (state) => state.pathology.pathologyListFilters
export const selectStaticMeds = (state) => state.pathology.staticMeds

export default pathologySlice.reducer;
