import { Autocomplete, TextField } from "@mui/material"
import { useState } from "react";
import { useController } from "react-hook-form";



export function HaloAutocomplete({ 
  control, 
  name, 
  label, 
  required, 
  fitOptions=false,
  onBlur,
  options, // [ val, val, val ] format
  slotProps={},
  title,
  inputLabelProps,
  autoCompleteSXProps,
  ...otherProps
 }) {
    const {
      field,
      formState: {  errors },
    } = useController({
      name,
      control,
      rules: { required: required? 'This field is required' : false },
    })

    const [inputValue, setInputValue] = useState('')
    if(fitOptions){
      slotProps = {
        ...slotProps,
        popper:{
          style:{maxWidth:'fit-content'},
          placement: 'bottom-start'
        }
        
      }
    }

      return(
        <Autocomplete
            fullWidth
            openOnFocus
            autoComplete
            value={field.value || null}
            onChange={(event, newValue) => {
              field.onChange(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            slotProps={{
              ...slotProps
            }}
            options={options || []}
            onBlur={onBlur || field.onBlur}
            isOptionEqualToValue={(option, val) => option === val || option === null || option === ""}
            title={title? field.value : ''}
            sx={{
              ...autoCompleteSXProps
            }}
            renderInput={(params) => (
              <TextField 
                {...params} 
                required={required} 
                label={label} 
                size="small" 
                error={Boolean(errors[name])}
                helperText={errors[name]?.message}
                InputLabelProps={{...inputLabelProps}}
                />
            )}
            {...otherProps}
        />
      )
  
  }
  
  