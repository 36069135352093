
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material"
import { useController } from "react-hook-form"


export function HaloRadioGroup({ 
  control, 
  name, 
  label, 
  required, 
  options=[
    {value:'', label:''}
  ],
  row=false
}) {
  const {
    field,
    formState: {  errors },
  } = useController({
    name,
    control,
    rules: { required: required? 'This field is required' : false },
  })

    return(
        <FormControl fullWidth sx={row? {
          display:'flex',
          flexDirection:'row',
          gap:3,
          alignItems:'center'
        } : null}>
            <FormLabel>{label}</FormLabel>
            <RadioGroup
                row
                value={field.value} 
                name={field.name} 
                onChange={field.onChange}
                >
                {
                    options?.map((option) => (
                        <FormControlLabel key={option?.value} value={option?.value} control={<Radio size="small"/>} label={option?.label} />
                    ))
                }
            </RadioGroup>
      </FormControl>
    )

}

