import { useManageTrialResistanceGenesMutation } from "../../../../trialUserAPISlice";
import CustomTextFieldCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell";
import CRUDDataGrid from "../../../../../globalComponents/DataGrid/CRUDDataGrid";
import { GeneticsIcon } from "../../../../../../assets/icons/MaterialDesignIcons";



export default function ResistanceGenes({readOnly, resistanceGenes}) {

    const columns = [
        { field: 'gene',
            headerName: 'Gene',
            flex:1,
            editable: !readOnly,
            headerClassName:'required-col',
            renderEditCell: (params) => (
                <CustomTextFieldCell {...params} />
            ),
        }
    ]

return (
    <CRUDDataGrid
        title={'Resistance Genes'}
        columns={columns} 
        initRows={resistanceGenes} 
        mutation={useManageTrialResistanceGenesMutation}
        GridIcon={GeneticsIcon}
        fieldToFocus='gene'
        requiredFields={['gene']}
        isReadOnly={readOnly}
        isTrialReview={true}
    />
)}
