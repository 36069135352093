import { SvgIcon } from "@mui/material";

export const AddFileIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 30 33">
      <g clipPath="url(#clip0_3849_71352)">
        <path d="M29.375 12.125H20C19.75 12.125 19.525 12.275 19.425 12.5063L17.7125 16.5H0.625C0.28125 16.5 0 16.7812 0 17.125V32.125C0 32.4688 0.28125 32.75 0.625 32.75H29.375C29.7188 32.75 30 32.4688 30 32.125V12.75C30 12.4062 29.7188 12.125 29.375 12.125ZM12.5 25.875V24.625H15.625V21.5H16.875V24.625H20V25.875H16.875V29H15.625V25.875H12.5Z" fill={props.color || "black"} />
        <path d="M7.5 1.5H26.875V10.875H28.125V0.875C28.125 0.53125 27.8438 0.25 27.5 0.25H6.875C6.53125 0.25 6.25 0.53125 6.25 0.875V15.25H7.5V1.5Z" fill={props.color || "black"} />
        <path d="M3.75 2.75H5V1.5H3.125C2.78125 1.5 2.5 1.78125 2.5 2.125V15.25H3.75V2.75Z" fill={props.color || "black"} />
      </g>
  </SvgIcon>
)