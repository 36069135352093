import { useLocation, useNavigate } from "react-router-dom";
import { List } from "@mui/material";
import UserSection from "./UserSection/UserSection";
import  NavListItem  from "./NavListItem/NavListItem";
import { useCallback, useEffect, useState } from "react";
import { usePrefetchEndpoint } from "../../../../utils/apiUtils/usePrefetchEndpoint";
import { useGetNavTabs } from "./useGetNavTabs";



const getPathState = (pathname) => {
  const segments = pathname.split('/');
  return '/' + segments[1];
};


export default function SideBarNavigation({ open, handleDarkMode }) {
  const location = useLocation();
  const navigate = useNavigate()
  const prefetch = usePrefetchEndpoint()
  const tabs = useGetNavTabs()
  const [currentTab, setCurrentTab] = useState(false);

  const handleNav = useCallback((link) =>{
    navigate(link)
  }, [navigate])

  const handlePrefetch = useCallback(({endpoint, params}) =>{
      prefetch(endpoint, params)
  },[prefetch])

  useEffect(() => {

    let tab = getPathState(location.pathname)
    
    if (tab === '/trial-users')  {
      tab = location.pathname
    }

    setCurrentTab(tab)

  },[location])

  return (
    <>
    <List 
      className="flex-col" 
      sx={{height:'100%', p:1, pt:0}}>

      {tabs.map((tab) =>
          <NavListItem 
            key={tab.title} 
            label={tab.title} 
            link={tab.link} 
            open={open} 
            handleNav={handleNav}
            isSelected={tab.link === currentTab}
            icon={tab.icon}
            prefetch={tab?.prefetch}
            handlePrefetch={handlePrefetch}
            >
          </NavListItem>
        )}

      <UserSection open={open} handleDarkMode={handleDarkMode}/>
      
    </List>

    
    </>
  );
}
