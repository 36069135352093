import { Button, Typography } from "@mui/material"





const OutlinedToolbarChip = ({onClick=null, children, ...props}) => (
    <Button
        {...props}
        onClick={onClick}
        size='small'
        variant='outlined'
        sx={{borderRadius:'15px'}}
        >
            <Typography variant="body2">
                {children}
            </Typography> 
    </Button>
)

export default OutlinedToolbarChip