import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import DataInput from "./DataInput/DataInput";
import Analysis from "./Analysis/Analysis";
import Recommendations from "./Recommendations/Recommendations";





export default function PathologySummaryContainer({ data, isLoading, allowEdits }) {

    const {caseType, caseId} = useParams()
    
    const { encounter, metrics } = data || {};
    const { patient, lab_values, imaging_values, resistance_genes, drug_interactions } = encounter || {};
    const { medications, allergies, icd10_references } = patient || {};
    const caseInfo = { 
        case_type: caseType,
        case_id: caseId
    }


    return (
        <>  
            <Box p={3} className='flex-col' gap={3}>
                <DataInput
                    meds={medications}
                    allergies={allergies}
                    diagnoses={icd10_references}
                    readOnlyView={true}
                    allowEdits={allowEdits}
                    caseInfo={caseInfo}
                    isLoading={isLoading}
                />
                <Analysis
                    labValues={lab_values}
                    resistanceGenes={resistance_genes}
                    imagingValues={imaging_values}
                    drugInteractions={drug_interactions}
                    readOnlyView={true}
                    allowEdits={allowEdits}
                    caseInfo={caseInfo}
                    isLoading={isLoading}
                />
                <Recommendations
                    meds={medications}
                    metrics={metrics}
                    encounter={encounter}
                    readOnlyView={true}
                    allowEdits={allowEdits}
                    caseInfo={caseInfo}
                    isLoading={isLoading}
                />
            </Box>
        </>
    );
}