import Medications from './Medications/Medications';
import Allergies from './Allergies/Allergies'
import ResistanceGenes from './ResistanceGenes/ResistanceGenes'
import Diagnoses from './Diagnoses/Diagnoses'
import CaseAccordion from '../../../../globalComponents/Accordions/CaseAccordion';
import SummaryContainer from '../../../../globalComponents/Containers/SummaryContainer'
import { memo } from 'react';
import { Box } from '@mui/material';

function DataInput(props){
    const {meds,  allergies, diagnoses, readOnlyView, allowEdits, caseInfo, isLoading} = props

    const content = [
        <Box className='flex-row'gap={2} key={'meds-and-diags'}> 
                <Medications meds={meds} key={'meds'} readOnly={readOnlyView}/>
                <Diagnoses diagnoses={diagnoses} key={'diags'} readOnly={readOnlyView}/>
        </Box>,
        <Allergies allergies={allergies} key={'allergies'} readOnly={readOnlyView}/>, 
       
    ]
                    

    return readOnlyView ? (
        <SummaryContainer number={1} title={'Data Input'} disableEdits={!allowEdits} caseInfo={caseInfo} isLoading={isLoading}>
            {content}
        </SummaryContainer>
    ) : (
        <CaseAccordion number={1} title={'Data Input'}>
            {content}
        </CaseAccordion>
    );
}


export default memo(DataInput)