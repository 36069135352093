import { Box, Divider, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton";
import { HaloTextField } from "../../../globalComponents/ReactHookFormFields/TextFields/HaloTextField";
import { HaloNumberField } from "../../../globalComponents/ReactHookFormFields";
import HaloCheckbox from "../../../globalComponents/ReactHookFormFields/CheckBox/HaloCheckbox";
import { useEffect } from "react";
import HaloSkeleton from "../../../globalComponents/Skeletons/HaloSkeleton";
import { useFinalizeTrialEncounterMutation } from "../../trialUserAPISlice";
import { show400Error } from "../../../../app/errors/genericErrors";
import EmptyContentAlert from "../EmptyContentAlert/EmptyContentAlert";
import useCountDown from "../../../globalComponents/hooks/useCountDown";


export default function TrialInfecDisFinalizeContainer({ setSubmitted, treatingProvider, isLoading, data }) {

    const theme = useTheme()
    const { encounterType, trialEncounterId } = useParams()
    const [finalizeReview, { isLoading: isSubmitting }] = useFinalizeTrialEncounterMutation()
    const progress = useCountDown(5)

    const { trial_encounter } = data || {}

    const checkEmptyContent = (trial_encounter, data) => {
        const requiredFields = [
            { field: 'diagnostic_recommendation', label: 'Diagnostic Recommendation' },
            { field: 'treatment_recommendation', label: 'Treatment Recommendation' }
        ]
    
        return requiredFields
            .filter(({ field }) => !trial_encounter?.[field] && data)
            .map(({ label }) => label)
    }

    const emptyContentArray = checkEmptyContent(trial_encounter, data)
    const isContentEmpty = emptyContentArray.length > 0

    const disabled = isSubmitting || (progress > 0 && isContentEmpty)


    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            time_spent: null,
            treating_provider: treatingProvider || '',
            checked: false
        }
    })

    const submitCase = (values) => {
        finalizeReview({
            trialEncounterId,
            encounterType,
            payload: {
                time_spent: values.time_spent,
                treating_provider: values.treating_provider,
            }
        }).unwrap()
            .then(res => {
                setSubmitted(true) //show success
            })
            .catch(err => show400Error(err))
    }


    useEffect(() => {
        reset({
            treating_provider: treatingProvider,
            checked: false
        })
    }, [treatingProvider, reset])


    return (
        <Box sx={{ ...theme.shadowedBox }}>
            {/* Header */}
            <Box className='flex-row' alignItems={'center'} gap={2} p={'22px 16px'}>
                <HaloSkeleton loading={isLoading}>
                    <CheckCircleOutlineIcon sx={{ color: '#00B295', height: 32, width: 32 }} />
                </HaloSkeleton>
                <HaloSkeleton loading={isLoading}>
                    <Typography sx={{ ...theme.typography.subtitle1, fontWeight: 600 }}>Submit</Typography>
                </HaloSkeleton>
            </Box>
            {/* Form */}
            <Box
                sx={{ p: '0px 16px 24px 16px' }}
                className='flex-col'
                gap={3}
                component={'form'}
                noValidate
                onSubmit={handleSubmit(submitCase)}>
                {isContentEmpty && <EmptyContentAlert progress={progress} emptyContent={emptyContentArray}/>}
                <Box sx={{ ...theme.standardBox }}>
                    <Box p={'8px 16px'}>
                        <HaloSkeleton loading={isLoading}>
                            <Typography sx={{ ...theme.typography.body1, }}>Confirmation</Typography>
                        </HaloSkeleton>
                    </Box>
                    <Divider />
                    {/*Form Fields */}
                    <Box className='flex-col' p={'16px'} gap={2}>
                        <HaloSkeleton loading={isLoading} width={'100%'}>
                            <HaloNumberField
                                name='time_spent'
                                label='Time spent'
                                control={control}
                                required
                                disabled={disabled}
                                helperText="Enter estimated time (min) spent on case."
                            />
                        </HaloSkeleton>
                        <HaloSkeleton loading={isLoading} width={'100%'}>
                            <HaloTextField
                                name='treating_provider'
                                label='Treating provider'
                                control={control}
                                required
                                disabled={disabled}
                                helperText="Please confirm the patient's treating provider is correct."
                            />
                        </HaloSkeleton>
                        <HaloSkeleton loading={isLoading} >
                            <HaloCheckbox
                                name='checked'
                                label="I confirm that I have reviewed the relevant documentation and acknowledge that past this point, I will no longer be able to edit this review."
                                control={control}
                                required
                                disabled={disabled}
                            />
                        </HaloSkeleton>
                    </Box>
                </Box>
                <HaloSkeleton loading={isLoading}>
                    <ActionButton
                        type='submit'
                        disabled={disabled}
                        variant='contained'
                        color='success'
                        startIcon={<CheckIcon />}
                        sx={{
                            width: 200,
                        }}>
                        Submit
                    </ActionButton>
                </HaloSkeleton>
            </Box>

        </Box>

    )
}
