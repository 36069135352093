import { Box } from "@mui/material";
import { SmallHeaderBox, ProceedButton } from "../../../globalComponents/SubHeader/components";
import BackButton from "../../../globalComponents/SubHeader/Backbutton";
import { useNavigate } from "react-router-dom";
import CaseReviewTimer from "./CaseReviewTimer";




export default function CaseReviewNav({ caseType, caseId, totalSessionDurationInSeconds, isCaseOwner }) {


  const navigate = useNavigate()


  return (
    <SmallHeaderBox>
      <Box className='flex-row' gap={1}>
        <BackButton />
        <CaseReviewTimer
          caseId={caseId}
          totalSessionDurationInSeconds={totalSessionDurationInSeconds}
          isCaseOwner={isCaseOwner}
        />
      </Box>
      <ProceedButton
        onClick={() => navigate('/caseload/case-summary/' + caseType + '/' + caseId)}
        text='Proceed to summary' />
    </SmallHeaderBox>
  )
}
