
import { useTheme } from '@emotion/react';
import { Box, Collapse } from '@mui/material';
import HeaderCollapseBtn from '../../../patients/components/PatientHeader/HeaderCollpaseBtn';

export default function CollapseHeader ({children, show, setShow}) {
    const theme = useTheme()

    const toggleShow = () => {
        setShow((show) => !show)
    }

    return (
        <>
            <Box sx={{
                background: theme.palette.background.paper,
            }}>
                <Collapse
                    timeout="auto"
                    in={show} >
                    {children}
                </Collapse>
                <HeaderCollapseBtn show={show}  toggleShow={toggleShow} />

            </Box>

        </>
    )
}
