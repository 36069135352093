import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"


import TrialPathologyReviewContainer from "../components/TrialPathologyReview/TrialPathologyReviewContainer"
import { useEffect, useRef, useState } from "react"
import { Box, Skeleton, Typography } from "@mui/material"
import { AccordionLoader, CaseHeaderLoader, SubHeaderLoader } from "../../globalComponents/Loaders"
import { setTrialStaticMeds } from "../trialUserSlice"
import { useGetTrialEncounterQuery, useGetTutorialStatusQuery, useSetTutorialStatusMutation } from "../trialUserAPISlice"
import TrialEncounterHeader from "../components/Header/TrialEncounterHeader"
import { setCurrentTrialEncounter } from "../trialUserSlice"
import TrialTutorialPopover from "../components/Tutorial/TrialTutorialPopover"
import TrialInfecDisReviewContainer from "../components/TrialInfectiousDiseaseReview/TrialInfecDisReviewContainer"
import TrialTutorialModal from "../components/Tutorial/TrialTutorialModal"
import { useTutorial } from "../utils/TutorialContext"

const pathReviewTutorialSteps = [
    "Review the patient's vitals and refer to them during the pathology review to ensure accuracy and a comprehensive understanding.",
    "Review the patient's chief complaint and EHR to gain insights.",
    "Start by reviewing the pre-filled medications list. Note that YouScript and TextBlaze are company-wide tools for analysis, but not available for this test case.",
    "Here, you'll conduct the bulk of your analysis",
    "Ensure every section is thoroughly completed to the best of your ability. Remember, this is based on a real-world scenario — approach it as if you're directly contributing to a patient's optimal preventive care. When you are satisfied with the quality of your review, click here to continue."
]

const infecDisReviewTutorialSteps = [
    "Review the patient's information and refer to them during the review to ensure accuracy and a comprehensive understanding.",
    "Review the patient's EHR to gain insights.",
    "Here, you'll conduct the bulk of your analysis",
    "Ensure every section is thoroughly completed to the best of your ability. Remember, this is based on a real-world scenario — approach it as if you're directly contributing to a patient's optimal preventive care. When you are satisfied with the quality of your review, click here to continue."
]


function TrialEncounterReview() {

    let { encounterType, trialEncounterId } = useParams()
    const dispatch = useDispatch()
    const { data, isLoading, isFetching } = useGetTrialEncounterQuery({ encounterType, trialEncounterId }, { refetchOnMountOrArgChange: true })

    const { trial_encounter } = data || {}
    const { patient } = trial_encounter || {}
    const isInfecDisCase = encounterType.toLowerCase().includes('infectious');

    useEffect(() => {
        if (data && trialEncounterId) {
            const patient = trial_encounter?.patient
            dispatch(setCurrentTrialEncounter({
                patient: {
                    id: patient?.id,
                    name: patient?.name
                },
                encounterId: trialEncounterId,
            }))
        }
    }, [data, trialEncounterId, dispatch])

    useEffect(() => {
        if (data) dispatch(setTrialStaticMeds(data?.trial_encounter?.patient?.medications))
    }, [data?.trial_encounter?.patient?.medications])

    //tutorial related query and state
    const { data: tutorialStatus } = useGetTutorialStatusQuery()
    const [setTutorialStatus] = useSetTutorialStatusMutation()
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false)
    const [isReviewTutorialPopoverOpen, setIsReviewTutorialPopoverOpen] = useState(false)

    const openTutorialPopoverHandler = () => {
        setIsReviewModalOpen(false)
        setIsReviewTutorialPopoverOpen(true)
    }

    const closeTutorialModalHandler = () => setIsReviewModalOpen(false)
    const closeTutorialPopoverHandler = () => setIsReviewTutorialPopoverOpen(false)

    useEffect(() => {
        if (tutorialStatus) {
            setIsReviewModalOpen(!tutorialStatus?.is_review_tutorial_completed)
        }
    }, [tutorialStatus, setIsReviewModalOpen])

    const { refs } = useTutorial()


    if (isLoading || isFetching) {
        return (
            <>
                <SubHeaderLoader />
                <CaseHeaderLoader />
                <Box className='flex-col' p={3} gap={1}>
                    <Skeleton width={150} sx={{ mb: 3 }} variant={'rounded'} />
                    <AccordionLoader />
                    <AccordionLoader />
                    <AccordionLoader />
                </Box>
            </>
        )
    }


    return (
        <>
            <TrialTutorialModal
                open={isReviewModalOpen}
                onClose={closeTutorialModalHandler}
                onOpenPopover={openTutorialPopoverHandler}
            />
            <TrialTutorialPopover
                open={isReviewTutorialPopoverOpen}
                onClose={closeTutorialPopoverHandler}
                optionalAPICallback={() => setTutorialStatus({ tutorial_category: 'trial-review' })}
                stepsArray={isInfecDisCase ? infecDisReviewTutorialSteps : pathReviewTutorialSteps}
            />
            <TrialEncounterHeader
                trialPatient={patient}
                trialEncounterId={trialEncounterId}
                encounterType={encounterType}
            />
            <Box p={3} >
                <Typography variant="h6" mb={3}>
                    {isInfecDisCase ? 'Infectious disease case' : 'Pathology case'}
                </Typography>
                {
                    isInfecDisCase ?
                        <TrialInfecDisReviewContainer data={data} />
                        :
                        <TrialPathologyReviewContainer data={data} />
                }
            </Box>

        </>
    )
}

export default TrialEncounterReview
