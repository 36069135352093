import { useDispatch } from "react-redux"
import { useParams} from "react-router-dom"
import { useGetCaseQuery} from "../rosterAPISlice"

import PathologyReviewContainer from "../../pathology/components/PathologyReview/PathologyReviewContainer"
import { useEffect } from "react"
import { setCurrentCase } from "../rosterSlice"
import { Box, Skeleton, Typography } from "@mui/material"
import { AccordionLoader, CaseHeaderLoader, SubHeaderLoader } from "../../globalComponents/Loaders"
import MainQAFeedbackContainer from "../../qualityAssurance/components/QAFeedback/MainQAFeedbackContainer"
import InfectiousDiseaseReviewContainer from "../../infectiousDisease/components/infectiousDiseaseReview/InfectiousDiseaseReviewContainer"
import CaseReviewNav from "../components/CaseNavHeaders/CaseReviewNav"
import PatientHeader from '../../patients/components/PatientHeader/PatientHeader'
import { useUpdateCaseStatus } from "../hooks/useUpdateCaseStatus"
import { setStaticMeds } from "../../pathology/pathologySlice"
import { calculateAge } from "../../../utils/dateUtils/calculateAge"
import dayjs from "dayjs"
import MDFeedbackContainer from "../../MDReview/components/MDFeedback/MDFeebackContainer"
import GenomicsReviewContainer from "../../genomics/components/GeneomicsReview/GenomicsReviewContainer"



export default function CaseReview(){

    let {caseType, caseId} = useParams()
    const dispatch = useDispatch()
    const updateCaseStatus = useUpdateCaseStatus()
    const {data, isLoading, isFetching} = useGetCaseQuery(caseId, { refetchOnMountOrArgChange: true })
    const {qa_obj, encounter, paginated_encounter_history, paginated_lab_history, total_session_duration_in_seconds, is_case_owner, md_review} = data || {}
    const {patient} = encounter || {}
    const isUnder18 = calculateAge(dayjs(patient?.date_of_birth)) < 18

    useEffect(() =>{
        if(data && caseId){
            const patient = data?.encounter?.patient
            dispatch(setCurrentCase({
                patient: patient,
                encounterId: data?.encounter?.id,
                caseId
            }))        
            updateCaseStatus(caseId, patient?.id, 'in_progress')
        }
    },[data, caseId, dispatch, updateCaseStatus])

    useEffect(() => {
        //seperate useEffect so meds aren't reset when finding metrics edit the cache
        if(data) dispatch( setStaticMeds(data?.encounter?.patient?.medications) ) 
    },[data?.encounter?.patient?.medications])

    if(isLoading || isFetching) {
        return(
            <>
            <SubHeaderLoader />
            <CaseHeaderLoader />
            <Box className='flex-col' p={3} gap={1}>
                <Skeleton  width={150} sx={{mb:3}} variant={'rounded'}/>
                <AccordionLoader />
                <AccordionLoader />
                <AccordionLoader />
            </Box>
            </>
        )
    }   

    return(
        <>

        <PatientHeader 
            patient={patient} 
            paginated_lab_history={paginated_lab_history}
            paginated_encounter_history={paginated_encounter_history}>
            {/* This needs to be embeded to also be a sticky header */}
            <CaseReviewNav 
                caseType={caseType} 
                caseId={caseId}                     
                totalSessionDurationInSeconds = {total_session_duration_in_seconds}
                isCaseOwner={is_case_owner}
                />
        </PatientHeader>
            

        <Box p={3} >
                <Typography variant="h6" mb={3}>
                    {caseType + ' case'}
                </Typography>
            {
                md_review?.feedback &&
                    <MDFeedbackContainer review={md_review} containerSx={{mb:3}}/>
            }
            {
                qa_obj?
                    <MainQAFeedbackContainer qaObj={qa_obj} containerSx={{mb:3}}/>
                    :
                    null
            }

            {
                caseType.toLocaleLowerCase().includes('infectious disease') ?
                    <InfectiousDiseaseReviewContainer data={data} isUnder18={isUnder18}/>
                    :
                caseType.toLocaleLowerCase().includes('pathology') ?
                    <PathologyReviewContainer data={data} isUnder18={isUnder18}/>
                    :
                caseType.toLocaleLowerCase().includes('gx') ?
                    <GenomicsReviewContainer data={data} isUnder18={isUnder18}/>
                    : 
                null
            }
        </Box>
        </>
    )


}
