export const formatDateTimeField = (dateTimeField) => {
    const dateObj = new Date(dateTimeField)
    const formattedDateAndTime = dateObj.toLocaleString('en-us', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    }).replace(',', '')

    return formattedDateAndTime
}

export const formatDateField = (dateTimeField) => {
    const dateObj = new Date(dateTimeField)
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = dateObj.toLocaleDateString('en-US', options);
    return formattedDate
}
