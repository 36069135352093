import { Avatar, Typography } from "@mui/material";







export default function UserAvatar({
    firstName,  
    imgUrl=false,
    typographyVariant=false,
    ...otherProps
}){

    if (imgUrl){
        return(
            <Avatar 
                src={imgUrl}
                {...otherProps}
            />
        )
    }
        
    

    return(
        <Avatar variant="circular"  {...otherProps}>
            {
                typographyVariant ? 
                
                <Typography variant={typographyVariant}>
                    {firstName?.slice(0, 1)?.toUpperCase()}
                </Typography>

                :
                firstName?.slice(0, 1)?.toUpperCase()
            }
        </Avatar>
    )




}