import { useDispatch } from 'react-redux';
import { apiSlice } from '../../../app/api/apiSlice';
import dayjs from 'dayjs';


//helper func
function updateCachedCaseStatus (draft, caseId, newStatus) {
    const index = draft?.assignments.findIndex(assignment => assignment.case === parseInt(caseId));
    if (index !== -1) {
        draft.assignments[index].case_resolution_status = newStatus;
    }
};

const today = dayjs().format('YYYY-MM-DD')

export const useUpdateCaseStatus = () => {
    const dispatch = useDispatch();

    const updateCaseStatus = (caseId, patientId, newStatus) => {
        
        dispatch(apiSlice.util.updateQueryData('getPatientProfile', patientId?.toString(), (draft) => {
            
            updateCachedCaseStatus(draft, caseId, newStatus);
        }));

        dispatch(apiSlice.util.updateQueryData('getCaseload', today, (draft) => {
            updateCachedCaseStatus(draft, caseId, newStatus);
        }));

        dispatch(apiSlice.util.updateQueryData('getDashboardData', undefined, (draft) => {
            updateCachedCaseStatus(draft, caseId, newStatus)
        }))

    };

    return updateCaseStatus;
};
