import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: {
            id: null,
            firstName: null,
            lastName: null,
            name: null,
            email: null,
            phone: null,
            groups: [],
            frontEndUserType: null,
            baa: {}
        },
        isAuthenticated: false,
        isImpersonating: false,
        isForbidden: false,
    },
    reducers: {
        setCredentials: (state, action) => {
            const { user, groups, isAuthenticated, isImpersonating } = action.payload
            state.user = user
            state.groups = groups
            state.isImpersonating = isImpersonating
            state.isAuthenticated = isAuthenticated
        },
        setBAAConsent: (state, action) => {
            state.user.baa.consented_to_esign = true
        },
        logOut: (state) => {
            state.user = {
                id: null,
                firstName: null,
                lastName: null,
                name: null,
                email: null,
                phone: null,
                frontEndUserType: null,
                baa: {}
            }
            state.isAuthenticated = false
        },
        updateUserInfo: (state, action) => {
            const { infoType, updatedValue } = action.payload

            switch (infoType) {
                case 'phone':   
                    state.user.phone = updatedValue;
                    break;
                case 'name':
                    state.user.name = updatedValue
                    break;
                case 'preferred_title':
                    state.user.preferredTitle = updatedValue
                    break;
                // add case for profile avatar in the future
                default:
                    break;
            }
        },
        setIsForbidden: (state, action) => {
            state.isForbidden = action.payload
        },
    },
})
// what we dispatch in component
export const { setCredentials, logOut, updateUserInfo, setBAAConsent, setIsForbidden } = authSlice.actions

//auth slice
export const selectCurrentUser = (state) => state.auth.user
export const getIsAuthStatus = (state) => state.auth.isAuthenticated
export const getIsRouteForbidden = (state) => state.auth.isForbidden
export const selectIsImpersonating = (state) => state.auth.isImpersonating

export default authSlice.reducer;
