import { Box } from "@mui/material";
import GroupContainer from "../../../globalComponents/Containers/GroupContainer";
import { HaloTextField } from "../../../globalComponents/ReactHookFormFields";
import { useForm } from "react-hook-form";
import HaloSkeleton from "../../../globalComponents/Skeletons/HaloSkeleton";
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton";
import { enqueueSnackbar } from "notistack";
import { useSubmitMDReviewMutation } from "../../MDApiSlice";
import { useNavigate } from "react-router-dom";








export default function MDFeedbackForm({queryLoading, reviewId}){


    const navigate = useNavigate()
    const [ submitReview, {isSubmitting} ] = useSubmitMDReviewMutation()
    const { control, getValues} = useForm({ defaultValues: { feedback:'', } }) 

    const handleMDDecision = (needs_edits) => (e) => {

        const values = getValues()

        if(needs_edits && !values?.feedback) {
            enqueueSnackbar('Feedback is required if requesting edits.', {variant:'error'})
            return;
        }

        submitReview({
            reviewId,
            body:{
                ...values,
                needs_edits,
                is_approved: !needs_edits
            }
        }).unwrap()
        .then((res) => {
            enqueueSnackbar('Submitted review', {variant:'success'})
            navigate('/admin')
        })


    }



    return(
        <Box>
            <GroupContainer title='MD Review'>
                <HaloSkeleton  loading={queryLoading} width={'100%'}>
                    <HaloTextField 
                        name='feedback'
                        label='Feedback'
                        control={control}
                        multiline
                        minRows={10}
                        maxRows={20}
                        maxLength={5000}
                    />
                </HaloSkeleton>
                <Box className='flex-row' justifyContent={'flex-end'} p={2} pb={3} gap={1}>
                    <HaloSkeleton loading={queryLoading}>
                        <ActionButton
                            variant='contained' 
                            color='error' 
                            loading={isSubmitting}
                            sx={{ width:'fit-content' }}
                            onClick={handleMDDecision(true)}
                            >
                            Request edits
                        </ActionButton>
                    </HaloSkeleton>
                    <HaloSkeleton loading={queryLoading}>
                        <ActionButton 
                            variant='contained' 
                            color='success' 
                            onClick={handleMDDecision(false)}
                            loading={isSubmitting}
                            sx={{width:96}}>
                            Approve
                        </ActionButton>
                    </HaloSkeleton>
                </Box>

            </GroupContainer>
        </Box>
    )
}