import HaloModal from "../../../globalComponents/Modals/HaloModal"
import { HaloDatePicker } from "../../../globalComponents/ReactHookFormFields"
import HaloSelectField from "../../../globalComponents/ReactHookFormFields/SelectFields/HaloSelectField"
import { useForm } from "react-hook-form"
import { Box } from "@mui/system"
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton"
import { useSubmitFinalApprovalMutation } from "../../trialAdminAPISlice"
import { enqueueSnackbar } from "notistack"
import { show400Error } from "../../../../app/errors/genericErrors"
import dayjs from "dayjs"

const defaultValues = {
    employmentType: '1099', //default to contractor 
    start_date: null
}

function TrialFinalApprovalModal({ open, handleClose, trialUserId, trialUserInfo }) {

    const { control, handleSubmit} = useForm({ defaultValues })
    
    const [submitFinalApproval, { isLoading: isSubmitting }] = useSubmitFinalApprovalMutation()

    const finalApprovalHandler = (values) => {
        const { employmentType, start_date } = values || {}

        const payload = {
            finalApproval: 'approved',
            employmentType,
            start_date : dayjs(start_date).format('MM-DD-YYYY')
        }

        submitFinalApproval({ trialUserId, payload })
            .unwrap()
            .then((res) => {
                enqueueSnackbar('User approved!', { variant: 'success' })
                handleClose()
            })
            .catch((err) => show400Error(err))

    }

    return (
        <HaloModal open={open} title='Employment Type and User Info' handleClose={handleClose} subtitle={'Please provide the user information below'}>

            <Box 
                className='flex-col' 
                sx={{ gap:2, p:2 }}
                component='form'
                noValidate
                onSubmit={handleSubmit(finalApprovalHandler)}>
                <HaloSelectField
                    required
                    name='employmentType'
                    key='employmentType'
                    label='Employment type'
                    control={control}
                    options={[
                        { val: 'W2', label: 'W2' },
                        { val: '1099', label: '1099' }
                    ]}
                    inputLabelProps={{ fontSize: '14px' }}
                    menuItemProps={{ fontSize: '14px' }}
                    selectProps={{ fontSize: '14px' }}
                />
                <HaloDatePicker 
                    required
                    name='start_date'
                    label='Mandatory start date'
                    control={control}
                    inputLabelProps={{ sx: { fontSize: '14px' } }}
                    InputProps={{ sx: { fontSize: '14px' } }}
                    maxDate={dayjs().add(90, 'day')}
                />
                <Box className='flex-row-flex-end' gap={1}>
                    <ActionButton variant='contained' color='success' type='submit' loading={isSubmitting}>Submit</ActionButton>
                </Box>
            </Box>

        </HaloModal>
    )
}

export default TrialFinalApprovalModal
