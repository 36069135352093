import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from "react";





export default function BackButton(){
    const navigate = useNavigate();
    const [canGoBack, setCanGoBack] = useState(true);

    useEffect(() => {
        setCanGoBack(window.history.length > 1);
    }, [window.history.length]);


    if (!canGoBack) {
        return null; 
    }


    return(
        <IconButton onClick={() => navigate(-1)}><ArrowBackIcon/></IconButton>
    )
}