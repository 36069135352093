import { useState, useEffect } from 'react';

export function useGetAddingItemStatus(apiRef) {
  const [isAddingItem, setAddingItemStatus] = useState(false);

  useEffect(() => {
    const getAddingItemStatus = () => {
      let rowIds = apiRef.current.getAllRowIds();
      setAddingItemStatus(rowIds.some(item => typeof item === "string"));
    };

    return apiRef.current.subscribeEvent('rowModesModelChange', getAddingItemStatus);
  }, [apiRef]);

  return isAddingItem;
}


