import { Box, styled } from '@mui/material';

const CustomToolTipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.background.paper,
  color: theme.palette.text.secondary,
  fontSize: '14px',
  borderRadius: '2px',
  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
  padding: '5px 9px',
  gap: 0.5,
  width:'max-content'
}));

export default CustomToolTipContainer;