import { Avatar, Box, Typography, Fade } from "@mui/material";
import { HaloTextField } from "../../../../../globalComponents/ReactHookFormFields";
import { stringToTitleCase } from "../../../../../../utils/stringUtils/titleCase";
import { useState } from "react";





export function WatchedName({ control, handleCustomBlur, patient }) {

    const [isEditing, setIsEditing] = useState(false)

    return (
        <Box
            className='flex-col-center'
            gap={2}
            p={2}
            pt={3}
            component={'div'}
            onMouseEnter={() => setIsEditing(true)}
            onMouseLeave={() => setIsEditing(false)}
        >
            <Box className='flex-col-center' gap={1}>
                <Avatar
                    sx={(theme) => ({ width: 56, height: 56, bgcolor: theme.avatar.primary })}>{patient?.first_name.slice(0, 1)?.toUpperCase()}</Avatar>
                <Box className='flex-row' gap={1}
                    sx={{
                        height: '24px',
                        overflow: 'hidden',
                    }}
                >
                    <Fade in={!isEditing} timeout={300}>
                        <Typography
                            variant="subtitle1"
                            sx={{ display: isEditing ? 'none' : 'block' }}
                        >
                            {stringToTitleCase(patient?.first_name + ' ' + patient?.middle_name + ' ' + patient?.last_name) || '-'}
                        </Typography>
                    </Fade>
                    <Fade in={isEditing} timeout={1000}>
                        <Box className='flex-row'
                            sx={(theme) => ({
                                display: isEditing ? 'flex' : 'none',
                                gap: 1,
                                '.MuiOutlinedInput-input': {
                                    p: '0px 0px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    fontSize: '14px',
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent', //theme.palette.divider,
                                        background: theme.palette.action.hover
                                    },
                                    '&:focus-within': {
                                        pl: '4px !important',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                        background: 'transparent'
                                    },
                                },
                                'input': {
                                    ...theme.typography.subtitle1,
                                    minWidth: '4ch',
                                },
                            })}
                        >
                            <HaloTextField
                                name='first_name'
                                required
                                onBlur={handleCustomBlur}
                                placeholder='First name'
                                hideHelperText
                                control={control}
                            />
                            <HaloTextField
                                name='middle_name'
                                onBlur={handleCustomBlur}
                                placeholder='Middle name'
                                hideHelperText
                                control={control}
                            />
                            <HaloTextField
                                name='last_name'
                                required
                                onBlur={handleCustomBlur}
                                placeholder='Last name'
                                hideHelperText
                                control={control}
                            />
                        </Box>
                    </Fade>
                </Box>
            </Box>
        </Box>
    )



}
