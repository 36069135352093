
import { Box, Typography, Popover, IconButton } from "@mui/material"
import { useTheme } from "@emotion/react"
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton"
import { useEffect } from "react"
import { useTutorial } from "../../utils/TutorialContext"
import CloseIcon from '@mui/icons-material/Close';



const TrialTutorialPopover = ({ open, onClose, stepsArray, optionalAPICallback }) => {

    const theme = useTheme()
    const { currentRef, description, nextStep, prevStep, currentStep, steps, addStep, clearRefs } = useTutorial()

    const removeFocusedBorder = () => {
        if (steps[currentStep].ref.current?.classList) {
            steps[currentStep].ref.current.classList.remove('focused-element')
        }
    }

    const completeTutorialHandler = () => {
        removeFocusedBorder()
        onClose()
        if (optionalAPICallback) {
            optionalAPICallback()
                .unwrap()
                .then(() => {
                })
        }
    }

    useEffect(() => {
        clearRefs()
        stepsArray?.forEach((step) => addStep(step))
    }, [stepsArray])

    useEffect(() => {
        if (open) {
            steps.forEach((step) => {
                if (step.ref.current) {
                    step.ref.current.classList.remove('focused-element')
                }
            })

            if (steps[currentStep]?.ref.current) {
                currentRef.current.scrollIntoView({block: 'end', behavior: 'smooth', inline: 'nearest'  })
                currentRef.current.classList.add('focused-element')
            }
        }
    }, [open, currentStep, steps])

    return (
        <>
            {currentRef && (<Popover
                open={open}
                anchorEl={currentRef.current}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        return;
                    }
                    removeFocusedBorder()
                    onClose()

                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                sx={{ borderRadius: '8px', mt: 2 }}
            >
                <Box className='flex-col' sx={{ maxWidth: 500 }}>
                    <Box className='flex-row' p={2} gap={1} sx={{ maxHeight: 100, alignItems: 'flex-start' }}>
                        <Typography sx={{ ...theme.typography.body2 }}>{description}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            sx={{ paddingTop: 0 }}
                            onClick={() => {
                                removeFocusedBorder()
                                onClose()
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                    <Box className='flex-row-between' gap={1} p={'12px'} sx={{ alignItems: 'center', mt: 1 }}>
                        {currentStep !== 0 ? <ActionButton
                            variant='contained'
                            sx={{ backgroundColor: theme.palette.text.secondary }}
                            onClick={prevStep}
                        >
                            Back
                        </ActionButton> : <Box></Box>}
                        <Typography sx={{ ...theme.typography.body2, color: theme.palette.text.secondary }}>{`${currentStep + 1} of ${steps.length}`}</Typography>
                        {currentStep === steps.length - 1 ? <ActionButton variant='contained' onClick={completeTutorialHandler}>Finish</ActionButton> : <ActionButton variant='contained' onClick={nextStep}>Next</ActionButton>
                        }
                    </Box>
                </Box>
            </Popover>)}
        </>
    )


}

export default TrialTutorialPopover
