import { Box, Button,IconButton,Switch,Typography } from "@mui/material"
import {  getToolbarStatusIcon } from "../../utils/getToolbarStatusIcon";
import  { useEffect, useState} from "react";
import { useErrorTracking, useSelectedRowsCount } from "../../hooks/subscriptions";
import AddIcon from '@mui/icons-material/Add';
import { useRowCount } from "../../hooks/subscriptions/useRowCount";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function GenericToolbar(props) {
  const {
    handleDeleteClick,
    apiRef,
    initRowCount,
    title, 
    toolbarChildren,
    handleAddRow,
    openGrid,
    closeGrid,
    open,
    isReadOnly,
    GridIcon,
  } = props
  
  const rowCount = useRowCount(apiRef, initRowCount)
  const selectedCnt = useSelectedRowsCount(apiRef);
  const error = useErrorTracking(apiRef);
  const isEmpty = rowCount === 0;


  useEffect(() => {
    if(isEmpty) closeGrid()

    if (!isEmpty) openGrid()

  }, [isEmpty, isReadOnly, initRowCount])

  const handleToolbarClick = () => {

    if (isReadOnly) return null
    else if (isEmpty ){
        handleAddRow()
        openGrid()
    }
    else if(!isEmpty && open){
      closeGrid()
    }
    else{
      openGrid()
    }

  }

  const getActionIcon = () => {
    let Icon = null

    if (isReadOnly) return Icon;
    else if (isEmpty){
      Icon = AddIcon
    }
    else if (!isEmpty && open){
      Icon = ExpandLessIcon
    }
    else{
      Icon = ExpandMoreIcon
    }

    return <Icon size='small' color='action'/>
  }

 

  return (
    <Box 
      className='flex-row'
      onClick={handleToolbarClick}
      sx={ (theme) => ({
        p:'8px 16px',
        justifyContent:'space-between',
        borderBottom: open? `1px solid ${theme.palette.divider}` : '' ,
        minHeight:48,
        backgroundColor: theme.palette.primary.shades4,
        '&:hover':{
          backgroundColor: !isReadOnly && theme.palette.action.hover ,
          cursor: !isReadOnly && 'pointer'
        }
      })}>
      {selectedCnt> 0 ?
          <Button
            sx={{ fontSize:16, height:24, alignSelf:'center'}}
            onClick={(e) => {
              e.stopPropagation()
              handleDeleteClick(null)()
            }}>
            Delete {selectedCnt}
          </Button>
        :
        <Box className='flex-col' justifyContent={'center'}>

          <Box className='flex-row' alignItems='center' gap={1}>
            <Box className='flex-row' gap={0.5} alignItems='center'>
              {GridIcon && <GridIcon color='primary' style={{fontSize:22}}/>}
              <Typography variant='body1' color='text.primary' >{title}</Typography>
            </Box>
            {!isReadOnly && getToolbarStatusIcon(error) }
          </Box>
        </Box>
        }
        <Box className='flex-row' gap={1} alignItems='center'>
          {toolbarChildren}
          {
            getActionIcon()
          }
        </Box>
    </Box>
  );
}
