import { apiSlice } from '../../app/api/apiSlice'
import { manageStaticMeds } from './pathologySlice'

export const pathologyApiSlice = apiSlice
.injectEndpoints({
    endpoints: builder => ({
        getApprovedReviews: builder.query({
            query: () => '/pathology/approved-reviews'
        }),
        getAwaitingApprovalReviews: builder.query({
            query: () => '/pathology/awaiting-approval-reviews'
        }),
        getCompletedReviews : builder.query({
            query: () => 'pathology/completed-reviews'
        }),
        getPathologyHistory: builder.query({
            query: (patient_id) => 'pathology/history/' + patient_id
        }),
        updateRecsAndComments: builder.mutation({
            query: (payload) =>({
                url: 'pathology/review',
                method: 'PUT',
                body: payload
            })
        }),
        manageLabValues : builder.mutation({
            query : (payload) => ({
                url:  'pathology/review/findings/LAB',
                method: payload.method,
                body: payload.body
            }),
        }),
        manageImagingValues : builder.mutation({
            query : (payload) => ({
                url:  'pathology/review/findings/IMG',
                method: payload.method,
                body: payload.body
            }),
        }),
        manageDrugInteraction: builder.mutation({
            query: (payload) =>({
                url: 'pathology/review/drug-interactions',
                method: payload.method,
                body: payload.body
            })
        }),
        manageResistanceGene: builder.mutation({
            query: (payload) =>({
                url: 'pathology/review/resistance-genes',
                method: payload.method,
                body: payload.body
            })
        }),
        manageDiagnoses: builder.mutation({
            query: (payload) =>({
                url: 'pathology/review/chronic-health-conditions',
                method: payload.method,
                body: payload.body
            })
        }),
        manageMedication: builder.mutation({
            query: (payload) =>({
                url: 'pathology/review/medications',
                method: payload.method,
                body: payload.body
            }),
            async onQueryStarted({method, body}, { dispatch, queryFulfilled }) {
                try {
                  const { data } = await queryFulfilled
                  const patchResult = dispatch( manageStaticMeds({method, data, body}) )
                } catch {}
            },
        }),
        addMetrics: builder.mutation({
            query: (payload) =>({
                url: 'pathology/review/metrics',
                method: 'POST',
                body: payload
            })
        }),
        deleteMetrics: builder.mutation({
            query: (payload) =>({
                url: 'pathology/review/metrics',
                method: 'DELETE',
                body: payload
            })
        }),
        submitPathologyReview: builder.mutation({
            query: (payload) =>({
                
                url: 'pathology/review/' + payload.patient_id,
                method: 'POST',
                body: payload
            })
        }),
        //claims system--------------------------
        getPathologyList: builder.query({
            query: (params) => "/pathology/list?page=" + params.pageNumber + "&first_name=" + params.firstName
            + "&last_name=" + params.lastName + "&site=" + params.site.id + "&date_of_birth=" + params.dateOfBirth
            + "&current_pathology_clinician=" + params.currentPathologyClinician + "&last_pathology_clinician=" + params.lastPathologyClinican
            + "&last_patho_review_date=" + params.lastReviewDate + "&submit=Submit"
            
        }),
        getSites: builder.query({
            query: () => 'pathology/sites'
        }),
 
        //pathology details------------------------
        getReviewDetails: builder.query({
            query: (encounterId) => 'pathology/review/details/' + encounterId
        }),
        getPrevD2D: builder.query({
            query: (patientId) => 'pathology/sync-d2d/' + patientId,
            keepUnusedDataFor:1
        }),
        syncD2d: builder.mutation({
            query: ({body, patientId}) => ({
                url: 'pathology/sync-d2d/' + patientId,
                method:'POST',
                body
            })

        })
        
    })
})

export const {
    useGetApprovedReviewsQuery, useGetAwaitingApprovalReviewsQuery,  
    useGetCompletedReviewsQuery,  useUpdateRecsAndCommentsMutation,
    useGetPathologyHistoryQuery,
    //pathology section mutations
    //useManageDiagnosesMutation,
    useManageDrugInteractionMutation, 
    useManageLabValuesMutation, useManageImagingValuesMutation,
    useManageResistanceGeneMutation,
    useManageMedicationMutation, useAddMetricsMutation, useDeleteMetricsMutation,
    useSubmitPathologyReviewMutation,
    useGetPathologyListQuery, useGetSitesQuery,
    useGetReviewDetailsQuery, useGetPrevD2DQuery, useSyncD2dMutation
} = pathologyApiSlice 