import { apiSlice } from "../../app/api/apiSlice"



export const doctorFlowAPISlice = apiSlice
.enhanceEndpoints({addTagTypes:['MD Review List']})
.injectEndpoints({
    endpoints: builder => ({
        getMDReviewList: builder.query({
            query: ({pagination, ordering, search, is_approved}) => {
                let url = `roster/md/review/list`
                let queryParams = `/?page=${pagination?.page + 1}&ordering=${ordering}&size=${pagination?.pageSize}&search=${search}&is_approved=${is_approved}`

                return url + queryParams
            },
            providesTags:['MD Review List'],
        }),
        getMDReviewDetails: builder.query({
            query: (reviewId) => 'roster/md/review/' + reviewId
        }),
        submitMDReview: builder.mutation({
            query: (payload) =>({
                url: 'roster/md/review/' + payload.reviewId,
                method: 'PUT',
                body: payload.body
            }),
            invalidatesTags:['MD Review List']
        })
    })
})


export const {
    useGetMDReviewListQuery, useGetMDReviewDetailsQuery, useSubmitMDReviewMutation
} = doctorFlowAPISlice
