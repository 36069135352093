import { Alert, Box, Divider, Tooltip, Typography } from "@mui/material";
import { useGetCaseDoughnutChartsQuery } from "../../../../../rosterAdminAPISlice";
import ResponsiveDoughtnutChart from "../../../../../../globalComponents/NivoCharts/Doughnut/ResponsiveDoughtnutChart";
import CustomToolTipContainer from "../../../../../../globalComponents/NivoCharts/Tooltips/CustomTooltipContainer";
import Cat from '../../../../../../../assets/images/generic/crying-cat-meme.jpg'


const SiteTooltip = ({statuses=[], color, title, total, percent}) => {

    return(

        <CustomToolTipContainer>
            <Box className='flex-row' gap={1} alignItems={'center'}>
                <Box backgroundColor={color} height={8} width={8} borderRadius={'50%'}></Box>
                <Typography variant="body3">{title} • {percent} %</Typography>
            </Box>
            <Divider flexItem sx={{mb:'2px', mt:'2px'}}/>
            {
                statuses?.slice(0,4)?.map((status) =>(
                    <Box className='flex-row' key={status?.label} gap={1} alignItems={'center'}>
                        <Typography variant='body3'>{status?.label + ': ' + status?.value}</Typography>
                    </Box>
                ))
            }
            <Divider flexItem sx={{mb:'2px', mt:'2px'}}/>
            {
                statuses?.slice(4)?.map((status) =>(
                    <Box className='flex-row' key={status?.label} gap={1} alignItems={'center'}>
                        <Typography variant='body3'>{status?.label + ':  ' + status?.value}</Typography>
                    </Box>
                ))
            }
            <Divider flexItem sx={{mb:'2px', mt:'2px'}}/>
            <Typography variant="body3">Total cases: {total}</Typography>
        </CustomToolTipContainer>

    )


}


const SiteStat = ({label,value, color}) => {


    return(
        <Box className='flex-row' gap={1} height={24} alignItems={'center'}>
            <Box height={8} width={8} borderRadius={'50%'} sx={{background: color}}></Box>
            <Tooltip title={label + ' • ' + value}>
                <Typography variant="body2" color='text.disabled' noWrap>{label} • {value}</Typography>
            </Tooltip>
        </Box>
    )


}

export default function SpotlightDoughnutChart({dates, stat}){


    const skip = stat === 'Requested' || stat === 'Assigned' || stat === 'Completed'

    const {data} = useGetCaseDoughnutChartsQuery({dates, statType:stat}, {skip})
    return(
        <Box className='flex-row' gap={5} justifyContent={'center'}>

            {
                skip ?
                    <Box className='flex-col-center' gap={1}>
                        <Alert severity="warning">
                            Requested, Assigned, Completed are not ready/may change for the sites chart tab
                        </Alert>
                        <img src={Cat} alt='crying cat' style={{height:229, width:400, borderRadius:8}}/>
                    </Box>
                :
                <>
                    <Box height={229} width={240} mt={'31px'}>
                        <ResponsiveDoughtnutChart 
                            data={data} colors={{ datum: 'data.color' }} 
                            tooltip={({datum: {color, data}}) =>{
                                return ( 
                                    <SiteTooltip
                                    title={data.label}
                                    color={color}
                                    total={data?.value}
                                    percent={data?.percent}
                                    statuses={data?.statuses}/> 
                                )}}
                                />
                    </Box> 
                    <Box className='flex-row' gap={2} sx={{maxWidth:500}}>
                        <Box className='flex-col' gap={1}>
                                {   
                                    data?.slice(0,8)?.map((stat) => (
                                        <SiteStat   
                                        key={stat.id}
                                        {...stat}
                                        />
                                    ))
                                }
                        </Box>
                        <Box className='flex-col' gap={1}>
                                {   
                                    data?.slice(8,16)?.map((stat) => (
                                        <SiteStat   
                                        key={stat.id}
                                        {...stat}
                                        />
                                    ))
                                }
                        </Box>
                    </Box>
                </>
            }

        </Box>
    )
}