

export const getNumberRule = (variant) =>{

    let rule = {}

    switch(variant){
        case 'ssn':
            rule.minLength = {
                value:11,
                message:'SSN is too short'
            }

            break;
       case 'phone':
            rule.minLength = {
                value:15,
                message:'Phone number is too short'
            }
            break;
        case 'zip':
            rule.pattern = {
                value: /^\d{5}(-\d{4})?$/,
                message: 'Invalid zip code format'
            }
            break;
        default:
            break;
    }
    
    return rule
}