import { Alert, Box, LinearProgress } from "@mui/material";







export default function Under18Alert({progress=null}){


    return(
        <Box > 
            { progress !== null && <LinearProgress color="warning" variant="determinate" value={progress} sx={{borderRadius:1}}/>}
            <Alert severity="warning">
                Important: This patient is under 18 years old. Administering inappropriate doses can be life-threatening. Please ensure medication doses are correctly adjusted for a pediatric patient before proceeding.
            </Alert>
        </Box>
    )



}