import axios from 'axios';
import Cookies from 'js-cookie';

const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
});

axiosAuth.defaults.headers.common['Content-Type'] = 'application/json';

// axiosAuth.defaults.headers.common['X-CSRFToken'] = Cookies.get('halocsrftoken'); <--  This caused an empty cookie to be set if no csrf was yet present

// we are making use of interceptors so that the CSRF token gets attached before a request rathern then when the axiosAuth instance is created
axiosAuth.interceptors.request.use((config) => {
  const csrfToken = Cookies.get('halocsrftoken');
  if (csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken;
  }
  return config;
});

axiosAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosAuth;

