import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ActionButton } from '../../../../../../../globalComponents/Buttons/ActionButton/ActionButton';
import { useManagePatientDocsMutation } from '../../../../../../patientAPISlice';
import { apiSlice } from '../../../../../../../../app/api/apiSlice';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';




export default function DeleteDocDialog({open, docToArchive, handleClose}){

    let { patientId } = useParams()
    const dispatch = useDispatch()
    const [manageDocs , {isLoading}] = useManagePatientDocsMutation()
    

    const deleteDoc = () => {
        manageDocs({
            method: 'DELETE',
            body: { document_id: docToArchive.id }
        }).then((res) => {

            // delete doc from cache
            dispatch(
                apiSlice.util.updateQueryData('getPatientProfile', {patientId: patientId?.toString()}, (draft) => {
                    const indx = draft.history.findIndex(obj => obj.type === 'Documentation' && obj.data.id === docToArchive.id)
                    if (indx > -1) {
                        draft.history.splice(indx, 1)
                    }
    
                  })
            )
            enqueueSnackbar('Document deleted', {variant:'success'})
            handleClose()
        })

    }


    return(
        <Dialog open={open} onClick={(e) => e.stopPropagation()}>
            <DialogTitle id="alert-dialog-title">
                Delete document
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete {docToArchive?.summary || 'this document'}?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    color='inherit'
                    onClick={(e)=> {
                        
                        handleClose()
                    }}>
                    Cancel
                </ActionButton>
                <ActionButton
                    onClick={deleteDoc}
                    loading={isLoading}
                    variant='contained'>
                    Delete
                </ActionButton>
            </DialogActions>
        </Dialog>
    )

}