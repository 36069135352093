import { useEffect, useState } from "react"
import { Cancel, CheckCircle } from '@mui/icons-material';
import { Stepper, Step, StepLabel } from "@mui/material"
import { useTheme } from "@emotion/react"


const steps = ['On trial', 'Initial review', 'Final review', 'Onboarding', 'Completed']


function TrialUserStepper({ review_state }) {

    const theme = useTheme()

    const [activeStep, setActiveStep] = useState(0)

    const isRejectedOrDenied = (step) => (step === 1 && review_state?.includes('Rejected')) || (step === 2 && review_state?.includes('Denied'))

    useEffect(() => {

        if (review_state === 'Rejected - Initial review') {
            setActiveStep(steps.indexOf('Initial review'))
        }

        else if (review_state === 'Denied - Final review') {
            setActiveStep(steps.indexOf('Final review'))
        }

        else {
            setActiveStep(steps.indexOf(review_state))
        }
    }, [review_state])


    return (
        <Stepper
            alternativeLabel
            activeStep={activeStep}
        >
            {steps.map((label, index) => (
                <Step key={index} >
                    <StepLabel
                        icon={isRejectedOrDenied(index) ? (<Cancel sx={{ color: theme.palette.error.main }} />) :
                            activeStep === 4 ? <CheckCircle sx={{ color: theme.palette.primary.main }} /> :
                                ''}>
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

export default TrialUserStepper
