import SvgIcon from '@mui/material/SvgIcon';

const ManualAssignmentIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <g id="Manual assignment">
      <g id="Vector">
        <path d="M38.5 38.5H17.5C17.0187 38.5 16.625 38.8937 16.625 39.375V51.625C16.625 52.1063 17.0187 52.5 17.5 52.5H38.5C38.9813 52.5 39.375 52.1063 39.375 51.625V39.375C39.375 38.8937 38.9813 38.5 38.5 38.5ZM37.625 50.75H18.375V40.25H37.625V50.75Z" />
        <path d="M38.5 3.5H17.5C17.0168 3.5 16.625 3.89175 16.625 4.375V16.625C16.625 17.1082 17.0168 17.5 17.5 17.5H38.5C38.9832 17.5 39.375 17.1082 39.375 16.625V4.375C39.375 3.89175 38.9832 3.5 38.5 3.5Z" />
        <path d="M27.125 21V32.3663L22.1287 26.985L20.8425 28.175L28 35.875L35.1575 28.175L33.8713 26.985L28.875 32.3663V21H27.125Z" />
      </g>
    </g>
  </SvgIcon>
);

export default ManualAssignmentIcon;
