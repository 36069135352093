
import { TextField, InputAdornment, IconButton, } from "@mui/material"
import { useController } from "react-hook-form"
import { getTextRule } from "./getTextRule"
import { useState } from "react"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export function HaloTextField({
  control,
  name,
  label,
  required = false,
  variant,
  onBlur,
  // onFocus,
  maxLength = 100,
  inputProps,
  InputProps,
  inputLabelProps,
  sx,
  title,
  multiline,
  helperText,
  hideHelperText = false,
  type,
  ...otherProps
}) {

  const { field, formState: { errors } } = useController({
    name,
    control,
    rules: {
      required: required ? 'This field is required' : false,
      ...getTextRule(variant)
    },
  })


  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const isPasswordField = type === 'password'

  const getInputType = () => {
    if(isPasswordField) {
      return showPassword ? 'text' : 'password'
    }
    else {
      return type
    }
  }

  return (
    <TextField
      type={getInputType()}
      required={required}
      label={label}
      fullWidth
      placeholder=" -"
      size="small" //app wide default
      onChange={field.onChange}
      onBlur={onBlur || field.onBlur} //in case you'd like to save field on blur
      // onFocus={onFocus || field.onFocus}
      value={field.value || ''}
      name={field.name}
      inputRef={field.ref}
      inputProps={{ maxLength, ...inputProps }}
      InputProps={{
        ...InputProps,
        endAdornment: isPasswordField && (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              tabIndex={-1}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>)
      }}
      InputLabelProps={{ ...inputLabelProps }}
      error={Boolean(errors[name])}
      helperText={hideHelperText ? '' : helperText ? helperText : errors[name]?.message}
      title={title ? field.value : ''}
      multiline={multiline}
      {...otherProps}
      sx={{
        ...sx,
        "& .MuiInputBase-input": {
          textOverflow: "ellipsis",
        },
        'textarea': {
          p: Boolean(multiline) ? '8.5px 8px 8.5px 14px' : 0,
        },
        '.MuiOutlinedInput-root': {
          p: 0
        }
      }}

    />
  )

}
