import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuthStatus from "./useAuthStatus"
import ForbiddenAccess from "../trialUsers/pages/ForbiddenAccess";

const redirectMap = {
    'Trial user': '/trial-users/dashboard',
    'PharmD': '/dashboard',
    'Admin': '/admin',
    'Developer': '/admin',
    'Staff': '/admin',
    'M.D.': '/admin'
}

const allowedTypesMap = {
    'admin': ['Admin', 'Developer', 'Staff', 'PharmD', 'M.D.'],
    'caseload': ['Admin', 'Developer', 'Staff', 'PharmD', 'M.D.']
}

const RequireAuth = () => {
    const { isAuth, loading, user, isForbidden } = useAuthStatus();
    const { frontEndUserType } = user || {}
    const location = useLocation();

    if (loading) {
        return null; // let's return a loading spinner or any loading component?
    }

    if (isAuth) {

        if (frontEndUserType === 'Trial user' && !user?.baa?.consented_to_esign) {
            if(location.pathname.includes(('baa-consent'))) {
                return <Outlet />
            }

            return <Navigate to='/trial-users/baa-consent' />
        }

        if (isForbidden && frontEndUserType === 'Trial user') {
            return <ForbiddenAccess />
        }

        const routeType = location.pathname.split('/')[1]
        const isRouteProtected = routeType in allowedTypesMap

        if (isRouteProtected) {
            const allowedUserTypes = allowedTypesMap[routeType]

            const isAllowed = allowedUserTypes?.includes(frontEndUserType)

            return isAllowed ?
                <Outlet /> :
                <Navigate to={redirectMap[frontEndUserType]} />
        }

        return <Outlet />

    }

    else if (!isAuth) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }



}

export default RequireAuth;
