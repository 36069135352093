import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser, updateUserInfo } from '../../auth/authSlice'
import { useForm } from "react-hook-form";
import axiosAuth from '../../../utils/apiUtils/axiosAuth'
import { enqueueSnackbar } from 'notistack'
import { HaloTextField } from '../../globalComponents/ReactHookFormFields'
import EditUserInfoSkeleton from '../components/EditUserInfoSkeleton'

function EditName() {
    const dispatch = useDispatch()
    const {firstName, lastName} = useSelector(selectCurrentUser)
    const defaultValues = {first_name: firstName, last_name: lastName}
    const {control, getValues, trigger} = useForm({ defaultValues })

    const updateUserInfoHandler = async(event) => {
        event.preventDefault()
        let valid = await trigger()
        if(!valid) return; 

        let userInfo = getValues()

        axiosAuth({
            url: 'users/update-user-info',
            method: 'PUT',
            data: {first_name: userInfo.first_name, last_name: userInfo.last_name}
        }).then(() => {
            dispatch(updateUserInfo({infoType: 'name', updatedValue: `${userInfo.first_name} ${userInfo.last_name}`}))
            enqueueSnackbar('Name successfully updated', {variant: 'success'})
        })
        .catch(() => {
            enqueueSnackbar('Name failed to update, please try again', {variant:'error'})
        })
    }

    return (
    <EditUserInfoSkeleton 
        infoType='Name' 
        height={248} 
        updateHandler={updateUserInfoHandler}>
        <HaloTextField
            label={'First name'}
            name='first_name'
            control={control}
            sx={{paddingBottom: '12px'}}/>
        <HaloTextField
            label={'Last name'}
            name='last_name'
            control={control}/>
    </EditUserInfoSkeleton>
    )
}

export default EditName
