import { Box, Typography, Chip } from "@mui/material";
import Divider from '@mui/material/Divider';
import DashboardCases from "./ToDoSections/DashboardCases";
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton";
import { useDispatch } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import {  setIsReqCaseDialogOpen } from "../../../rosterManagement/rosterSlice";
import { useNavigate } from "react-router-dom";
import HaloSkeleton from "../../../globalComponents/Skeletons/HaloSkeleton";


function BasicToolbar({header, count, isLoading}){

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const openRequestCaseDialog = () => {
        navigate('/caseload')
        dispatch(setIsReqCaseDialogOpen(true));
    } 

    return(
        <>
        <Box className='flex-row-between-center' p={'12px 16px'} height={48}>
            <Box className='flex-row-center' gap={'6px'}>
                    <Typography sx={{fontWeight:500, fontSize:14}}>
                        {header}
                    </Typography>
                <HaloSkeleton loading={isLoading}>
                    <Chip label={!count && count !==0 ? '-' : count} variant="outlined" size="small" sx={{fontSize:12, height:18}} />
                </HaloSkeleton>
            </Box>
            {
                count > 0?
                <HaloSkeleton loading={isLoading}>
                    <ActionButton onClick={() => navigate('/caseload')}>
                        See all
                    </ActionButton>
                </HaloSkeleton>
                :
                <HaloSkeleton loading={isLoading}>
                    <ActionButton 
                        startIcon={<AddIcon />} 
                        sx={{'span': {mr:0.5}}} 
                        onClick={openRequestCaseDialog}
                        >
                        Cases
                    </ActionButton>
                </HaloSkeleton>
            }   
        </Box>
        <Divider />
        </>
    )
}


export default function ToDoList({assignments, isLoading}){

    return(
        <Box className='flex-col' gap={2} >
            <Typography sx={(theme) => ({...theme.typography.h6, fontWeight:500})}>To-do list</Typography>
            <DashboardCases assignments={assignments} Toolbar={BasicToolbar} isLoading={isLoading}/>
        </Box>
    )
}
