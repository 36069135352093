import { useDispatch } from "react-redux"
import { apiSlice } from "../../app/api/apiSlice"





export const usePrefetchEndpoint = () => {

    const dispatch = useDispatch()

    const prefetchEndpoint = (endpoint, args, options={force:false}) => {

        dispatch(apiSlice.util.prefetch(endpoint, args, options))

    }
    return prefetchEndpoint 
}