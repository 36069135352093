import { useTheme } from "@emotion/react";
import { TextField } from "@mui/material";
import { memo } from "react";



 function FormikTextField(props) {
    const { name, label,  value, onChange,onBlur,error,  ...otherProps } = props;
    const theme = useTheme();

    return (
        <TextField
            fullWidth
            value={value || ''} 
            onChange={onChange}
            onBlur={onBlur}
            error={ error }
            autoComplete={name}
            name={name} 
            label={label}
            size="small"
            sx={{ 
                '& .Mui-disabled': {
                    WebkitTextFillColor: `${theme.palette.text.primary} !important`,
                },
            }}
            maxRows={10}
            {...otherProps}  
        />
    );
}

export default memo(FormikTextField)