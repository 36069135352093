import { useForm } from "react-hook-form"
import { useManageGeneralEncounterMutation } from "../../../../infectiousDiseaseAPISlice"
import { HaloTextField } from "../../../../../globalComponents/ReactHookFormFields"
import { showGenericValidationError } from "../../../../../../app/errors/genericErrors"
import { Box, Typography } from "@mui/material"
import { ClinicalNotesIcon } from "../../../../../../assets/icons/MaterialDesignIcons"
import { getToolbarStatusIcon } from "../../../../../globalComponents/DataGrid/utils/getToolbarStatusIcon"
import PortraitIcon from '@mui/icons-material/Portrait';






export default function General({readOnly, general}){


    const [updateNotes] = useManageGeneralEncounterMutation()
    const {control, handleSubmit, formState:{ isDirty, isValid }, reset} = useForm({
        defaultValues:{
            treating_provider: general?.treating_provider,
        }
    })

    const handleSave = (values) =>{
        if(isDirty){
            updateNotes({
                method:'PUT',
                body:{
                    id: general.encounter, 
                    ...values
                }
            })
            .unwrap()
            .then(res => reset( {...values}, {keepDirtyValues:true, keepDirty:false, }))
            .catch(err => showGenericValidationError(err))
        }
        
    }




    return(
        <Box
            className='flex-col'   
            sx={(theme)=>({...theme.standardBox})}
            onBlur={handleSubmit(handleSave)}>
                <Box
                    className='flex-row'
                    sx={(theme) => ({
                        p:'8px 16px',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:0.5,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        height:48,
                        backgroundColor: 'primary.shades4',
                    })}>
                        {<PortraitIcon color='primary' style={{fontSize:22}}/>}
                        <Typography variant="body1" color='text.primary' > 
                            General
                        </Typography>
                        {!readOnly && getToolbarStatusIcon(!isValid)}
                </Box>
            <Box p={2}>
                <HaloTextField
                    name='treating_provider'
                    label='Treating Provider'
                    disabled={readOnly}
                    hideHelperText
                    maxLength={300}                
                    control={control}
                    // required
                    />
            </Box>
        </Box>
    )
}