import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { QABody } from './EntryBody/QABody';
import { CaseBody } from './EntryBody/CaseBody';
import { LabBody } from './EntryBody/LabBody';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteDocDialog from './DeleteDocDialog/DeleteDocDialog';
import { EntryIcon } from './EntryIcon/EntryIcon';
import DeleteCaseDialog from './DeleteEncounterDialog/DeleteCaseDialog';
import HistoryIcon from '@mui/icons-material/History';
import CaseHistoryEntriesModal from '../../../../../../rosterManagement/admin/components/CaseHistoryEntriesModal/CaseHistoryEntriesModal';
import ReopenDialog from '../../../../../../rosterManagement/admin/components/RosterAdminPanel/RosterGrids/Grids/RMCasesGrid/CaseMgmtToolbar/Dialogs/ReopenDialog';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CaseStatusChip from '../../../../../../rosterManagement/utils/StatusChips/CaseStatusChip';



export default function HistoryEntry({ 
    entry, 
    isDeveloper , 
    handleClose, 
    open, 
    setOpen, 
    target, 
    setTarget,
    isRosterAdmin
}) {

    const navigate = useNavigate()

    const { type, data } = entry;
    const isCase = type === 'CaseObject';

    const handleNav =() =>{
        if(isCase){
            navigate(`/caseload/case-details/${data.id}`)
            return
        }else{
            const newTab = window.open();
            if (newTab) {newTab.location.href = `${process.env.REACT_APP_API_URL}roster/download/doc/Documentation/${data?.id}`}
        }
    }   

    return (
        <TimelineItem
            onClick={handleNav}
            sx={(theme) =>({
                ':hover':{
                    background:theme.palette.action.hover,
                    cursor:'pointer',
                    '& .MuiIconButton-root': {
                        opacity: 1,
                        transition: 'opacity 0.2s ease',
                    },
                },
                pl:2,
                pr:2
            })}>
            <TimelineSeparator>
                <EntryIcon type={isCase? entry?.data?.case_type : type}/>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <Box className='flex-row-between'>
                    <Box className='flex-col'>
                        <Box className='flex-row' alignItems={'center'} gap={1}>
                            <Typography variant='body2'>
                                {isCase? entry?.data?.case_type : type}
                            </Typography>
                            {isCase && <CaseStatusChip caseStatus={entry?.data?.resolution_status}/>}
                        </Box>
                        <Box className='flex-col'>
                            {isCase ? (
                                data.qa_rejected ? (
                                    <QABody data={data}/>
                                ) : (
                                    <CaseBody type={type} data={data} />
                                )
                            ) : (
                                    <LabBody data={data}/>                            
                            )}
                        </Box>
                    </Box>
                    <Box className='flex-row-center' gap={1}>
                        {
                            !isCase &&
                            <>  
                                <Tooltip title='Delete doc'>
                                    <IconButton 
                                        size='small'
                                        sx={{ opacity:0 }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setTarget(entry.data)
                                            setOpen('document')
                                        }}>
                                        < DeleteOutlineOutlinedIcon/>
                                    </IconButton>
                                </Tooltip>
                                <DeleteDocDialog open={open === 'document'} docToArchive={target} handleClose={handleClose}/>
                            </>
                        }
                        {
                            (isCase && isDeveloper) ?
                            <>
                                <Tooltip title='View case history'>
                                    <IconButton
                                        size='small'
                                        sx={{ opacity:0 }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setTarget(data?.id)
                                            setOpen('history')
                                        }}>
                                        <HistoryIcon />
                                    </IconButton>
                                </Tooltip>
                                <CaseHistoryEntriesModal open={open === 'history'} caseId={target} handleClose={handleClose}/>
                                
                                <Tooltip title='Delete case'>
                                    <IconButton
                                        size='small'
                                        sx={{ opacity:0 }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setTarget(entry)
                                            setOpen('case')
                                        }}>
                                        < DeleteOutlineOutlinedIcon/>
                                    </IconButton>
                                </Tooltip>
                                <DeleteCaseDialog open={open === 'case'} handleClose={handleClose} caseToDelete={target}/>
                            </>
                            :
                            null
                        }
                        {
                            (isCase && isRosterAdmin) &&
                            <>  
                                <Tooltip title='Re-open case'>
                                    <IconButton 
                                        size='small'
                                        sx={{ opacity:0 }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setTarget([data?.id])
                                            setOpen('re-open')
                                        }}>
                                        <LockOpenIcon/>
                                    </IconButton>
                                </Tooltip>
                                <ReopenDialog open={open === 're-open'} handleClose={handleClose} selected={target}/>
                            </>
                        }
                    </Box>
                </Box>
            </TimelineContent>
        </TimelineItem>
    );
}
