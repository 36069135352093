import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useLazyGetICD10CodesQuery } from "../../../../patients/patientAPISlice"
import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import { useGridApiContext } from "@mui/x-data-grid-pro"
import { debounce } from "lodash"



const sx={
    p:0, 
    '& .Mui-error':{
        outlineOffset:-2
    }
}
const inputSx={
    '& .MuiOutlinedInput-root': {
        borderRadius: '0px', 
        p:0
    },
    '& .MuiAutocomplete-input':{
        pb: '5.5px !important'
    },
    '& fieldset': {
        border: 'none',
    },
}


export default function DiagnosesSearchCell(props){

    const { id, 
            value:valueProp, 
            hasFocus, 
            row, 
            field,
            freeSolo=false
        } = props
    

    const [inputValue, setInputValue] = useState('') 
    const [queryICD10Codes, {data, isLoading}] = useLazyGetICD10CodesQuery()
    const inputRef = useRef()
    const apiRef = useGridApiContext()
    const error = row?.errors?.includes(field)
   
    useLayoutEffect(() => {
        if (hasFocus) {
            inputRef.current.focus()
        }
    }, [hasFocus])

    useEffect(() => {
        if (inputValue && !valueProp){ // prevents dupe request once item is selected
            debouncedQuery(inputValue)
        }
    }, [inputValue])


    const handleValueChange = (event,newValue) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue.joint_fields })
    } 

    const handleInputChange = (event, newInputValue) =>{
        setInputValue(newInputValue)
    }

    const debouncedQuery = useCallback(
        debounce((value) =>{
            queryICD10Codes(value)
        }, [300]),
        [debounce]
    )

    return(
        <Autocomplete
            freeSolo={freeSolo}
            fullWidth
            options={data?.codes || []}
            autoSelect
            noOptionsText={'No ICD10 codes found'}
            autoComplete
            sx={sx}
            getOptionLabel={(option) => option.joint_fields || ''}
            onChange={handleValueChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            renderInput={(params) => ( 
                <TextField {...params} 
                sx={inputSx}
                error={error}
                inputRef={inputRef}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}/> 
                )}
            
            />
    
    )



}

