import { memo } from "react";
import CRUDDataGrid from "../../../../../globalComponents/DataGrid/CRUDDataGrid";
import CustomTextFieldCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell";
import { useManageTrialMedicationMutation } from "../../../../trialUserAPISlice";
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import { useTutorial } from "../../../../utils/TutorialContext";


function TrialMedications({ readOnly, meds }) {


    let columns = [
        {
            field: 'med_name',
            headerName: 'Medication',
            flex: 3,
            editable: !readOnly,
            headerClassName: readOnly ? '' : 'required-col',
            renderEditCell: (params) => (
                <CustomTextFieldCell {...params} />
            ),
        },
        {
            field: 'dosage',
            headerName: 'Dosage',
            flex: 1,
            maxWidth: 150,
            editable: !readOnly,
            renderEditCell: (params) => (
                <CustomTextFieldCell {...params} />
            ),
        },
        {
            field: 'med_sig',
            headerName: 'SIG',
            flex: 1,
            minWidth: 150,
            editable: !readOnly,
            renderEditCell: (params) => (
                <CustomTextFieldCell {...params} />
            ),
        },


    ]

    return (

        <CRUDDataGrid
            title={'Medications'}
            columns={columns}
            initRows={meds}
            mutation={useManageTrialMedicationMutation}
            fieldToFocus='med_name'
            requiredFields={['med_name']}
            isReadOnly={readOnly}
            GridIcon={MedicationOutlinedIcon}
            isTrialReview={true}
            gridParentSx={{
                flex: 1.5
            }}
        />
    )

}

export default memo(TrialMedications);
