
import TrialClinicalFindings from "./TrialClinicalFindings/TrialClinicalFindings";
import TrialResistanceGenes from "../TrialDataInput/ResistanceGenes/TrialResistanceGenes"
import TrialDrugInteractions from "./TrialDrugInteractions/TrialDrugInteractions";
import CaseAccordion from '../../../../globalComponents/Accordions/CaseAccordion';
import SummaryContainer from '../../../../globalComponents/Containers/SummaryContainer';
import { memo } from 'react';


function TrialAnalysis(props) {
    const { clinicalFindings, resistanceGenes, drugInteractions, readOnlyView, allowEdits, caseInfo, isLoading } = props

    const content = [
        <TrialClinicalFindings clinicalFindings={clinicalFindings} key={'findings'} />,
        <TrialResistanceGenes resistanceGenes={resistanceGenes} key={'genes'} readOnly={readOnlyView} />,
        <TrialDrugInteractions drugInteractions={drugInteractions} key={'d2d'} />
    ]

    return readOnlyView ? (
        <SummaryContainer number={2} title={'Analysis'} disableEdits={!allowEdits} caseInfo={caseInfo} isLoading={isLoading} baseURL={'/trial-users/encounter-review/'}>
            {content}
        </SummaryContainer>
    ) : (
        <CaseAccordion number={2} title={'Analysis'}>
            {content}
        </CaseAccordion>
    );


}

export default memo(TrialAnalysis)
