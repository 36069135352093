import axiosAuth from "./axiosAuth"
import { enqueueSnackbar } from "notistack"


const openDocInNewTab = (url, optionalSuccessCallback) => {

    //exclude api from url arg as axiosAuth includes it already (e.g., /api/trial-user/show_baa will be passed in as /trial-user/show-baa )

    axiosAuth({
        url,
        method: 'GET',
        responseType: 'blob'
    })
        .then((res) => {
            const contentType = res?.headers['content-type']
            const blob = new Blob([res?.data], { type: contentType })
            const fileURL = URL.createObjectURL(blob)
            const newTab = window.open()
            if (newTab) { newTab.location.href = fileURL }
            if(optionalSuccessCallback) {
                optionalSuccessCallback() //allows for additional logic to be executed on successful doc retrieval 
            }
        })
        .catch(err => {
            if (err?.response?.status === 404) enqueueSnackbar('File not found', { variant: 'error' })
            if (err?.response?.status === 500) enqueueSnackbar("Server error, please try again later", { variant: 'error' })
        })

}

export default openDocInNewTab
