export const secsToMins = (secs, roundUp = false) => {

    if(!secs) return '-:--'

    if(roundUp) return Math.ceil(secs / 60)

    const minutes = Math.floor(secs / 60)
    const seconds = Math.round(secs % 60)
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`
}
