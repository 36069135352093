import { useEffect, useState } from "react";
import StyledFilterChip from "../../../../globalComponents/Chips/DropDownChip/StyledFilterChip";
import { Box, Tooltip } from "@mui/material";
import isEqual from 'lodash/isEqual'




const hideCompletedCasesFilter = { field: 'case_resolution_status', operator: 'doesNotContain', value:'completed' }

export default function CaseLoadFilters({apiRef,initialGridState}){


    const [ isActive, setisActive ] = useState(false)

    const handleChipClick = () => {

        if(!isActive){
            apiRef?.current?.setFilterModel( { items: [hideCompletedCasesFilter] })

        }else{
            apiRef?.current?.setFilterModel({items: []})
        }
        setisActive(prev => !prev)
    }

    useEffect(() => {

        if(initialGridState){
            const gridFilters = initialGridState?.filter?.filterModel?.items
            const filterIsOn = gridFilters?.some(filter => isEqual(filter, hideCompletedCasesFilter ))
            setisActive(filterIsOn)
        }

    }, [initialGridState])


    return(
        <Box className='flex-row' gap={3}>
            <Tooltip title={isActive? 'show completed' : 'hide completed'}> 
                <Box>
                    <StyledFilterChip
                        isActive={isActive}
                        label={isActive? 'show completed' : 'hide completed'}
                        onClick={handleChipClick}
                        color={isActive ? 'primary' : 'default'}
                        variant={isActive ? 'filled' : 'outlined'}
                        />

                </Box>
            </Tooltip>
        </Box>
    )
}