
import { HaloTextField } from "../../../globalComponents/ReactHookFormFields";
import { HaloNumberField } from "../../../globalComponents/ReactHookFormFields";
import { HaloAutocomplete } from "../../../globalComponents/ReactHookFormFields";
import { USStates } from "../../../../utils/formUtils/menuData/USStates";
import { Box, Typography, TextField } from "@mui/material"
import { useTheme } from '@emotion/react';
import HaloAddressAutocomplete from "../../../globalComponents/ReactHookFormFields/AutocompleteFields/HaloAddressAutocomplete";




function SignUpInfoFields({ control, password1, register, errors, setValue }) {

    const theme = useTheme()

    return (
        <>
            <Typography sx={{ ...theme.typography.subtitle1, fontWeight: 500 }}>Referral account sign up</Typography>
            <Box className='flex-row' gap={2}>
                <HaloTextField
                    name='first_name'
                    label='Legal first name'
                    control={control}
                    maxLength={300}
                    required
                    inputLabelProps={{ sx: { fontSize: '14px' } }}
                    InputProps={{ sx: { fontSize: '14px' } }}
                    inputProps={{ style: { textTransform: 'capitalize' } }}

                />
                <HaloTextField
                    name='last_name'
                    label='Legal last name'
                    control={control}
                    maxLength={300}
                    required
                    inputLabelProps={{ sx: { fontSize: '14px' } }}
                    InputProps={{ sx: { fontSize: '14px' } }}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                />
            </Box>
            <HaloTextField
                name='email'
                label='Email'
                variant={'email'}
                type='email'
                control={control}
                maxLength={300}
                required
                inputLabelProps={{ sx: { fontSize: '14px' } }}
                InputProps={{ sx: { fontSize: '14px' } }}
            />
            <HaloNumberField
                label={'Phone number'}
                name='phone'
                variant="phone"
                control={control}
                required
                inputLabelProps={{ sx: { fontSize: '14px' } }}
                InputProps={{ sx: { fontSize: '14px' } }}
            />

            <HaloAddressAutocomplete
                name='address'
                setValue={setValue}
                control={control}
                required
                inputLabelProps={{ sx: { fontSize: '14px' } }}
                autoCompleteSXProps={
                    {
                        '& .MuiAutocomplete-inputRoot': {
                            fontSize: '14px',
                        },
                        '& .MuiAutocomplete-option': {
                            fontSize: '14px',
                        }
                    }
                }
            />

            <HaloTextField
                name='address_2'
                key='address_2'
                label='Address line 2'
                control={control}
                inputLabelProps={{ sx: { fontSize: '14px' } }}
                InputProps={{ sx: { fontSize: '14px' } }}
            />

            <HaloTextField
                required
                name='city'
                key='city'
                label='City'
                control={control}
                inputLabelProps={{ sx: { fontSize: '14px' } }}
                InputProps={{ sx: { fontSize: '14px' } }}
            />

            <HaloAutocomplete
                required
                label='State'
                name='state'
                options={USStates}
                control={control}
                inputLabelProps={{ sx: { fontSize: '14px' } }}
                autoCompleteSXProps={{
                    '& .MuiAutocomplete-inputRoot': {
                        fontSize: '14px',
                    },
                    '& .MuiAutocomplete-option': {
                        fontSize: '14px',
                    }
                }}
            />

            <HaloNumberField
                required
                label='Zip code'
                name='zip'
                key='zip'
                variant="zip"
                control={control}
                inputLabelProps={{ sx: { fontSize: '14px' } }}
                InputProps={{ sx: { fontSize: '14px' } }}
            />

            <HaloTextField
                name='password1'
                label='Password'
                type={'password'}
                variant={'password-sign-up'}
                control={control}
                required
                inputLabelProps={{ sx: { fontSize: '14px' } }}
                InputProps={{ sx: { fontSize: '14px' } }}
            />
            <TextField
                required
                fullWidth
                label='Confirm password'
                type='password'
                {...register('password2', {
                    required: 'Please confirm your password',
                    validate: value => value === password1 || 'The passwords do not match'
                })}
                error={!!errors.password2}
                helperText={errors.password2 ? errors.password2.message : ''}
                InputLabelProps={{ sx: { fontSize: '14px' } }}
                InputProps={{ sx: { fontSize: '14px' } }}
                size='small'
                height={100}
            />
        </>
    )
}

export default SignUpInfoFields
