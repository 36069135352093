import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie';
import { handleMacroExceptions } from './handleMacroExceptions';
import { setIsForbidden } from '../../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,         
    credentials: 'include',                    //include http only cookies
    prepareHeaders: (headers) => {  
        const csrfToken = Cookies.get('halocsrftoken'); //incluse csrf token 
        if (csrfToken){
            headers.set('X-CSRFToken', csrfToken )
            headers.set('Accept', 'application/json')
        }

        return headers
    }
})

// wrapper to refresh token as needed without auto logging out the user
const baseQueryMiddleware = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    
    if(result.error && (result.error.originalStatus === 403 || result.error.status === 403)) {
        api.dispatch(setIsForbidden(true)) //handle trial user forbidden page 
    }

    handleMacroExceptions({result, api}) // 'error hanlding middleware'
    
    return result
}

//base api (endpoints open so we can inject them from each feature folder)
export const apiSlice = createApi({
    baseQuery: baseQueryMiddleware,
    endpoints: builder => ({})
})
