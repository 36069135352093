import { useCallback, useEffect, useState } from "react";





const useCenterResize = (localStorageVariable) => {
    const [width, setWidth] = useState(localStorage.getItem(localStorageVariable) || 50)

    const dragHandler = useCallback(
        (e) => {
          const w =
            ((e.clientX -
              e.currentTarget.parentElement.parentElement.getBoundingClientRect()
                .left) /
              e.currentTarget.parentElement.parentElement.getBoundingClientRect()
                .width) *
            100;
          if (w > 0 && w !== width) {
            setWidth(w);
          }
    },[width]);

    useEffect(() => {
      const handleBeforeUnload = () => {
        localStorage.setItem(localStorageVariable, width);
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        handleBeforeUnload()
      };
    }, [width]);

    return [width, dragHandler]
}


export default useCenterResize;