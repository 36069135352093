import { Box, Typography } from "@mui/material";
import HaloModal from "../../../../../globalComponents/Modals/HaloModal";
import DetailItem from "../../../../../globalComponents/TextGroups/DetailItem";
import { useForm } from "react-hook-form";
import { HaloAsyncObjAutocomplete } from "../../../../../globalComponents/ReactHookFormFields/AutocompleteFields/HaloAsyncObjAutocomplete";
import { useGetRosterUsersQuery } from "../../../../../rosterManagement/rosterAPISlice";
import { HaloTextField } from "../../../../../globalComponents/ReactHookFormFields";
import { stringToTitleCase } from "../../../../../../utils/stringUtils/titleCase";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectScorePercentage } from "../../../../qualitySlice";
import { ActionButton } from "../../../../../globalComponents/Buttons/ActionButton/ActionButton";





export default function ReassignModal({
    open,
    handleClose,
    finalFeedback,
    patient,
    currentClinician,
    handleDecision,
    isSubmitting
}){
    const score = useSelector(selectScorePercentage)
    const {control, setValue, handleSubmit} = useForm({
        defaultValues:{
            final_feedback: finalFeedback
,            clinician: ''
        }
    })


    useEffect(()=>{

        setValue('final_feedback', finalFeedback)

    }, [finalFeedback])


    const handleReassign = (values) => {

        // this is a curried function so have to pass empty arguments for the "e" arg
        handleDecision( 2, values )() 
    }


    return(
        <HaloModal 
            open={open}
            handleClose={handleClose}
            title={'Reassign to another clinician '}
            modalMaxWidth={500}
        >
            <Box className='flex-col' gap={3} p={2} component={'form'} onSubmit={handleSubmit(handleReassign)} noValidate>
                <Box className='flex-col' sx={(theme) =>({...theme.standardBox, p:2})} >
                    <Typography variant="subtitle1">{stringToTitleCase(patient?.name)}</Typography>
                    <Box className='flex-row' gap={1}>
                        <DetailItem
                            label='Clinician'
                            value={currentClinician}
                        />
                        <DetailItem 
                            label='Score'
                            value={score + '%'}
                        />
                    </Box>
                </Box>
                <HaloTextField 
                    name='final_feedback'
                    label='General feedback'
                    control={control}
                    multiline
                    rows={3}
                />
                <HaloAsyncObjAutocomplete
                    label='Clinician'
                    name='clinician'
                    query={useGetRosterUsersQuery}
                    queryParams={{
                        allowedSite: patient?.site?.id
                    }}
                    optionKey='name'
                    required
                    control={control}
                />
                <Box className='flex-row' sx={{  gap:1, justifyContent:'flex-end'}}>
                    <ActionButton color='inherit' onClick={handleClose}>
                        Cancel
                    </ActionButton>
                    <ActionButton color='success' variant='contained' type='submit' loading={isSubmitting}>
                        Submit
                    </ActionButton>
                </Box>
            </Box> 
        </HaloModal>
    )
}