import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ActionButton } from '../../../../../globalComponents/Buttons/ActionButton/ActionButton';
import { useManageCaseStatusMutation } from '../../../../rosterAPISlice';
import { stringToTitleCase } from '../../../../../../utils/stringUtils/titleCase';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';







export default function ConfirmCloseCaseDialog({
    open,
    handleClose,
    selected,
    setSelected,
    patient
}) {

    const [ closePendingCase, {isLoading} ] = useManageCaseStatusMutation()
    const theme = useTheme()
    const mutate = () => {
        closePendingCase({
          action:'close pending',
          body:{
              case_id: selected[0]
          }
        })
        .unwrap()
        .then(res => {
            setSelected([])
            handleClose()
        })
        .catch(err => {})
    }
    

   return(
    <Dialog open={open} onClick={(e) => e.stopPropagation()}>
        <DialogTitle >
            Close pending case
        </DialogTitle>
        <DialogContent>
        <DialogContentText >
            Confirm you want to close the pending results case for <span style={{color:theme.palette.primary.main}}>{stringToTitleCase(patient)}</span> ?
        </DialogContentText>
        </DialogContent>
        <DialogActions>
            <ActionButton
                color='inherit'
                onClick={(e)=> {
                    handleClose()
                    setSelected([])
                }}>
                Cancel
            </ActionButton>
            <ActionButton
                onClick={mutate}
                loading={isLoading}
                variant='contained'>
                Mark complete
            </ActionButton>
        </DialogActions>
    </Dialog>
    
   )

}