import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import CancelIcon from '@mui/icons-material/Close';
import {  GridActionsCellItem, useGridApiContext, useGridSelector} from '@mui/x-data-grid-pro'
import { useTheme } from "@emotion/react";
import { memo } from 'react';


const ActionCell = ({ row, id,tabIndex, handleCancelClick, handleDeleteClick, ...otherProps }) => {
    const theme = useTheme();
    const apiRef = useGridApiContext()
    const rowModes = useGridSelector(apiRef, () => {
        const editState = apiRef.current.state.editRows;
        return editState
      });
    

    if(rowModes[id] || row?.isNew ){
        return (
        <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            tabIndex={tabIndex}
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color={theme.palette.text.secondary}/>
        )
    }

    else{
        return (
            <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            tabIndex={tabIndex}
            onClick={handleDeleteClick(id)}
            color={theme.palette.text.secondary}
            />
        )
    }

}

export default memo(ActionCell)
