import { MuiChipsInput } from "mui-chips-input";
import { useController } from "react-hook-form";









export default function HaloChipsInput({
    control,
    name,
    label,
    required=false,
    title,
    sx,
}){


    const { field, formState: {  errors }, } = useController({
        name,
        control,
        rules: { 
            required: required? 'This field is required' : false,
        },
    })

    return(
        <MuiChipsInput 
            required={required}
            addOnBlur={true}
            label={label}
            {...field}
            fullWidth
            size='small'
            error={Boolean(errors[name])}
            helperText={errors[name]?.message}
            sx={{
                ...sx,
              "& .MuiInputBase-input": {
                textOverflow: "ellipsis",
              },
            }}
            validate={(val) => {
                const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/

                return {
                    isError: !(re.test(String(val).toLowerCase())),
                    textError: 'Invalid email format'
                  }
            }}
        />
    )
}