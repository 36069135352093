import { useParams } from "react-router-dom";
import { selecteRosterMgmtPreset, selectRosterMgmtDates } from "../../admin/adminSlice";
import BreadCrumbHeader from "../../globalComponents/Headers/BreadCrumbHeader";
import BackButton from "../../globalComponents/SubHeader/Backbutton";
import { Alert, Box } from "@mui/material";
import { useGetClinicianAndSettingsQuery } from "../rosterAdminAPISlice";
import ClinicianInfoAndSettings from "../admin/components/RosterAdminClinician/InfoAndSettings/ClinicianInfoAndSettings";
import { ClinicianStatCards } from "../admin/components/RosterAdminClinician/ClinicianStatCards/ClinicianStatCards";
import { ClinicianCaseHistory } from "../admin/components/RosterAdminClinician/CaseHistory/CaseHistory";
import { ClinicianStatSpotlight } from "../admin/components/RosterAdminClinician/ClinicianStatSpotlight/ClinicianStatSpotlight";
import RosterDateRangeButton from "../admin/components/RosterAdminPanel/RosterDateRangeButton/RosterDateRangeButton";







export default function RosterMgmtClinician() {

    let { clinicianId } = useParams()
    const {data:clinicianData, isLoading, isFetching} = useGetClinicianAndSettingsQuery(clinicianId)

    
    return(
        <>
            <BreadCrumbHeader 
                startCrumb={'Admin'}
                title={clinicianData?.clinician_info?.name}
                crumbs={['Clinician']}
                startComponent={<BackButton />}
                endComponent={ <RosterDateRangeButton dateSelector={selectRosterMgmtDates} presetSelector={selecteRosterMgmtPreset}/> }
            />
            <Box className='flex-col' p={3} gap={1}>
            <Alert severity="warning">Beta - use legacy for site asgmts</Alert>

                <ClinicianStatCards />
                <ClinicianStatSpotlight clinicianId={clinicianId}/>
                <ClinicianInfoAndSettings clinicianData={clinicianData}/>
                <ClinicianCaseHistory clinicianId={clinicianId}/>
            </Box>
        </>
    )
}