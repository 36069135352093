import {useDropzone} from 'react-dropzone'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, Button, Collapse, IconButton, Typography } from '@mui/material';
import { FileUploadIcon } from '../../../../assets/icons/MaterialDesignIcons';
import { useController } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { enqueueSnackbar } from 'notistack';
import { TransitionGroup } from 'react-transition-group';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

const getAcceptedFileTypes = (types) => {
    const MIMETypes ={
        pdf : {'application/pdf': ['.pdf'] },
        csv : { 'text/csv': ['.csv'] }
    }

    let fileTypes = {}

    types?.map((type) => {
        if (MIMETypes?.[type]) fileTypes = {...fileTypes, ...MIMETypes[type]}
    })

    return fileTypes

}

// init value must be an empty array []
export default function HaloDropZone({
    fileTypes=['pdf'], // specify what file types the fil input should take, default is pdf files only...
                        //... can take multiple e.g. ['pdf', 'csv']
    dropZoneSx={},
    name,
    control,
    required,
    maxNumFiles=1
}){ 

    const  {field, formState: {errors}} = useController({
        name,
        control,
        rules:{
            required: required? 'This file is required' : false
        },
    })

    const onDrop = ( files ) => {
        if(field.value.length + files?.length > maxNumFiles){
            enqueueSnackbar('Too many files, max ' + maxNumFiles, {variant:'error'})
        }else{
            field?.onChange(field.value.concat(files))
        }

    }

    const onDropRejected = ( params ) => {
        enqueueSnackbar(params[0]?.errors[0]?.message , {variant:'error'})
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDropRejected,
        accept: getAcceptedFileTypes(fileTypes), 
        maxFiles: maxNumFiles,
        disabled: field?.value?.length >= maxNumFiles
    });

    

    const hasFiles = field?.length > 0
    const isMaxFiles = field?.value?.length >= maxNumFiles


    const removeFile = (name) =>{
        field?.onChange(field.value.filter(obj => obj.name !== name))
    }


    return(
        <div {...getRootProps()} style={{width:'100%'}}>
            <input  
                {...getInputProps({name, type:'file'})}
                name={field?.name}
                onBlur={field?.onBlur}
                />
            <Box className='flex-col-center' 
                sx={({palette}) => ({
                    border: 
                        `1px dashed ${errors?.[name] && !hasFiles ? 
                                    palette.error.light 
                                        : 
                                        isMaxFiles? 
                                            palette.success.main
                                            : 
                                            palette.primary.main}`,
                    p: '24px 16px',
                    borderRadius:2,
                    mt:0.13,
                    ":hover":{
                        cursor:'pointer'
                    },
                    ...dropZoneSx
                })}>
                    {
                        field?.value?.length >= maxNumFiles ?
                        <>
                            <TaskOutlinedIcon color={'success'}/>
                            <Typography variant='subtitle1' >
                                File(s) added successfully
                            </Typography>
                        </>
                        :
                        <>
                            <FileUploadIcon color={errors?.[name] ? 'error' : 'primary'}/>
                            <Typography variant='subtitle1' >
                                <Button variant="text" color={errors?.[name] ? 'error' : 'primary'} sx={{p: 0, textDecoration:'underline'}}>
                                    Click to upload 
                                </Button> or drag and drop
                            </Typography>
                            <Typography variant='body2' color='text.disabled'>{fileTypes.map(str=> str.toUpperCase()).join(', ')} - max {maxNumFiles} file(s)</Typography>
                        </>
                    }
            </Box>
            <List sx={{ mt: 1 }} disablePadding >
                <TransitionGroup>
                    {
                        field?.value?.map((value, indx) => (
                            <Collapse key={value?.name + indx}>
                                <ListItem
                                    disablePadding
                                    secondaryAction={
                                        <IconButton
                                            size='small'
                                            edge="end"
                                            aria-label="remove"
                                            title="Remove"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                removeFile(value?.name)
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    }>
                                        <ListItemIcon sx={(theme) => ({color:theme.palette.text.primary, minWidth:28})}>
                                            <AttachFileIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={value?.name} />
                                    </ListItem>
                            </Collapse>
                        ))
                    }
                </TransitionGroup>
            </List>
        </div>
    )

}