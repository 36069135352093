import { Box, Checkbox, IconButton, Menu, Typography } from "@mui/material";
import { memo, useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from "@emotion/react";
import AsyncSiteAutoComplete from "../../../SiteAutoComplete/AsyncSiteAutoComplete";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ActionButton } from "../../../Buttons/ActionButton/ActionButton";
import StyledFilterChip from "../StyledFilterChip";
import { FilterChipTitle } from "../FilterChipTitle";



const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


 function SitesFilterChip({filterState, setFilterState}){

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const isActive = Boolean(filterState.length > 0)
    
    const handleChipClick = (event) => setAnchorEl(event.currentTarget);
    const handleChipClose = () => setAnchorEl(null); 

    const handleMenuItemClick = (event, value) => {
        setFilterState(value);
        setAnchorEl(null);
    };

    const handleClearFilter =() => setFilterState([])


    const getLabel = () =>{
        let label = 'Site' 

        if (isActive){
            label = filterState[0].site_name
            if (filterState.length>1){
                label += ` +${filterState.length - 1}`
            }

        }
        return label
    }   



    return(
        <>
        <StyledFilterChip
                isActive={isActive}
                label={getLabel()}
                onClick={handleChipClick}
                color={isActive ? 'primary' : 'default'}
                variant={isActive ? 'filled' : 'outlined'}
                onDelete={isActive? handleClearFilter : null}
                icon={isActive? null:  <ArrowDropDownIcon />}/>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleChipClose}
                sx={{
                    '& .MuiPopover-paper':{
                        boxShadow: theme.customShadows.elevation8,
                        borderRadius:2
                    }
                }}
                >
                <Box className='flex-col'  width={288}  justifyContent={'space-between'}>
                    <FilterChipTitle chipLabel={'Site'} handleChipClose={handleChipClose} />
                        <AsyncSiteAutoComplete 
                            forceOpen
                            multiple
                            disablePortal={true}
                            disableCloseOnSelect
                            value={filterState}
                            setValue={(field, value) => setFilterState(value)}
                            ListboxProps={{sx:{height:250}}}
                            renderOption={( {key , ...props}, option, { selected }) => (
                                <li key={key} {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.site_name}
                                </li>
                            )}
                            PopperComponent={({ children }) => (
                                <Box sx={{
                                    '& .MuiAutocomplete-paper':{
                                        boxShadow:'none'
                                    }
                                }}>{children}</Box>
                            )}
                            />
                    <Box display="flex" justifyContent="flex-end" p={1} >
                        <ActionButton size='small' onClick={(event) => handleMenuItemClick(event, [])}>
                            Clear
                        </ActionButton>
                    </Box>
                </Box>
            </Menu>
        </>
    )
}

export default memo(SitesFilterChip);
