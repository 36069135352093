import { Box } from "@mui/material";





//REQUIREMENT: The parent of this Element must have a set height for scrolling to work 
// e.g. height={600px} or height={'calc(100vh - 196px)'}


//for now this component is only meant to take Styling props
export default function HiddenScrollableBox({sx, children, hideScrollbar=true}){


    //Might delete all together if we can replicate this functionality with kingsora's library

    return(
        <Box sx={{
            ...sx,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: hideScrollbar? '0px': 'default',
                background: hideScrollbar? 'transparent':'default', 
            },
        }}>
            {children}
        </Box>
    )


}