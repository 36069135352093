import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Box } from "@mui/material";
import { useTheme } from '@emotion/react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useEffect, useRef, useState } from 'react';
import { ActionButton } from '../../../../../../globalComponents/Buttons/ActionButton/ActionButton';



const filterOptions = [
  ['', 'All'],
  ['Documentation', 'Documentation'],
  ['CaseObject', 'Cases'],

]

export default function FilterMenu({
    filter,
    setFilter,
    history
}) {
  const theme = useTheme()
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false)
  
  const handleFilterChange = (newFilter) =>{
    setFilter(newFilter)
  }   
  
  
  const handleToggle = () => setOpen((prevOpen) => !prevOpen)
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleMenuClick = (e,filter) =>{
      handleFilterChange(filter)
      handleClose(e)
    }

  
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);


  return (
      <Box>
        <ActionButton
          ref={anchorRef}
          onClick={handleToggle}
          sx={{color:theme.palette.action.active}}
          endIcon={<FilterListIcon sx={{height:20, width:20}}/>}
          >
          Filter by
        </ActionButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{zIndex:2}}
        >
          {({ TransitionProps}) => (
            <Grow
              {...TransitionProps}
              >
                <Paper >
                  <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                          autoFocusItem={open}
                          onKeyDown={handleListKeyDown}
                          sx={{borderRadius:1}}>
                          {
                            filterOptions?.map((opt) => {
                              const disabled = (history?.filter(obj => obj.type === opt[0]).length === 0 ) && opt[1] !== 'All'

                              return (
                              <MenuItem 
                                key={opt[0]} 
                                disabled={disabled}
                                selected={filter===opt[0]} 
                                onClick={ (e) => handleMenuClick(e, opt[0]) }>
                                  {opt[1]}
                              </MenuItem>
                            )
                            }
                          )
                          }
                  </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
      </Box>
  );
}
