import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, IconButton, Typography, Alert, AlertTitle, } from "@mui/material";


export function ScannedTable({
    setViewErrors,
    errors,
    fileName,
    template,
    templatedCsvColumns,
    hasErrors
}){


    return(
        <>

        {
            hasErrors &&
            <Alert 
                severity="error" 
                sx={{
                    width:'100%',
                    '.MuiAlert-message':{
                        width:'100%'
                    }
                }}>
                    <Box className='flex-col'>
                        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                            <Box sx={{display:'flex'}}>
                                <AlertTitle>Critical errors</AlertTitle>
                            </Box>
                            <Box sx={{display:'flex', alignItems:'center'}}>
                                <AttachFileIcon/>
                                <Typography variant='body2' noWrap>{fileName}</Typography>
                            </Box>
                        </Box>
                        <Typography variant="body2">{errors?.number_of_rows_affected} row(s) with errors detected. If you proceed, these row(s) will not be uploaded.</Typography>
                        <Typography variant="body2">If you are a member of the data team, please fix the errors and reupload the CSV file!</Typography>
                    </Box>
            </Alert>
        }
       
        <TableContainer sx={(theme) => ({...theme.standardBox})}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{minWidth:200}}>CSV field</TableCell>
                        <TableCell sx={{minWidth:200}}>CSV values</TableCell>
                        <TableCell sx={{minWidth:200}}>Halo field</TableCell>
                        <TableCell sx={{minWidth:200}}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {templatedCsvColumns?.map((row, indx) => {
                        const mapped_field =  template?.template_objects.find(obj => obj.database_field === row.haloField)
                        const columnErrorIndx = errors?.columns_affected.findIndex(item => item.column === mapped_field?.database_field)
                        const columnError = errors?.columns_affected[columnErrorIndx]
                        const hasError = columnErrorIndx >= 0
                        
                        const getErrorText = () =>{
                            const errorCnt = columnError?.count

                            if(!hasError) {
                                return 'OK'
                            }
                            else if (errorCnt === errors?.total_rows_scanned){
                                return 'All rows' 
                            }
                            else{
                                return errorCnt + ' ' + (errorCnt >1 ? 'errors' : 'error')
                            } 
                        }
                        

                        return (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {row.csvHeader || '-'}
                                </TableCell>
                                <TableCell sx={{maxWidth:350}}>
                                    {row.values.slice(0,2).map((val, indx) =>(
                                        <Typography key={val + indx} variant='body2' color='text.secondary' noWrap>{val || '-'}</Typography>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {mapped_field?.description || '-'}
                                </TableCell>
                                <TableCell>
                                    <Box className='flex-row-between' gap={1} alignItems={'center'}>
                                        <Box className='flex-row' gap={1} alignItems={'center'}>
                                            {
                                                hasError ? 
                                                    getErrorText() === 'All rows'? 
                                                        <ErrorIcon color='error'/> :  
                                                        <WarningIcon color='warning'/>
                                                : 
                                                    <CheckCircleIcon color='success'/>
                                            }
                                            <Typography variant="body2">{getErrorText()}</Typography>
                                        </Box>
                                            {
                                                hasError  && getErrorText() !== 'All rows'? 
                                                <IconButton onClick={(e) => setViewErrors(mapped_field?.database_field)}> 
                                                    <EditOutlinedIcon/> 
                                                </IconButton> : null
                                            }
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}