import { Box, Chip } from "@mui/material";
import { memo } from "react";








const MetricsChips = memo(({ metrics, name, fieldKey, handleDelete, disabled }) => {
    return (
        <Box className='flex-row' gap={1} flexWrap={'wrap'}>
            {metrics?.[name].map((metric) => (
                    <Chip size="small"
                        key={metric.id}
                        label={metric[fieldKey]}
                        {...(disabled ? {} : { onDelete: (e) => handleDelete(e, name, metric.id) })}
                        disabled={disabled}
                />
            ))}
        </Box>
    );
});


export default MetricsChips;
