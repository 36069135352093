import HaloModal from "../../../../globalComponents/Modals/HaloModal";
import { useGetCaseHistoryQuery } from "../../../rosterAdminAPISlice";
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Alert, Box, Collapse, Typography } from "@mui/material";
import AutorenewOutlinedicon from '@mui/icons-material/AutorenewOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import TrendingFlatOutlinedIcon from '@mui/icons-material/TrendingFlatOutlined';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import HaloUserAvatar from "../../../../globalComponents/Avatar/HaloUserAvatar/HaloUserAvatar";
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
  } from '@mui/lab/TimelineOppositeContent';
import dayjs from "dayjs";
import snakeCaseToSentence from "../../../../../utils/stringUtils/snakeCaseToSentence";
import CaseStatusChip from "../../../utils/StatusChips/CaseStatusChip";
import { TransitionGroup } from 'react-transition-group';



const timelineMAP = {
    'resolution_status': {
        icon: LocalOfferOutlinedIcon,
    },
    'assignment': {
        icon: ArrowCircleRightOutlinedIcon,
    },
    'user': {
        icon: AccountCircleOutlinedIcon,
    },
    'object_id': {
        icon: DataObjectOutlinedIcon,
    },
    'time_spent_reviewing_entered': {
        icon: AccessTimeOutlinedIcon,
    },
    'date_submitted': {
        icon: EventOutlinedIcon,
    },
    'documentation': {
        icon: StickyNote2OutlinedIcon,
    }
};

const subtitleProps = {
    variant:'caption', color:'text.disabled'
}

export default function CaseHistoryEntriesModal({
    caseId,
    open,
    handleClose
}) {
    const { data: entries, isLoading, isFetching } = useGetCaseHistoryQuery(caseId, { skip: !open });


    return (
        <HaloModal
            title={'Case history'}
            open={open}
            onClick={(e) =>  e?.stopPropagation()}
            handleClose={handleClose}
            modalMaxWidth={800}>
            <Box> 
                <Timeline
                    sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                            flex: 0.2,
                        },
                        '.MuiTimelineDot-root': {
                            boxShadow: 'none'
                        }
                    }}>
                    <Alert severity="warning">Note: cases submitted before October 2023 may be lacking case history</Alert>
                    <TransitionGroup>
                    {entries?.map((entry, index) => {
                        const IconComponent = timelineMAP?.[entry?.field]?.icon || SubjectOutlinedIcon;
                        const { user } = entry || {}
                                                
                        const isLastEntry = index === entries.length - 1;
                        const nextTimestamp = !isLastEntry ? entries[index + 1].timestamp : null;
                    
                        const isDifferentDay = nextTimestamp && !dayjs(entry.timestamp).isSame(nextTimestamp, 'day');
                    

                        return (
                            <Collapse key={index}>
                                <TimelineItem key={entry?.id} sx={(theme) =>  ({':hover':{ background:theme.palette.action.hover }}) }>
                                    <TimelineOppositeContent color="textSecondary">
                                        <Typography variant="body2">
                                            {dayjs(entry?.timestamp).format('MMMM D, YYYY h:mm A')}
                                        </Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot color='primary' sx={{ m:0 }}>
                                            <IconComponent fontSize='small' /> 
                                        </TimelineDot>
                                        <TimelineConnector sx={{ minHeight: isDifferentDay? 250: 70}} />
                                    </TimelineSeparator>
                                    <TimelineContent >
                                        <Box className='flex-col'  >
                                        <Typography variant='body2'>
                                            {snakeCaseToSentence(entry?.field)}
                                        </Typography>
                                            <Box className='flex-row align-center' gap={1}>
                                                <HaloUserAvatar 
                                                    name={user?.name}
                                                    imageUrl={user?.google_picture}
                                                    activityStatus={user?.activity_status}
                                                    slotProps={{
                                                        userAvatar: {
                                                            sx:{ height:20, width:20 }
                                                        }
                                                    }}
                                                />
                                                <Typography variant='caption' color='text.disabled'>
                                                    User: {entry?.user?.name}
                                                </Typography>   
                                            </Box>
                                            <Box className='flex-row' sx={{ml:3.5, gap:0.5}}>
                                                <Typography {...subtitleProps}>
                                                    Prev:
                                                </Typography>   
                                                <Typography {...subtitleProps}>
                                                    {entry?.field === 'resolution_status'? <CaseStatusChip caseStatus={entry?.old_value} fontSize={11}/> : entry?.old_value}
                                                </Typography>
                                            </Box>
                                            <Box className='flex-row' sx={{ml:3.5, gap:0.5, mt:0.2}}>
                                                <Typography {...subtitleProps}>
                                                    New:
                                                </Typography>   
                                                <Typography {...subtitleProps}>
                                                    {entry?.field === 'resolution_status'? <CaseStatusChip caseStatus={entry?.new_value} fontSize={11}/> : entry?.new_value}
                                                </Typography>
                                            </Box>
                                                

                                        </Box>
                                    </TimelineContent>
                                </TimelineItem>
                            </Collapse>
                        );
                    })}
                    </TransitionGroup>
                </Timeline>
            </Box>
        </HaloModal>
    );
}