import { useTheme } from "@emotion/react"
import HeaderFrameLayout from "../../../globalComponents/Headers/HeaderFrames/FrameLayout/FrameLayout"

const TrialPatientFrame = ({ trialPatient, encounterType }) => {
    const { case_age, sex, height, weight, bmi, systolic, diastolic, pulse, respiration_rate } = trialPatient || {}

    const theme = useTheme()

    const firstRow = !encounterType?.toLowerCase().includes('infectious') ? [
        { label: 'Age', value: case_age },
        { label: 'Sex', value: sex },
        { label: 'Hgt', value: height },
        { label: 'Wgt', value: weight },
        { label: 'BMI', value: bmi || '-' },
        { label: 'SBP', value: systolic },
        { label: 'DBP', value: diastolic },
        { label: 'Pulse', value: pulse },
        { label: 'RR', value: respiration_rate || '-' },
    ] : [
        { label: 'Age', value: case_age },
        { label: 'Sex', value: sex }
    ]



    return (
        <HeaderFrameLayout
            avatarColor={theme.palette.primary.main}
            name={trialPatient?.name}
            firstRow={firstRow}
        />
    )
}

export default TrialPatientFrame
