import { Box } from "@mui/material";
import { SmallHeaderBox, ProceedButton, TypographySubtitle } from "../../../globalComponents/SubHeader/components";
import BackButton from "../../../globalComponents/SubHeader/Backbutton";
import { useNavigate } from "react-router-dom";
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";
import { SubHeaderLoader } from "../../../globalComponents/Loaders";
import { useTheme } from "@emotion/react";









export default function CaseSummaryNav({caseType, caseId, isLoading, patientName, isResubmitting }){

    const navigate = useNavigate()
    const theme = useTheme()


    if(isLoading) {
      return(
        <SubHeaderLoader />
      )
    }


    return(
      <>
      <Box height={64}></Box>
      <Box sx={{...theme.stickyHeader}}>
        <SmallHeaderBox >
            <Box className='flex-row-center'>
              <BackButton/>
              <TypographySubtitle>Confirm {stringToTitleCase(patientName)+ "'s case" }</TypographySubtitle>
            </Box>
            <ProceedButton onClick={() => navigate('/caseload/case-finalize/' + caseType + '/' + caseId, {state: {fromCaseSummary: true}})} text={isResubmitting? 'Proceed to Re-submit': 'Proceed to submit'} />
        </SmallHeaderBox>
      </Box>
      </>
    )



}