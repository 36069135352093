import { Box, Typography, Chip } from "@mui/material";
import Divider from '@mui/material/Divider';
import TrialDashboardCases from "../../components/TrialDashboard/TrialDashboardCases"
import HaloSkeleton from "../../../globalComponents/Skeletons/HaloSkeleton";
import { useTutorial } from "../../utils/TutorialContext";



function BasicToolbar({ header, count, isLoading }) {

    return (
        <>
            <Box className='flex-row-between-center' p={'12px 16px'} height={48}>
                <Box className='flex-row-center' gap={'6px'}>
                    <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                        {header}
                    </Typography>
                    <HaloSkeleton loading={isLoading}>
                        <Chip label={!count && count !== 0 ? '-' : count} variant="outlined" size="small" sx={{ fontSize: 12, height: 18 }} />
                    </HaloSkeleton>
                </Box>
            </Box>
            <Divider />
        </>
    )
}


export default function TrialToDoList({ assignments, isLoading }) {

    const {refs} = useTutorial()

    return (
        <div ref={refs.current[2]} >
            <Box className='flex-col' gap={2}>
                <TrialDashboardCases assignments={assignments} Toolbar={BasicToolbar} isLoading={isLoading} />
            </Box>
        </div>
    )
}
