import { Box, Button, Typography } from "@mui/material";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { memo, useState } from "react";
import DelayRemoveCaseModal from "./DelayRemoveCaseModal/DelayRemoveCaseModal";
import {DeleteFillIcon} from "../../../../../assets/icons/MaterialDesignIcons";
import { BorderBottom, BorderColor } from "@mui/icons-material";
import { stickyHeaderProps } from "../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";



const sxProps = (theme) =>({
  p:'8px 16px', 
  alignItems:'center', 
  gap:1, 
  height:55, 
  background:theme.palette.background.paper,
  borderBottom:1,
  borderColor:theme.palette.divider,
  ...stickyHeaderProps
})


 function CaseloadToolbar({ selected, setSelected}){

  const [modalOpen, setOpen] = useState(false)
  const [modalAction, setAction] = useState('delay')
  
  const closeModal = () => setOpen(false)
  const openModal = (action) => {
      setAction(action)
      setOpen(true)
  }

  

    return (
      <>
        <Box className='flex-row' sx={sxProps}>
            <Button
              size='small'
              variant='outlined'
              onClick={() => openModal('delay')}
              sx={{borderRadius:'15px'}}
              startIcon={<MoreTimeIcon sx={{ height:18, width:18 }}/>}>
               <Typography variant="body2">
                  Delay
                </Typography> 
            </Button>
            <Button
              size='small'
              variant='outlined'
              onClick={() => openModal('remove')}
              sx={{borderRadius:'15px'}}
              startIcon={<DeleteFillIcon sx={{ height:18, width:18 }}/>}>
               <Typography variant="body2">
                  Remove
                </Typography> 
            </Button>
        </Box>
        <DelayRemoveCaseModal 
          open={modalOpen} 
          closeModal={closeModal} 
          modalAction={modalAction}
          selected={selected}
          setSelected={setSelected}/>
        </>
    )


}

export default memo(CaseloadToolbar)