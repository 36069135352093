import { useDispatch, useSelector } from "react-redux"
import { selectCurrentUser, updateUserInfo } from "../../auth/authSlice"
import { useForm } from "react-hook-form";
import axiosAuth from "../../../utils/apiUtils/axiosAuth";
import { enqueueSnackbar } from "notistack";
import EditUserInfoSkeleton from "../components/EditUserInfoSkeleton";
import { HaloTextField } from "../../globalComponents/ReactHookFormFields";

function EditPreferredTitle() {
    const dispatch = useDispatch()
    const {preferredTitle} = useSelector(selectCurrentUser)
    const defaultValues = {preferred_title: preferredTitle}
    const {control, getValues, trigger} = useForm({ defaultValues })

    const updateUserInfoHandler = async(event) => {
        event.preventDefault()
        let valid = await trigger()
        if(!valid) return; 

        let userInfo = getValues()

        axiosAuth({
            url: 'users/update-user-info',
            method: 'PUT',
            data: {preferred_title: userInfo.preferred_title}
        }).then(() => {
            dispatch(updateUserInfo({infoType: 'preferred_title', updatedValue: userInfo.preferred_title}))
            enqueueSnackbar('Preferred title successfully updated', {variant: 'success'})
        })
        .catch(() => {
            enqueueSnackbar('Preferred title failed to update, please try again', {variant:'error'})
        })
    }

    return (
    <EditUserInfoSkeleton infoType='Preferred title' height={192} updateHandler={updateUserInfoHandler}>
        <HaloTextField
            label={'Preferred title'}
            name='preferred_title'
            control={control}
        />
    </EditUserInfoSkeleton>
    )
}

export default EditPreferredTitle
