import CSRFToken from "../CSRFToken"
import { Box, Modal } from "@mui/material"
import signInBackground from "../../../assets/images/generic/signIn.png"
import beyondMDLogo from "../../../assets/images/beyondMD/beyondMD.png"
import beyondMDDarkLogo from "../../../assets/images/beyondMD/beyondMDDark.png"
import { useTheme } from '@emotion/react';




function ModalWithHaloLogo({ children, sx }) {

    const theme = useTheme()

    return (
        <Box
            sx={{
                height: '100vh',
                backgroundImage: `url(${signInBackground})`,
                backgroundSize: 'cover',    // cover the entire viewport
                backgroundPosition: 'center', // center the image
                backgroundRepeat: 'no-repeat', // don't repeat the image
            }}
        >
            <CSRFToken />
            <Modal open={true} disableEnforceFocus>
                <Box
                    className='flex-col'
                    sx={{
                        ...theme.modal.centered,
                        ...theme.standardBox,
                        maxWidth: 480,
                        width: '100%',
                        maxHeight: '95vh',
                        overflow: 'auto',
                        p: '48px 64px',
                        gap: 3,
                        border: 'none',
                        '&:focus-visible': { outline: 'none' },
                        alignItems:'center',
                        ...sx
                    }}>
                    <img src={theme.palette.mode === 'dark' ? beyondMDDarkLogo : beyondMDLogo} alt='BeyondMD Logo' height={40} width={227} />
                    {children}
                </Box>
            </Modal>
        </Box>
    )
}

export default ModalWithHaloLogo
