import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import HeaderFrameLayout from "../../../../globalComponents/Headers/HeaderFrames/FrameLayout/FrameLayout";
import { selectCurrentUser } from "../../../../auth/authSlice";
import { useSelector } from 'react-redux'






export default function ClinicianFrame({
    billableEvent,
    encounter,
    pendingQA,
    compensationObjs,
    caseInfo
}) {


    const theme = useTheme()
    const { id: userId, groups, name } = useSelector(selectCurrentUser)

    const billingPts = pendingQA ? 'QA pending' : billableEvent?.point_value
    const compensationPts = pendingQA ? 'QA pending' : compensationObjs?.map(comp => comp?.points).join(' + ')



    const generatePtsRow = () => {
        if (groups?.['Internal Staff'] && (billableEvent || pendingQA)) {
            return {
                label: 'Billing pts.', value: billingPts,
                valueSx: pendingQA ? { color: theme.palette.warning.main } : null
            }
        }

        else if ( (compensationObjs || pendingQA)) {
            return {
                label: 'Compensation pts.', value: compensationPts,
                valueSx: pendingQA ? { color: theme.palette.warning.main } : null
            }
        }
        else {
            return
        }
    }

    const firstRow = [
        { label: 'Last submitted', value: dayjs(encounter?.date_submitted).format('LL') || '-' },
        { label: 'Duration', value: encounter?.time_spent_reviewing_entered  ? encounter?.time_spent_reviewing_entered  + ' min' : '-' },
        generatePtsRow(),
        { label: 'Site', value: encounter?.patient?.site?.site_name },
    ]

    const secondRow = [
        { label: 'Treating Provider', value: encounter?.treating_provider  },
        { label: 'CPT Codes', value: billableEvent?.cpt_codes?.map(event => event.cpt_code.code).join(', ') },
    ]

    return (
        <HeaderFrameLayout
            avatarColor={theme.avatar.primary}
            name={caseInfo?.case_user}
            namesArray={compensationObjs?.map(comp => comp?.user?.name)}
            firstRow={firstRow}
            secondRow={secondRow} />
    )
}