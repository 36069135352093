


const formatFileFormData = (values, filesKey='files') => {

    const formData = new FormData()
    const files = values[filesKey]
    const { [filesKey]: _ , ...additionalData } = values


    files.forEach((file, index) => {
        formData.append(filesKey, file);  
    });
    Object.keys(additionalData).forEach((key) => {
        const value = additionalData[key];

        if (typeof value === 'object' && value !== null) {
            formData.append(key, JSON.stringify(value)); // note that these will need to be re converted to json on the backend
        } else {
            formData.append(key, value);
        }
    });

    return formData

}

export default formatFileFormData;