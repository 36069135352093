import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import TrialDataInput from "./TrialDataInput/TrialDataInput";
import TrialAnalysis from "./TrialAnalysis/TrialAnalysis";
import TrialRecommendations from "./TrialRecommendations/TrialRecommendations";


export default function TrialPathologySummaryContainer({ data, isLoading, allowEdits }) {

    const { encounterType, trialEncounterId } = useParams()

    const { trial_encounter } = data || {};
    const { patient, trial_clinical_findings, resistance_genes, drug_interactions } = trial_encounter || {};
    const { medications, allergies, icd10_references } = patient || {};

    const encounterInfo = {
        case_type: encounterType,
        case_id: trialEncounterId
    }


    return (
        <>
            <Box p={3} className='flex-col' gap={3}>
                <TrialDataInput
                    meds={medications}
                    allergies={allergies}
                    diagnoses={icd10_references}
                    readOnlyView={true}
                    allowEdits={allowEdits}
                    caseInfo={encounterInfo}
                    isLoading={isLoading}
                />
                <TrialAnalysis
                    clinicalFindings={trial_clinical_findings}
                    resistanceGenes={resistance_genes}
                    drugInteractions={drug_interactions}
                    readOnlyView={true}
                    allowEdits={allowEdits}
                    caseInfo={encounterInfo}
                    isLoading={isLoading}
                />
                <TrialRecommendations
                    meds={medications}
                    encounter={trial_encounter}
                    readOnlyView={true}
                    allowEdits={allowEdits}
                    caseInfo={encounterInfo}
                    isLoading={isLoading}
                />
            </Box>
        </>
    );
}
