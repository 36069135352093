import { Box } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectCurrentCase } from "../rosterSlice";
import PathologyFinalizeContainer from "../../pathology/components/PathologyReview/PathologyFinalizeContainer";
import InfectiousDiseaseFinalizeContainer from "../../infectiousDisease/components/infectiousDiseaseReview/InfectiousDiseaseFinalizeContainer";
import SuccessMessage from "../components/CaseSuccessMessage/SuccessMessage";
import CaseFinalizationNav from "../components/CaseNavHeaders/CaseFinalizationNav";
import { useUpdateCaseStatus } from "../hooks/useUpdateCaseStatus";
import { useGetFinalizeCaseInfoQuery } from "../rosterAPISlice";
import dayjs from "dayjs";
import { calculateAge } from "../../../utils/dateUtils/calculateAge";
import GenomicsFinalizeContainer from "../../genomics/components/GeneomicsReview/GenomicsFinalizeContainer";




export default function CaseFinalization() {

    const location = useLocation()
    const navigate = useNavigate()


    let { caseType, caseId } = useParams()
    const patient = useSelector(selectCurrentCase)?.patient
    const [isSubmitted, setSubmitted] = useState(false)
    const [canRender, setCanRender] = useState(false)
    const { data, isLoading, isFetching } = useGetFinalizeCaseInfoQuery(caseId, { refetchOnMountOrArgChange: true })
    const updateCaseStatus = useUpdateCaseStatus()
    const sharedProps = {
        isUnder18: calculateAge(dayjs(patient?.date_of_birth)) < 18,
        isLoading: isLoading || isFetching,
        setSubmitted,
        treatingProvider: data?.encounter?.treating_provider,
        sessionDuration: data?.total_session_duration_in_minutes,
    }

    const { case_res_status, encounter, is_case_owner } = data || {}
    const {name:patientName, date_of_birth} = encounter?.patient || {}


    const checkNavigation = useCallback(() => {

        if(!data) {
            return
        }
        
        if (data && (case_res_status === 'completed' || case_res_status === 'pending_results')) {
            navigate(`/caseload/case-details/${caseId}`)
        }
        else if (!location.state?.fromCaseSummary && data && case_res_status === 'in_progress' && is_case_owner) {
            navigate(`/caseload/case-summary/${caseType}/${caseId}`)    
        }
        else if(!location.state?.fromCaseSummary && data) {
            navigate('/caseload')
        }
        else {
            setCanRender(true)
        }
    }, [location, data, navigate, case_res_status, caseType, encounter, is_case_owner, caseId])

    useEffect(() => {
        checkNavigation()
    }, [checkNavigation])

    useEffect(() => {

        if (isSubmitted) {
            updateCaseStatus(caseId, patient.id, 'completed')
        }

    }, [isSubmitted])

    if(!canRender) {
        return null
    } 

    return(
        <>
            <CaseFinalizationNav isSubmitted={isSubmitted} patientName={patientName} isLoading={isLoading || isFetching} />

            <Box p={3}>
                {isSubmitted?
                    <SuccessMessage caseType={caseType} patient={patient} caseId={caseId}/>
                    :
                    caseType.toLocaleLowerCase().includes('infectious disease') ?

                        <InfectiousDiseaseFinalizeContainer {...sharedProps}/>  
                        : 
                    caseType.toLocaleLowerCase().includes('pathology') ?
                        <PathologyFinalizeContainer {...sharedProps}/>      
                        :
                    caseType.toLocaleLowerCase().includes('gx') ?
                        <GenomicsFinalizeContainer {...sharedProps}/>
                        :
                        null
                }
            </Box>
        </>
    )
}
