import React from 'react';
import { Box, Button } from '@mui/material';
import { stringToTitleCase } from '../../../../../../utils/stringUtils/titleCase';
import { ActionButton } from '../../../../../globalComponents/Buttons/ActionButton/ActionButton';

const DRModalFooter = ({ 
    clearModalAction, 
    formik, 
    modalAction, 
    isRemoving 
    }) => {

        const handleValidate = async() => {
            const errors = await formik.validateForm()
    
            if(Object.keys(errors).length === 0){
                formik.handleSubmit()
            }
        }
        
    return (
        <Box className='flex-row' sx={{ justifyContent: 'flex-end', alignItems: 'center', p: '12px 8px' }}>
            <ActionButton onClick={clearModalAction} color='inherit' disabled={isRemoving}>
                Cancel
            </ActionButton>
            <ActionButton
                onClick={handleValidate}
                loading={isRemoving}
                disabled={isRemoving}
                color="error"
                variant='contained'>
                {stringToTitleCase(modalAction) + ' Case'}
            </ActionButton>
        </Box>
    );
};

export default DRModalFooter;
