import { Skeleton } from "@mui/material";



export default function HaloSkeleton({
    loading = false, 
    children, 
    ...otherProps
}){

    if(!children && loading){
        return(
            <Skeleton {...otherProps}/>
        )
    }


    if(loading){
        return(
            <Skeleton  {...otherProps}>
                {children}
            </Skeleton>
        )
    }


    return children
}

