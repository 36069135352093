import { Box, Typography } from "@mui/material";


export default function InvalidTrialLink(){

return(

    <Box className='flex-col-center' sx={{width: '100%', height: '100vh'}}>
        <Box className='flex-row-center' sx={{ m:'0 auto', height: '100%' }}>
            <Box className='flex-col-center' sx={{height:'80%'}} gap={3}>
                <Box className='flex-col-center' sx={{minHeight: 0}}>
                    <img style={{display: 'block', width: '100%', height: '80%', objectFit:'cover'} } src="/images/link_not_valid.svg" alt='guy holding box'/>
                </Box>
                <Box className='flex-col-center' maxWidth={598} gap={2}>
                    <Typography variant='h5'>This link is no longer valid</Typography>
                    <Typography sx={{textAlign:'center'}}>It may have been used or it may have expired. Please contact your referring clinician or CW Hall at cw@beyondmd.care to send you a new link. </Typography>
                </Box>
            </Box>
        </Box>
    </Box>
)


}
