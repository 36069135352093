import { ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';





export function PresetDateListItem({label, selected, handlePresetClick}){




return(
    <ListItem disablePadding dense>
        <ListItemButton onClick={handlePresetClick(label)} selected={selected}>
                <ListItemIcon sx={{visibility: selected ? 'visible' : 'hidden', minWidth:32}}>
                    <CheckIcon/>
                </ListItemIcon>
            <ListItemText primary={label} />
        </ListItemButton>
    </ListItem>
)

}