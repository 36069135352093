import { AlertTitle, Alert, Collapse, IconButton } from "@mui/material"
import { useState } from "react"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';


function DismissibleAlert({ severity, title, msg, optionalDismissLogic = false }) {

  const [show, setShow] = useState(true)

  return (

    <Collapse in={show} timeout="auto">
      <Alert
        severity={severity}
        sx={{ borderRadius: '8px', p: '6px 16px' }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShow(false)
              if (optionalDismissLogic) {
                optionalDismissLogic()
              }
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        iconMapping={{
          //can add other icons for different severities 
          success: <CheckCircleOutlineIcon fontSize="inherit" />,
        }}
      >
        <AlertTitle>{title}</AlertTitle>
        {msg}
      </Alert>
    </Collapse>
  )
}

export default DismissibleAlert
