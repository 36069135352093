
import { Box, List, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { PresetDateListItem } from "./PresetDateListItem";
import { useSelector } from "react-redux";
import { selecteRosterMgmtPreset } from "../../../../../../../admin/adminSlice";

const presets = [
    'Today',
    'Yesterday',
    'Last 7 days',
    'Last 30 days',
    'This Week',
    'Last Week',
    'This Month',
    'Last Month'
];

function getDates(preset){
    let dates = [null, null]
    const today = dayjs()

    switch (preset) {
        case 'Today':
            dates = [today, null];
            break;
        case 'Yesterday':
            dates = [today.subtract(1, 'day'), null];
            break;
        case 'Last 7 days':
            dates = [today.subtract(7, 'day'), today];
            break;
        case 'Last 30 days':
            dates = [today.subtract(30, 'day'), today];
            break;
        case 'This Week':
            dates = [today.startOf('week'), today.endOf('week')];
            break;
        case 'Last Week':
            dates = [today.subtract(1, 'week').startOf('week'), today.subtract(1, 'week').endOf('week')];
            break;
        case 'This Month':
            dates = [today.startOf('month'), today.endOf('month')];
            break;
        case 'Last Month':
            dates = [today.subtract(1, 'month').startOf('month'), today.subtract(1, 'month').endOf('month')];
            break;
        default:
            break;
    }

    return dates
}


export function PresetDateList({setDates, setPresetValue , selectedPreset}){


    const handlePresetClick = (preset) => (e) => {
        //might have to use dayjsEST iin the date utils folder
        const dates = getDates(preset)
        
        setDates(dates);
        setPresetValue(preset)
    }


    return(
        <Box className='flex-col' width={200}>
            <Box p={2}>
                <Typography variant='subtitle2'>Date presets</Typography>
            </Box>
            <List >
                {
                    presets?.map((preset) => (
                        <PresetDateListItem 
                            key={preset}
                            label={preset} 
                            selected={preset === selectedPreset}
                            handlePresetClick={handlePresetClick}/>
                    ))
                }
            </List>
        </Box>
    )

}