

export function stringToCamelCase(input) {
    if (typeof input !== 'string') {
      throw new Error('Input must be a string');
    }
  
    const words = input.split(' ');
    if (words.length < 2) {
      return input.toLowerCase();
    }
  
    const firstWord = words[0].toLowerCase();
    const secondWord = words[1].charAt(0).toUpperCase() + words[1].slice(1).toLowerCase();
  
    return firstWord + secondWord;
  } 


  function toCamelCase(snakeStr) {
      return snakeStr.replace(/(_\w)/g, match => match[1]?.toUpperCase());
  }
  
export function keysToCamelCase(obj) {
      let camelCaseData = {};
      for (let key in obj) {
          camelCaseData[toCamelCase(key)] = obj[key];
      }
      return camelCaseData;
  }

  