import { Avatar, Box, IconButton, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React from "react";
import { useNavigate } from "react-router-dom";


export default function SummaryContainer({
    number, 
    title, 
    children, 
    disableEdits,
    isLoading,
    baseURL,
    caseInfo={}}){

    const readOnly = true 
    //this used to be state but the design of the component's parent workflow has changed
    //this will need to be refactored in the future
    const theme = useTheme()

    const navigate = useNavigate()



    //what's a better way of allowing users to make same day edits than just redirecting?
    //this will be okay but there needs to be a check to prevent users from simply entering in the URL
        // maybe include this check in the group permissions?

    const handleClick = () => navigate((baseURL || '/caseload/case-review/') + caseInfo?.case_type + '/' + caseInfo?.case_id)



    return(
        <Box className='flex-col'  sx={{
                ...theme.shadowedBox,
                p:2,
                gap:3,
                '.Mui-disabled' :{
                    opacity: '1 !important'
                },
                'textarea, input' :{
                   WebkitTextFillColor: `${theme.palette.text.secondary} !important`,
                
                }
                }}>
            <Box className="flex-row-between-center" sx={{ p:'6px 0px 0px' }}>
                <Box className='flex-row' sx={{ alignItems:'center', gap:2}}>
                {
                    isLoading?
                    <>
                        <Skeleton variant="circular" height={40} width={40}/> 
                        <Skeleton height={28} width={80}/>
                    </>

                    :
                    <>
                        <Avatar 
                            sx={{
                                color:theme.avatar.secondary,
                                background:theme.palette.background.paper,
                                border:`2px solid ${theme.avatar.secondary}`
                            }}
                            >{number}</Avatar>

                        <Typography sx={{ ...theme.typography.subtitle1 , fontWeight:600, color: theme.palette.text.primary }}>
                            {title}
                        </Typography>
                    </>

                }


                </Box>
                {
                    isLoading? 
                        <Skeleton width={40} height={40}/>
                    :
                    !disableEdits &&
                        <IconButton onClick={handleClick}>
                            <EditOutlinedIcon/>
                        </IconButton>

                }
            </Box>



            {
            isLoading ?
                <>
                <Skeleton height={200} width={'100%'}></Skeleton>
                </>
                :
                React.Children.map(children, child => {
                    if(React.isValidElement(child)){
                        return React.cloneElement(child, { readOnly});
                    }
                    return child;
                })
            }
        </Box>
    )
}
