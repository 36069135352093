import { useTheme } from "@emotion/react"
import { useEffect, useState } from "react"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import getNumberIcon from "../../../assets/icons/NumberIcons";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box } from "@mui/material";

const iconSize = {height:'35px', width:'35px'}

export default function CaseAccordion({number, title, children, iconVariant='number', initialOpenState=false}){
    const theme = useTheme()
    const [isOpen, setOpen] = useState(initialOpenState)
    const handleChange = () => setOpen(!isOpen);
    

    return(
        <Accordion expanded={isOpen} onChange={handleChange} sx={{ background: `${theme.palette.background.paper} !important`, boxShadow: theme.customShadows.shadow2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Box className='flex-row' sx={{ width: '33%', flexShrink: 0, alignItems:'center', gap:2 }}>

                    {
                        iconVariant === 'error'?
                            <ErrorOutlineIcon  sx={{...iconSize, color:theme.palette.error.main}}/>
                        :
                        getNumberIcon(number, '#10A5E7', iconSize)
                    }
                    <Typography 
                      sx={{ ...theme.typography.subtitle1 , fontWeight:600, color: theme.palette.text.primary }}>
                        {title}
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails className='flex-col' sx={{gap:3, background:theme.palette.mode === 'dark'? theme.palette.grey[900] : null}}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}