import { Box, Typography } from "@mui/material";





export const TitleAndBodyBox = ({title, body, gap='4px', width, bodyColor, p , element=null}) => (
    <Box className='flex-col' gap={gap} width={width} p={p}>
      <Typography variant="body2" color='text.disabled'>{title}</Typography>
      {
        element ?
          element
        :
          <Typography variant="body2" sx={{color: bodyColor}}>{body || '-'}</Typography>

      }
    </Box>
  
  )