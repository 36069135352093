import dayjs from 'dayjs';
import CustomTextFieldCell from '../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell';
import CRUDDataGrid from '../../../../../globalComponents/DataGrid/CRUDDataGrid';
import CustomDateFieldCell from '../../../../../globalComponents/DataGrid/subComponents/customCells/CustomDateFieldCell';
import TextFieldPortalCell from '../../../../../globalComponents/DataGrid/subComponents/customCells/TextFieldPortalCell';
import { useManageTrialClinicalFindingsMutation } from '../../../../trialUserAPISlice';
import { LabIcon } from '../../../../../../assets/icons/MaterialDesignIcons';

const columns = [
    {
        field: 'type',
        headerName: 'Type',
        flex: 1.5,
        headerClassName: 'required-col',
        editable: true,
        renderEditCell: (params) => (<CustomTextFieldCell {...params} />),
    },
    {
        field: 'date',
        headerName: 'Date',
        type: 'date',
        width: 120,
        editable: true,
        headerClassName: 'required-col',
        valueFormatter: (params) => params?.value ? dayjs(params?.value).format('MMM DD, YYYY') : null,
        renderEditCell: (params) => (<CustomDateFieldCell {...params} />)
    },
    {
        field: 'renal_function',
        headerName: 'Renal Function',
        flex: 1,
        editable: true
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 2,
        headerClassName: 'required-col',
        editable: true,
        type: 'string',
        renderEditCell: (params) => (<TextFieldPortalCell {...params} />)
    },
]

export default function TrialClinicalFindings({ readOnly, clinicalFindings }) {

    return (
        <>
            <CRUDDataGrid
                title={'Clinical Findings'}
                GridIcon={LabIcon}
                columns={columns}
                initRows={clinicalFindings}
                mutation={useManageTrialClinicalFindingsMutation}
                fieldToFocus='type'
                requiredFields={['type', 'date', 'description']}
                isReadOnly={readOnly}
                isTrialReview={true}
            />
        </>
    )
}
