import dayjs from 'dayjs';
import CustomTextFieldCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell";
import CRUDDataGrid from '../../../../../globalComponents/DataGrid/CRUDDataGrid';
import { useManageImagingValuesMutation } from '../../../../pathologyAPISlice';
import CustomDateFieldCell from '../../../../../globalComponents/DataGrid/subComponents/customCells/CustomDateFieldCell';
import QACommentsContainer from '../../../../../qualityAssurance/components/QAFeedback/QACommentsContainer';
import TextFieldPortalCell from '../../../../../globalComponents/DataGrid/subComponents/customCells/TextFieldPortalCell';
import { RadiologyIcon } from '../../../../../../assets/icons/MaterialDesignIcons';



export default function ImagingValues({readOnly, imagingValues, qaObj}){

  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      flex:1.5,
      editable: !readOnly, 
      headerClassName: readOnly? '' : 'required-col',
      renderEditCell: (params) => (
        <CustomTextFieldCell {...params} />
      ),
    },
    { 
      field: 'date', 
      headerName: 'Date',
      type:'date',
      width:120,
      editable: !readOnly,
      headerClassName: readOnly? '' : 'required-col',
      valueFormatter: (value) => value? dayjs(value).format('MMM DD, YYYY') : null,
      renderEditCell: (params) => (
        <CustomDateFieldCell {...params} />
      )
    },
    { 
      field: 'description', 
      headerName: 'Description',
      flex:2,
      editable: !readOnly ,
      headerClassName: readOnly? '' : 'required-col',
      renderEditCell: (params) => (
        <TextFieldPortalCell {...params} />
      )
    },
  ]



  return(
    <>
      <CRUDDataGrid
        title={'Imaging Values'}
        columns={columns} 
        initRows={imagingValues} 
        mutation={useManageImagingValuesMutation}
        GridIcon={RadiologyIcon}
        fieldToFocus='type'
        requiredFields={ ['type','date', 'description'] }
        isReadOnly={readOnly}/>
        {
        qaObj && 
        <QACommentsContainer fieldReview={qaObj?.field_reviews["Imaging Values"]} /> 
      }
    </>
  )
}
  


