import { Tab, Tabs, Typography } from "@mui/material";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { SettingsOutlinedIcon, PayslipOutlinedIcon } from "../../../../assets/icons/MaterialDesignIcons";
import { useTheme } from '@emotion/react';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../auth/authSlice";


const tabSx = {
    height: 46,
    pl: 3,
    '& .MuiTab-root': {
        p: '12px',
        minHeight: 0,
    }
}

function TabLabel({ label }) {
    const theme = useTheme()

    return <Typography variant='subtitle2' color={theme.palette.text.primary}>{label}</Typography>
}


function ProfileTabs({ tabValue, handleTabChange, userGroups }) {

    const { frontEndUserType } = useSelector(selectCurrentUser)

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={tabSx}>

                <Tab
                    label={<TabLabel label='My info' />}
                    value='My info'
                    icon={<PersonOutlinedIcon color='primary' sx={{ height: 20, width: 20 }} />}
                    iconPosition="start"
                />

                {/* will implement at a later time - only show payslip if user is a clinician */}
                {/* <Tab 
            label={<TabLabel label='My payslips'/>} 
            value="My payslips" 
            icon={<PayslipOutlinedIcon color='primary' sx={{height:20, width:20}}/>} 
            iconPosition='start'/> */}

                {frontEndUserType !== 'Trial user' &&
                    <Tab
                        label={<TabLabel label='Referrals' />}
                        value="Referrals"
                        icon={<ContentPasteIcon color='primary' sx={{ height: 20, width: 20 }} />}
                        iconPosition='start' />}


                {/* waiting on Tom mock */}
                {/* <Tab 
            label={<TabLabel label='Settings'/>} 
            value="Settings" 
            icon={<SettingsOutlinedIcon variant='outline' color='primary' sx={{height:20, width:20}}/>} 
            iconPosition='start'/> */}

            </Tabs>
        </>
    )
}

export default ProfileTabs
