import { Box, Collapse, Typography } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import HistoryEntry from "./HistoryEntry/HistoryEntry"
import { memo, useState } from "react";
import DefaultNoRowsOverlay from "../../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay"
import FilterMenu from "./FilterMenu/FilterMenu";
import { TransitionGroup } from 'react-transition-group';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../auth/authSlice";


function PatientHistory({ history }){

    const [filter, setFilter] = useState('')
    const currentUser = useSelector(selectCurrentUser)
    const isDeveloper = currentUser?.groups?.['Developer']
    const isRosterAdmin = currentUser?.groups?.['Roster Management | Admin']
    const hasHistory = history?.length > 0

    const [ target, setTarget ] = useState()
    const [ open, setOpen ] = useState('')


    const handleClose = (e) => {
        e?.stopPropagation()
        setOpen('')
    }


    return(
        <Box  className='flex-col' gap={2} >
            <Box className='flex-row-between' alignItems={'center'}>
                <Typography variant='subtitle2' sx={{ fontWeight:600}}>
                    Activity
                </Typography>
                <FilterMenu 
                    setFilter={setFilter} 
                    filter={filter} 
                    history={history}/>
            </Box>
            <Box className='flex-col' sx={(theme) =>({...theme.standardBox, pt:2, gap:1})}>
               
                <Timeline sx={{ p:0, m:0, [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0, }, }}>
                    {
                        hasHistory?
                        <TransitionGroup>
                            {
                                history?.filter(obj => filter ? obj.type === filter : obj )?.map((entry, index) =>{
                                    return(
                                        <Collapse key={index}>
                                            <HistoryEntry 
                                                entry={entry} 
                                                isDeveloper={isDeveloper}
                                                isRosterAdmin={isRosterAdmin}
                                                handleClose={handleClose}
                                                setTarget={setTarget}
                                                target={target}
                                                setOpen={setOpen}
                                                open={open}
                                                />
                                        </Collapse>
                                    )
                                })
                            }
                        </TransitionGroup>
                        :
                        !hasHistory?
                            <Box mt={3} mb={3}>
                                <DefaultNoRowsOverlay message="No history"/> 
                            </Box>
                            : 
                            null
                    }
                </Timeline>
            </Box>
        </Box>
    )


}


export default memo(PatientHistory)
