
import { Box, Divider } from '@mui/material';
import RosterGridTabs from './Tabs/RosterGridTabs';
import { useState } from 'react';
import RMSitesGrid from './Grids/RMSitesGrid';
import { useDispatch, useSelector } from 'react-redux';
import { selectRosterMgmtPageSize, setRosterMgmtPageSize } from '../../../../../admin/adminSlice';
import DefaultNoRowsOverlay from '../../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { RMCasesGrid } from './Grids/RMCasesGrid/RMCasesGrid';
import { linkedRowProps, stickyHeaderProps } from '../../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps';
import RMCliniciansGrid from './Grids/RMCliniciansGrid/RMCliniciansGrid';
import { setRosterMgmtTab, selectCurrentRosterMgmtTab } from '../../../../../admin/adminSlice';

const pageSizeOptions = [5, 10, 15, 25, 50, 100]
const gridProps = { 
    sx:{
        border:'none',
        minHeight:500,
        '--DataGrid-overlayHeight': '300px',
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
        },
        ...stickyHeaderProps,
    },
    autoHeight:true,
    slots:{
        noRowsOverlay:DefaultNoRowsOverlay,
    },

}

export default function RosterGrids({dates}){

    const dispatch = useDispatch()
    const pageSize = useSelector(selectRosterMgmtPageSize)
    const tabValue = useSelector(selectCurrentRosterMgmtTab)

    const apiRefClinician = useGridApiRef()
    const apiRefSite = useGridApiRef()


    const handleTabChange = (e, newValue) => dispatch(setRosterMgmtTab(newValue))

    const setPageSize = ( newSize ) => dispatch(setRosterMgmtPageSize(newSize))

    return (

        <Box sx={(theme) => ({...theme.standardBox})}>
            <RosterGridTabs 
                tabValue={tabValue} 
                handleTabChange={handleTabChange}
                apiRef={ tabValue === 'Clinicians' ? apiRefClinician : apiRefSite }/>
            <Divider />
            <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                <RMCliniciansGrid
                    gridProps={gridProps}
                    dates={dates} 
                    pageSize={pageSize} 
                    pageSizeOptions={pageSizeOptions} 
                    setPageSize={setPageSize}
                    apiRef={apiRefClinician}
                    visible={tabValue === 'Clinicians'}/>
                <RMSitesGrid 
                    gridProps={gridProps}
                    pageSize={pageSize} 
                    pageSizeOptions={pageSizeOptions} 
                    setPageSize={setPageSize}
                    apiRef={apiRefSite}
                    visible={tabValue === 'Sites'}/>
                <RMCasesGrid
                    gridProps={gridProps}
                    pageSize={pageSize} 
                    pageSizeOptions={pageSizeOptions}
                    setPageSize={setPageSize}
                    visible={tabValue === "Today's cases"}
                    />
            </Box>
                 
                
                    
            
        </Box>

    )
}