import { List, ListItem, Divider, Box } from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { TitleAndBodyBox } from '../../../../../../../globalComponents/TextGroups/TitleAndBodyBox';
import DialogWithRadioOptions from '../../../../../../../globalComponents/Dialog/DialogWithRadioOptions';
import ClinicianRosterSettingsModal from '../../../../ClinicianRosterSettingsModal/ClinicianRosterSettingsModal';
import { useState } from 'react';
import { useGetCaseTypesQuery } from '../../../../../../rosterAPISlice';
import { useGetUserCaseTypePermissionsQuery, useModifyUserCaseTypePermissionsMutation, useModifyUserTrialReviewerPermissionMutation, useModifyUserEmploymentTypeMutation } from '../../../../../../rosterAdminAPISlice';
import { useParams } from 'react-router-dom';

export function Permissions({ info, allowedCaseTypes }) {

    const permissionItems = [
        { label: 'Employment type', value: info?.employment_type },
        { label: 'Trial reviewer permission', value: info?.is_trial_reviewer },
        { label: 'Case type permission', value: allowedCaseTypes.map(ct => ct.name).join(', ') },
    ];

    const { clinicianId } = useParams();

    const [open, setOpen] = useState(false);

    const handleItemClick = (item) => {
        setOpen(item.label);
    };

    const handleClose = (e, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <List sx={{ width: '100%', p: 0 }}>
                {permissionItems.map((item, index) => (
                    <Box key={item.label}>
                        <ListItem
                            button
                            onClick={() => handleItemClick(item)}
                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '0px 8px 0px 0px' }}
                        >
                            <TitleAndBodyBox title={item.label} body={item.value} />
                            <ChevronRightIcon />
                        </ListItem>
                        {index !== permissionItems.length - 1 && <Divider sx={{ m: '8px 0px 8px 0px' }} />}
                    </Box>))}
            </List>
            {open === 'Trial reviewer permission' &&
                <DialogWithRadioOptions
                    open={open === 'Trial reviewer permission'}
                    onClose={handleClose}
                    title={`Edit ${open}`}
                    options={[{
                        label: 'Reviewer',
                        value: 'Reviewer'
                    }, 
                    {
                        label: 'Non-reviewer',
                        value: 'Non-reviewer'
                    }]}
                    initialValue={permissionItems.find(item => item.label === 'Trial reviewer permission').value}
                    description={`Update the trial reviewer permission for ${info?.name}`}
                    updateMutation={useModifyUserTrialReviewerPermissionMutation}
                    userId={clinicianId}
                />}

            {open === 'Employment type' &&
                <DialogWithRadioOptions
                    open={open === 'Employment type'}
                    onClose={handleClose}
                    title={`Edit ${open}`}
                    options={[{
                        label: 'W2',
                        value: 'W2'
                    },
                    {
                        label: '1099',
                        value: '1099'
                    }]}
                    initialValue={permissionItems.find(item => item.label === 'Employment type').value}
                    description={`Update the employment type for ${info?.name}`}
                    updateMutation={useModifyUserEmploymentTypeMutation}
                    userId={clinicianId}
                />}

            {open === 'Case type permission' &&
                <ClinicianRosterSettingsModal
                    open={open === 'Case type permission'}
                    handleClose={handleClose}
                    userIds={[clinicianId]}
                    allSettingOptionsQuery={useGetCaseTypesQuery}
                    assignedSettingOptionsQuery={useGetUserCaseTypePermissionsQuery}
                    settingsMutation={useModifyUserCaseTypePermissionsMutation}
                    title={'Update case type permissions'}
                    settingType='caseTypes'
                    name={info?.name}
                />}
        </>
    );
}
