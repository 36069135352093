import { Typography, Box, IconButton, Avatar, Link } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useManagePatientMutation } from '../../../patientAPISlice';
import { useNavigate} from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { stringToTitleCase } from '../../../../../utils/stringUtils/titleCase'
import { showGenericValidationError } from '../../../../../app/errors/genericErrors';
import { LabeledBox } from '../../../../globalComponents/Formik/QuickEditFields';
import { useForm } from 'react-hook-form';
import { HaloAutocomplete, HaloDatePicker, HaloNumberField, HaloTextField } from '../../../../globalComponents/ReactHookFormFields';
import dayjs from 'dayjs';
import WatchedAge from '../../PatientProfile/PatientVerticalCard/WatchedFields/WatchedAge';
import WatchedBMI from '../../PatientProfile/PatientVerticalCard/WatchedFields/WatchedBMI';
import HaloSelectField from '../../../../globalComponents/ReactHookFormFields/SelectFields/HaloSelectField';
import { formatSex } from '../../../../../utils/stringUtils/formatSex';
import { usePrefetchEndpoint } from '../../../../../utils/apiUtils/usePrefetchEndpoint';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { USStates } from '../../../../../utils/formUtils/menuData/USStates';


export default function PatientDetails(props){

    const {patient, theme} = props
    const navigate = useNavigate()
    const [managePatient] = useManagePatientMutation()
    const prefetch = usePrefetchEndpoint()
    const {control,  handleSubmit, reset, formState:{ isDirty, isValid}} = useForm({
        defaultValues:{
            date_of_birth: dayjs(patient?.date_of_birth) || null,
            sex: formatSex(patient?.sex) || '',
            height: patient?.vitals.height === 0.0? '' : patient?.vitals?.height?.toString(),
            weight: patient?.vitals.weight === 0.0? '' : patient?.vitals?.weight?.toString(),
            pulse: patient?.vitals.pulse?.toString() || '',
            respiration_rate: patient?.vitals.respiration_rate?.toString() || '',
            systolic: patient?.vitals?.systolic?.toString() || '',
            diastolic: patient?.vitals?.diastolic?.toString() || '',
            city: patient?.city || '',
            state: patient?.state || '',
            zip: patient?.zip?.toString() || ''
        },
    })


    const submit =  (values) => {
        if(isValid && isDirty){
            managePatient(
                {
                    method:'PUT',
                    body:{
                        patient_id: patient.id,
                        ...values
                    },
                }).unwrap()
                .then(res => {
                    reset({}, { keepValues: true, keepDirty:false })
                })
                .catch(err =>{
                    showGenericValidationError(err)
                })
        }     
    };

    return(
        <>
        <Box className='flex-row-between' alignContent={'flex-start'}>
            <Box className='flex-row' gap={2} >
                <Avatar sx={{bgcolor:theme.avatar.primary}}>
                        {patient?.first_name.slice(0,1)?.toUpperCase()}
                </Avatar>
                <Typography sx={{...theme.typography.h5, fontWeight:600}} >
                        {stringToTitleCase(patient?.name) || '-'}
                </Typography>
            </Box>
            <Box className='flex-row' gap={1} >
                <IconButton 
                    title='Patient profile'
                    sx={{height:24, width:24, borderRadius:12}} 
                    onClick={() => navigate('/patients/profile/'+ patient?.id)}
                    onMouseEnter={() => prefetch('getPatientProfile', {patientId: patient?.id?.toString() })}
                    >
                    <AccountCircleOutlinedIcon sx={{ fontSize:20}}/>
                </IconButton>
            </Box>
        </Box>
        <Box className='flex-col' gap={2} marginLeft={7} onBlur={handleSubmit(submit)}>
            <Box className='flex-row' gap={2.5} >
                <LabeledBox label='Date of birth' sx={{minWidth:115,  maxWidth:125}}>
                    <HaloDatePicker
                        name='date_of_birth'
                        key={'date_of_birth'}
                        control={control}
                        title
                        slotProps={{
                            openPickerIcon:{
                                sx:{
                                    fontSize:20
                                }
                            },
                            openPickerButton:{
                                sx:{
                                    p:'2px',
                                    borderRadius:1
                                }
                            }
                        }}
                    />
                </LabeledBox>
                <LabeledBox label='Age' sx={{maxWidth:35}} >
                    <WatchedAge control={control}/>
                </LabeledBox>
                <LabeledBox label='Sex' sx={{maxWidth:75}} >
                    <HaloSelectField
                        name='sex'
                        key='sex'
                        control={control}
                        title
                        options={[
                            {val: 'male', label:'Male'},
                            {val: 'female', label:'Female'},
                        ]}
                    />
                </LabeledBox>
                <LabeledBox label='Hgt' sx={{maxWidth:55}}>
                    <HaloNumberField
                        name='height'
                        key='height'
                        suffix='in'
                        sx={{fontSize:14}}
                        decimalPlace={1}
                        control={control}
                        title
                    />
                </LabeledBox>
                <LabeledBox label='Wgt' sx={{maxWidth:50}}>
                    <HaloNumberField
                        name='weight'
                        key='weight'
                        suffix='lb'
                        decimalPlace={1}
                        control={control}
                        title
                        />
                </LabeledBox>
                <LabeledBox label='BMI' sx={{maxWidth:50}} >
                    <WatchedBMI control={control}/>
                </LabeledBox>
                <LabeledBox label='EMR' sx={{maxWidth:40}}>
                    {
                        patient?.site?.emr?.login_url ?
                        <Link underline='none' variant='body2' target="_blank" href={patient?.site?.emr?.login_url} rel="noreferrer" noWrap>{patient?.site?.emr?.name || '-'}</Link>
                        :
                        <Typography variant='body2' noWrap>{patient?.site?.emr?.name || '-'}</Typography>
                    }
                </LabeledBox>
            </Box>
            <Box className='flex-row' gap={2.5}>
                
                <LabeledBox label='SBP' sx={{maxWidth:40}} >
                        <HaloNumberField
                            name='systolic'
                            key='systolic'
                            control={control}
                            title
                            />
                </LabeledBox>
                <LabeledBox label='DBP' sx={{maxWidth:40}}>
                        <HaloNumberField
                            name='diastolic'
                            key='diastolic'
                            control={control}
                            title
                            />
                </LabeledBox>
                <LabeledBox label='Pulse' sx={{maxWidth:60}}>
                        <HaloNumberField
                            name='pulse'
                            key='pulse'
                            suffix='bpm'
                            control={control}
                            title
                            />
                </LabeledBox>
                <LabeledBox label='RR' sx={{maxWidth:60}}>
                        <HaloNumberField
                            name='respiration_rate'
                            key='respiration_rate'
                            suffix='rpm'
                            control={control}
                            title
                            />
                </LabeledBox>
                <LabeledBox label='City' sx={{maxWidth:70}}>
                    <HaloTextField  
                        name='city' 
                        key='city'
                        control={control}
                        title
                        />
                </LabeledBox>
                <LabeledBox label='State' sx={{maxWidth:50}}>
                    <HaloAutocomplete
                        name='state'
                        key='state'
                        freeSolo
                        disableClearable
                        fitOptions
                        options={USStates}
                        control={control}
                        title
                    />
                </LabeledBox>
                <LabeledBox label='Zip' sx={{maxWidth:50}}>
                    <HaloNumberField
                        name='zip'
                        key='zip'
                        variant="zip"
                        control={control}
                        title
                    />
                </LabeledBox> 
                <LabeledBox label='Site' sx={{maxWidth:150}}>
                    <Typography noWrap variant='body2' title={patient?.site?.site_name || '-'}>{patient?.site?.site_name || '-'}</Typography>
                </LabeledBox>
            </Box>
        </Box>

        </>
    )
}
