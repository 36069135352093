import { Box, IconButton, Typography } from "@mui/material";
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import HaloModal from "../../../../../globalComponents/Modals/HaloModal";
import { useState } from "react";
import { ActionButton } from "../../../../../globalComponents/Buttons/ActionButton/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { apiSlice } from "../../../../../../app/api/apiSlice";
import { useGetPrevD2DQuery, useSyncD2dMutation } from "../../../../pathologyAPISlice";
import { selectCurrentCase } from "../../../../../rosterManagement/rosterSlice";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useTheme } from "@emotion/react";
import { useForm } from "react-hook-form";
import HaloCheckbox from "../../../../../globalComponents/ReactHookFormFields/CheckBox/HaloCheckbox";
import { enqueueSnackbar } from "notistack";
import DefaultNoRowsOverlay from "../../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay";
import FaxImage from '../../../../../../assets/icons/fax-graphic.svg'

const columns = [
    {
      field: 'drug1',
      headerName: 'Drugs',
      editable: false, 
      flex:1,
    },
    { field: 'clinical_relevance', 
      headerName: 'Clinical Relevance',
      editable: false,
      flex:1.5,
    },
  ]


export default function D2DSyncModal({readOnly}){

    const dispatch = useDispatch()
    const apiRef = useGridApiRef()
    const theme = useTheme()
    const { caseId } = useParams()
    const [open, setOpen] = useState(false)
    const patientId = useSelector(selectCurrentCase)?.patient?.id
    const {data, isLoading, isFetching} = useGetPrevD2DQuery(patientId ,{skip:!open})
    const [triggerD2D, {isLoading:syncingD2D}] = useSyncD2dMutation()
    const {control, handleSubmit} = useForm({
        defaultValues:{
            confirm: false
        }
    })

    const handleClose = (e) => {
        e?.stopPropagation()
        setOpen(false)
    }
    const handleOpen = (e) => {
        e?.stopPropagation()
        setOpen(true)
    }

    const syncD2D = (values) => {

        const selected = apiRef.current.getSelectedRows()

        const ids = Array.from(selected.keys())

        if( ids.length === 0) {
            enqueueSnackbar('Please select at least one interaction', {variant:'error'})
            return;
        }

        triggerD2D({
            patientId,
            body:{ 
                ids,
                case_id: caseId 
            }
        })
        .unwrap()
        .then( res => {

            dispatch(apiSlice.util.updateQueryData('getCase', caseId, (draft) => {
                draft.encounter.drug_interactions = res
            }));
            enqueueSnackbar('Successfully synced',{variant:'success'})
            handleClose()
        })

        
    }

    if(readOnly) return null;



    return(
        <>
            <IconButton onClick={handleOpen} size="small">
                <CloudSyncOutlinedIcon />
            </IconButton>
            <HaloModal  
                modalMaxWidth={700}
                open={open}
                handleClose={handleClose}
                onClick={(e) =>  e.stopPropagation() } //prevent parent container's `onClick` from being triggered
                title={'Sync drug interactions'}>
                <Box component={'form'} onSubmit={handleSubmit(syncD2D)}>
                    <Box className='flex-col' gap={2} p={2}>
                        <Box className='flex-row-center' p={2}>
                            <img src={FaxImage} height={75} width={64} />
                        </Box>
                        <Typography variant='body2'>These are the drug interactions from the patient's previous review. Syncing now will add these interactions to your current review without removing any existing ones.</Typography>
                        <Box sx={{
                            ...theme.standardBox, 
                            '.odd': {
                                background: theme.palette.primary.shades4
                            }
                            }}>
                            <DataGridPro 
                                rows={data || []}
                                columns={columns}
                                density="compact"
                                autoHeight
                                loading={isLoading || isFetching}
                                apiRef={apiRef}
                                getRowHeight={() => 'auto'}
                                getRowClassName={(params) =>  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                                hideFooter
                                checkboxSelection
                                slots={{
                                    noRowsOverlay:DefaultNoRowsOverlay
                                }}
                                slotProps={{
                                    noRowsOverlay:{
                                        message:'No interactions found'
                                    }
                                }}
                                />
                        </Box>
                        {
                        data?.length > 0 && !isLoading && !isFetching &&
                            <HaloCheckbox 
                                label='I performed an independent evaluation of the selected drug interactions'
                                name='confirm'
                                required
                                control={control}
                            />
                        }
                    </Box>

                {
                    data?.length > 0 && !isLoading && !isFetching &&
                    <Box className='flex-row-flex-end' p={2} pt={1} gap={1}>
                        <ActionButton
                            onClick={handleClose}
                            color='inherit'>
                            Cancel
                        </ActionButton>
                        <ActionButton 
                            loading={syncingD2D}
                            variant='contained'
                            color='primary'
                            type='submit'>
                                Sync
                        </ActionButton>
                    </Box>
                }
                </Box>
            </HaloModal>
        </>
    )
}
