import { Switch } from "@mui/material"
import { useController } from "react-hook-form"






export default function HaloSwitch({
    name,
    label,
    control,
    // do we need error and label?
    ...otherProps
}){




    const {field, formState:{errors}} = useController({
        name,
        control, 
    })


    return(
        <Switch 
            checked={field.value || false}
            onChange={(e) => field.onChange(e.target.checked)}
            name={name}
            {...otherProps}
        />
    )

}