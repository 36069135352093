import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ActionButton } from '../../../../../../../globalComponents/Buttons/ActionButton/ActionButton';
import { useDeleteCaseMutation, useGetDeleteCaseRelatedObjectsQuery } from '../../../../../../../rosterManagement/rosterAdminAPISlice';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DataObjectIcon from '@mui/icons-material/DataObject';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { Avatar } from '@mui/material';


export default function    DeleteCaseDialog({open, caseToDelete, handleClose}){
   
    const caseId = caseToDelete?.data?.id
    const { data:relatedObjs, isLoading, isFetching } = useGetDeleteCaseRelatedObjectsQuery(caseId  , {skip:!caseId})
    const [ deleteCase, {isLoading:deleting} ] = useDeleteCaseMutation()
    const type = caseToDelete?.data?.case_type
    const date = caseToDelete?.data?.associated_case?.date_submitted ? dayjs( caseToDelete.data.associated_case.date_submitted).format('MMMM D, YYYY') : '-'

    const confirmDelete = () => {
        deleteCase(caseId)
        .then((res) => {
            enqueueSnackbar(res?.data, {variant:'success', style: { whiteSpace: 'pre-line' }},)
            handleClose()
        })

    }


    return(
        <Dialog open={open} onClick={(e) => e.stopPropagation()} >
            <DialogTitle id="Delete case">
                Delete case
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="Delete case">
                    Delete {type} case ({date}) and related objects?
                </DialogContentText>
                <List dense disablePadding sx={{mt:2}}>
                    {
                    relatedObjs?.map((objType) => (
                        objType &&

                        <ListItem key={objType}>
                            <ListItemAvatar>
                                <Avatar sx={(theme) => ({bgcolor: theme.palette.primary.main})}>
                                    <DataObjectIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={objType} />
                        </ListItem>
                        
                        )
                    )
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    color='inherit'
                    onClick={(e)=> {
                        handleClose()
                    }}>
                    Cancel
                </ActionButton>
                <ActionButton
                    onClick={confirmDelete}
                    disabled={isLoading || isFetching}
                    loading={deleting}
                    variant='contained'>
                    Delete
                </ActionButton>
            </DialogActions>
        </Dialog>
    )
}