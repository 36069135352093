import { apiSlice } from "../../app/api/apiSlice";

export const profileAPISlice = apiSlice
.injectEndpoints({
    endpoints: builder => ({
        reportHippa: builder.mutation({
            query: (payload) => ({
                
                url: 'hipaa/hotline',
                method: 'POST',
                body: payload
            }),
        }),
    })
})


export const {
    useReportHippaMutation
} = profileAPISlice