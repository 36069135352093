import { Alert, Box, Typography } from "@mui/material"
import HaloModal from "../../../globalComponents/Modals/HaloModal"
import { useForm } from "react-hook-form"
import { useReportHippaMutation } from "../../profileAPISlice"
import { enqueueSnackbar } from "notistack"
import { show400Error } from "../../../../app/errors/genericErrors"
import { HaloTextField } from "../../../globalComponents/ReactHookFormFields"
import HaloModalStickyFooter from "../../../globalComponents/Modals/HaloModalStickyFooter/HaloModalStickyFooter"
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton"



const defaultValues = { violation_summary:'' }

export default function HipaaModal({ open, handleClose}) {


    const [ reportHipaa, {isLoading} ] = useReportHippaMutation()
    const { control, handleSubmit, reset} = useForm({ defaultValues })
    
    const mutate = (values) => {
      reportHipaa({
        ...values
      })
      .unwrap()
      .then(res => {
        enqueueSnackbar('Submitted', {variant:'success'})
        reset(defaultValues)
        handleClose()
      })
      .catch(err => show400Error(err))
    }
    
    

   return(
        <HaloModal
            title={'Report a compliance concern'}
            handleClose={handleClose}
            open={open}
            modalMaxWidth={800}
        >
            <Box className='flex-col' gap={2} p={2} component={'form'} onSubmit={handleSubmit(mutate)} noValidate>
                <Alert severity="warning">
                    Please provide as many details of the concern as possible. By default, your report is anonymous. If you wish to remain anonymous, please do not enter in your personal information.
                    <br/>
                    <br/>
                    While you are not required to leave any identifiable information, we encourage you to record your preferred contact for any follow-up questions that can aid in a more thorough investigation.
                </Alert>
                <HaloTextField
                    name='violation_summary'
                    label='My concern'
                    placeholder='Please thoroughly describe your concern. Include as much detail as possible.'
                    required
                    control={control}
                    multiline
                    minRows={5}
                    maxRows={10}
                    maxLength={7000}
                >
                </HaloTextField>
                <Typography color='error' variant="caption">All reports are encrypted. We send your report via an anonymous encrypted email to the compliance committee. </Typography>
                <HaloModalStickyFooter className='flex-row' justifyContent={'flex-end'} p={2} pt={1} gap={1}>
                    <ActionButton variant='outlined' onClick={handleClose}>
                        Cancel
                    </ActionButton>
                    <ActionButton variant='contained' type='submit'>
                        Submit
                    </ActionButton>
                </HaloModalStickyFooter>
            </Box>

        </HaloModal>
   )

}