import CaseStatusChip from "../../../../rosterManagement/utils/StatusChips/CaseStatusChip"





export  const AssignmentStatusChipCell = ({row}) => {

        const {case_resolution_status:status, qa_status, md_review} = row || {}

        //we really need to keep this on the server and in one cetral location, the interfaces?
        let modedStatus = status

        if(qa_status === 'Rejected'){
                modedStatus = qa_status
        }

        else if(md_review?.needs_edits){
                modedStatus = 'Rejected (m.d.)'
        }

        return <CaseStatusChip caseStatus={modedStatus} qaStatus={qa_status} mdReview={md_review}/>

}