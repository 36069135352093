import { Box, Typography, Divider } from "@mui/material"
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton"
import { useTheme } from "@emotion/react"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../../auth/authSlice"

function TrialStatusBox({ reviewState, setOpenFinalApprovalModal, denyHandler, averageScore, loading }) {

    const generateTrialStatusMsg = (reviewState, avgScore) => {
        let statusMsg;
        switch (reviewState) {
            case 'On trial':
                statusMsg = 'Awaiting trial user case submissions.'
                break;
            case 'Initial review':
                statusMsg = `Trial clinician has submitted all trial cases and is pending initial review.`
                break;
            case 'Final review':
                statusMsg = `The trial clinician, with an average score of ${avgScore}%, has received initial approval and is pending admin's final review.`
                break;
            case 'Onboarding':
                statusMsg = `The trial clinician, with an average score of ${avgScore}%, has been approved by an admin and is pending legal documents and onboarding`
                break;
            case 'Denied - Final review':
                statusMsg = `The trial clinician, with an average score of ${avgScore}%, has been denied by an admin.`
                break;
            case 'Rejected - Initial review':
                statusMsg = `The trial clinician, with an average score of ${avgScore}%, has been rejected.`
                break;
            case 'Completed':
                statusMsg = `The trial clinician has completed onboarding.`
                break;
            default:
                break;
        }
        return statusMsg
    }

    const theme = useTheme()

    const { groups } = useSelector(selectCurrentUser)
    const isTrialAdmin = groups.hasOwnProperty('Trial User | Admin')
    const pendingFinalReview = isTrialAdmin && reviewState === 'Final review'

    return (
        <Box sx={{ ...theme.standardBox, width: '35%' }}>
            <Typography sx={{ ...theme.typography.subtitle2, padding: '12px 16px' }}>Trial status</Typography>
            <Divider />
            <Box p={'8px 16px 16px 16px'}>
                <Typography
                    sx={{ ...theme.typography.subtitle1, color: reviewState?.includes('Denied') || reviewState?.includes('Rejected') ? theme.palette.error.main : theme.palette.success.main }}>{reviewState}</Typography>
                <Typography sx={{ ...theme.typography.body2 }}>{generateTrialStatusMsg(reviewState, averageScore)}</Typography>
                {pendingFinalReview ? <Divider sx={{ mt: '16px' }} /> : null}
            </Box>
            {pendingFinalReview ?
                <Box className='flex-col' gap={1} p={'0px 16px 16px 16px'}>
                    <Typography sx={{ ...theme.typography.subtitle2 }}>Final approval</Typography>
                    <ActionButton variant='contained' color='success' onClick={() => { setOpenFinalApprovalModal(true) }} loading={loading}>Approve</ActionButton>
                    <ActionButton variant='contained' color='error' onClick={denyHandler} loading={loading}>Deny</ActionButton>
                </Box> : null}
        </Box>
    )
}

export default TrialStatusBox
