import { createSlice } from "@reduxjs/toolkit"

const infectiousDiseaseSlice = createSlice({
    name: 'infectiousDisease',
    initialState:{
        currentInfectiousDisease:{}
    },
    reducers: {
        setInfectiousDisease:(state, action) =>{
            state.currentInfectiousDisease = action.payload 
        }
    }
})


export const {
    setInfectiousDisease
} = infectiousDiseaseSlice.actions


export default infectiousDiseaseSlice.reducer;