import RecsAndComments from './RecsAndComments/RecsAndComments'
import FindingMetrics from './FindingMetrics/FindingMetrics'
import CaseAccordion from '../../../../globalComponents/Accordions/CaseAccordion';
import SummaryContainer from '../../../../globalComponents/Containers/SummaryContainer';
import { memo } from 'react';
import Medications from '../DataInput/Medications/Medications';
import { Box, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectStaticMeds } from '../../../pathologySlice';
import useCenterResize from '../../../../globalComponents/hooks/useCenterResize';

function Recommendations({readOnlyView,qaObj, allowEdits,caseInfo, isLoading, isUnder18, ...props}){

    const {id,treating_provider,medication_review, 
            treatment_recommendation, 
            diagnostic_recommendation, comments, findingsmetrics} = props?.encounter || {}
    const {field_reviews} = qaObj || {}
    const meds = useSelector(selectStaticMeds)
    const [ width, dragHandler ] = useCenterResize('recsContainerWidth')
  



    const content =[
        <Box className='flex-row' key={'meds-and-recs'}>
            <Box width={width + '%'}>
              <Medications 
                meds={meds} 
                key={'meds'} 
                readOnly={true}
                />
            </Box>
            <Box 
              component={'div'} 
              onDrag={dragHandler}
              draggable
              sx={{
                width:24,
                cursor:'col-resize',
                '&:hover':{
                  'hr':{
                    borderRightWidth:'thin'
                  }
                },
                '&:active': {
                  opacity:0
                }
              }}
              >
                <Divider  orientation='vertical' sx={{mr:'50%', borderRightWidth:0}}/>
            </Box>
            <Box width={(100-width) + '%'}>
              <RecsAndComments 
                id={id}
                readOnly={readOnlyView}
                treating_provider={treating_provider}
                medication_review={medication_review}
                treatment_recommendation={treatment_recommendation} 
                diagnostic_recommendation={diagnostic_recommendation}
                comments={comments}
                qaObj={qaObj}
                isUnder18={isUnder18}
                key={'recs'} />
            </Box>
        </Box>,
        <FindingMetrics id={id} findingsmetrics={findingsmetrics} key={'metrics'}/>
    ]

    const hasQAComments = field_reviews?.["Medication Review"]?.score_percentage < 100 || field_reviews?.["Recommendations & Treatment Plan"]?.score_percentage < 100

    

    return readOnlyView ? (
        <SummaryContainer number={3} title={'Recommendations'} disableEdits={!allowEdits} caseInfo={caseInfo} isLoading={isLoading}>
            {content}
        </SummaryContainer>
    ) : (
        <CaseAccordion number={3} title={'Recommendations'} iconVariant={hasQAComments? 'error' : null}>
            {content}
        </CaseAccordion>
    );

}


export default memo(Recommendations)
