import { useTheme } from "@emotion/react";
import { Box, Skeleton, Divider } from "@mui/material";





export function BreadCrumbHeaderLoader() {

    const theme = useTheme().palette

    return (
        <>
            <Box className='flex-row-between' height={100} width={'100%'} sx={{ background: theme.background.paper, p: '16px 24px' }}>

                {/*Left */}
                <Box className='flex-col' gap={1} width={'10%'}>
                    <Box className='flex-row' gap={2}>
                        <Skeleton animation="wave" width={40} height={20} />
                        <Skeleton animation="wave" width={40} height={20} />
                        <Skeleton animation="wave" width={40} height={20} />
                    </Box>
                    <Box className='flex-row-center'>
                        <Skeleton animation='wave' width={'80%'} height={40} />
                    </Box>
                </Box>

                {/*Right */}
                <Skeleton animation='wave' variation='circular' width={'10%'} height={40} sx={{ borderRadius: '18px', p: '6px 16px' }} />

            </Box>
            <Divider width={'100%'} />


        </>
    )
}
