import { apiSlice } from "../../app/api/apiSlice";

export const trialUsersAPISlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ['Trial User List', 'Trial User Profile', 'Answer Key', 'Trial Review', 'Referral List'] })
    .injectEndpoints({
        endpoints: builder => ({

            //Referrals
            sendReferral: builder.mutation({
                query: (payload) => (
                    {
                        url: 'trial-users/send-referral',
                        method: 'POST',
                        body: payload
                    }
                ),
                invalidatesTags: ['Referral List']
            }),
            getReferralList: builder.query({
                query: ({ pagination, ordering, search }) => `trial-users/referral-links-list/?page=${pagination.page + 1}&size=${pagination.pageSize}&ordering=${ordering}&search=${search}`,
                providesTags: ['Referral List']
            }),

            getReferralTypes: builder.query({
                query: () => 't/referral-types'
            }),
            getReferralLinkTypes: builder.query({
                query: () => 'trial-users/referral-link-types'
            }),

            //Trial reviewer/admin
            getTrialUserList: builder.query({
                query: ({ pagination, ordering, search, review_state }) => {
                    const url = `trial-users/list?page=${pagination.page + 1}&ordering=${ordering}&size=${pagination.pageSize}&search=${search}&review_state=${review_state}`
                    return url
                },
                providesTags: ['Trial User List']
            }),
            getTrialUserProfile: builder.query({
                query: (trialUserId) => `trial-users/profile/${trialUserId}`,
                providesTags: ['Trial User Profile']

            }),
            getTrialReview: builder.query({
                query: ({ encounterType, trialEncounterId }) => `trial-users/trial-case/review/${encounterType}/${trialEncounterId}`,
                providesTags: ['Trial Review']
            }),
            getFeedbackSection: builder.query({
                query: ({ encounterType, trialEncounterId }) => `trial-users/trial-case/review/grading/${encounterType}/${trialEncounterId}`,
                providesTags: ['Answer Key']
            }),
            gradeFeedbackSection: builder.mutation({
                query: ({ encounterType, trialEncounterId, payload }) => ({
                    url: `trial-users/trial-case/review/grading/${encounterType}/${trialEncounterId}`,
                    method: 'PUT',
                    body: payload
                }),
                invalidatesTags: ['Answer Key', 'Trial User List']
            }),
            submitFeedbackSection: builder.mutation({
                query: ({ encounterType, trialEncounterId, payload }) => ({
                    url: `trial-users/trial-case/review/grading/${encounterType}/${trialEncounterId}`,
                    method: 'POST',
                    body: payload
                }),
                invalidatesTags: ['Answer Key', 'Trial User List', 'Trial Review', 'Trial User Profile']
            }),
            submitFinalApproval: builder.mutation({
                query: ({ trialUserId, payload }) => ({
                    url: `trial-users/final-approval/${trialUserId}`,
                    method: 'POST',
                    body: payload
                }),
                invalidatesTags: ['Trial User List', 'Trial Review', 'Trial User Profile']
            }),
            grantFullAccess: builder.mutation({
                query: (payload) => ({
                    url: `trial-users/grant-full-access/redirect`,
                    method: 'POST',
                    body: payload
                }),
                invalidatesTags: ['Trial User List', 'Trial User Profile']
            }),
            getAllTrialReviewers: builder.query({
                query: () => `trial-users/trial-reviewers`
            }),
            changeReviewer: builder.mutation({
                query: (payload) => ({
                    url: `trial-users/trial-reviewers`,
                    method: 'POST',
                    body: payload
                }),
                invalidatesTags: ['Trial User List', 'Trial User Profile', 'Trial Review']
            }),
            addOrRemoveReviewerGroup: builder.mutation({
                query: (payload) => ({
                    url: `trial-users/trial-reviewers`,
                    method: 'POST',
                    body: payload
                }),
                invalidatesTags: ['Trial User Profile']
            }),
            extendTrial: builder.mutation({
                query: (payload) => ({
                    url: `trial-users/extend-trial`,
                    method: 'PUT',
                    body: payload
                }),
                invalidatesTags:['Trial User List']
            })


        })
    })

export const {
    useGetTrialUserListQuery, useGetTrialUserProfileQuery, useGetTrialReviewQuery, useGetFeedbackSectionQuery, useGradeFeedbackSectionMutation, useSubmitFeedbackSectionMutation,
    useSubmitFinalApprovalMutation, useGrantFullAccessMutation, useChangeReviewerMutation, useGetAllTrialReviewersQuery, useGetReferralLinkTypesQuery, useSendReferralMutation, useGetReferralListQuery, useExtendTrialMutation
} = trialUsersAPISlice
