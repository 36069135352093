import { useTheme } from "@emotion/react"
import { Chip } from "@mui/material"
import { memo } from "react"

export const SubmissionStatusChip = memo(({ status }) => {

    const theme = useTheme()

    const submissionStatus = status ? 'Submitted' : 'Not submitted'

    const colorToStatus = {
        'Submitted': theme.palette.success.main,
        'Not submitted': theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.action.active
    }

    return <Chip label={submissionStatus} size='small' sx={{ color: '#fff', background: colorToStatus[submissionStatus] }} />

})

export const InitialApprovalChip = memo(({ score }) => {
    const theme = useTheme()

    const colorToStatus = {
        'Passed': theme.palette.success.main,
        'Failed': theme.palette.error.main,
    }

    return (
        <Chip label={`${score}%`} size='small' sx={{ color: '#fff', backgroundColor: score > 65 ? colorToStatus['Passed'] : colorToStatus['Failed'] }} />
    )

})

export const FinalApprovalChip = memo(({ status }) => {
    const theme = useTheme()

    const colorToStatus = {
        'Approved': theme.palette.success.main,
        'Denied': theme.palette.error.main,
    }

    const approvalStatus = status ? 'Approved' : status === false ? 'Denied' : '...'

    return (
        <Chip label={approvalStatus} size='small' sx={{ color: '#fff', backgroundColor: colorToStatus[approvalStatus] }} />
    )

})
