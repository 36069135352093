import { apiSlice } from "../../app/api/apiSlice";

export const patientsAPISlice = apiSlice
.enhanceEndpoints({addTagTypes:['Patient List', 'Patient Profile']})
.injectEndpoints({
    endpoints: builder => ({
        getPatientProfile: builder.query({
            query: ({patientId, extensive=true}) => 'patients/profile/' + patientId + '?extensive=' + extensive,
            providesTags: (result, error, patientId) => [{ type: 'Patient Profile', id: patientId }],
        }),
        managePatient: builder.mutation({
            query: (payload) => ({
                
                url: 'patients/manage',
                method: payload.method,
                body: payload.body
            }),
            async onQueryStarted({body:{patient_id}}, { dispatch, queryFulfilled }) {
                try {
                  const { data } = await queryFulfilled
                  const patchResult = dispatch(
                    apiSlice.util.updateQueryData('getPatientProfile', {patientId: patient_id?.toString()}, (draft) => {
                      draft.patient = data?.patient
                    })
                  )
                } catch {}
            },
            invalidatesTags:['Patient List', 'Caseload', 'Dashboard', 'Case Details']
        }),
        getPatientsList: builder.query({
            query: ({pagination, ordering, sites, search}) => {
                let url = `/patients/list?page=${pagination.page +1}&ordering=${ordering}&size=${pagination.pageSize}&search=${search}`

                if (sites?.length) url += `&site=${sites?.map((site) => site?.id).join(',')}`
                return url;
            },
            keepUnusedDataFor:30,
            providesTags:['Patient List'],

        }),
        getSites: builder.query({
            query: (params) => {
                let url = `patients/sites/`

                if (params?.include) url += `?include=${params.include}`


                return url
            }
        }),
        manageAllergy: builder.mutation({
            query:(payload) => ({
                url: 'patients/allergies',
                method: payload.method,
                body: payload.body
            })
        }),
        manageICD10Code: builder.mutation({
            query:(payload) => ({
                url: 'patients/diagnoses' ,
                method: payload.method,
                body: payload.body
            })
        }),
        getICD10Codes: builder.query({
            query: (query) => '/patients/autocomplete/icd10-codes/' + query
        }),
        getPaginatedPatientHistory: builder.query({
            query: ({patientId, dataType, page}) => `/patients/paginated-history/?patient_id=${patientId}&data_type=${dataType}&page=${page}`
        }),
        managePatientIPC: builder.mutation({
            query: ({patientId, body,method}) => ({
                url: 'ipc/patient/' + patientId,
                method,
                body,
            }),
            onQueryStarted({ patientId, patch}, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    apiSlice.util.updateQueryData('getPatientProfile', {patientId}, (draft) => {
                        draft?.ipc_history?.unshift(patch)
                    })
                )
                queryFulfilled.catch(patchResult.undo)
            }
        }),
        callPatient: builder.mutation({
            query: ({body}) => ({
                url: 'dialer/call-patient',
                method: 'PUT',
                body
            })
        }),
        searchForPatientDupes: builder.query({
            query: ({firstName, lastName, dateOfBirth}) => `/patients/search-duplicate-patients/?first_name=${firstName}&last_name=${lastName}&date_of_birth=${dateOfBirth}`
        }),
        getEmrs: builder.query({
            query: () => 'patients/emr/list'
        }),
        archivePatient: builder.mutation({
            query: (payload) => ({
                url: 'patients/archive',
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['Patient List', 'Caseload', 'Dashboard']
        }),
        managePatientDocs: builder.mutation({
            query:(payload) => ({
                url: 'patients/documentation',
                method: payload.method,
                body: payload.body
            })
        })
    })
})


export const {
    useGetPatientProfileQuery,
    useManagePatientMutation, useGetPatientsListQuery, 
    useManageAllergyMutation, useGetSitesQuery, useManageICD10CodeMutation,
    useGetICD10CodesQuery, useLazyGetPaginatedPatientHistoryQuery, useManagePatientIPCMutation, 
    useCallPatientMutation, useLazySearchForPatientDupesQuery, useGetEmrsQuery,
    useLazyGetICD10CodesQuery, useManagePatientDocsMutation,
    useArchivePatientMutation
} = patientsAPISlice