import { Box } from "@mui/material";
import SingleFilterChip from "../../../../globalComponents/Chips/DropDownChip/SingleFilterChip/SingleFilterChip";
import SitesFilterChip from "../../../../globalComponents/Chips/DropDownChip/SItesFilterChip/SitesFilterChip";
import { useGetCaseTypesQuery } from "../../../rosterAPISlice";
import ReduxStoreSearchBar from "../../../../globalComponents/SearchBars/ReduxStoreSearchBar/ReduxStoreSearchBar";
import { setCaseHistoryFilters } from "../../../rosterSlice";


const statusOptions= [
    { label: 'Completed', value: 'completed' },
    { label:'Pending results', value:'pending_results'},
]

  

export default function HistoryFilters({ setParams, queryOptions }){



    const handleFilterChange = (filterName) => (value) => {
        setParams({[filterName]: value })
    };

    const { data:caseTypes, isLoading: loadingTypes, isFetching: fecthingTypes } = useGetCaseTypesQuery()


    return(
        <>
        <Box className="flex-row" gap={1}>
            <SingleFilterChip 
                chipLabel='Status'
                options={statusOptions}
                filterState={queryOptions?.resolution_status}
                setFilterState={handleFilterChange('resolution_status')}/>
            <SingleFilterChip
                chipLabel='Type'
                options={caseTypes?.map((type) => ({label:type.name, value:type.id}))}
                filterState={queryOptions?.case_type}
                setFilterState={handleFilterChange('case_type')}/>
            <SitesFilterChip
                filterState={queryOptions?.sites}
                setFilterState={handleFilterChange('sites')}/>
        </Box>
        <ReduxStoreSearchBar 
            selectorPath={'roster.caseHistoryFilters.searched'}
            setter={(val) => setCaseHistoryFilters({searched:val})}
            placeholder='Search your cases'
            sx={(theme) => ({
                backgroundColor:theme.palette.primary.shades8,
            })}
        />
        </>
    )


}