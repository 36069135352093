import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../../../features/auth/authSlice"
import {AdminShieldFillIcon, HomeFillIcon, PatientListFillIcon, PersonFillIcon} from '../../../../assets/icons/MaterialDesignIcons'
import dayjs from "dayjs";
import { selectPatientListFilters, selectQAListFilters } from "../../../../features/admin/adminSlice";
import { useMemo } from "react";

const rosterUserTabs = [
  {
    title: "Home",
    link: "/dashboard",
    icon: <HomeFillIcon  style={{ fontSize: 24 }} />,
    prefetch: {
      endpoint:'getDashboardData',
      params: dayjs().format('YYYY-MM-DD')
    }
  },
  {
    title: "Caseload",
    link: "/caseload",
    icon: <PatientListFillIcon style={{fontSize:24}}/>,
    prefetch: {
      endpoint:'getCaseload',
      params: dayjs().format('YYYY-MM-DD')
    }
  },
]

const MDTabs = [
  {
    title: "Caseload",
    link: "/caseload",
    icon: <PatientListFillIcon style={{fontSize:24}}/>,
    prefetch: {
      endpoint:'getCaseload',
      params: dayjs().format('YYYY-MM-DD')
    }
  },
]


const trialUserTabs = [
  {
    title: "Trial Home",
    link: "/trial-users/dashboard",
    icon: <HomeFillIcon  style={{ fontSize: 24 }} />,
  }
]

const baseTabs = [
  {
      title: 'Profile',
      link: "/profile",
      icon: <PersonFillIcon/> 
    },
  ]

  const adminTabs = (prefetch) => ([
    {
      title: "Admin",
      link: "/admin",
      icon: <AdminShieldFillIcon style={{fontSize:24}}/> ,
      prefetch //add prefetch details based on group permissions
    }
  ])
  
  export const useGetNavTabs = () => {
    const user = useSelector(selectCurrentUser)
    const userGroups = user?.groups
    const adminGroups = userGroups?.adminGroupTabs
    const qualityFilters = useSelector(selectQAListFilters)
    const patientFilters = useSelector(selectPatientListFilters)

    //if not memoized the baseTab gets asses on an item with each render
    return useMemo(() => {
        let tabs = [...baseTabs];

        if (user?.frontEndUserType === 'Trial user') {
          tabs = trialUserTabs.concat(tabs)
        }

        else if (user?.frontEndUserType === 'M.D.') {
          tabs = MDTabs.concat(tabs)
        }

        else if (userGroups?.['Roster Management | User']){
          tabs = rosterUserTabs.concat(tabs)
        }

        if (adminGroups?.length > 0) {
          const tabToPreFetch = adminGroups[0];
          let prefetch = { endpoint: '', params: '' };
          
          if (tabToPreFetch === 'Patients') {
            prefetch.endpoint = 'getPatientsList';
            prefetch.params = patientFilters 
          } else if (tabToPreFetch === 'QA cases') {
            prefetch.endpoint = 'getQAReviewerList';
            prefetch.params = qualityFilters;
          } 

          tabs = tabs.concat(adminTabs(prefetch));
        }
        
        return tabs;
    }, [adminGroups, qualityFilters]);
};
