import { Alert, AlertTitle, Box, FormControlLabel,  Radio, RadioGroup, Typography } from "@mui/material";
import { stringToTitleCase } from "../../../../../../utils/stringUtils/titleCase";
import CallerSVG from '../../../../../../assets/icons/Caller.svg'
import {  useState } from "react";
import { useCallPatientMutation } from "../../../../patientAPISlice";
import { show400Error } from "../../../../../../app/errors/genericErrors";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../auth/authSlice";
import { ActionButton } from "../../../../../globalComponents/Buttons/ActionButton/ActionButton";
import HaloModal from "../../../../../globalComponents/Modals/HaloModal";
import { useNavigate } from "react-router-dom";




export function CallPatientModal({
    open,
    closeModal,
    patient
}){
    const {site, beyondMD_site} = patient || {}
    const user = useSelector(selectCurrentUser)
    const [triggerCallPatient] = useCallPatientMutation()
    const [siteId, setSiteId] = useState(beyondMD_site?.id)
    const navigate = useNavigate()

    const handleNumberSelection = (e) => setSiteId(e.target.value) 

    const callPatient = () => {
        triggerCallPatient({
            body:{
                patient_id: patient?.id,
                site_id: siteId
            }
        })
        .unwrap()
        .then(res => {
            closeModal()
            enqueueSnackbar('Successfully placed call', {variant:'success'})
        })
        .catch(err => {
            show400Error(err)
        })
    }


    return(
        <HaloModal
            open={open}
            title={'Call ' + stringToTitleCase(patient?.name)}
            handleClose={closeModal}
            modalMaxWidth={400}>
                <Box className='flex-col' p={2} gap={2}>
                    <Box p={'16px 0px'} alignSelf={'center'}>
                        <img src={CallerSVG}/>
                    </Box>
                    <Box>
                        
                        {
                            user?.phone ?
                            <>
                                <Typography variant="body2">Select your calling number</Typography>
                                <RadioGroup
                                    value={siteId}
                                    onChange={handleNumberSelection}
                                >
                                    <FormControlLabel value={beyondMD_site?.id || ''} control={<Radio size="small"/>} label={beyondMD_site?.site_name}/>
                                    {
                                        site?.masking_approved &&
                                        <FormControlLabel value={site?.id || ''} control={<Radio size='small'/>} label={site?.site_name} />
                                    }
                                    
                                </RadioGroup>
                            </>
                            :
                            <Alert severity="warning" sx={{borderRadius:2}}>
                                <AlertTitle>Missing phone number</AlertTitle>
                                You have not set your phone number, please update it to make calls.
                            </Alert>
                        }

                    </Box>
                </Box>
                <Box className='flex-row' justifyContent={'flex-end'} p={2} pt={1} gap={1}>
                    <ActionButton 
                        color='inherit'
                        onClick={closeModal}>
                        Cancel
                    </ActionButton>
                    <ActionButton
                        variant="contained" 
                        onClick={user?.phone? callPatient : () => navigate('/profile/edit-phone-number')}>
                        {user?.phone ? 'Call patient': 'Add phone number'}
                    </ActionButton>
                </Box>
        </HaloModal>
    )




}