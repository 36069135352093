import { SvgIcon } from "@mui/material"


export const CaseTypeQuickAddIcon = (props) =>(
    <SvgIcon {...props} viewBox="0 0 24 24">
            <g>
                <path d="M3.80727 22.3957C3.19993 22.3957 2.68085 22.1802 2.25002 21.7492C1.81902 21.3184 1.60352 20.7993 1.60352 20.192V7.9965C1.60352 7.3865 1.81902 6.86516 2.25002 6.4325C2.68085 5.99966 3.19993 5.78325 3.80727 5.78325H7.63652V3.45C7.63652 2.84933 7.85202 2.33183 8.28302 1.8975C8.71385 1.46333 9.23293 1.24625 9.84027 1.24625H14.1583C14.7656 1.24625 15.2847 1.46333 15.7155 1.8975C16.1465 2.33183 16.362 2.84933 16.362 3.45V5.78325H20.1913C20.8013 5.78325 21.3226 5.99966 21.7553 6.4325C22.1879 6.86516 22.4043 7.3865 22.4043 7.9965V20.192C22.4043 20.7993 22.1879 21.3184 21.7553 21.7492C21.3226 22.1802 20.8013 22.3957 20.1913 22.3957H3.80727ZM3.80727 20.192H20.1913V7.9965H3.80727V20.192ZM9.84027 5.78325H14.1583V3.45H9.84027V5.78325ZM10.8975 15.196V18.196H13.101V15.196H16.101V12.9925H13.101V9.9925H10.8975V12.9925H7.89752V15.196H10.8975Z" fill={props.color} />
            </g>
    </SvgIcon>
)
