import { useTheme } from "@emotion/react"
import ResponsiveDoughtnutChart from "../../../../../globalComponents/NivoCharts/Doughnut/ResponsiveDoughtnutChart"
import { useGetCaseAsgmtStatsQuery } from "../../../../rosterAdminAPISlice"
import CustomNivoTooltip from '../../../../../globalComponents/NivoCharts/Tooltips/CustomNivoTooltip'
import { Box } from "@mui/material"



const formatAsgmtStats = (data={}, theme) => {
    const colorKey = {
        assigned: theme.palette.primary.main,
        unassigned: theme.palette.warning.light,
        dequeued: theme.palette.error.main
    }

    return Object.keys(data).map(key => (
      {
          id: key,
          label: key,
          value: data[key],
          color:colorKey[key]
      }
    ))

}



export default function AsgmtStatChart({dates}){


    const theme = useTheme()
    const {data:asgmtData} = useGetCaseAsgmtStatsQuery(dates)


    return(
        <Box height={87} width={87} className='flex-row' alignItems={'flex-end'}>
            <ResponsiveDoughtnutChart
                data={formatAsgmtStats(asgmtData, theme) || []} 
                colors={{ datum: 'data.color' }}
                tooltip={obj => (
                    <CustomNivoTooltip 
                    stats={formatAsgmtStats(asgmtData, theme) || []}
                    />
                )}/>
        </Box>
    )
}