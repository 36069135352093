import { useParams, useNavigate } from "react-router-dom"
import { useGetQAReviewQuery } from "../qualityAPISlice"
import QAReviewHeader from "../components/QAReview/QAReviewHeader/QAReviewHeader"
import { Box } from "@mui/material"
import QAReviewGrading from "../components/QAReview/QAReviewGrading/QAReviewGrading"
import PathologyDetailsContainer from "../../pathology/components/PathologyReview/PathologyDetailsContainer"
import InfectiousDiseaseDetailsContainer from "../../infectiousDisease/components/infectiousDiseaseReview/InfectiousDiseaseDetailsContainer"
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import HiddenScrollableBox from "../../globalComponents/Boxes/HiddenScrollableBox"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setCurrentScores, updateFieldScore } from "../qualitySlice"
import { setStaticMeds } from "../../pathology/pathologySlice"

const caseTypeComponents = {
    'Pathology': (props) => <PathologyDetailsContainer {...props}/> ,
    'Infectious Disease': (props) => <InfectiousDiseaseDetailsContainer {...props} />,
    // Add more case types and their components here as needed
}


export default function QAReview() {

    const { caseType, qualityId } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { data, isLoading, isFetching } = useGetQAReviewQuery(qualityId)
    const { encounter, qa_data, tracked_duration, is_dequeued,  case_id } = data || {}


    useEffect(() => {
        if (qa_data?.id) {
            let { max_possible_score, score_only_fields } = qa_data || {}
            dispatch(setCurrentScores({
                max_possible_score,
                fields: score_only_fields
            }))
            dispatch(updateFieldScore({})) //prevents reset of current score values
            dispatch(setStaticMeds(
                encounter?.patient?.medications
            ))
        }

    }, [qa_data?.max_possible_score, encounter])

    const DetailsContainer = () => caseTypeComponents[caseType]({encounter, isLoading: isLoading || isFetching})

    useEffect(() => {
        if(is_dequeued) {
            navigate('/admin?tab=QA%20cases', {state: {isDequeued: true}})
        }
    },[is_dequeued])
    
    return (
        <>
            <QAReviewHeader
                loading={isLoading || isFetching}
                encounter={encounter}
                qaObj={qa_data}
                patient={encounter?.patient}
                trackedDuration={tracked_duration}
                caseId={case_id}
            />
            <Box className='flex-row' pl={3} height={'calc(100vh - 196px)'}>

                <HiddenScrollableBox sx={{ flex: 1 }}>
                    <QAReviewGrading
                        qaObj={qa_data}
                        loading={isLoading || isFetching}
                        readOnly={qa_data?.review_state === 2 || qa_data?.review_state === 3}
                        patient={encounter?.patient}
                        currentClinician={encounter?.clinician_name}
                    />
                </HiddenScrollableBox>
                <OverlayScrollbarsComponent style={{ flex: 2 }}>
                    <Box className='flex-col' sx={{ m: 3, gap: 3 }}>
                        {DetailsContainer()}
                    </Box>
                </OverlayScrollbarsComponent>
            </Box>
        </>
    )
}
