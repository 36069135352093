import { useTheme } from "@emotion/react";
import { Box, Skeleton,Divider } from "@mui/material";





export function CaseHeaderLoader(){

    const theme = useTheme().palette

    return(
        <>
        <Box className='flex-row' height={132} sx={{ background: theme.background.paper, p: '16px 24px 24px', gap:1}}>

            {/*Left */}
            <Box className='flex-col' width={'45%'}>
                <Box className='flex-row' gap={2}>
                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    <Box width={'100%'}>
                        <Skeleton animation='wave' width={'100%'} />
                        <Skeleton animation='wave' width={'100%'} />
                    </Box>
                </Box>
                <Box className='flex-col' justifyContent={'space-between'} alignItems={'center'}>
                    <Skeleton animation='wave' width={'100%'}/>
                    <Skeleton animation='wave' width={'100%'} />
                </Box>

            </Box>

            {/*Divider */}
            <Box sx={{ height: '100%', width: '1px', border: `1px solid ${theme.divider}` , ml: 1, mr: 1 }}></Box>

            {/*Right */}
            <Box className='flex-col' sx={{width:'27%'}}>

                <Skeleton animation='wave' width={'10%'} />
                <Skeleton animation='wave' width={'100%'} />
                <Skeleton animation='wave' width={'100%'} />
                <Skeleton animation='wave' width={'100%'} />
            </Box>
            <Box className='flex-col' sx={{width:'27%'}}>

                <Skeleton animation='wave' width={'10%'} />
                <Skeleton animation='wave' width={'100%'} />
                <Skeleton animation='wave' width={'100%'} />
                <Skeleton animation='wave' width={'100%'} />
            </Box>

        </Box>
        <Divider width={'100%'} />

        
        </>
    )
}