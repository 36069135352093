import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';





const DRModalHeader = ({ title, onClose }) => (
    <Box className="flex-row-between-center" sx={{ justifyContent: 'space-between', p: '8px 16px' }}>
      <Typography variant="subtitle2">{title}</Typography>
      <IconButton size="small" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );

export default DRModalHeader;