import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material';
import { AddFileIcon } from '../../../../../assets/icons/AddFileIcon';


const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height:'100%',
    
  }));
  

export default function DefaultNoRowsOverlay({message='No Rows', icon}) {
    return (
      <StyledGridOverlay>
        {icon? icon : <AddFileIcon sx={{fontSize: 33}} color='disabled'/>}
        {
          message && 
          <Box mt={1}>
            <Typography variant='body2' color='text.secondary'>
              {message}
            </Typography>
          </Box>
        }
      </StyledGridOverlay>
    );
  }
  

