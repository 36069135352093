import { SvgIcon } from "@mui/material"


export const EmptyLabIcon = (props) =>(
    <SvgIcon {...props} viewBox="0 0 41 40">
            <g >
                <path d="M6.125 4.375H31.125V12.5H32.375V3.75C32.375 3.40625 32.0938 3.125 31.75 3.125H5.5C5.15625 3.125 4.875 3.40625 4.875 3.75V36.25C4.875 36.5938 5.15625 36.875 5.5 36.875H26.125V35.625H6.125V4.375Z" fill={props.color || "black"} />
                <path d="M14.1625 9.0126L11.4687 12.7876L9.48745 10.8063L8.6062 11.6876L11.1062 14.1876C11.225 14.3063 11.3812 14.3688 11.55 14.3688C11.5687 14.3688 11.5812 14.3688 11.6 14.3688C11.7812 14.3563 11.95 14.2563 12.0562 14.1063L15.1812 9.73135L14.1625 9.00635V9.0126Z" fill={props.color || "black"} />
                <path d="M26.75 11.25H18V12.5H26.75V11.25Z" fill={props.color || "black"} />
                <path d="M9.48745 19.5563L8.6062 20.4376L11.1062 22.9376C11.225 23.0563 11.3812 23.1188 11.55 23.1188C11.5687 23.1188 11.5812 23.1188 11.6 23.1188C11.7812 23.1063 11.95 23.0063 12.0562 22.8563L15.1812 18.4813L14.1625 17.7563L11.4687 21.5313L9.48745 19.5501V19.5563Z" fill={props.color || "black"} />
                <path d="M24.875 20H18V21.25H24.875V20Z" fill={props.color || "black"} />
                <path d="M35.5 13.75H27.375C27.0312 13.75 26.75 14.0312 26.75 14.375V17.5C26.75 17.8438 27.0312 18.125 27.375 18.125V32.8125C27.375 35.05 29.2 36.875 31.4375 36.875C33.675 36.875 35.5 35.05 35.5 32.8125V18.125C35.8438 18.125 36.125 17.8438 36.125 17.5V14.375C36.125 14.0312 35.8438 13.75 35.5 13.75ZM28 15H34.875V16.875H28V15ZM34.25 21.25H28.625V18.125H34.25V21.25Z" fill={props.color || "black"} />
            </g>
    </SvgIcon>
)


