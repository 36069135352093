import { Box, styled } from "@mui/material";




const KeepMountedTabPanel = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: 'none', // Prevent interaction when not visible
    '&.visible': {
      opacity: 1,
      pointerEvents: 'auto', // Enable interaction when visible
      position: 'relative', // Ensure the visible container is positioned correctly
    },
    minHeight:500
  }));


  export default KeepMountedTabPanel;