import TrialDataInput from "./TrialDataInput/TrialDataInput"
import TrialAnalysis from "./TrialAnalysis/TrialAnalysis"
import TrialRecommendations from "./TrialRecommendations/TrialRecommendations"
import { useTutorial } from "../../utils/TutorialContext"

function TrialPathologyReviewContainer({ data }) {
    const { trial_encounter: encounter } = data || {}
    const { refs } = useTutorial()

    return (
        <>
            <TrialDataInput
                meds={encounter?.patient?.medications}
                allergies={encounter?.patient?.allergies}
                diagnoses={encounter?.patient?.icd10_references}
            />
            <TrialAnalysis
                clinicalFindings={encounter?.trial_clinical_findings}
                resistanceGenes={encounter?.resistance_genes}
                drugInteractions={encounter?.drug_interactions}
            />
            <TrialRecommendations
                metrics={data?.metrics}
                encounter={encounter}
            />
        </>
    )
}

export default TrialPathologyReviewContainer
