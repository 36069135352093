import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { Link } from "react-router-dom";
import HaloSkeleton from "../Skeletons/HaloSkeleton";





function DetailItem({label, value,  flexBasis, link, loading}){

    const theme = useTheme()

    return(
        <Box className='flex-col' flexGrow={1} flexBasis={flexBasis || '20%'} minWidth="0">
            <HaloSkeleton loading={loading}>
                <Typography variant="subtitle2" color={theme.palette.text.disabled}>{label}</Typography>
            </HaloSkeleton>
            {
            link && value?
                <HaloSkeleton loading={loading}>
                    <Link style={{textDecoration:'none'}} to={link} target='_blank'>
                        <Typography variant='body2' color={'primary'}>{value}</Typography>
                    </Link>

                </HaloSkeleton>
                :
            <HaloSkeleton loading={loading}>
                <Typography variant='body2'>{value || '-'}</Typography>
            </HaloSkeleton>

            }
        </Box> 
    )



}

export default memo(DetailItem)