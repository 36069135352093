import DataInput from './DataInput/DataInput'
import Analysis from './Analysis/Analysis'
import Recommendations from './Recommendations/Recommendations'
import FindingMetricsProvider from './Recommendations/FindingMetrics/MetricsContextMenu/FindingMetricsProvider'




export default function PathologyReviewContainer({data,isUnder18}){
    const {encounter, qa_obj} = data || {}


    return (
        <FindingMetricsProvider encounterId={encounter?.id}>  
            <DataInput
                meds={encounter?.patient?.medications} 
                allergies={encounter?.patient?.allergies} 
                diagnoses={encounter?.patient?.icd10_references}/> 
            <Analysis
                labValues={encounter?.lab_values}
                resistanceGenes={encounter?.resistance_genes} 
                imagingValues={encounter?.imaging_values}
                drugInteractions={encounter?.drug_interactions}
                qaObj={qa_obj}/>
            <Recommendations 
                isUnder18={isUnder18}
                metrics={data?.metrics} 
                encounter={encounter}
                qaObj={qa_obj}/> 
        </FindingMetricsProvider>
    )
}