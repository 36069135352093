import { Typography } from "@mui/material";
import { calcBMI } from "../../../../utils/vitalsUtils/calcBMI";
import { useWatch } from "react-hook-form";





//moved here to prevent rerenders

export default function WatchedBMI({control}){

    const watchedHeight = useWatch({control, name:'height'})
    const watchedWeight = useWatch({control, name:'weight'})

    return(
        <Typography variant="body2">{calcBMI({height: watchedHeight, weight:watchedWeight})}</Typography>
        )



}