import { Box, Chip } from "@mui/material";
import { TitleAndBodyBox } from "../../../../../../../globalComponents/TextGroups/TitleAndBodyBox";









export function General({ info }) {
    const {
        employment_type,
        address,
        city,
        state,
        zip,
        email,
        phone,
        preferred_title,
    } = info || {}

    return (
        <>
            <Box className='flex-col' gap={2}>
                <Box>
                    <TitleAndBodyBox title='Employment type' element={<Chip label={employment_type} variant="outlined" color='primary'
                        size="small" sx={{ width: 'fit-content' }} />} />
                </Box>
                <TitleAndBodyBox title='Preferred title' body={preferred_title} />
                <TitleAndBodyBox title='Email' body={email} />
                <TitleAndBodyBox title='Address' body={address} />
            </Box>
            <Box className='flex-col' gap={2}>
                <TitleAndBodyBox title='Phone' body={phone} />
            </Box>

        </>
    )
}