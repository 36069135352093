import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import {  Alert, AlertTitle, Box, Switch, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { HaloObjAutocomplete } from '../../../../globalComponents/ReactHookFormFields/AutocompleteFields/HaloObjAutocomplete';
import { useGetCustomFieldsQuery, useManageIntakeTemplateMutation } from '../../../dataIntakeAPISlice';
import StepButtons from '../StepButtons/StepButtons';
import { show400Error, showAndIterateValidationError } from '../../../../../app/errors/genericErrors';
import { useDispatch } from 'react-redux';
import { apiSlice } from '../../../../../app/api/apiSlice';
import HaloSkeleton from '../../../../globalComponents/Skeletons/HaloSkeleton';
import AttachFileIcon from '@mui/icons-material/AttachFile';




// first_name, last_name, dob always required
const validateAndFormatMappings = (values={}) => {

    let jsonTemplate = {}

    for (const [key, value] of Object.entries(values)) {
        const field = values[key]?.database_field
        if (field) jsonTemplate[key] = field
    }

    return jsonTemplate
}




export function MapFields({
    csvColumns,
    template={}, 
    goNextStep,
    goPrevStep,
    site,
    templateName, //indicates an EMR template, can this be more obvious?
    setValue,
    templateTypes,
    type,
    fileName
}){ 
    const isNewTemplate = Object.keys(template).length === 0
    const dispatch = useDispatch()
    const [ editMode, setEditMode ] = useState(isNewTemplate) 
    const { data:customFields } = useGetCustomFieldsQuery()
    const [ manageTemplate, {isLoading:savingTemplate} ] = useManageIntakeTemplateMutation() 
    const { control, reset, getValues, formState:{isDirty} } = useForm({ defaultValues:{} })
    const [ isLoading, setIsLoading ] = useState(true);

    const handleEditMode = () => setEditMode(prev => !prev)

    useEffect(()=>{
        if(csvColumns?.length > 0 ){
            setIsLoading(true);
            let inits = {}
            csvColumns.forEach(( row,indx ) => {
                inits[indx] = row?.haloField ? template?.template_objects?.find(obj => obj.database_field === row?.haloField) : null
            })
            reset(inits)
            setIsLoading(false);
        }
    },[csvColumns, reset, template?.template_objects])

    useEffect(() =>{

        setEditMode(isNewTemplate)

    }, [template])

    const saveIntakeTemplate = () => {
        
        if(!isNewTemplate && !isDirty) goNextStep() 
        
        else{

            const values = getValues()
            const jsonTemplate = validateAndFormatMappings(values)

            let body = {
                site: site?.id,
                template_type: templateTypes?.find(type => type.name === 'site')?.id,
                template_name:  site?.site_name + ' template',
                template: jsonTemplate,
                medication_delimiter: ';',
                icd10_delimiter: ';'
            } 

            //indicates it's an emr upload 
            //prefer this to be more obvious
            if(templateName && type ==='Patient upload'){ 
                body = {
                    ...body,
                    template_type: templateTypes?.find(type => type.name === 'emr')?.id,
                    template_name: templateName,
                    emr: site?.emr?.id,
                    site: null
                }
            }

            if(type === 'Demographics update'){
                body= {
                    ...body,
                    template_type: templateTypes?.find(type => type.name === 'demographics')?.id,
                    template_name: templateName ? templateName : template?.template_name,
                    emr: null,
                    site: null
                }
            }
            if (!isNewTemplate) body.id = template?.id
    
            manageTemplate({
                method: !isNewTemplate? 'PUT' : 'POST',
                body
            })
            .unwrap()
            .then((res) => {
                const templateType = res?.template_type?.name?.toLowerCase()

                if(templateType === 'site'){
                    dispatch(
                        apiSlice.util.updateQueryData('getSites', {include:'templates_and_emr_templates'}, (draft) => {
                            const siteIndx = draft.findIndex(site => site.id === body.site)
                            let dataTemplates = draft[siteIndx].data_templates

                            const templateIndx = dataTemplates.findIndex(obj => obj.id === res.id) //check if template exists
                            if(templateIndx === -1) {
                                dataTemplates.push(res)
                            }else{
                                dataTemplates[templateIndx] = res
                            }
                        })
                    )
                }
                setValue('template', res)
                goNextStep()
            })
            .catch((err) => {
                
                if(err?.data?.detail){ 
                    show400Error(err)
                }else{ 
                    showAndIterateValidationError("Tragic... you're missing these required mappings:  ", err)
                }
            } )
        }
    }    

    return(
        <>
        <Box className='flex-col-center'  gap={2.2} sx={{ m:'auto', maxWidth:1000,  p:2}}>
            <Typography variant='h6'>Map columns from your file to patient fields</Typography>
            <Typography variant='body2'>Each column header below should be mapped to a case property in Halo. If you'd like a column to be ignored, simply leave it blank.</Typography>
            {
                isNewTemplate ?
                <Alert severity='info' sx={{width:'100%'}}>
                    <AlertTitle> Creating {templateName? templateName: site?.site_name} template</AlertTitle>
                    {templateName }
                </Alert>
                :
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                    <Box sx={{display:'flex', alignItems:'center', width:'60%'}}>
                        <AttachFileIcon/>
                        <Typography variant='body2' noWrap>{fileName}</Typography>
                    </Box>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body1" color='text.secondary'>Enable edit mode</Typography>
                        <Switch size='small' checked={editMode} onClick={handleEditMode}/>
                    </Box>
                </Box>
            }
            <TableContainer sx={(theme) => ({...theme.standardBox, 'td':{height:54, p:'4px 16px'}, 'tr':{height:54}})}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{minWidth:200}}>CSV field</TableCell>
                            <TableCell sx={{minWidth:200}}>CSV values</TableCell>
                            <TableCell sx={{minWidth:250}}>Halo field</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {csvColumns.map((row, indx) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {row.csvHeader || '-'}
                                    </TableCell>
                                    <TableCell sx={{maxWidth:300}}>
                                        {row?.values?.slice(0,2).map((val, indx) =>(
                                            
                                            <Typography key={val + indx} variant='body2' color='text.secondary' noWrap>{val || '-'}</Typography>
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        <HaloSkeleton loading={isLoading} width={200}>
                                            <HaloObjAutocomplete
                                                name={indx.toString()}
                                                options={customFields}
                                                getOptionDisabled={(option) => {
                                                    const fields = getValues()
                                                    return Object.values(fields).some((field) => field?.database_field === option.database_field)
                                                }}
                                                autoHighlight
                                                placeholder="Ignored" 
                                                optionKey='description' 
                                                disablePortal
                                                control={control}
                                                disabled={!editMode}
                                            />
                                        </HaloSkeleton>
                                    </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        <StepButtons handleBack={goPrevStep} handleNext={saveIntakeTemplate} loading={savingTemplate}/>
        </>
    )
}