import { SvgIcon } from "@mui/material"


export const EmptyEncountersIcon = (props) =>(
    <SvgIcon {...props} viewBox="0 0 56 56">
        <svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.1281 11.6627L36.5844 11.3065L37.9594 9.21896C38.7156 8.06896 38.4031 6.51271 37.2531 5.75646C36.1031 5.00021 34.5469 5.31271 33.7906 6.46271L21.3906 25.2377C21.3406 25.3127 21.3094 25.4002 21.2969 25.4877L19.5156 27.3252C19.4094 27.4377 19.3469 27.5877 19.3406 27.744L19.2344 32.169C19.2344 32.3815 19.3344 32.5877 19.5156 32.7065C19.6219 32.7752 19.7406 32.8127 19.8594 32.8127C19.9469 32.8127 20.0344 32.794 20.1219 32.7565L24.1469 30.919C24.2906 30.8565 24.4031 30.7377 24.4656 30.594L25.5469 28.019C25.5469 28.019 25.5594 28.0065 25.5656 27.994L35.8969 12.3502L36.4406 12.7065C37.0031 13.0815 37.1594 13.8377 36.7844 14.4002L31.9469 21.7252L32.9906 22.4127L37.8281 15.0877C38.5781 13.9502 38.2656 12.4127 37.1281 11.6627ZM32.7656 10.2815L33.4531 9.23771L35.5406 10.6127L34.8531 11.6565L32.7656 10.2815Z" fill={props.color || "black"} />
            <path d="M28.8718 35.9375H3.87183V4.0625H28.8718V9.6875H30.1218V3.4375C30.1218 3.09375 29.8406 2.8125 29.4968 2.8125H3.24683C2.90308 2.8125 2.62183 3.09375 2.62183 3.4375V36.5625C2.62183 36.9062 2.90308 37.1875 3.24683 37.1875H29.4968C29.8406 37.1875 30.1218 36.9062 30.1218 36.5625V27.8125H28.8718V35.9375Z" fill={props.color || "black"} />
            <path d="M25.1218 7.8125H7.62183V10.9375H25.1218V7.8125Z" fill={props.color || "black"} />
            <path d="M11.3718 14.6875H6.99683V15.9375H11.3718V14.6875Z" fill={props.color || "black"} />
            <path d="M19.4968 18.4375H6.99683V19.6875H19.4968V18.4375Z" fill={props.color || "black"} />
            <defs>
            </defs>
        </svg>
    </SvgIcon>
)

