import { useEffect, useState } from "react"
import { useScanForErrorsMutation } from "../../../../dataIntakeAPISlice"
import StepButtons from "../../StepButtons/StepButtons"

import {  Box, styled, Typography, Alert, AlertTitle } from "@mui/material";
import ViewErrors from "./ViewErrors";
import Button from '@mui/material/Button';
import { Loader } from "./Loader";
import { ScannedTable } from "./ScannedTable";
import { useMinLoadTime } from "../../../../../../utils/apiUtils/useMinLoadTime";


//borrowed from MUI https://mui.com/material-ui/react-button/#file-upload
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


export function ScanForErrors({
    goPrevStep,
    goNextStep,
    template, 
    file,
    csvColumns,
    setValue
}){

    const [scanForErrros, {data:errors, isLoading:scanningCSV, error}] = useScanForErrorsMutation()
    const [viewError, setViewErrors] = useState(false)
    const templatedCsvColumns = csvColumns.filter((item) => item.haloField)
    const hasScanned = useMinLoadTime(1500, scanningCSV)

    const hasErrors = errors?.number_of_rows_affected > 0



    useEffect(() => {
        
        //only make the api call to scan when the template state is set or file is reuploaded
        //this prevents a dupe request that fails at first but then succeeds
        if(template?.id){
            scanForErrros({
                file: file[0],
                template_id: template.id
            })
            .unwrap()
        }
    },[template, file])

    const handleClose = () => setViewErrors(false)

    return(
        <>
         <Box className='flex-col-center'  gap={2.2} sx={{ maxWidth:1000,m:'auto',  p:2}}>
             <Typography variant='h6'>{hasScanned? 'Validate data' : 'Validating data'}</Typography>
            {
                hasScanned?
                    error?
                        <Alert severity='error' sx={{width:'100%'}}>
                            <AlertTitle>Error</AlertTitle>
                            {error?.data?.detail}
                        </Alert>
                    :
                        <ScannedTable 
                            setViewErrors={setViewErrors}
                            errors={errors}
                            fileName={file[0].name}
                            template={template}
                            templatedCsvColumns={templatedCsvColumns}
                            hasErrors={hasErrors}/>
                    :
                        <Loader /> 
            }
            
        </Box>
        <ViewErrors viewError={viewError} handleClose={handleClose} errors={errors} csvColumns={csvColumns}/>
        <StepButtons 
            handleBack={goPrevStep} 
            handleNext={goNextStep} 
            loading={scanningCSV}
            // disableNext={hasErrors || Boolean(error)}
            secondaryNextBtn={
                hasErrors ?
                <Button
                    component="label"
                    disableElevation
                    role={undefined}
                    tabIndex={-1}
                    sx={{borderRadius:'18px', width:120}}
                    variant='contained'
                    >
                    Reupload
                    <VisuallyHiddenInput
                        type="file"
                        accept=".csv"
                        onChange={(event) => {
                            setValue('file', event.target.files)
                        }}
                    />
                </Button>

                :null
            }
            />
        </>
    )
}