import { Box, Avatar, MenuItem, Select, Typography } from "@mui/material"
import { useTheme } from "@emotion/react";
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import { useGetAllTrialReviewersQuery } from "../../trialAdminAPISlice";
import { useChangeReviewerMutation } from "../../trialAdminAPISlice";
import { enqueueSnackbar } from 'notistack';




function ChangeReviewerMenu({ currentReviewerName, currentReviewerId, trialUserId, isFeedbackStarted }) {

    const theme = useTheme()

    const { data } = useGetAllTrialReviewersQuery()
    const [changeReviewer] = useChangeReviewerMutation()

    const changeReviewerHandler = (e) => {

        changeReviewer({ action: 'change reviewer', trialReviewerId: e.target.value, trialUserId: trialUserId })
            .unwrap()
            .then(() => enqueueSnackbar('Reviewer updated', { variant: 'success' }))
    }


    return (

        <>
            {(currentReviewerName && !isFeedbackStarted) ?
                <Select
                    value={currentReviewerId || ''}
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={changeReviewerHandler}
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            p: 0,
                        },
                        '& .MuiSelect-select': {
                            padding: 0,
                        },
                    }}>
                    {data?.trial_reviewers?.map((reviewer) => {
                        return (
                            <MenuItem value={reviewer.id} key={reviewer.id}>
                                <Box className='flex-row-flex-start' sx={{ alignItems: 'center' }} gap={1}>
                                    <Avatar
                                        sx={{
                                            bgcolor: theme.palette.success.main,
                                            height: 24,
                                            width: 24,
                                            fontSize: '12px'
                                        }}>
                                        {reviewer.name?.slice(0, 1)?.toUpperCase()}
                                    </Avatar>
                                    <Typography sx={{ ...theme.typography.body2 }}>{stringToTitleCase(reviewer.name)}</Typography>
                                </Box>
                            </MenuItem>
                        )
                    })}
                </Select> :
                <Box className='flex-row-flex-start' sx={{ alignItems: 'center', height: '100%' }} gap={1}>
                    <Avatar
                        sx={{
                            width: 24,
                            height: 24,
                            fontSize: '12px',
                            bgcolor: currentReviewerName ? theme.palette.success.main : ''
                        }}>
                        {currentReviewerName ? currentReviewerName?.slice(0, 1).toUpperCase() : <PersonIcon />}
                    </Avatar>
                    <Typography sx={{ ...theme.typography.body2 }}> {currentReviewerName ? currentReviewerName : 'Unassigned'}</Typography>
                </Box>
            }
        </>
    )


}

export default ChangeReviewerMenu
