import { Box, Typography } from "@mui/material"
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase"
import QACommentsContainer from "./QACommentsContainer"
import dayjs from "dayjs"





export default function MainQAFeedbackContainer({qaObj,...props}){


    const isApproved = qaObj?.review_state_string === 'approved'
    const approval_date = qaObj?.approval_date? dayjs(qaObj.approval_date).format('LL') : ''
    
    return(
        <Box className='flex-col' p={2} gap={1}  sx={(theme)=>({...theme.shadowedBox, ...props?.containerSx})}>
            <Box className='flex-col'>
                <Typography variant='subtitle1'>QA Result</Typography>
                <Typography variant='body2' color='text.secondary'>Reviewer: {qaObj?.qa_reviewer_name}</Typography>
            </Box>
            <Box className='flex-row' gap={1}>
                <Typography flexGrow={1} variant='subtitle1' color={isApproved? 'success.main' : 'error.main'} >{stringToTitleCase( qaObj?.review_state_string)}: {approval_date}</Typography>
                <Typography flexGrow={1} variant='subtitle1'>Current score: { qaObj?.score_percentage}%</Typography>
            </Box>
            <QACommentsContainer 
                sectionTitle='General Comments'
                timeStamp={qaObj?.created_at}
                comments={qaObj?.final_feedback}
                fieldReview={qaObj}
                approved={isApproved}
            />
        </Box>
    )

}