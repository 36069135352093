import { Box, Button } from "@mui/material";
import { SmallHeaderBox, TypographySubtitle } from "../../../globalComponents/SubHeader/components";
import BackButton from "../../../globalComponents/SubHeader/Backbutton";
import TrialClinicianPatientHeader from "./TrialClinicianPatientHeader";
import { useTheme } from "@emotion/react";
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";
import { CaseHeaderLoader, SubHeaderLoader } from "../../../globalComponents/Loaders";

function TrialEncounterSubmittedDetailsNav({ encounter, isLoading }) {
    const theme = useTheme()

    if (isLoading) {
        return (
            <>
                <SubHeaderLoader />
                <CaseHeaderLoader />
            </>
        )
    }


    return (
        <>
            <Box sx={{ ...theme.stickyHeader }}>
                <SmallHeaderBox>
                    <Box className='flex-row-center'>
                        <BackButton />
                        <TypographySubtitle>
                            {stringToTitleCase(encounter?.patient?.name)}
                        </TypographySubtitle>
                    </Box>
                </SmallHeaderBox>
            </Box>
            <TrialClinicianPatientHeader encounter={encounter} trialPatient={encounter?.patient} />
        </>
    )
}

export default TrialEncounterSubmittedDetailsNav
