

import SvgIcon from '@mui/material/SvgIcon';

const BatchDistributionIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 56 56">
      <g id="Batch distribution">
        <g id="Vector">
          <path d="M39.375 3.5H18.375C17.8918 3.5 17.5 3.89175 17.5 4.375V16.625C17.5 17.1082 17.8918 17.5 18.375 17.5H39.375C39.8582 17.5 40.25 17.1082 40.25 16.625V4.375C40.25 3.89175 39.8582 3.5 39.375 3.5Z"/>
          <path d="M35.875 42H21.875C21.3937 42 21 42.3937 21 42.875V51.625C21 52.1063 21.3937 52.5 21.875 52.5H35.875C36.3563 52.5 36.75 52.1063 36.75 51.625V42.875C36.75 42.3937 36.3563 42 35.875 42ZM35 50.75H22.75V43.75H35V50.75Z"/>
          <path d="M18.375 32.375H4.375C3.89375 32.375 3.5 32.7687 3.5 33.25V42C3.5 42.4813 3.89375 42.875 4.375 42.875H18.375C18.8562 42.875 19.25 42.4813 19.25 42V33.25C19.25 32.7687 18.8562 32.375 18.375 32.375ZM17.5 41.125H5.25V34.125H17.5V41.125Z"/>
          <path d="M53.375 32.375H39.375C38.8937 32.375 38.5 32.7687 38.5 33.25V42C38.5 42.4813 38.8937 42.875 39.375 42.875H53.375C53.8563 42.875 54.25 42.4813 54.25 42V33.25C54.25 32.7687 53.8563 32.375 53.375 32.375ZM52.5 41.125H40.25V34.125H52.5V41.125Z"/>
          <path d="M29.75 38.0625H28V39.375H29.75V38.0625Z"/>
          <path d="M29.75 32.4625H28V35.2625H29.75V32.4625Z"/>
          <path d="M29.75 26.8625H28V29.6625H29.75V26.8625Z"/>
          <path d="M29.75 22.75H28V24.0625H29.75V22.75Z"/>
          <path d="M39.1192 21.2544L37.8817 22.4918L39.1192 23.7293L40.3566 22.4918L39.1192 21.2544Z"/>
          <path d="M42.1569 24.301L40.9194 25.5384L42.7199 27.3389L43.9573 26.1015L42.1569 24.301Z"/>
          <path d="M45.7514 27.8957L44.514 29.1331L45.7514 30.3705L46.9889 29.1331L45.7514 27.8957Z"/>
          <path d="M18.635 21.2635L17.3976 22.501L18.635 23.7384L19.8725 22.501L18.635 21.2635Z"/>
          <path d="M15.5884 24.2925L13.788 26.093L15.0254 27.3304L16.8259 25.5299L15.5884 24.2925Z"/>
          <path d="M11.9938 27.8871L10.7563 29.1245L11.9938 30.3619L13.2312 29.1245L11.9938 27.8871Z"/>
        </g>
      </g>
  </SvgIcon>
);

export default BatchDistributionIcon;

