import { Box, Typography } from "@mui/material"
import { EmptyChartIcon } from "../../../assets/icons/EmptyChartIcon"
import { useTheme } from "@emotion/react"

function EmptyChartPlaceholder(width) {

    const theme = useTheme()

    return (
        <Box className='flex-col-center' width={width} gap={1}>
            <EmptyChartIcon style={{fontSize:40}}/>
            <Typography sx={{...theme.typography.body3, textAlign:'center', width: 174, color: theme.palette.text.disabled}}>
                You do not have enough data yet. When you do, it will display here.
            </Typography>
        </Box>
    )
}

export default EmptyChartPlaceholder
