import { Box, Button, InputAdornment } from "@mui/material";
import GroupContainer from "../../../../globalComponents/Containers/GroupContainer";
import { useForm } from "react-hook-form";
import { HaloTextField, HaloAutocomplete, HaloDatePicker, HaloRadioGroup, HaloNumberField, HaloObjAutocomplete } from "../../../../globalComponents/ReactHookFormFields";
import { USStates } from "../../../../../utils/formUtils/menuData/USStates";
import { useManagePatientMutation } from "../../../patientAPISlice";
import { enqueueSnackbar } from "notistack";
import { showGenericValidationError } from "../../../../../app/errors/genericErrors";
import { ActionButton } from "../../../../globalComponents/Buttons/ActionButton/ActionButton";
import DupeAlert from "./DupeAlert";
import { useEffect } from "react";
import HaloAddressAutocomplete from "../../../../globalComponents/ReactHookFormFields/AutocompleteFields/HaloAddressAutocomplete";


const rowSx = {
    display:'flex',
    flexDirection:'row',
    gap:2,
    pt:1,
    pb:1
}

const defaultValues ={
    first_name:'',
    middle_name:'',
    last_name:'',
    sex:'', //select
    email:'',
    date_of_birth:null, //date
    site:'', //select/autocomplete
    ssn:'',
    address:'',
    address_2:'',
    city:'',
    state:'',
    zip:'',
    height:'',
    weight:'',
    phone_mobile:'',
    primary_insurance:'',
    primary_insurance_id:'',
    group_number_primary:'',
    secondary_insurance:'',
    secondary_insurance_id:'',
    group_number_secondary:'',
    systolic:'',
    diastolic:'',
    pulse:'',
    respiration_rate:''
    
}

export function CPModalBody({
    open,
    activeStep,
    handleBack,
    steps,
    handleNext,
    sites,
    handleClose,
    handleReset,
    openAssignment
}){

    const {control, getValues, trigger, setFocus, setValue} = useForm({ defaultValues });   
    const [managePatient,{isLoading: isCreatingPatient}] = useManagePatientMutation()

    const isLastStep = activeStep === steps?.length -1


    const createPatient = async({assign}) => {
        let valid = await trigger()
        if(!valid) return;

        let patientData = getValues()

        managePatient({
            method:'POST',
            body:patientData
        })
        .unwrap()   
        .then(res=> {
            
            handleClose()
            handleReset()
            enqueueSnackbar('Patient successfully created', {variant:'success'})
            
            if(assign){
                openAssignment(res?.patient)
            }
        })
        .catch(err=>{
            showGenericValidationError(err)
        })
    }

    useEffect(() => {
        if(open) setFocus('first_name')
    }, [setFocus, open])

    return (
        <>
        <Box className='flex-col' 
            p={'0px 16px'} gap={2}
            component={'form'}  
            noValidate>
            <DupeAlert control={control} handleParentModalClose={handleClose}/>
            {
            activeStep === 0?
                <>
                <GroupContainer title='Basic information'>
                    <Box className='flex-col' gap={'10px'}>
                        <Box sx={rowSx}>
                            <HaloTextField
                                label='First name'
                                name='first_name'
                                required
                                inputProps={{style: {textTransform:'capitalize'}}}
                                control={control}
                            />
                            <HaloTextField
                                label='Last name'
                                name='last_name'
                                required
                                inputProps={{style: {textTransform:'capitalize'}}}
                                control={control}
                            />
                            <HaloTextField
                                label='Middle name'
                                name='middle_name'
                                inputProps={{style: {textTransform:'capitalize'}}}
                                control={control}
                            />
                        </Box>
                        <Box sx={rowSx}>  
                            <HaloDatePicker
                                label='Date of birth'
                                name='date_of_birth'
                                required
                                disableFuture
                                control={control}
                            />
                            <HaloNumberField
                                label={'SSN'}
                                name='ssn'
                                variant="ssn"
                                control={control}
                            />
                            <HaloRadioGroup 
                                row
                                label='Sex'
                                name='sex'
                                control={control}
                                options={[
                                    { value: 'Male', label: 'Male' },
                                    { value: 'Female', label: 'Female' },
                                ]}
                            />
                        </Box>
                    </Box>
                </GroupContainer>
                <GroupContainer title='Contact details'>
                    <Box  className='flex-col' gap={'10px'}>
                        <Box sx={rowSx}>
                            <HaloAddressAutocomplete
                                name='address'
                                control={control}
                                setValue={setValue}
                            />
                        </Box>
                        <Box sx={rowSx}>
                            <HaloTextField
                                label='Address line 2'
                                name='address_2'
                                control={control}
                                />
                        </Box>
                        <Box sx={rowSx}>
                            <HaloTextField 
                                label='City'
                                name='city'
                                control={control}
                            />
                            <HaloAutocomplete
                                label='State'
                                name='state'
                                options={USStates}
                                control={control}
                            />
                            <HaloNumberField 
                                label='Zip code'
                                name='zip'
                                variant="zip"
                                control={control}
                            />
                        </Box>
                        <Box sx={rowSx}>
                            <HaloNumberField 
                                label='Phone number'
                                name='phone_mobile'
                                variant='phone'
                                control={control}
                            />
                            <HaloTextField 
                                label='Email'
                                name='email'
                                variant='email'
                                control={control}
                            />
                        </Box>

                    </Box>
                </GroupContainer>
                </>
            :activeStep === 1?
                <>
                <GroupContainer title={'Site & insurance'}>
                        <Box className='flex-col' gap={'10px'}>
                            <Box sx={{...rowSx, width:'50%'}}>
                                <HaloObjAutocomplete 
                                    label='Site'
                                    name='site'
                                    options={sites}
                                    required
                                    optionKey='site_name'
                                    control={control}
                                />
                            </Box>
                            <Box sx={rowSx}>
                                <HaloTextField 
                                    label='Primary insurance'
                                    name='primary_insurance'
                                    control={control}
                                />
                                <HaloTextField 
                                    label='Primary insurance ID'
                                    name='primary_insurance_id'
                                    control={control}
                                />
                                <HaloTextField 
                                    label='Group number (Primary)'
                                    name='group_number_primary'
                                    control={control}
                                />
                            </Box>
                            <Box sx={rowSx}>
                                <HaloTextField 
                                    label='Secondary insurance'
                                    name='secondary_insurance'
                                    control={control}
                                />
                                <HaloTextField 
                                    label='Secondary insurance ID'
                                    name='secondary_insurance_id'
                                    control={control}
                                />
                                <HaloTextField 
                                    label='Group number (Secondary)'
                                    name='group_number_secondary'
                                    control={control}
                                />
                            </Box>
                        </Box>
                </GroupContainer>
                <GroupContainer title={'Vitals'}>
                        <Box className='flex-col' gap={'10px'}>
                            <Box sx={rowSx}>
                                <HaloNumberField
                                    label='Height'
                                    name='height'
                                    decimalPlace={1}
                                    control={control}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">in</InputAdornment>
                                    }}
                                />
                                <HaloNumberField 
                                    label='Weight'
                                    name='weight'
                                    decimalPlace={1}
                                    control={control}
                                    InputProps={{
                                        endAdornment: <InputAdornment position='start'>lb</InputAdornment>
                                    }}
                                />
                                <HaloNumberField 
                                    label='BP (Systolic)'
                                    name='systolic'
                                    control={control}
                                />
                                <HaloNumberField 
                                    label='BP (Diastolic)'
                                    name='diastolic'
                                    control={control}
                                />
                            </Box>
                            <Box sx={rowSx}>
                                    <HaloNumberField
                                        label='Pulse'
                                        name='pulse'
                                        control={control}
                                        InputProps={{
                                        endAdornment: <InputAdornment position="end">bpm</InputAdornment>
                                        }}
                                    />
                                    <HaloNumberField 
                                        label='Respiration rate'
                                        name='respiration_rate' 
                                        control={control}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">rpm</InputAdornment>
                                        }}
                                    />
                            </Box>
                        </Box>
                </GroupContainer>
                </>
            :null
            }

        <Box className='flex-row' p={'8px 16px'} >
            {
            activeStep !== 0 &&
            <Button
                variant="outlined"
                onClick={handleBack}
                sx={{
                borderRadius:'18px',
                width:120
                }}>
                Back
            </Button>
            }
            <Box className='flex-row' flex={1} gap={1} justifyContent={'flex-end'}>
                {
                !isLastStep &&
                    <Button
                        onClick={async(e) => {
                            let validate = await trigger()
                            if(validate) handleNext()
                        }}
                        variant="contained"
                        disableElevation
                        sx={{
                            borderRadius:'18px',
                            width:120,
                            }}>
                    Next
                    </Button>
                }
                {
                    isLastStep &&
                    <>                
                    <ActionButton
                        onClick={(e) => createPatient({assign:false})}
                        variant="outlined"
                        loading={isCreatingPatient}>
                        
                    Finish
                    </ActionButton>
                    <ActionButton
                        onClick={(e) =>createPatient({assign:true})}
                        variant="contained"
                        loading={isCreatingPatient}>
                        Finish and assign
                    </ActionButton>
                    </>
                }
            </Box>
            
        </Box>
        </Box>
        </>
    )
}
