import { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Radio,
    RadioGroup,
    FormControlLabel,
    IconButton,
    Typography,
    Box,
    Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import {ActionButton} from '../Buttons/ActionButton/ActionButton';
import { enqueueSnackbar } from 'notistack';

const DialogWithRadioOptions = ({
    open,
    onClose,
    title,
    description,
    options = [],
    initialValue,
    updateMutation,
    userId
}) => {

    const theme = useTheme()
    const [selectedValue, setSelectedValue] = useState(initialValue);

    const [updateSetting, {isLoading}] = updateMutation()


    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };


    const handleUpdate = () => {
        updateSetting({
            modified_setting_option: selectedValue,
            user_id: userId
        })
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res, { variant: 'success' })
                onClose()
            })
    };


    return (
        <Dialog open={open} onClose={onClose}>
            <Box className='flex-row-between-center' p={'8px 16px'} height={48}>
                <Typography sx={{ ...theme.typography.subtitle2 }}>{title}</Typography>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
            <DialogContent sx={{ width: '540px', height: 152, p: 2, gap: 2 }} className='flex-col'>
                <Typography sx={{ ...theme.typography.body2 }}>{description}</Typography>
                <RadioGroup value={selectedValue} onChange={handleChange}>
                    {options.map((option) => (
                        <FormControlLabel
                            checked={option.value === selectedValue}
                            key={option.value}
                            value={option.value}
                            control={<Radio size='small' />}
                            label={<Typography sx={{ ...theme.typography.body2 }}>{option.label}</Typography>}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions sx={{p: '8px 16px 16px 16px'}} className='flex-row-flex-end'>
                <ActionButton sx={{color:theme.palette.text.primary}} onClick={onClose}>Cancel</ActionButton>
                <ActionButton disabled={selectedValue === initialValue} onClick={handleUpdate} variant="contained" color="primary">
                    Update
                </ActionButton>
            </DialogActions>
        </Dialog>
    );
};

export default DialogWithRadioOptions;
