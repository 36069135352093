import { Box, Tooltip } from "@mui/material";
import { enqueueSnackbar } from 'notistack'
import { useGetIntakeRecordsListQuery } from "../../dataIntakeAPISlice";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import { DownloadIcon } from "../../../../assets/icons/MaterialDesignIcons";
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useDispatch, useSelector } from "react-redux";
import { selectDataIntakeListFilters, setDataIntakeListFilters } from "../../../admin/adminSlice";
import usePaginatedRowCount from "../../../globalComponents/DataGrid/hooks/commonUseEffects/usePaginatedRowCount";
import axiosAuth from "../../../../utils/apiUtils/axiosAuth";
import downloadDoc from "../../../../utils/fileUtils/downloadDoc";
import { showGenericNotFoundError } from "../../../../app/errors/genericErrors";
import BulkPatientsToBatchModal from "../../../rosterManagement/admin/components/BulkPatientsToBatchModal/BulkPatientsToBatchModal";
import { useState } from "react";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';


export default function DataIntakePanel() {
    
    const dispatch = useDispatch()
    const params = useSelector(selectDataIntakeListFilters)
    const {data, isLoading, isFetching} = useGetIntakeRecordsListQuery({...params})
    const rowCountState = usePaginatedRowCount(data?.count)
    const [ recordToBatch, setRecordToBatch ] = useState({ recordId:'', site:'' })

    const clearRecordToBatch = () => setRecordToBatch({ recordId:'', site:'' })

    const downloadCSV = (recordId, type) => {

        axiosAuth({
            url:`data-intake/intake-records/download-csv/${recordId}/${type}`,
            method: 'GET',
            responseType: "blob" 
        })
        .then(res => {
            downloadDoc(res)
            if (type === 'skipped') {
                enqueueSnackbar('If you reupload this file, just know that you may or may not be overwriting changes.', { variant: 'warning' })
            }
        })
        .catch(err => {
            if (err?.response?.status === 404) {
                showGenericNotFoundError({status:404})
            }
        })

    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
        },
        {
            field: 'upload_type',
            headerName: 'Type',
            flex:1,
        },
        {
            field: 'associated_patient_count',
            headerName: 'Patients',
        },
        {
            field: 'uploaded_at',
            headerName: 'Upload date (EST)',
            width:200,
            valueGetter: (value) => dayjs(value).format('MM/DD/YYYY, h:mm A')
        },
        {
            field: 'uploader',
            headerName: 'Uploader',
            flex:1,
            valueGetter: (value, row) => value?.name
        },
        {
            field:'is_processed',
            headerName: 'Batched',
            renderCell:({row}) => (
                <Box className='flex-row align-center' height={'100%'}>
                    {
                        row.is_processed ?
                        <CheckCircleOutlinedIcon color="success" />
                        :
                        <RemoveCircleOutlineOutlinedIcon color={row?.associated_site ? 'warning' : 'action'}  />
                    }
                </Box>
            )
        },
        {
            field: 'new_objects',
            headerName: 'New'
        },
        {
            field: 'updated_objects',
            headerName: 'Updated'
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 120,
            cellClassName: 'actions',
            getActions: ({row}) =>{
                return [
                    <Tooltip title='Batch patients'>
                        <GridActionsCellItem
                            sx={{visibility: row?.associated_site && row?.associated_patient_count? 'visible': 'hidden' }}
                            icon={<TodayOutlinedIcon />}
                            label="Batch patients"
                            onClick={() => setRecordToBatch({
                                recordId: row.id,
                                site: row?.associated_site
                            })}
                        />
                    </Tooltip>,
                    <Tooltip title='Skipped rows'>
                        <GridActionsCellItem
                            sx={{visibility: row?.skipped_rows?.number_of_rows_affected > 0 && row?.associated_patient_count ? 'visible' : 'hidden' }}
                            icon={<WarningAmberIcon/>}
                            label="Skipped rows"
                            onClick={() => downloadCSV(row.id, 'skipped')}
                        />
                    </Tooltip>,
                    <Tooltip title='Download'>
                        <GridActionsCellItem
                            sx={{visibility: row?.file_paths?.length > 0? 'visible': 'hidden' }}
                            icon={<DownloadIcon/>}
                            label="Download"
                            onClick={() => downloadCSV(row.id, 'original')}
                        />
                    </Tooltip>
                ]
            }
        }
    ]

    const handlePaginationChange = (pageModel) => dispatch(setDataIntakeListFilters(({pagination:pageModel}))) 

    return(
    <>
        <Box sx={(theme) => ({ ...theme.standardBox})} >
            <DataGridPro
                columns={columns}
                rows={data?.results || []}
                paginationMode="server"
                pagination
                autoHeight
                disableColumnMenu
                disableRowSelectionOnClick
                disableColumnSorting
                rowCount={rowCountState}
                loading={isLoading || isFetching}
                pageSizeOptions={[5, 10, 15, 20, 50, 100]}
                paginationModel={params?.pagination}
                onPaginationModelChange={handlePaginationChange}
                sx={{border:'none'}}
            />
            <BulkPatientsToBatchModal 
                {...recordToBatch}
                handleClose={clearRecordToBatch}
            />
        </Box>
    </>
)}