
import { Box } from "@mui/material";

import DefaultNoRowsOverlay from '../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay'
import { useNavigate } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { linkedRowProps } from "../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps"; 
import { stringToTitleCase } from "../../../../../utils/stringUtils/titleCase";
import { stringToSentenceCase } from "../../../../../utils/stringUtils/sentenceCase";
import { AssignmentStatusChipCell } from "../../../../globalComponents/DataGrid/subComponents/customCells/AssignmentStatusChipCell";


const columns = [
    {
        field: 'patient',
        headerName: 'Patient',
        flex:1,
        maxWidth:200,
        valueFormatter: (value) => stringToTitleCase(value)
    },
    {
        field: 'case_type',
        headerName: 'Case type',
        flex:1,
        maxWidth:150,
        renderCell:(params) => (
            <>{params.value.split(' ')[0]}</>
        )
    },
    {
        field: 'site',
        headerName:'Site',
        flex:1,
        maxWidth:200,
    },
    {
        field: 'case_resolution_status',
        headerName: 'Status',
        flex:1,
        minWidth:150,
        maxWidth:200,
        renderCell: (params) =>  <AssignmentStatusChipCell {...params} />
    },
    {
        field: 'case_resolution_notes',
        headerName: 'Notes',
        flex:2,  
        valueGetter: (value, row) => {
            if(row?.qa_status === 'Rejected') return value || '-'
            return value? stringToSentenceCase(value) : '-'
        }
    },
];

const sortingStatusState = {
    "sorting": {
        "sortModel": [
            {
                "field": "case_resolution_status",
                "sort": "asc"
            }
        ]
    }
}

export default function DashboardCases({assignments, Toolbar, isLoading}){
    const navigate = useNavigate()


    return(
        <Box sx={(theme) => ({...theme.standardBox,height:324})} >
            <DataGridPro
                sx={{
                "& .MuiDataGrid-virtualScroller": {
                    overflowX:  "hidden",
                    overflowY: assignments?.length === 0? 'hidden' : 'auto'
                  },
                ...linkedRowProps  
                }}
                columnHeaderHeight={0} 
                initialState={sortingStatusState}
                columns={columns}
                rows={assignments || []}
                loading={isLoading}
                disableColumnFilter
                disableColumnMenu
                disableColumnResize
                hideFooter
                onRowClick={(params) =>{
                    if(params.row.status==='completed' || params.row.status === 'pending_results'){ 
                        navigate(`/caseload/case-details/${params.row.case}`) //needs to use the pathology or infectious disease id 
                    }
                    else{
                        navigate('/caseload/case-review/'  + params.row.case_type + '/' + params.row.case)
                    }
                }}
                slots={{
                    toolbar: Toolbar,
                    noRowsOverlay:DefaultNoRowsOverlay
                }}
                slotProps={{
                    toolbar:{
                        header:'Assigned cases',
                        count:assignments?.length ,
                        isLoading
                    },
                    noRowsOverlay:{
                        message:'No assigned cases'
                    }
                }}/>

        </Box>
    )
}
