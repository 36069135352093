

import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { ActionButton } from '../../../../globalComponents/Buttons/ActionButton/ActionButton';
import { useState } from 'react';
import AddPatientDocsModal from './AddPatientDocsModal/AddPatientDocsModal';



export default function AddPatientDocsButton({patientId}){

    const [ open, setOpen ] = useState(false)

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    return(
        <>
        <ActionButton
            variant='outlined'
            startIcon={<UploadFileOutlinedIcon />}    
            onClick={handleOpen}
            >
            Upload documentation
        </ActionButton>
        <AddPatientDocsModal open={open} handleClose={handleClose} patientId={patientId}/>
        </>
    )


}