import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import HeaderFrameLayout from "../../../globalComponents/Headers/HeaderFrames/FrameLayout/FrameLayout";


function TrialClinicianFrame({ encounter }) {
    const theme = useTheme()
    let { encounterType } = useParams()
    const {date_submitted, treating_provider, review_duration} = encounter || {}

    const firstRow = [
        { label: 'Submitted', value: dayjs(date_submitted).format('LL') || '-' },
        { label: 'Duration', value: review_duration ? review_duration + ' min' : '-' },
        { label: 'Treating Provider', value: treating_provider },
    ]

    return (
        <HeaderFrameLayout
            avatarColor={theme.avatar.primary}
            name={encounter?.clinician_name}
            firstRow={firstRow} />
    )
}

export default TrialClinicianFrame
