import React, { forwardRef } from 'react';
import { Button, Typography } from "@mui/material";
import {cardio} from 'ldrs'
import { useTheme } from '@emotion/react';

export const ActionButton = forwardRef(({
    loading, 
    disabled, 
    children, 
    sx,
    variant='text',
    startIcon=null, 
    ...props

},  ref) => {

    cardio.register()
    const theme = useTheme()

    return (
        
        <Button 
            {...props} 
            disabled={loading || disabled} 
            disableElevation 
            sx={{borderRadius:'18px', p:'6px 16px', ...sx}} 
            startIcon={loading? null : startIcon}
            variant={variant}
            ref={ref}>
            {
                loading ? 
                    <l-cardio 
                        size="30" 
                        stroke="4" 
                        speed="1.8" 
                        color={variant === 'text' ? theme.palette.grey[500]:theme.palette.background.paper} 
                        /> 
                : 
                children?
                    <Typography noWrap variant="subtitle2">{children}</Typography>
                :
                    null    
            }
        </Button>
    );
});
