import { useTheme } from "@emotion/react"
import { Box, Divider,Tab, Tabs} from "@mui/material"
import { useState } from "react";
import { useManagePatientMutation } from "../../../patientAPISlice";
import { LabeledBox } from "../../../../globalComponents/Formik/QuickEditFields";
import { showGenericValidationError } from "../../../../../app/errors/genericErrors";
import { CallPatientField } from "./CallPatientField.jsx/CallPatientField";
import { useForm } from "react-hook-form";
import { HaloDatePicker } from "../../../../globalComponents/ReactHookFormFields/DateFields/HaloDatePicker";
import { HaloNumberField } from "../../../../globalComponents/ReactHookFormFields/NumberFields/HaloNumberField";
import dayjs from "dayjs";
import HaloSelectField from "../../../../globalComponents/ReactHookFormFields/SelectFields/HaloSelectField";
import { formatSex } from "../../../../../utils/stringUtils/formatSex";
import { HaloAutocomplete, HaloTextField } from "../../../../globalComponents/ReactHookFormFields";
import { USStates } from "../../../../../utils/formUtils/menuData/USStates";
import WatchedBMI from "./WatchedFields/WatchedBMI";
import WatchedAge from "./WatchedFields/WatchedAge";
import { WatchedName } from "./WatchedFields/WatchedName";





export default function PatientVerticalCard({patient}){


    const theme = useTheme()
    const [tabState, setTabState] = useState('Patient info')
    const [managePatient,{isLoading}] = useManagePatientMutation()

    const {control, trigger, getValues, reset, formState:{ isDirty, dirtyFields}} = useForm({
        defaultValues:{
            first_name: patient?.first_name || '',
            middle_name: patient?.middle_name || '', 
            last_name: patient?.last_name || '',
            phone_mobile : patient?.phone_mobile?.toString() || '',
            date_of_birth: dayjs(patient?.date_of_birth) || null,
            sex: formatSex(patient?.sex) || '',
            height: patient?.vitals.height?.toString() || '',
            weight: patient?.vitals.weight?.toString() || '',
            pulse: patient?.vitals.pulse?.toString() || '',
            respiration_rate: patient?.vitals.respiration_rate?.toString() || '',
            systolic: patient?.vitals?.systolic?.toString() || '',
            diastolic: patient?.vitals?.diastolic?.toString() || '',
            primary_insurance: patient?.primary_insurance || '',
            primary_insurance_id: patient?.primary_insurance_id || '',
            address: patient?.address || '',
            city: patient?.city || '',
            state: patient?.state || '',
            zip: patient?.zip?.toString() || ''
        },
    })

    const handleTabChange = (event, newValue) => setTabState(newValue)
  
    const handleCustomBlur = async (e) => {
        const valid = await trigger()

        if(valid && Object.keys(dirtyFields).length > 0 && isDirty){
            const values = getValues()
            managePatient(
                {
                    method:'PUT',
                    body:{
                        patient_id: patient.id,
                        ...values
                    },
                }).unwrap()
                .then(res => {
                    reset({}, { keepValues: true, keepDirty:false })
                })
                .catch(err =>{
                    showGenericValidationError(err)
                })
        }     
    };

    return(
        <Box sx={{
                ...theme.standardBox,
                minWidth:272, 
                width:272,
                
                }}>
            <WatchedName control={control} handleCustomBlur={handleCustomBlur} patient={patient}/>
            <Box >
                <Tabs
                    sx={{
                        '& .Mui-selected':{
                            background: theme.palette.paper,
                            color:theme.palette.text.primary
                        },
                        '& .MuiTab-root':{
                            width:'50%'
                        }
                    }}
                    value={tabState}
                    onChange={handleTabChange}>
                    <Tab value={'Patient info'} label='Patient info' />
                    <Tab value={'Contact info'} label='Contact info' />
                </Tabs>
                <Divider/>
            </Box>
            <Box 
                className='flex-col' 
                gap={2} 
                p={2} 
                component={'form'} 
                   >
                {
                    tabState === 'Patient info' ?
                    <>
                        <CallPatientField 
                            patient={patient}
                            name='phone_mobile'
                            onBlur={handleCustomBlur}
                            control={control}/>
                        <LabeledBox label='Date of birth'>
                            <HaloDatePicker
                                name='date_of_birth'
                                key={'date_of_birth'}
                                onBlur={handleCustomBlur}
                                control={control}
                                slotProps={{
                                    openPickerIcon:{
                                        sx:{
                                            fontSize:20
                                        }
                                    },
                                    openPickerButton:{
                                        sx:{
                                            p:'2px',
                                            borderRadius:1
                                        }
                                    }
                                }}
                            />
                        </LabeledBox>
                        <LabeledBox label='Age'>
                            <WatchedAge control={control}/>
                        </LabeledBox>
                        <LabeledBox label='Sex'>
                            <HaloSelectField
                                name='sex'
                                key='sex'
                                control={control}
                                onBlur={handleCustomBlur}
                                options={[
                                    {val: 'male', label:'Male'},
                                    {val: 'female', label:'Female'},
                                ]}
                            />
                        </LabeledBox>
                        <LabeledBox label='Height'>
                            <HaloNumberField
                                name='height'
                                key='height'
                                suffix='in'
                                decimalPlace={1}
                                control={control}
                                onBlur={handleCustomBlur}
                            />
                        </LabeledBox>
                        <LabeledBox label='Weight'>
                            <HaloNumberField 
                                name='weight'
                                key='weight'
                                suffix='lb'
                                decimalPlace={1}
                                control={control}
                                onBlur={handleCustomBlur}
                                
                            />
                        </LabeledBox>
                        <LabeledBox label='BMI'>
                            <WatchedBMI control={control}/>
                        </LabeledBox>
                        <LabeledBox label='Systolic BP'>
                                <HaloNumberField
                                    name='systolic'
                                    key='systolic'
                                    control={control}
                                    onBlur={handleCustomBlur}/>
                        </LabeledBox>
                            <LabeledBox label='Diastolic BP'>
                                    <HaloNumberField
                                        name='diastolic'
                                        key='diastolic'
                                        control={control}
                                        onBlur={handleCustomBlur}/>
                            </LabeledBox>
                            <LabeledBox label='Pulse'>
                                    <HaloNumberField
                                        name='pulse'
                                        key='pulse'
                                        suffix='bpm'
                                        control={control}
                                        onBlur={handleCustomBlur}/>
                            </LabeledBox>
                            <LabeledBox label='Respiration rate'>
                                    <HaloNumberField
                                        name='respiration_rate'
                                        key='respiration_rate'
                                        suffix='rpm'
                                        control={control}
                                        onBlur={handleCustomBlur}/>
                            </LabeledBox>
                    </>
                    :
                    <>
                        <LabeledBox label='Address'>
                            <HaloTextField  
                                name='address' 
                                key='address'
                                onBlur={handleCustomBlur}
                                control={control}
                            />
                        </LabeledBox>
                        <LabeledBox label='City'>
                            <HaloTextField  
                                name='city' 
                                key='city'
                                onBlur={handleCustomBlur}
                                control={control}
                                />
                        </LabeledBox>
                        <LabeledBox label='State'>
                            <HaloAutocomplete
                                name='state'
                                key='state'
                                freeSolo
                                onBlur={handleCustomBlur}
                                options={USStates}
                                control={control}
                            />
                        </LabeledBox>
                        <LabeledBox label='Zip'>
                            <HaloNumberField
                                name='zip'
                                key='zip'
                                variant="zip"
                                onBlur={handleCustomBlur}
                                control={control}
                            />
                        </LabeledBox>   
                        <LabeledBox label='Primary insurance'>
                                <HaloTextField
                                    name='primary_insurance'
                                    onBlur={handleCustomBlur}
                                    control={control}
                                />
                        </LabeledBox>
                        <LabeledBox label='Primary insurance id'>
                                <HaloTextField
                                    name='primary_insurance_id'
                                    onBlur={handleCustomBlur}
                                    control={control}
                                />
                        </LabeledBox>
                    </>
                }
            </Box>
        </Box>
    )
}
