


import Box from '@mui/material/Box'
// import {tracerStyling }from '../performance/tracerStyling' //keeping here for quick enabling of perfomance testing
import { memo } from 'react'


const DefaultGridContainer = ({
  children, 
  sx
}) =>{


  return(
    <Box className='flex-col' sx={(theme) =>({
      ...theme.standardBox,
      height:'fit-content',
      minWidth:'0px',
      flex:1,
      //...tracerStyling,
      '& .MuiDataGrid-virtualScroller':{
        overflowX:'hidden',
      },
      '& .MuiDataGrid-cell--editing': {
        backgroundColor: 'rgb(255,215,115, 0.19)',
        padding:`0px !important`,
        color: '#1a3e72',
        '& .MuiInputBase-root': {
            height: '100%',
          },
      },
      '.MuiDataGrid-row--editing':{
        border: 'none !important',
        boxShadow: 'none !important',
      },
      '& .Mui-error': {
          backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1}) !important`,
          outline: ` solid ${theme.palette.error.main} 1px !important`,
          outlineOffset:'-2px',
      },
      '& .odd': {
          background: 'rgba(16, 165, 231, 0.04)',  
      },
      ...sx //added to end to override any default styles set above
    })}>
      {children}
    </Box>
  )
}

  export default memo(DefaultGridContainer);


