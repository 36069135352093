import CRUDDataGrid from '../../../../../../../globalComponents/DataGrid/CRUDDataGrid'
import AutoCompleteCell from '../../../../../../../globalComponents/DataGrid/subComponents/customCells/AutoCompleteCell';
import { useManageOrganismsMutation, useManageSamplesMutation } from "../../../../../../infectiousDiseaseAPISlice"
import { MicroBiologyIcon } from '../../../../../../../../assets/icons/MaterialDesignIcons';
import AutoCompleteCreateCell from '../../../../../../../globalComponents/DataGrid/subComponents/customCells/AutoCompleteCreateCell';

//allows for selection of dropdown cells with arrow and enter keys
//default behavior on enter is stop edit mode and proccessRowUpdate
const preValidation = (params, event) => {
    const arr = ['organism', 'quantitative'];
    const enterKeyHit = params.reason === 'enterKeyDown'
    const selectIsOpen = event.target.ariaExpanded === 'true'
    
    if (enterKeyHit && arr.includes(params.field) && selectIsOpen) {
      event.defaultMuiPrevented = true;
      return true; 
    }
    return false; 
};
  

export default function Organisms({readOnly, sample, organism_options, unit_label_options}){


    //custom order requested by Users
    let sortedUnits = unit_label_options? [...unit_label_options]?.sort((a, b) =>  b.id - a.id  ) : []
    const microbialLoadIndx = sortedUnits?.findIndex(unit => unit.name == 'Estimated Microbial Load')
    const microbialLoad = sortedUnits?.splice(microbialLoadIndx, 1)[0]
    sortedUnits.push(microbialLoad)


    const columns = [
        {
            field:'organism',
            headerName:'Organism',
            flex:1,
            editable: !readOnly,
            headerClassName: readOnly? '' : 'required-col',
            valueFormatter: value => value?.name,
            renderEditCell: (params) =>(
                <AutoCompleteCell 
                    {...params}
                    openOnFocus={false}
                    options={organism_options}
                    optionKey={'name'}/>
            )
        },
        {
            field:'quantitative',
            headerName:'Quantitative',
            flex:1,
            editable: !readOnly,
            renderEditCell: (params) => (
                <AutoCompleteCreateCell
                    {...params} 
                    options={ [
                        "10^1",
                        "10^2",
                        "10^3",
                        "10^4",
                        "10^5",
                        "10^6",
                        "10^6-10^8",
                        "less than 10^4 Low",
                        "greater than 10^5",
                        "10^4-10^6 Low",
                        "10^4-10^5 Medium",
                        "10^4-10^6 Medium",
                        "10^4-10^8 Medium",
                        "10^6-10^8 Medium",
                        "10^8-10^9 High",
                        "greater than 10^6 High",
                        "greater than 10^9 Very High"
                      ]}  
                    isOptionEqualToValue={(option, value) => option === value || value === ''} />
            ),
        },
        {
            field:'unit_label',
            headerName:'Unit label',
            flex:1,
            editable: !readOnly,
            valueFormatter: value => value?.name,
            renderEditCell: (params) => (
                <AutoCompleteCell 
                    {...params} 
                    options={sortedUnits}
                    optionKey='name'  
                     />
            ),
        },

    ]

    return(

        <CRUDDataGrid
            title={'Organisms'}
            columns={columns} 
            initRows={sample?.organisms} 
            mutation={useManageOrganismsMutation}
            GridIcon={MicroBiologyIcon}
            fieldToFocus='organism'
            requiredFields={['organism' ]}
            preValidation={preValidation}
            toggleProps={{
                value: sample?.no_organisms_provided,
                mutation: useManageSamplesMutation,
                mutationKey: 'no_organisms_provided',
                helperText: 'no organisms provided in this sample'
            }}
            defaultValues={{
                unit_label: unit_label_options?.find(unit => unit?.name === 'Ct value')
            }}
            parentId={sample?.id} //this should be the instance pk, not the field 'sample_id
            isReadOnly={readOnly }/> 
    )
}


    
