import { Box, Typography } from "@mui/material";
import HaloDropZone from "../../../../../globalComponents/ReactHookFormFields/FileUpload/HaloDropZone";
import StepButtons from "../../StepButtons/StepButtons";
import { enqueueSnackbar } from "notistack";
import PatientUpload from "./PatientUpload";
import DemographicsUpload from "./DemographicsUpload";



export function Upload({
    type,
    control, 
    goPrevStep,
    goNextStep, 
    trigger, 
    isValid, 
    site, 
    setValue,
    templateTypes
}){

    
    const handleNext = () => {
        trigger()
        if(isValid) goNextStep()

        else enqueueSnackbar('Missing required fields', {variant:'error'})
    }



    return(
        <>
        <Box className='flex-col-center' flex={1} gap={2} p={2}>
            <Box width={400} className='flex-col' gap={1.2}>
                {
                    type === 'Patient upload' ?
                        <PatientUpload site={site} control={control} setValue={setValue}/>
                    :
                        <DemographicsUpload control={control} templateTypes={templateTypes}/>
                }
            </Box>
            <Typography variant="h6">Upload CSV file</Typography>   
            <HaloDropZone
                fileTypes={['csv']}
                name='file'
                required
                dropZoneSx={{
                    height:280,
                    width:600,
                    margin:'auto'
                }}
                control={control}
                />
        </Box>
        <StepButtons 
            handleBack={goPrevStep}
            handleNext={handleNext}
        />
        </>
    )
}