import { Route } from 'react-router-dom'
import RequireAuth from '../auth/RequireAuth'
import TrialSignUp from './pages/TrialSignUp'
import TrialDashboard from './pages/TrialDashboard'
import TrialEncounterReview from './pages/TrialEncounterReview'
import TrialEncounterSummary from "./pages/TrialEncounterSummary.jsx"
import TrialEncounterFinalization from "./pages/TrialEncounterFinalization.jsx"
import TrialEncounterSubmittedDetails from './pages/TrialEncounterSubmittedDetails.jsx'
import BAAConsent from './pages/BAAConsent.jsx'

export const trialUserRoutes = [
    <Route path='/trial-users' element={<RequireAuth />} key='Trial Users'>
        <Route path='baa-consent' element={<BAAConsent/>} key='BAA Consent'/>
        <Route path='dashboard' element={<TrialDashboard />} key='Trial Dashboard' />
        <Route path='encounter-review/:encounterType/:trialEncounterId' element={<TrialEncounterReview />} key='Trial Encounter Review' />
        <Route path='encounter-summary/:encounterType/:trialEncounterId' element={<TrialEncounterSummary />} key='Trial Encounter Summary' />
        <Route path='encounter-finalize/:encounterType/:trialEncounterId' element={<TrialEncounterFinalization />} key='Trial Encounter Finalization' />
        <Route path='encounter-details/:encounterType/:trialEncounterId' element={<TrialEncounterSubmittedDetails />} key='Trial Encounter Submitted Details' />
    </Route>,

    <Route path='/t/referral/sign-up/:signedPayload' element={<TrialSignUp />} key='Trial User Sign Up' />


]
