import { useTheme } from '@emotion/react';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Box, Typography } from "@mui/material";
import dayjs from 'dayjs';

function getChipStyle(val, selected, {palette}) {
    let styling = {
        bgColor: palette.action.hover, 
        color:'inherit',
        Icon: null,
        
    }

    if (val === 0) return {...styling, Icon: TrendingFlatIcon}; 


    else if (val > 0) {
        return {
            ...styling, 
            bgColor: selected? palette.success.main : palette.success.shades8, 
            color: selected? palette.primary.contrastText : palette.success.main,
            Icon: TrendingUpIcon
        }; 

    } 
    else if (val < 0) {
        return {
            ...styling, 
            bgColor: selected? palette.error.main : palette.error.shades12, 
            Icon: TrendingDownIcon, 
            color: selected? palette.error.contrastText : palette.error.main,
        };  
    }

    return styling
}

function getLabel(label){

    if(label === 'N/A') return label

    return label?.toString()?.replace('-','') + ' %'

}

function getHelperText(label, dayPeriod, dates){

    if(label === 'N/A' || label === null) return ''

    else if (dayPeriod > 1) return 'vs prev. ' + dayPeriod + ' days'
    
    else if (dates[0] === dayjs().format('YYYY-MM-DD'))  return 'vs yesterday'

    return 'vs prev. day'


}

export default function PercentChip({
    size,
    label,
    sx,
    dayPeriod,
    selected,
    dates
}) {

    const theme = useTheme()
    const {bgColor, color, Icon} = getChipStyle(label,selected, theme)


    return(
        <Box className='flex-row' gap={1} alignItems={'center'}>
            <Box 
                className='flex-row'
                sx={{
                    p:'4px 8px',
                    borderRadius:'16px',
                    background: bgColor,
                    color:color,
                    gap:1,
                    flex:1,
                    height:26
                }}>
                { Icon && <Icon sx={{fontSize:18}} color={color}/>}
                <Typography noWrap sx={{fontSize:13, fontWeight:400, color:color}} >{getLabel(label) }</Typography>
            </Box>
            <Typography noWrap  sx={{fontSize:13, fontWeight:400}} color={selected ? 'primary.contrastText' :'text.secondary'}> {getHelperText(label, dayPeriod, dates) }</Typography>
        </Box>
    )


}