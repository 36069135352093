import { useEffect } from "react"
import { useWatch } from "react-hook-form"
import { useDispatch } from "react-redux"
import { updateFieldScore } from "../../../../qualitySlice"


export const useWatchScore = ({control, section_name}) => {


    const dispatch = useDispatch()


    const updated_score = useWatch({control, name:'score'})
    
    useEffect(() => {
                
        dispatch(updateFieldScore(
            {[section_name]:updated_score}
        ))

    }, [updated_score])
    

}