import { Box, Typography } from "@mui/material";
import ResponsiveBarChart from "../../../../../../globalComponents/NivoCharts/ResponsiveBar/ResponsiveBarChart";
import { useGetCaseStatsBarChartsQuery } from "../../../../../rosterAdminAPISlice";
import CalendarIcon from '../../../../../../../assets/icons/Calendar.svg'
import CustomNivoTooltip from "../../../../../../globalComponents/NivoCharts/Tooltips/CustomNivoTooltip";

const getPadding = (dataSetCount) =>  dataSetCount > 7 ? 0.3 : 0.7

export default function SpotlightBarChart({dates, stat, isDateRange}){


    const {data} = useGetCaseStatsBarChartsQuery({dates, statType:stat}, {skip: !isDateRange})

    return(
        isDateRange?
            <Box height={229} width={'100%'} mt={'31px'}>
                <ResponsiveBarChart 
                    data={data || []} 
                    padding={getPadding(data?.length)}
                    axisBottom={{tickSize:0, tickPadding:8}}
                    enableLabel={false}
                    customTooltipFunc={obj => (
                        <CustomNivoTooltip
                            stats={[{
                                label: stat,
                                value: obj.data.y,
                                color: 'rgb(0, 111, 169)'
                            }]}/>
                    )}/>
            </Box>  
            :
            <Box className='flex-col-center' m={'auto'} mt={'65px'} gap={2} width={184}>
                <img src={CalendarIcon} height={74} width={64}/>
                <Typography variant="subtitle2" color={'text.disabled'} textAlign={'center'}>Please select a date range to enable trend widget</Typography>
            </Box>
    )
}