import { Box, Typography } from "@mui/material";
import HaloSkeleton from "../../Skeletons/HaloSkeleton";








export default function TypographyCard({
    title,
    titleVariant = 'body2', 
    value,
    valueVariant = 'subtitle1',
    endComponent = () => null,
    loading=false
}) {




    return(
        <Box className='flex-row-between align-center' key={title} sx={(theme)=> ({...theme.standardBox, p:2, flex:1})}>
            <Box className='flex-col' gap={2}>
                <Typography variant={titleVariant}>{title}</Typography>
                <Typography variant={valueVariant} noWrap>
                    {
                        loading ?
                            <HaloSkeleton loading={loading} width={40}/>
                            :
                            value || '-'
                    }
                </Typography>
            </Box>
            {endComponent()}
        </Box>
    )




}