import { apiSlice } from "../../app/api/apiSlice";


export const dataIntakeApiSlice = apiSlice
.enhanceEndpoints({addTagTypes:['Intake Records', 'Custom Templates']})
.injectEndpoints({
    endpoints: builder => ({
        scanForErrors: builder.mutation({
            query:({file, ...additionalData}) => {
                const formData = new FormData()
                formData.append('file', file);  
                Object.keys(additionalData).forEach((key) => {
                    formData.append(key, additionalData[key]);
                });
                return {
                    url: 'data-intake/scan-for-errors',
                    method: 'POST',
                    body: formData
                }
            },
        }),
        getCustomFields: builder.query({
            query:() => 'data-intake/custom-field'
        }),
        getCustomTemplates: builder.query({
            query:(params) => {
                const { emr, site, type} = params || {}
                let url = `data-intake/custom-template/list/?emr=${emr || ''}&site=${site || ''}&type=${type || ''}`
                return url
            },
            providesTags:['Custom Templates']
        }),
        getTemplateTypes: builder.query({
            query:() => 'data-intake/custom-template/type'
        }),
        manageIntakeTemplate: builder.mutation({
            query: (payload) =>({
                url: 'data-intake/custom-template',
                method: payload.method,
                body: payload.body
            }),
            invalidatesTags:['Custom Templates']
        }),
        uploadPatients: builder.mutation({
            query: ({file,...additionalData}) => {
                const formData = new FormData()
                formData.append('file', file); 
                Object.keys(additionalData).forEach((key) => {
                    formData.append(key, additionalData[key]);
                });
                return ({
                    url: 'data-intake/generic-data-intake-upload',
                    method:'POST',
                    body: formData
                })
            },
            invalidatesTags:['Intake Records']
        }),
        getIntakeRecordsList: builder.query({
            query: ({pagination}) => `data-intake/intake-records/list?page=${pagination.page +1}&size=${pagination.pageSize}`,
            providesTags:['Intake Records']
        })
    })
})


export const { 
    useScanForErrorsMutation, useGetCustomFieldsQuery, useManageIntakeTemplateMutation, useGetCustomTemplatesQuery, useGetTemplateTypesQuery,
    useUploadPatientsMutation, useGetIntakeRecordsListQuery
} = dataIntakeApiSlice
