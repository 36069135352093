
export function getMasking({customMask, suffix, variant, maxValue, decimalPlace}) {
    let mask = null; //default to number
    let definition = null;
  
    if (customMask) {
      mask = customMask;
    } else if (suffix) {
      mask = [
        {
          mask: new RegExp(`^.*${suffix}.*$`, 'g'),
        },
        {
          mask: 'num ' + suffix,
          lazy: false,
          blocks: {
            num: {
              mask: Number,
              expose: true,
              max: maxValue,
              radix: '.',
              scale: decimalPlace,
            },
          },
        },
      ];
    } else if (variant === 'ssn') {
      mask = 'XXX-XX-0000';
      definition = {
        X: {
          mask: '0',
          displayChar: 'X',
          placeholderChar: '#',
        },
      };
    } else if (variant === 'zip') {
      mask = [
        {
          mask: '00000',
          maxLength: 5,
        },
        {
          mask: '00000-0000',
          minLength: 6,
        },
      ];
    } else if (variant === 'phone') {
      mask = '+{1}(000)000-0000';
    } else if (variant === 'height') {
      mask = [{ mask: `0'0"`, eager: true }, { mask: `0'00"`, eager: true }];
    } else {
      //default
      mask = Number;
    }
  
    return { mask, definition };
  }
  