import { Autocomplete, CircularProgress, Paper, TextField } from "@mui/material";
import {  useState } from "react";
import { useGetDisclaimersQuery } from "../../../../infectiousDiseaseAPISlice";
import SearchIcon from '@mui/icons-material/Search';


//unique component that allows async search...
//..and when an item is clicked it is saved to a different field

// I haven't found an additional use case for this component so it'll stay unique for now

function MedDisclosureSearch({setRHFFields, getRHFFields, handleSave}) {

    const [isOpen, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { data:options, isLoading } = useGetDisclaimersQuery(undefined, { skip: !isOpen });

    return (
        <Autocomplete
            fullWidth
            open={isOpen}
            size="small"
            freeSolo
            openOnFocus
            disableClearable
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            loading={isLoading}
            options={options || []}
            getOptionLabel={(option) => option['content'] || ''}
            isOptionEqualToValue={(option, val) => option['content'] === val['content']}
            getOptionKey={(option) => option?.id}
            noOptionsText={isLoading ? 'Loading...' : 'No options'}
            value={''}
            onChange={(e, newValue) => {
                if(newValue){
                    const currentNotes = getRHFFields('notes_from_pharmacist')
                    const seperator = currentNotes? '\n \n' : ''

                    const updatedNotes = currentNotes + seperator + newValue.content

                    setRHFFields('notes_from_pharmacist', updatedNotes, {shouldDirty:true, shouldValidate:true, shouldTouch:true})
                                    }
                    handleSave(
                        {
                            ...getRHFFields()
                        }
                    )
            }}
            inputValue={inputValue}
            onInputChange={ (event, newInputValue) => { 

                if(event.type === 'click' || event.key === 'Enter'){
                    setInputValue('')
                }else{
                    setInputValue(newInputValue)
                }
        
            }}
            PaperComponent={(props) => <Paper elevation={8} {...props} />}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label='Search disclaimers'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {params.InputProps.endAdornment}
                                {isLoading ? <CircularProgress color="primary" size={20} /> : <SearchIcon color="action" size={20}/>}

                            </>
                        ),
                    }}
                />
            )}
        />
    );
}


export default MedDisclosureSearch