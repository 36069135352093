import { apiSlice } from "../../app/api/apiSlice";

export const infectiousDiseaseAPISlice = apiSlice
.injectEndpoints({
    endpoints: builder => ({
        getInfectiousDiseaseEncounter: builder.query({
            query: (encounterId) => '/infectious-disease/encounter/' + encounterId,
            keepUnusedDataFor:1

        }),
        manageSamples: builder.mutation({
            query: (payload) =>({
                url: '/infectious-disease/samples',
                method: payload.method,
                body: payload.body
            })
        }),
        manageOrganisms:builder.mutation({
            query: (payload) => ({
                url: 'infectious-disease/samples/organisms',
                method: payload.method,
                body: payload.body
            }),
        }),
        manageAntibioticResistanceGenes: builder.mutation({
            query: (payload) => ({
                url: 'infectious-disease/samples/antibiotic-resistance-genes',
                method: payload.method, 
                body: payload.body
            })
        }),
        manageGeneralEncounter: builder.mutation({
            query: (payload) =>({
                url:'infectious-disease/general-encounter',
                method: payload.method,
                body: payload.body
            })
        }),
        manageMedInstructions: builder.mutation({
            query: (payload) => ({
                url: 'infectious-disease/medication-instructions',
                method: payload.method,
                body: payload.body
            })
        }),
        manageD2DInteractions: builder.mutation({
            query: (payload) => ({
                url: 'infectious-disease/d2d-interactions',
                method: payload.method,
                body: payload.body
            })
        }),
        getSampleTypes: builder.query({
            query: () => '/infectious-disease/sample-types'
        }),
        getSampleUnits: builder.query({
            query: () => '/infectious-disease/sample-units'
        }),
        getOrganisms: builder.query({
            query: () => '/infectious-disease/organisms'
        }),
        getMicrobialLoads: builder.query({
            query: () => 'infectious-disease/microbial-loads'
        }),
        getAntibioticResistanceGenes:builder.query({
            query: () => 'infectious-disease/antibiotic-resistance-genes'
        }),
        getDisclaimers: builder.query({
            query: () => 'infectious-disease/disclaimers'
        })

    })
})


export const {
    useGetInfectiousDiseaseEncounterQuery,useManageMedInstructionsMutation,
    useManageSamplesMutation, useGetSampleTypesQuery, useGetSampleUnitsQuery, useManageOrganismsMutation,
    useGetOrganismsQuery, useGetMicrobialLoadsQuery, useGetAntibioticResistanceGenesQuery, useManageAntibioticResistanceGenesMutation,
    useManageGeneralEncounterMutation, useGetDisclaimersQuery, useManageD2DInteractionsMutation
} = infectiousDiseaseAPISlice