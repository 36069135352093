import {  Box, TextField, Typography } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { useManageMedInstructionsMutation } from "../../../../../infectiousDiseaseAPISlice";
import StyledWarningSwitch from "../../../../../../globalComponents/Switches/StyledWarningSwitch";




export default function MedNotePanel({ row, isReadOnly }){

    const apiRef = useGridApiContext()
    const [editMed] = useManageMedInstructionsMutation()
    const [notes, setNotes] = useState(row?.row?.note || '')
    // const [ rejectInfoNugget, setReject] = useState(row?.row.is_consideration_rejected || false)


    const handleChange = (e) => setNotes(e.target.value)
    const handleReject = (e) => {
        const new_row = {...row?.row, note:notes, is_consideration_rejected:e.target.checked} 
        apiRef.current.updateRows([new_row])

        editMed({
            method:'PUT',
            body: {
                ...row.row,
                is_consideration_rejected: e.target.checked,
            }
        })
        .unwrap()
        .then(res => {})
        .catch(err =>{ })
    }

    const updateDataGridState = (e) => {
        const new_row = {...row?.row, note:notes} 
        apiRef.current.updateRows([new_row])
    }

    const sendUpdate = () => {

        editMed({
            method:'PUT',
            body: {
                ...row.row,
                note:notes
            }
        })
        .unwrap()
        .then(res => {})
        .catch(err =>{ })
    }

    const handleUpdate =() =>{
        updateDataGridState()
        sendUpdate()
    }

    
    return(
        <Box
            className='flex-col'
            sx={(theme) =>({
                background:theme.palette.background.paper,
                p:2,
                pt:1,
                gap:1
            })}>
            <Box className='flex-col' sx={{p:'0px 12px'}}>
                <Box className='flex-row' alignItems={'center'} gap={1}>
                    <Typography variant='subtitle2' color='text.secondary' >Med Considerations:</Typography>
                    <StyledWarningSwitch 
                        size='small' 
                        checked={row?.row.is_consideration_rejected}
                        onClick={handleReject}
                        disabled={isReadOnly}
                        />
                </Box>
                <Typography variant='body2' color={row?.row.is_consideration_rejected ? 'warning.dark' : 'text.secondary'}>
                    {
                        row?.row.is_consideration_rejected ? 
                            'preset med consideration will not be added '
                            :
                            row?.row?.medication?.considerations || 'n/a'
                    }
                </Typography>
            </Box>
            <TextField
                fullWidth
                label='notes'
                size="small"
                value={notes}
                name='notes'
                onChange={handleChange}
                disabled={isReadOnly}
                onBlur={handleUpdate}
                onKeyDown={e =>{ 
                    if(e.key === 'Enter'){
                        e.target.blur()
                    }
                }}
                />
            
            
        </Box>
    )
}