import { Button, Typography, Box } from "@mui/material";
import PercentChip from "../../Chips/PercentChip/PercentChip";
import HaloSkeleton from "../../Skeletons/HaloSkeleton";



const buttonSx =(theme, selected) => ({
    justifyContent:'space-between', 
    height:134, 
    flex:1,
    minWidth: 263,
    p:2, 
    textAlign:'start',
    background: selected? theme.palette.primary.dark :   theme.palette.background.paper,
    borderRadius: 2,
    border:`1px solid ${theme.palette.divider}`
})


export default function StatCard({
    label,
    stat,
    percentDiff,
    selected,
    endComponent=null,
    setStat,
    dayPeriod,
    dates,
    loading=false
}) {




    return (
        <Button
            className='flex-row' 
            disableElevation
            color={selected? 'primary' : 'inherit'}
            sx={(theme) => buttonSx(theme, selected)} 
            onClick={setStat}
            variant={selected? 'contained' : 'text'}>
            <Box className='flex-col' justifyContent={'space-between'} gap={2}>
                <HaloSkeleton loading={loading}>
                    <Typography variant="body2" color={selected ? 'primary.contrastText' :'text.secondary'}>{label}</Typography>
                </HaloSkeleton>
                <Box className='flex-col' gap={1} >
                    <HaloSkeleton loading={loading} width='100%' sx={{maxWidth:40}}>
                        <Typography variant='h5' noWrap>{!stat && stat !==0 ? '-' : stat}</Typography>
                    </HaloSkeleton>
                    <HaloSkeleton loading={loading}>
                        <PercentChip 
                            label={percentDiff} 
                            dayPeriod={dayPeriod} 
                            selected={selected}
                            dates={dates}/>
                    </HaloSkeleton>
                </Box>
            </Box>
            {endComponent}
        </Button>
    )


}