import { Box, Divider } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useSelector, useDispatch } from "react-redux";
import { selectCaseHistoryFilters, selectRosterMgmtDates, setCaseHistoryFilters } from "../../../../../admin/adminSlice";
import { useGetClinicianCaseAsgmtHistoryQuery } from "../../../../rosterAdminAPISlice";
import LinkedPatientCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/LinkedPatientCell";
import { AssignmentStatusChipCell } from "../../../../../globalComponents/DataGrid/subComponents/customCells/AssignmentStatusChipCell";
import { stringToSentenceCase } from "../../../../../../utils/stringUtils/sentenceCase";
import { Filters } from "../../RosterAdminPanel/RosterGrids/Grids/RMCasesGrid/Filters";
import { useCallback, useState } from "react"
import usePaginatedRowCount from "../../../../../globalComponents/DataGrid/hooks/commonUseEffects/usePaginatedRowCount"
import DefaultNoRowsOverlay from "../../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay";
import { stickyHeaderProps } from "../../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";
import ReduxStoreSearchBar from "../../../../../globalComponents/SearchBars/ReduxStoreSearchBar/ReduxStoreSearchBar";
import { hideSelectAllCheckBoxProps, linkedRowProps } from "../../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";
import ClinicianCaseMgmtToolbar from "./ClinicianCaseMgmtToolbar";
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom'

const pageSizeOptions = [5, 10, 15, 25, 50, 100]

const gridProps = {
    sx: {
        border: 'none',
        minHeight: 500,
        '--DataGrid-overlayHeight': '300px',
        ...stickyHeaderProps,
        '& .navigable-row': {
            cursor: 'pointer'
        }
    },
    autoHeight: true,
    slots: {
        noRowsOverlay: DefaultNoRowsOverlay,
    },

}

const columns = [
    {
        field: 'batch_date',
        headerName: 'Batch date',
        width: 120,
        valueGetter: (value) => dayjs(value).format('MMM D, YYYY')
    },
    {
        field: 'case_id',
        headerName: 'Case ID',
        width: 80,
    },
    {
        field: 'case_type',
        headerName: 'Case type',
        width: 150,
    },
    {
        field: 'patient_first_name',
        headerName: 'Patient',
        minWidth: 180,
        renderCell: (params) => (
            <LinkedPatientCell
                patientName={`${params?.row?.patient_first_name} ${params?.row?.patient_last_name}`}
                patientId={params?.row?.patient_id}
            />
        )
    },
    {
        field: 'site',
        headerName: 'Site',
        minWidth: 180,
    },
    {
        field: 'case_resolution_status',
        headerName: 'Status',
        minWidth: 180,
        renderCell: (params) => <AssignmentStatusChipCell {...params} />
    },
    {
        field: 'case_resolution_notes',
        headerName: 'Notes',
        flex: 2,
        valueFormatter: (value) => value ? stringToSentenceCase(value) : '-'
    },
]


export function ClinicianCaseHistory({ clinicianId }) {


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [selected, setSelected] = useState([])
    const isSelected = selected?.length > 0

    const dates = useSelector(selectRosterMgmtDates)
    const queryParams = useSelector(selectCaseHistoryFilters)
    const setQueryParams = useCallback((state) => dispatch(setCaseHistoryFilters(state)), [dispatch])

    const { data, isLoading, isFetching } = useGetClinicianCaseAsgmtHistoryQuery({ dates, clinicianId, queryParams })

    const handlePaginationChange = ({ pageSize, page }) => {
        setQueryParams({
            pagination: {
                ...queryParams,
                page,
                pageSize
            }
        })
    }


    const rowCount = usePaginatedRowCount(data?.count)


    const resetPagination = () => setQueryParams({ pagination: { page: 0, pageSize: 10 } })

    const handleFilterChange = (filterName) => (value) => {
        setQueryParams({ [filterName]: value })
        resetPagination()
    }

    const handleSortChange = (sortModel) => {
        const { field, sort } = sortModel[0] || {}

        let order = field

        if (sort === 'desc') {
            order = '-' + order
        }

        resetPagination()
        setQueryParams({ ordering: order })
    }

    const handleRowSelection = (selectedRowIds) => setSelected(selectedRowIds)

    const handleIsRowSelectable = ({ row }) => {
        const status = row?.status
        return ['completed', 'pending', 'in_progress', 'assigned', 'failed', 'pending_results'].includes(status)
    }

    const handleCellClick = ({ field, row }) => {

        const { case_resolution_status: status } = row || {}
        if (status === 'no_action' || status === 'delayed') return
        if (field.includes('__') || field === 'patient_first_name') return

        else {
            if (status === 'completed' || status === 'pending_results') {
                navigate(`/caseload/case-details/${row.case_id}`)
            }
        }
    }

    const getRowClassName = (params) => {
        const status = params.row.case_resolution_status
        return (status === 'completed' || status === 'pending_results') ? 'navigable-row' : ''
    }

    return (
        <Box sx={(theme) => ({
            '.MuiDataGrid-container--top': {
                borderRadius: 2
            },
            ...theme.standardBox,
        })}>
            <Box className="flex-row-between">
                <Filters handleFilterChange={handleFilterChange} queryParams={queryParams} disableClinicianFilter={true} isCaseAssignment={true} />
                <ReduxStoreSearchBar selectorPath='admin.rosterMgmtGrids.caseHistoryFilters.search' setter={(val) => setCaseHistoryFilters({ search: val })} placeholder='Search cases' />
            </Box>
            <Divider />
            <DataGridPro
                getRowId={(row) => row.case_id}
                getRowClassName={getRowClassName}
                rows={data?.results || []}
                isRowSelectable={handleIsRowSelectable}
                onCellClick={handleCellClick}
                columns={columns}
                rowCount={rowCount}
                pagination
                sortingMode="server"
                paginationMode="server"
                paginationModel={queryParams?.pagination}
                onPaginationModelChange={handlePaginationChange}
                pageSizeOptions={pageSizeOptions}
                loading={isFetching || isLoading}
                onSortModelChange={handleSortChange}
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selected}
                onRowSelectionModelChange={handleRowSelection}
                {...gridProps}
                sx={{
                    ...gridProps.sx,
                    ...hideSelectAllCheckBoxProps,
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none'
                    }
                }}
                slots={{
                    ...gridProps?.slots,
                    toolbar: ClinicianCaseMgmtToolbar
                }}
                slotProps={{
                    toolbar: { isSelected, selected, setSelected }
                }}
                columnHeaderHeight={isSelected ? 0 : 52}
            />
        </Box>
    )
}