//The data needs to be in [{x: 4/2/2024, y:5}] format for the groupByTimeFrame to work. Will look into making it more dynamic.
//accepts weekly or monthly time frame
export const  groupByTimeFrame = (data, timeFrame) => {
    const result = []


    let currentStartDate = null;
    let currentSum = 0;

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    data.forEach(obj => {
        if(timeFrame === 'daily') {
            const formattedDate = obj.x.split('/').slice(0,2).join('/')
            result.push({x: formattedDate, y: Math.round(obj.y*100)/100})
        }

        const date = new Date(obj.x);

        if (timeFrame === 'monthly') {
            const monthIndex = date.getMonth();
            const monthName = monthNames[monthIndex];
            if (currentStartDate === null) {
                currentStartDate = monthName;
            }

            if (monthName !== currentStartDate) {
                // New month, push the sum of the previous month
                result.push({x: currentStartDate, y: Math.round(currentSum*100)/100});
                currentStartDate = monthName;
                currentSum = 0;
            }

        } else if (timeFrame === 'weekly') {
            if (currentStartDate === null) {
                currentStartDate = new Date(date);
                currentStartDate.setDate(date.getDate() - date.getDay()); // Set to Sunday of the current week
            }

            if (date.getDay() === 0) {
                // Sunday, start a new week
                if (currentStartDate !== null) {
                    // New week, push the sum of the previous week
                    const formattedDate = `${currentStartDate.getMonth() + 1}/${currentStartDate.getDate()}`;
                    result.push({x: formattedDate, y: Math.round(currentSum*100)/100});
                    currentSum = 0;
                }
                currentStartDate = new Date(date);
            }
        }

        currentSum += obj.y;
    });

    // Add the sum of the last month/week if it's not already added
    if (currentStartDate !== null) {
        if (timeFrame === 'monthly') {
            result.push({x: currentStartDate, y: Math.round(currentSum*100/100) });
        } else if (timeFrame === 'weekly') {
            const formattedDate = `${currentStartDate.getMonth() + 1}/${currentStartDate.getDate()}`;
            result.push({x: formattedDate, y: Math.round(currentSum*100/100)});
        }
    }

    return result; 
}
