import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../auth/authSlice";
import { Avatar, Box, Divider, Typography, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import dashBoardVector from "../../../../assets/images/generic/dashBoardVector.png"
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";
import { memo } from "react";
import { formatDateTimeField } from "../../../../utils/dateUtils/formatDateTimeField";
import { useTutorial } from "../../utils/TutorialContext";


const tableSx = { width: '100%', 'td,': { p: '5px 0px', border: 0 } }

function TrialInfo({ referrer, trialEnd }) {

    const theme = useTheme()
    const user = useSelector(selectCurrentUser)
    const userInitial = user?.firstName?.slice(0, 1)?.toUpperCase()
    const userName = user.name ? stringToTitleCase(user.name) : ''

    const trialUserInfo = [
        { title: 'Trial end date', value: formatDateTimeField(trialEnd) },
        { title: 'Referring clinician', value: referrer }
    ]

    const { refs } = useTutorial()

    return (
        <Box className='flex-col' sx={{ ...theme.standardBox, p: '24px 16px', gap: 2, position: 'relative' }}>
            <img src={dashBoardVector} height={68} width={148} style={{ position: 'absolute', top: -68, left: '100px', }} />
            <Box className='flex-col-center' gap={1}>
                <Avatar sx={{ width: 56, height: 56, bgcolor: theme.avatar.primary }}>
                    {userInitial}
                </Avatar>
                <Typography sx={{ ...theme.typography.subtitle1, fontWeight: 500 }}>
                    {userName}
                </Typography>
            </Box>
            <Divider />
            <TableContainer>
                <Table sx={tableSx} size="small">
                    <TableBody>
                        {trialUserInfo?.map((info, indx) => (
                            <TableRow key={indx} title={info.metaTitle} ref={info.title === 'Trial end date' ? refs.current[0] : null}>
                                <TableCell align="left">
                                    <Typography sx={{ ...theme.typography.body2, color: theme.palette.text.disabled }}>
                                        {info.title}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right" width={150} sx={{ fontWeight: 500 }}>{info.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
}

export default memo(TrialInfo)
