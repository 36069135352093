import TrialReviewHeader from "../components/Header/TrialReviewHeader"
import { useGetTrialReviewQuery, useGetFeedbackSectionQuery } from "../trialAdminAPISlice"
import { useParams } from "react-router-dom"
import TrialPathologyDetailsContainer from "../components/TrialPathologyReview/TrialPathologyDetailsContainer"
import TrialGrading from "../components/TrialGrading/TrialGrading"
import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import { useDispatch } from "react-redux"
import { setStaticMeds } from "../../pathology/pathologySlice"
import TrialInfecDisDetailsContainer from "../components/TrialInfectiousDiseaseReview/TrialInfecDisDetailsContainer"

function TrialReview() {

    const dispatch = useDispatch()

    const { encounterType, trialEncounterId } = useParams()

    const { data: trialReviewSubmittedDetails, isLoading: trialReviewIsLoading, isFetching: trialReviewIsFetching } = useGetTrialReviewQuery({ encounterType, trialEncounterId })

    const { data: feedback, isLoading: feedbackIsLoading } = useGetFeedbackSectionQuery({ encounterType, trialEncounterId })

    const { trial_encounter } = trialReviewSubmittedDetails || {}


    const [score, setScore] = useState(null)

    useEffect(() => {
        setScore(feedback?.score_percentage)
    }, [feedback])

    useEffect(() => {
        if (trialReviewSubmittedDetails) {
            dispatch(setStaticMeds(trialReviewSubmittedDetails?.trial_encounter?.patient?.medications))
        }
    }, [trialReviewSubmittedDetails, dispatch])

    return (
        <>
            <TrialReviewHeader
                trialPatient={trial_encounter?.patient}
                encounterType={encounterType}
                loading={trialReviewIsLoading || trialReviewIsFetching}
                trialUserName={feedback?.feedback_obj?.trial_user_name}
                score={score}
                feedbackSubmitted={Boolean(feedback?.feedback_obj?.date_submitted)}
            />
            <Box className='flex-row' height={'calc(100vh - 128px)'}>
                <OverlayScrollbarsComponent style={{ flex: 1 }}>
                    <TrialGrading
                        feedback={feedback}
                        trialEncounterId={trialEncounterId}
                        setScore={setScore}
                        loading={feedbackIsLoading}
                        encounterType={encounterType}
                    />
                </OverlayScrollbarsComponent>
                <OverlayScrollbarsComponent style={{ flex: 2 }}>
                    <Box className='flex-col' sx={{ m: 3, gap: 3 }}>
                        {encounterType.toLowerCase().includes('infectious') ?
                            <TrialInfecDisDetailsContainer
                                isLoading={trialReviewIsLoading || trialReviewIsFetching}
                                encounter={trial_encounter} /> :
                            <TrialPathologyDetailsContainer
                                isLoading={trialReviewIsLoading || trialReviewIsFetching}
                                encounter={trial_encounter}
                            />}
                    </Box>
                </OverlayScrollbarsComponent>
            </Box>
        </>
    )
}

export default TrialReview
