import { DataGridPro, useGridApiRef, gridClasses } from '@mui/x-data-grid-pro'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {selectCurrentCase} from '../../rosterManagement/rosterSlice'

import ActionCell from './subComponents/ActionCell'
import BaseFooter from './subComponents/BaseFooter'
import DefaultGridContainer from './gridContainers/DefaultGridContainer';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { showGenericValidationError } from '../../../app/errors/genericErrors'
import DefaultNoRowsOverlay from './subComponents/noRowsOverlays/DefaultNoRowsOverlay'
import { MemoCell } from './performance/MemoGridItems'
// import { MemoTracerCell } from './performance/TracerCell' // used for visualizing cell re-renders
import { randomId } from '@mui/x-data-grid-generator'
import GenericToolbar from './subComponents/toolBars/GenericToolBar'
import Collapse from '@mui/material/Collapse';
import { selectCurrentTrialEncounter } from '../../trialUsers/trialUserSlice'
import ToggleToolbar from './subComponents/toolBars/ToggleToolBar'




const slots = {
  cell:  MemoCell, 
  footer: BaseFooter,
  noRowsOverlay: DefaultNoRowsOverlay
}



// ----- non state dependent functions -----
const handleRowUpdateError = (err) =>{
  //need to prevent default Data grid error in console
  console.error('Data Grid: Process Row Update Error')
};

const getIsRowSelectable = (params) => !params.row?.isNew ;



// !! Alot of these functions will be abstracted once we have an optimal base version of the data grid
export default function CRUDDataGrid(props){

  const {
    title,
    initRows,
    columns,
    mutation,
    fieldToFocus, 
    requiredFields,
    defaultValues = {}, //indicates defaults for new rows 
    isReadOnly = false,
    initialState=null,
    getDetailPanelHeight=null,
    getDetailPanelContent=null,
    isCellEditable=null,
    parentId= null, 
    preValidation=null,
    toolbarChildren,
    gridParentSx,
    GridIcon, 
    isTrialReview,
    toggleProps=null, //used to explicitly indicate a section is not provided
  } = props;

  const apiRef = useGridApiRef()

  let currentCase = {}
  
  const caseObj = useSelector(selectCurrentCase) 
  const trialEncounterObj = useSelector(selectCurrentTrialEncounter)
  
  !isTrialReview ? currentCase = caseObj : currentCase = trialEncounterObj //uses different redux state depending on if trial review or standard workflow 

  const patientId = currentCase.patient.id
  const parentObjId = parentId ?? currentCase.encounterId; //samples for infectious disease is the only time 'sampleId' will be used over parentId

  const [ manageItem ] = mutation()
  const [ open, setOpen ] = useState(( initRows?.length > 0))

  const openGrid = () => setOpen(true)
  const closeGrid = () => setOpen(false)



 
  const getRowClassName = useCallback((params) => {
    if (isReadOnly) {
        return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
    } else {
        return null;
    }
  },[isReadOnly]);

  const handleDeleteClick = useCallback((id) => () =>{

    const selectedObjs = apiRef.current.getSelectedRows()
    const selectedIds = []

    selectedObjs.forEach(obj => selectedIds.push(obj.id))

    
    const isSingleDeletion = id != null;
    const itemsToDelete = isSingleDeletion ? [id] : selectedIds;
    let fallbackRows = apiRef.current.getSortedRows()


    apiRef.current.updateRows(
      itemsToDelete.map((itemId) => ({id:itemId, _action:'delete'}))
    )


    manageItem({
      method: 'DELETE', 
      body: { 
        patient: patientId,
        parent_obj_id:parentObjId, 
        ids: itemsToDelete,
        caseId: currentCase?.caseId
      }
    }).unwrap()
      .then(res => {})
      .catch(err => {

        apiRef.current.updateRows( //clear all rows
          fallbackRows.map((row) => ({id:row.id, _action:'delete'}))
        );
        apiRef.current.updateRows( //restore rows before delete attempt
          fallbackRows.map((row) => ({...row})),
        );

      })

  }, [apiRef,  patientId, parentObjId])

  const processRowUpdate = useCallback(async (newItem, oldItem) => {
    const { id, isNew } = newItem;


    if(isEqual(oldItem, newItem)) return oldItem

    let payload = {
        ...newItem,
        patient: patientId,
        parent_obj_id: parentObjId,
        caseId: currentCase?.caseId
    };

    const method = isNew ? 'POST' : 'PUT';
    
    try {
        const res = await manageItem({method, body: payload}).unwrap();
  
        if (isNew) {
            apiRef.current.updateRows([{...res}])
            handleAddRow()  
             
            return {...oldItem, _action: 'delete'}
        } 
        
        let updatedRow = { ...res, isNew: false };
        return updatedRow; 

    }catch(err){
      if(err?.status === 400){
        showGenericValidationError(err)
      }
    } 
  }, [apiRef, currentCase?.caseId,  patientId, parentObjId, showGenericValidationError]);

  const handleCancelClick = useCallback((id) =>() => {

    let row = apiRef.current.getRow(id)
    //may be repetitive but help trigger events for error handling/'add' btn disabling

    if (row?.isNew) {
      apiRef.current.stopRowEditMode({id, ignoreModifications:true}) 
      apiRef.current.updateRows([{ ...row, _action: "delete" }]);
    } else{
      apiRef.current.stopRowEditMode({id:id, ignoreModifications:true, cellToFocusAfter:null, field: fieldToFocus})
      apiRef.current.updateRows([{...row, errors:[]}])
    }


  }, [apiRef, fieldToFocus, ])

  const handleAddRow = async () => {
    const id = randomId();
    
    await apiRef.current.updateRows([{ id, isNew: true, [fieldToFocus] : '', ...defaultValues}]);
    apiRef.current.startRowEditMode({id, fieldToFocus})
    
  }

  const handleRowEditStop = useCallback((params, event) => { 
    let initRow = params.row

    if (event.keyCode === 13 && event.shiftKey) {
      event.defaultMuiPrevented = true;
      return
    }

    if (event.key === 'Escape' && initRow?.isNew) {
      apiRef.current.updateRows([{ id: initRow.id, _action: "delete" }])
      return;
    }

    if (preValidation && preValidation(params, event)) {
      return;
    }


    let currentRow = apiRef.current.getRowWithUpdatedValues(initRow.id);

    let errFields = requiredFields.filter(field => {

      if (dayjs.isDayjs(currentRow[field])){
          return !currentRow[field].isValid()
      }
      else if (typeof currentRow[field] === 'object') {
          //some custom cells return objects 
          //(mainly when selecting static objects provided by the server )
          return currentRow[field] === null;  
      } 
      else {
          return currentRow[field]?.trim() === '' || !currentRow[field];
      }
    });
  
    let errCnt = errFields.length

    if (errCnt > 0){
      event.defaultMuiPrevented = true;
      apiRef.current.updateRows([
        {
          ...initRow,
          errors : errFields
        }
      ])
    }
  

    
  }, [apiRef,  preValidation, requiredFields]);

  const getIsCellEditable = useCallback((params) => !isReadOnly , [isReadOnly])
  const getRowHeight = useCallback(() => (isReadOnly ? 'auto' : 36), [isReadOnly]);


  const actionsColumn = useMemo(() => ({
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 50,
    cellClassName: 'actions',
    getActions: ({...otherProps}) => [
      <ActionCell 
        {...otherProps}
        handleCancelClick={handleCancelClick} 
        handleDeleteClick={handleDeleteClick}/>
    ],
    renderHeader: () => ''
  }), [ handleCancelClick, handleDeleteClick]);
  

  
  let rows = useMemo(() => initRows || [], [initRows])
  const modColumns = useMemo(() => isReadOnly ? columns : columns.concat(actionsColumn), [isReadOnly, columns, actionsColumn]);
  
  const sx = {
    border:'none',
    minHeight: isReadOnly? 75: 113,
    '--DataGrid-overlayHeight': 1 //hide the noRowsOverlay
  }

  const toolBarProps = {
    apiRef,
    title,
    isReadOnly,
    initRowCount: initRows?.length || 0,
    openGrid,
    closeGrid,
    open,
    GridIcon,
    handleDeleteClick,
    handleAddRow,
    toolbarChildren,
  }
  
  return (
      
    <DefaultGridContainer sx={gridParentSx}>
      { //will find a better way to simplify these components, once server side MUI gtid gets released
        toggleProps ?
          <ToggleToolbar 
            {...toolBarProps}
            toggleProps={toggleProps}
            parentObjId={parentObjId}
            />
          :
          <GenericToolbar 
            {...toolBarProps}
            />
      }
      <Collapse in={open} timeout={{exit:150, enter:300}}>
        <DataGridPro
          sx={{
            ...sx,
            ...(isReadOnly
              ? {
                  [`& .${gridClasses.cell}`]: {
                    py: 1,
                  },
                }
              : {}),
          }}              
          rows={rows}
          editMode="row"
          apiRef={apiRef}
          columns={modColumns}
          initialState={initialState}
          density='compact'
          hideFooter={isReadOnly}
          disableColumnMenu
          disableColumnFilter
          disableRowSelectionOnClick
          checkboxSelection={!isReadOnly}
          getRowClassName={getRowClassName}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          isRowSelectable={getIsRowSelectable}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          onProcessRowUpdateError={handleRowUpdateError}
          isCellEditable={isReadOnly? getIsCellEditable: isCellEditable}
          getRowHeight={getRowHeight}
          slots={slots}
          slotProps={{
            footer:{
              apiRef,
              fieldToFocus,
              isReadOnly,
              handleAddRow
            }
          }}
          autoHeight
          />
      </Collapse>
    </DefaultGridContainer>
      
  )
}
