import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../auth/authSlice";
import { Avatar, Box, Divider, LinearProgress, Typography } from "@mui/material";
import dashBoardVector from '../../../../../assets/images/generic/dashBoardVector.png'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { stringToTitleCase } from "../../../../../utils/stringUtils/titleCase";
import { memo } from "react";
import UserAvatar from "../../../../globalComponents/Avatar/UserAvatar";

const tableSx = { width:'100%', 'td,':{p:'5px 0px', border:0} }

function DashboardStats({ stats }) {
    const theme = useTheme();
    const user = useSelector(selectCurrentUser);
    const userName = user.name ? stringToTitleCase(user.name) : ''

    const percentageStats = [
        {title:'QA pass rate', percent: stats?.qa_percent, metaTitle:'% of your completed cases that passed quality assurance checks'},
        {title:'Case completion', percent: stats?.completion_percent, metaTitle:'% of cases assigned to you that are completed'},
    ]


    return (
        <>
        <Box className='flex-col' sx={{...theme.standardBox, p:'24px 16px', gap:'16px',  position:'relative' }}>
            <img src={dashBoardVector} height={68} width={148} style={{position: 'absolute', top: -68, left: '100px',}}/>
            <Box className='flex-col-center' gap={1}>
                <UserAvatar firstName={userName} sx={{ width: 56, height: 56, bgcolor: theme.avatar.primary }}/>
                <Typography sx={{...theme.typography.subtitle1, fontWeight:500}}>
                    {userName}
                </Typography>
            </Box>
            <Divider/>
            {/* Percent Stats*/}
            <TableContainer>
                <Table sx={tableSx} size="small">
                    <TableBody>
                    {percentageStats?.map((stat, indx) => (
                        <TableRow key={indx} title={stat.metaTitle}>
                            <TableCell  align="left">
                                <Typography sx={{...theme.typography.body2, color:theme.palette.text.disabled}}>
                                    {stat.title}
                                </Typography>
                            </TableCell>
                            
                            <TableCell align="right" width={40} >{stat.percent}%</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
        </>
    );
}
export default memo(DashboardStats)
