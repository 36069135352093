import { Route } from "react-router-dom";
import RequireAuth from "../auth/RequireAuth";
import Profile from "./pages/Profile";
import EditName from "./pages/EditName";
import EditPreferredTitle from "./pages/EditPreferredTitle";
import EditPhoneNumber from "./pages/EditPhoneNumber";

export const profileRoutes = [
    <Route path='/profile' element={<RequireAuth/>} key='profileRoutes'>
        <Route path='/profile' element={<Profile/>} key='profile'/> 
        <Route path='/profile/edit-name' element={<EditName/>}/>
        <Route path='/profile/edit-preferred-title' element={<EditPreferredTitle/>}/>
        <Route path='/profile/edit-phone-number' element={<EditPhoneNumber/>}/>
    </Route>
]
