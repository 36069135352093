import { Box } from "@mui/material";
import SingleFilterChip from "../../../../../../../globalComponents/Chips/DropDownChip/SingleFilterChip/SingleFilterChip";
import { useGetCaseTypesQuery, useGetRosterUsersQuery } from "../../../../../../rosterAPISlice";
import SitesFilterChip from "../../../../../../../globalComponents/Chips/DropDownChip/SItesFilterChip/SitesFilterChip"
import SingleAsyncObjFilterChip from "../../../../../../../globalComponents/Chips/DropDownChip/AsyncObjFilterChip/SingleAsyncObjFilterChip";









export function Filters({
    handleFilterChange,
    queryParams,
    disableClinicianFilter = false,
    isCaseAssignment = false
}) {

    const { data: caseTypes, isLoading: loadingTypes, isFetching: fecthingTypes } = useGetCaseTypesQuery()

    const statusOptions = [
        { label: 'Pending', value: 'pending' },
        { label: 'Pending results', value: 'pending_results' },
        { label: 'In Progress', value: !isCaseAssignment ? 'in_progress' : 'in-progress' },
        ...(isCaseAssignment ? [] : [{ label: 'Assigned', value: 'assigned' }]),
        { label: 'Completed', value: 'completed' },
        { label: 'Delayed', value: 'delayed' },
        { label: 'No Action Needed', value: 'no_action' },
        { label: 'Failed', value: 'failed' },
        { label: 'Did Not Finish', value: 'dnf' }
    ]
    

    return (
        <Box className='flex-row' sx={{ minHeight: 52, p: '8px 16px', alignItems: 'center', gap: 1, flexWrap: 'wrap' }} >
            <SingleFilterChip
                chipLabel='Type'
                options={caseTypes?.map((type) => ({ label: type.name, value: type.id }))}
                filterState={queryParams?.case_type}
                setFilterState={handleFilterChange('case_type')} />
            <SitesFilterChip
                filterState={queryParams?.sites}
                setFilterState={handleFilterChange('sites')} />
            <SingleFilterChip
                chipLabel='Status'
                options={statusOptions}
                filterState={!isCaseAssignment ? queryParams?.resolution_status : queryParams?.status}
                setFilterState={handleFilterChange(!isCaseAssignment ? 'resolution_status' : 'status')} />
            {!disableClinicianFilter && (
                <SingleAsyncObjFilterChip
                    chipLabel={'Clinician'}
                    query={useGetRosterUsersQuery}
                    filterState={queryParams?.user}
                    setFilterState={handleFilterChange('user')}
                    optionKey='name'
                />
            )}
        </Box>
    )
}