import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useLogSessionEntryEndTimeMutation } from "../../rosterAPISlice";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../../../app/api/apiSlice";
import { secsToMins } from "../../../../utils/stringUtils/secsToMins";
import axiosAuth from "../../../../utils/apiUtils/axiosAuth";


export default function CaseReviewTimer({ caseId, totalSessionDurationInSeconds, isCaseOwner}){


    const dispatch = useDispatch()
    const theme = useTheme()
    const [logSessionEnd] = useLogSessionEntryEndTimeMutation()
    let secondsRef = useRef()

    const [seconds, setSeconds] = useState(totalSessionDurationInSeconds)
    const [sessionEntryId, setSessionEntryId] = useState(null)
    
    useEffect(() => {
        secondsRef.current = seconds
    },[seconds])

    useEffect(() => {
        setSeconds(totalSessionDurationInSeconds)  
    }, [totalSessionDurationInSeconds])

    useEffect(()=>{
        const interval = setInterval(()=> {
        setSeconds((prevSeconds) => prevSeconds + 1)
        }, 1000)
        return () => clearInterval(interval)
    },[])

    useEffect(() => {
        if (caseId) {
            axiosAuth({
                url: 'roster/sessions/new-session-entry',
                method: 'PUT',
                data: {'case_id': caseId}
            }).then((res) => {
                setSessionEntryId(res?.data?.session_entry_id)
            })
        }
    },[caseId])

    useLayoutEffect(() => {
        if (sessionEntryId) {
            window.addEventListener('beforeunload', () => logSessionEnd(sessionEntryId))
        }
        return () => {
            if (sessionEntryId) {
                window.removeEventListener('beforeunload', () => logSessionEnd(sessionEntryId))
                logSessionEnd(sessionEntryId)
                .unwrap()
                .then(() => {
                    dispatch(apiSlice.util.updateQueryData('getCase', caseId, (caseData) => {caseData.total_session_duration_in_seconds = secondsRef.current})) 
                })   
            }
        }
    },[caseId, dispatch, logSessionEnd, sessionEntryId])

    return(
        <>
            {isCaseOwner ? 
            (<Box>
                <Typography sx={{color: theme.palette.text.disabled, fontSize: '12px'}}>Session time</Typography>
                <Typography variant="body2">{secsToMins(seconds)}</Typography>
            </Box>) : null}
        </>
    )
}
