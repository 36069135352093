import { SmallHeaderBox, TypographySubtitle } from "../../../globalComponents/SubHeader/components";
import BackButton from "../../../globalComponents/SubHeader/Backbutton";
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";
import { SubHeaderLoader } from "../../../globalComponents/Loaders";





export default function CaseFinalizationNav({isSubmitted, patientName, isLoading}){


    if(isLoading) {
        return(
          <SubHeaderLoader />
        )
      }


    return(
        <>
            {
                isSubmitted? 
                <SmallHeaderBox justifyContent='flex-start'>
                    <TypographySubtitle>Submitted {stringToTitleCase(patientName)+ "'s case" }</TypographySubtitle>
                </SmallHeaderBox>

                :
                <SmallHeaderBox justifyContent='flex-start'>
                    <BackButton/>
                    <TypographySubtitle>Submit {stringToTitleCase(patientName)+ "'s case" }</TypographySubtitle>
                </SmallHeaderBox>
            }
        
        </>
    )
}