import { useForm } from "react-hook-form";
import { HaloTextField } from "../../../../../globalComponents/ReactHookFormFields";
import { useEditQAObjMutation } from "../../../../qualityAPISlice";
import { show400Error } from "../../../../../../app/errors/genericErrors";
import {useParams} from "react-router-dom"





export default function GeneralFeedback({qaObj, disabled}){

    const [triggerEdit] = useEditQAObjMutation()
    const { control, formState:{ isDirty}, getValues, reset } = useForm({
        defaultValues:{
            final_feedback: qaObj?.final_feedback
        },
    })  


    const saveOnBlur = () =>{
        if(isDirty) {
            const values = getValues()
            triggerEdit({
                qualityId: qaObj?.id,
                payload: values
            })
            .unwrap()
            .then(res => {
                // pass the values to update values to "compare"
                reset( {...values}, {keepDirtyValues:true, keepDirty:false, })
            })
            .catch(err => show400Error(err))
        }
    }


    return(
        <HaloTextField 
            name='final_feedback'
            label='General feeback'
            control={control}
            onBlur={saveOnBlur}
            multiline
            rows={6}
            disabled={disabled}
        />
    )
}