import { Box, Divider, Typography, useTheme } from '@mui/material'
import { ActionButton } from '../../globalComponents/Buttons/ActionButton/ActionButton';
import EditUserInfoHeader from './ProfileHeader/EditUserInfoHeader';
import { useNavigate } from 'react-router-dom';


function EditUserInfoSkeleton({infoType, children, updateHandler, height}) {

    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <>
            <EditUserInfoHeader infoType={infoType}/>
            <Box p={3}>
                <Box component={'form'} onSubmit={updateHandler} noValidate sx={{width: 688, height: height, m: '0 auto', ...theme.standardBox}}>
                    <Box height={48} p={'8px 16px'}>
                        <Typography sx={{...theme.typography.body1}} p={'4px 0'}>{`Edit ${infoType.toLowerCase()}`}</Typography>
                    </Box>
                    <Divider/>
                    <Box className='flex-col' gap={1} p={2}>
                        {children}
                        <Box className='flex-row-flex-end' gap={2} p={'8px 0'}>
                            <ActionButton sx={{width:120}} variant='outlined' onClick={()=> navigate(-1)}>Cancel</ActionButton>
                            <ActionButton sx={{width:120}} variant='contained' onClick={updateHandler}>Save</ActionButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default EditUserInfoSkeleton
