import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

// Custom hook to manage monthly schedules
//moved here to make the SchedulingModal easier to read
const useMonthlySchedules = (monthSchedules) => {
    const [calendarState, setCalendarState] = useState(monthSchedules || []);
    
    useEffect(() => {
        if (monthSchedules) {
            setCalendarState(prevState => {
                // Get a set of dates from the current calendarState to check for duplicates.
                const existingSchedules = new Set(prevState.map(item => item.date));

                // Filter the new monthSchedules to only include those that don't exist in the state.
                const newSchedules = monthSchedules.filter(schedule => !existingSchedules.has(schedule.date));

                // Return the new state with the appended unique schedules.
                return [...prevState, ...newSchedules];
            });
        }
    }, [monthSchedules]);


    return [calendarState, setCalendarState]; 
};

export default useMonthlySchedules;