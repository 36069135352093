import { apiSlice } from '../../app/api/apiSlice'

export const genomicsApiSlice = apiSlice
.injectEndpoints({
    endpoints: builder => ({
        updateGxRecommendations: builder.mutation({
            query: (payload) =>({
                url: 'genomics/review',
                method: 'PUT',
                body: payload
            })
        }),
        manageGxGenes: builder.mutation({
            query: (payload) =>({
                url: 'genomics/review/genes',
                method: payload.method,
                body: payload.body
            })
        }),
        manageGxDrugInteractions: builder.mutation({
            query: (payload) =>({
                url: 'genomics/review/drug-interactions',
                method: payload.method,
                body: payload.body
            })
        }),
        manageGxLabValues : builder.mutation({
            query : (payload) => ({
                url:  'genomics/review/lab-values',
                method: payload.method,
                body: payload.body
            }),
        }),
        manageGxImagingValues : builder.mutation({
            query : (payload) => ({
                url:  'genomics/review/imaging-values',
                method: payload.method,
                body: payload.body
            }),
        }),
        
    })
})

export const {
    useUpdateGxRecommendationsMutation, useManageGxGenesMutation, useManageGxDrugInteractionsMutation,
    useManageGxLabValuesMutation, useManageGxImagingValuesMutation
} = genomicsApiSlice 