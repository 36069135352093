import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
import { Box, Slider } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { findAllByDisplayValue } from '@testing-library/react';

const MAX_CASES = 20
//this component is used to modify the calendar state
function SchedulingSingleDayDialog( { dialogData, setCalendarState,open, handleDailyScheduleDialog}){

    // dialogData will come in the form of a DailyCaseLoadSchedule Object
    const [value, setValue] = useState(dialogData?.requested_cases)


    useEffect(() => {
        if(dialogData){ 
            setValue(dialogData.requested_cases || 0)
        }
      }, [dialogData]);
      
    
    const handleSliderChange = (event, newValue) => setValue(newValue)
    const handleInputChange = (e) => {
        const input = e.target.value

        if(input > 20 ) {
            setValue(20)
            return;
        }
        setValue(e.target.value === '' ? 0 : Number(e.target.value));
    }

    const handleConfirm = () => {
        // Only update state if requested_cases changes
        //might have to pull this from calendar state instead
        if (dialogData.requested_cases !== value) {
    
            setCalendarState(prevState => {
                // If the dialogData is new, append it to the state
                if (dialogData?.is_new) {
                    return [
                        ...prevState,
                        {
                            ...dialogData,
                            requested_cases: value,
                        }
                    ];
                }
                // else if not new update it
                return prevState.map(item =>
                    item.id === dialogData.id ? {
                        ...item,
                        requested_cases: value,
                        is_edited: true
                    } : item
                );
            });
        }
    
        handleDailyScheduleDialog(false);
    };
    
    
    return(

        <Dialog open={open} onClose={() => handleDailyScheduleDialog(false)}>
            <DialogTitle>Requested Cases forr {dayjs(dialogData.date).format('MMMM D, YYYY')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Requested cases number・{value}         
                </DialogContentText>
                <Box className='flex-row' gap={2} alignItems={'center'}>
                    <Slider
                        value={value} //delay in state being intitialized so a fallback number prevents component from breaking
                        onChange={handleSliderChange}
                        max={MAX_CASES}
                        />
                    <TextField
                        value={value}
                        onChange={handleInputChange}
                        size="small"
                        variant='outlined'
                        inputProps={{
                            step:1,
                            min: 0,
                            max: MAX_CASES,
                            type: 'number',
                            }}/>
                </Box>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleConfirm}>Close</Button>
            </DialogActions>
        </Dialog>


    )
}

export default memo(SchedulingSingleDayDialog)