import { useTheme } from "@emotion/react";
import { Box, Button, Popover, Typography } from "@mui/material";





const getDialogMsg = (step) => {
    let msg;

    if(step === 1){
        msg = "You can set a weekly billing-point goal to help keep track of your earnings."
    }else if(step === 2){
        msg = "Set your default schedule preferences here."
    }
    else if(step === 3){
        msg = "You can adjust the number of cases you'd like to request by clicking the button below the date. We'll assign them to you based on availability for that day." 
    }
    return msg
}



export default function TutorialDialog({
    tutorialState, 
    setTutorialState, 
    schedulingRef,
    prefsRef,
    isLoading
}){

const {step, ref:anchorEl} = tutorialState || {}
const theme = useTheme( )
const open = Boolean(anchorEl) && !isLoading;
    
if(step >3) return null

return(

        <Popover 
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Box width={400}>            
                <Box className='flex-row' gap={1} p={2}>
                    <Typography variant='body1'>{getDialogMsg(step)}</Typography>

                </Box>
                <Box className='flex-row-between-center' gap={1} p={'12px'}>

                    <Typography variant="body1" color={theme.palette.text.secondary}>{step} of 3</Typography>
                    <Button 
                        variant="contained" 
                        size='large' 
                        color='primary' 
                        onClick={() => setTutorialState({step: step + 1, ref:step ===1? prefsRef.current : schedulingRef.current}) }
                        sx={{
                            color:theme.palette.primary.contrast, 
                            boxShadow:'none', 
                            borderRadius:'21px'
                        }}> 
                        Next
                    </Button>

                </Box>
            </Box>
        </Popover>

        )


}