import { useTheme } from "@emotion/react";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import Graphic from '../../../../assets/icons/WeeklySummaryGraphic.svg'
import CheckList from '../../../../assets/icons/CheckList.svg'
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";
import { useGetWeeklyBillingSummaryQuery } from "../../../billing/billingApiSlice";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { dateRangeToString } from "../../../../utils/dateUtils/dateRangeToString"; 
import HaloSkeleton from "../../../globalComponents/Skeletons/HaloSkeleton";
import HaloModal from "../../../globalComponents/Modals/HaloModal";

const iconSx = {height:20, width:20}

const getStatCards = (statName, statObj, theme) => {

    let icon ;
    let color ;
    let vsAvg = statObj?.compared_to_avg

    if(vsAvg<0){
        color = theme.palette.error.main
        icon = <TrendingDownIcon sx={iconSx} color={'error'}/>
    }else if(vsAvg>0){
        color = theme.palette.success.main
        icon = <TrendingUpIcon sx={iconSx} color={'success'}/>
    }else if(vsAvg === 0){
        color = theme.palette.text.secondary
        icon = <TrendingUpIcon sx={iconSx} color={theme.palette.text.secondary}/>
        vsAvg = '0'
    }



    return(
        <Box className='flex-col' sx={{gap:2, flexGrow:1,p:2, background:theme.palette.primary.shades4, border:`1px solid ${theme.palette.divider}`, borderRadius:2}}>
            <Typography variant="body2">{statName}</Typography>
            <Box className='flex-row-between'>
                <Typography variant="h5">{statObj?.cnt !== undefined && statObj?.cnt !== null ? statObj?.cnt : '-'}</Typography>
                <Box className='flex-row-center' gap={'3px'} >
                    {icon}
                    <Typography variant="caption" color={color}> {vsAvg || '-'}%</Typography>
                    <Typography variant="caption" color={theme.palette.text.disabled}> vs avg.</Typography>
                </Box>
            </Box>
        </Box>
    )

}


export default function UserPerformanceModal({user, setPanel, open}){
    
    const theme = useTheme()
    const {data, isLoading, isFetching}= useGetWeeklyBillingSummaryQuery()
    const {points_reached, assigned, completed, points_goal, goal_opt_out} = data || {}

    const normalise = (value) => {
        if(value > points_goal) return value
        return ((value - 0) * 100) / (points_goal - 0);
    }
    const changePanel = () => setPanel('confirm schedule')
    const weekRange = dateRangeToString(data?.week_start, data?.week_end)
    const goalMessage = points_reached > points_goal? <Typography variant="caption" color={theme.palette.success.main}>Well done, over achiever!</Typography> : null


    return(
        <HaloModal
            open={open}
            title='Weekly report'
            subtitle={weekRange}
            handleClose={changePanel}>
                <Box className='flex-col' sx={{p:2, gap:2, overflow:'auto', height:'fit-content'}}>
                    <HaloSkeleton loading={isLoading || isFetching}>
                        <img src={Graphic} alt='office staff' />
                    </HaloSkeleton>
                    <Box>
                    <HaloSkeleton loading={isLoading || isFetching}>
                        <Typography variant="subtitle1"> 👋 Hi {stringToTitleCase(user?.first_name)}! Hope you had a productive week!</Typography>
                        <Typography variant="body2" color={theme.palette.text.secondary}>Here's a summary of what you accomplished.</Typography>
                    </HaloSkeleton> 
                    </Box>

                    {
                        !goal_opt_out &&
                        <HaloSkeleton width={'100%'} loading={isLoading || isFetching}>
                            <Box className='flex-row' sx={{gap:2, background:theme.palette.primary.shades4, p:2, pb:goalMessage?1:2}}>
                                <img src={CheckList} alt='check list'/>
                                <Box className='flex-col' sx={{gap:1, flex:1}}>
                                    <Box className='flex-row-between'>
                                        <Typography variant="h5" fontWeight={500}> {points_reached !== undefined && points_reached !== null ? points_reached : '-'} / {points_goal}</Typography>
                                        <Typography variant="body2" color={theme.palette.text.secondary}>Billing points goal</Typography>
                                    </Box>
                                    <LinearProgress 
                                        variant="determinate" 
                                        color="success" 
                                        value={points_reached >= points_goal? 100 : normalise(points_reached)} 
                                        sx={{ borderRadius:1 }}/>
                                    {goalMessage}
                                </Box>
                            </Box>
                        </HaloSkeleton>
                    }
                    <Box className='flex-row-between' sx={{gap:2}}>
                    <HaloSkeleton width={'100%'} loading={isLoading || isFetching}>
                        {getStatCards('Completed', completed, theme)}
                    </HaloSkeleton>
                    <HaloSkeleton width={'100%'} loading={isLoading || isFetching}>
                        {getStatCards('Assigned', assigned, theme)}
                    </HaloSkeleton>

                    </Box>
                    <Box display={'flex'} p={1} justifyContent={'flex-end'}>
                        <HaloSkeleton loading={isLoading || isFetching}>
                            <Button color="inherit" onClick={changePanel}>
                                Next
                            </Button>
                        </HaloSkeleton>
                    </Box>
                </Box>
        </HaloModal>
    )

}