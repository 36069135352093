import { useTheme } from "@emotion/react";
import { Box, Chip, Typography } from "@mui/material";
import { memo } from "react";
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from "dayjs";
dayjs.extend(isBetween);

function determineHighlight(dateRange, day, today){

    if(!dateRange?.endDate  && today) return true
    if(!dateRange && !today) return false


    const startDate = dayjs(dateRange.startDate);
    const endDate = dayjs(dateRange.endDate);
    const date = dayjs(day);

    return date.isBetween(startDate, endDate,null, '[]')
}


function getBorderSx(day){

    //gets val of 0-6
    const dayIndex = day.day()
    let borderRadius = 0
    
    if(dayIndex === 0){
        borderRadius = '24px 0px 0px 24px'
    }else if (dayIndex === 6 ){
        borderRadius = '0px 24px 24px 0px'
    }

    return borderRadius
}



function SchedulingChip({
    outsideCurrentMonth,
    calendarState, 
    prefs, 
    handleDailyScheduleDialog, 
    setDialogData, 
    day,
    disabled,
    today,
    isLoading,
    unconfirmedDateRange = null}){


    const theme = useTheme()
    const date = day.format('YYYY-MM-DD')
    const dayOfWeek = day.format('dddd')
    const schedule = calendarState?.find(schedule => schedule.date === date)
    const isUpdated = schedule ? 'is_edited' in schedule || 'is_new' in schedule : false;
    
    const isHighlighted = determineHighlight(unconfirmedDateRange, day, today)

    const handleChipClick = () => {
        // this check is need to allow for multiple edits of newly added dates 
        if(schedule?.is_new) schedule.is_new=false 

        const dialogData = schedule || { is_new: true, requested_cases: Number(prefs?.[dayOfWeek]) , date }; //if no schdule pass a 'new' object
        setDialogData(dialogData);
        handleDailyScheduleDialog(true);
    };

    const dateNumber = day.date();
    const chipLabel = schedule ? schedule.requested_cases : prefs?.[dayOfWeek] ;


    
    return( 
        <>
           {             
            <Box 
                className='flex-col-center' 
                width={72} 
                height={96} 
                gap={2} 
                p={'4px 16px'} 
                visibility={outsideCurrentMonth? 'hidden': ''} 
                backgroundColor={isHighlighted? theme.palette.primary.shades8: ''}
                sx={{
                    borderRadius: unconfirmedDateRange?.endDate? getBorderSx(day) : 3
                }}
                >
                <Box className='flex-col-center' height={24} width={48} >
                    <Typography sx={{...theme.typography.body2}} >
                        {dateNumber}
                    </Typography>
                </Box>  
                <Chip   
                    disabled={disabled || today || isLoading}
                        size='small' 
                        color={'primary'} 
                        sx={{
                            width:40,
                            maxWidth: 'none',
                            background: isUpdated? theme.palette.primary.dark : '',
                            color: isUpdated || schedule? theme.palette.primary.contrast  : ''
                        }}
                        variant={schedule || isUpdated? 'filled': 'outlined'}
                        onClick={ handleChipClick}
                        label={chipLabel} 
                />
            </Box>}

        </>
    )
}


export default memo(SchedulingChip)

