import { useState, useEffect } from 'react';


export function useSelectedRowsCount(apiRef) {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const handleRowSelectCnt = (params) => {
      setSelected(params.length);
    };

    return apiRef.current.subscribeEvent('rowSelectionChange', handleRowSelectCnt);
  }, [apiRef]);

  return selected;
}


