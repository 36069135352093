import TrialAnalysis from "./TrialAnalysis/TrialAnalysis";
import TrialRecommendations from "./TrialRecommendations/TrialRecommendations";
import TrialDataInput from "./TrialDataInput/TrialDataInput";

function TrialPathologyDetailsContainer({ encounter, isLoading, allowEdits = false, caseInfo }) {

    const { patient, drug_interactions, resistance_genes, trial_clinical_findings
    } = encounter || {};
    const { medications, allergies, icd10_references } = patient || {};

    return (
        <>
            <TrialDataInput
                meds={medications}
                allergies={allergies}
                diagnoses={icd10_references}
                readOnlyView={true}
                allowEdits={allowEdits}
                caseInfo={caseInfo}
                isLoading={isLoading}
            />
            <TrialAnalysis
                findingsValues={trial_clinical_findings}
                resistanceGenes={resistance_genes}
                drugInteractions={drug_interactions}
                readOnlyView={true}
                allowEdits={allowEdits}
                caseInfo={caseInfo}
                isLoading={isLoading}
            />
            <TrialRecommendations
                meds={medications}
                encounter={encounter}
                readOnlyView={true}
                allowEdits={allowEdits}
                caseInfo={caseInfo}
                isLoading={isLoading}
            />
        </>

    )

}

export default TrialPathologyDetailsContainer
