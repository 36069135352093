import { useEffect } from 'react';
import axios from 'axios';

const CSRFToken = () => {
    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_API_URL}csrf-token`, {
                //await axios.get(`https://djangobeta.beyondmd.care/api/csrf-token`, {
                    xsrfCookieName: 'halocsrftoken',
                    xsrfHeaderName: 'X-CSRFTOKEN',
                    withCredentials: true
                });

            } catch (err) {
                console.error('Error fetching CSRF token:', err);
            }
        };

        fetchData();
    }, []);

    // This component doesn't render anything
    return null;
};

export default CSRFToken;




