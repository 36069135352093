import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers-pro';
import { useRef } from 'react';


const Search = styled('div', {shouldForwardProp: (prop) => !['hasText', 'alwaysShow'].includes(prop)})(({ theme, hasText, alwaysShow }) => ({
  position: 'relative',
  height:32,
  marginTop:'auto',
  marginBottom:'auto',
  '&:focus-within, &:hover': {
    border: `1px solid ${theme.palette.divider}`,
  },
  marginLeft: 0,
  borderRadius:18,
  border: `1px solid ${ hasText || alwaysShow ? theme.palette.divider: 'transparent'}` ,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },

}));
  
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(
  InputBase,
  {
    shouldForwardProp: (prop) => !['hasText', 'alwaysShow'].includes(prop) 
  }
  )(({ theme, hasText, alwaysShow }) => ({
  color: 'inherit',
  width: '100%',
  borderRadius:18,
  '& .MuiInputBase-input': {
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: hasText || alwaysShow ? '20ch' : '0ch',
      '&:focus, &:hover': {
        width: '20ch',
      },
    },
  },
}));

const ClearIconWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 2),
}));

export default function BaseSearchBar({
  value,
  onChange,
  handleClear,
  placeholder='Search', 
  alwaysShow = false,
  ...props
}){

    const hasText = value?.length > 0
    const inputRef = useRef()

    const focusOnHover = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    return(
        <Search hasText={hasText} alwaysShow={alwaysShow}>
          <SearchIconWrapper>
            <SearchIcon sx={{color:'action.active'}} />
          </SearchIconWrapper>
          <StyledInputBase
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            hasText={hasText}
            inputRef={inputRef}
            onMouseEnter={focusOnHover}
            alwaysShow={alwaysShow}
            {...props}
          />
          {value && (
            <ClearIconWrapper>
              <IconButton
                onClick={handleClear}
                sx={{ visibility: value ? 'visible' : 'hidden', padding: '0' }}>
                <ClearIcon sx={{ color: 'action.active', height: 24, width:24 }} />
              </IconButton>
            </ClearIconWrapper>
          )}
        </Search>
    )



}