import { memo, useCallback, useState } from "react"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../../auth/authSlice"
import CaseSchedulingModal from "../SchedulingModal/CaseSchedulingModal"
import UserPerformanceModal from "./UserPerformanceModal"







function WeeklySummary({unconfirmedSchedule}){

    const { ptGoalObjId } = unconfirmedSchedule || {} 

    //checking if user has an associated Weekly point goal to fetch
    //set this up with non binary values in case an extra modal is added
    const [panel, setPanel] = useState(ptGoalObjId? 'summary': 'confirm schedule') 
    const user = useSelector(selectCurrentUser)
    const closeModal = useCallback(() => setPanel(false)) 

    return(
        <>
        {
            panel === 'summary'?
                <UserPerformanceModal
                    setPanel={setPanel} 
                    user={user} 
                    open={true}/>
                :
                <CaseSchedulingModal 
                      unconfirmedSchedule={unconfirmedSchedule} 
                      closeModal={closeModal} 
                      goBackFunc={() => setPanel('summary')}
                      mode='confirming'
                      open={true}/>
        }

        </>
    )


}

export default memo(WeeklySummary);