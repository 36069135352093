


export const calcBMI = ({height, weight}) =>{

    let bmi = 0;
    if (weight > 0 && height > 0) {
        bmi = weight / (height * height) * 703;
    }

    if(!bmi) return '-'

    return bmi.toFixed(2);
}
