import { method } from "lodash";
import { apiSlice } from "../../app/api/apiSlice";
import Cookies from 'js-cookie';


export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        logout: builder.mutation({
            query: credentials => ({
                url: '/logout',
                method: 'POST',
            })
        }),
        logError: builder.mutation({
            query: (payload) => ({
                url: 'log-client-error',
                method: 'POST',
                body: payload
            })
        }),
        forgotPassword: builder.mutation({
            query: (payload) => ({
                url: 'forgot-password',
                method: 'POST',
                body: payload
            })
        }),
        resetPassword: builder.mutation({
            query: (payload) => ({
                url: 'reset-password',
                method: 'POST',
                body: payload
            })
        }),
        manageUserImpersonation: builder.mutation({
            query: (payload) => ({
                url: payload?.method === 'POST' ? 'impersonate/' + payload?.userId : 'stop-impersonate' ,
                method: payload?.method
            })
        })
    })
})


export const {
    useLogoutMutation, useLogErrorMutation, useForgotPasswordMutation, useResetPasswordMutation, useManageUserImpersonationMutation
} = authApiSlice
