import { Alert, AlertTitle } from "@mui/material";








export default function MDFeedbackContainer({review, containerSx}){

    const { is_approved, feedback, needs_edits } = review || {}

    return(
        <Alert severity={needs_edits? 'warning' : 'info'} sx={{...containerSx}}>
            <AlertTitle>MD Feedback: {needs_edits? 'Needs edits': ''}</AlertTitle>
            {feedback}
        </Alert>
    )

}