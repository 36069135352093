import { useTheme } from "@emotion/react";
import { Box, Divider, Skeleton } from "@mui/material";


export function SubHeaderLoader(){

    const theme = useTheme()

return(

    <>
    <Box className='flex-row-between-center'
        sx={{
            height:64,
            p:'8px 24px',
            background: theme.palette.background.paper
        }}>
        <Skeleton animation="wave" width={100}/>
        <Skeleton animation="wave" width={100}/>
    </Box>
    <Divider/>
    </>

)


}