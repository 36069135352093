import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../auth/authSlice";
import { useTheme } from "@emotion/react";
import dayjs from 'dayjs'
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";



export default function Header(){

    const user = useSelector(selectCurrentUser)
    const theme = useTheme()

    let greetingMessage
    let time = dayjs().hour()
    if (time < 12 && time > 0) {
        greetingMessage = 'Good morning'
    }
    else if(time >= 12 && time < 18) {
        greetingMessage = 'Good afternoon'
    }
    else {
        greetingMessage = 'Good evening'
    }


    return(

        <Box className='flex-col' >
            <Typography sx={{...theme.typography.h5, fontWeight:500}}> {greetingMessage}, {user.firstName ? stringToTitleCase(user.firstName): ''}</Typography>
            <Typography sx={{...theme.typography.body2, color:theme.palette.text.secondary}}>Here's what's happening with your account today.</Typography>
        </Box>

    )


}
