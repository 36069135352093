import { useTheme, Box, Typography, Divider} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAddMetricsMutation, useDeleteMetricsMutation } from '../../../../pathologyAPISlice'
import { useCallback, useEffect, useState } from 'react';
import { useFormik } from "formik";
import MetricsSection from "./MetricsSection";
import { showGenericValidationError } from "../../../../../../app/errors/genericErrors";
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';

const metricSections = [
    {
        name: 'missing_labs',
        label: 'Missing Labs',
        fieldKey: 'missing_lab'
    },
    {
        name: 'outdated_labs',
        label: 'Outdated Labs',
        fieldKey: 'outdated_lab'
    },
    {
        name: 'missing_drugs',
        label: 'Missing Drugs',
        fieldKey: 'missing_drug'
    },
    {
        name: 'drug_changes',
        label: 'Drug Changes',
        fieldKey: 'drug_changed'
    },
    {
        name: 'cultured_pathogens',
        label: 'Cultured Pathogens',
        fieldKey: 'cultured_pathogen'
    },
    {
        name: 'resistances_found',
        label: 'Resistances Found',
        fieldKey: 'resistance_found'
    },
    {
        name: 'duplicate_therapies',
        label: 'Duplicate Therapies',
        fieldKey: 'duplicate_therapy'
    },
];




export default function FindingMetrics({findingsmetrics,id, readOnly}){
    const theme = useTheme()
    
    const encounterId = id
    const [ saveMetrics ] = useAddMetricsMutation()
    const [ deleteMetric ] = useDeleteMetricsMutation()
    const [metrics, setMetrics] = useState(findingsmetrics); // hold the saved/current values that appear as chips
    //not ideal to have everyhting rerendering, but this section isn't used all the often
    const formik = useFormik({
        initialValues:{
            missing_labs: '',
            outdated_labs: '',
            missing_drugs: '',
            drug_changes: '',
            cultured_pathogens: '',
            resistances_found: '',
            duplicate_therapies: '',
        },
        validateOnBlur:true
    })

    const handleSave = ((e, key) =>{
        let value = formik.values[key]?.trim()
            
        if(value !== ''){
            saveMetrics({ 
                encounter_id: encounterId, 
                metric_type:key,
                metric_item:value
            })
            .unwrap()
            .then(res=> {
                formik.setFieldValue(key, '', false) //clear input box
                setMetrics(res.metrics) //update state that shows Chips
            })
            .catch(err => {
                showGenericValidationError(err)
            })
        }
    })

    const handleDelete =  useCallback((e, type, id) =>{

        const item = metrics[type].find(obj => obj.id === id)
        

        setMetrics(prev => ({
            ...prev,
            [type]: prev[type].filter(item => item.id !== id)
        }));

        deleteMetric({
            encounter_id : encounterId,
            metric_type: type,  
            item_id: id
        })
        .unwrap()
        .then(res =>{})
        .catch(err =>{
            setMetrics(prev => {
                    return {
                        ...prev,
                        [type]: [...prev[type], item].sort((a, b) => a.id - b.id) //sort to maintain order
                    };
                }
            );
        })
    }, [metrics, setMetrics])


    useEffect(() => {
        if(findingsmetrics) setMetrics(findingsmetrics)
    }, [findingsmetrics])
    
    return (
        <Box className='flex-col' sx={{bgcolor: theme.palette.background.paper, border: `1px solid ${theme.palette.divider}`,borderRadius:2}}>
            <Box className='flex-col' p={'12px 16px 8px 16px'} sx={{backgroundColor: theme.palette.primary.shades4,}}>
                <Box className='flex-row' gap={1} alignItems='center'>
                    <Box className='flex-row' gap={0.5} alignItems='center'>
                        <QueryStatsOutlinedIcon color='primary' style={{fontSize:22}}/>
                        <Typography sx={{ ...theme.typography.body1 ,color:theme.palette.text.primary}}> Finding Metrics</Typography>
                    </Box>
                    <CheckCircleIcon sx={{height:16, width:16, color:'#00B295'}}/>
                </Box>
                <Typography sx={{...theme.typography.caption, color: theme.palette.text.secondary}}>Press the Enter key after every value</Typography>
            </Box>
            <Divider/>
            <Box className='flex-col' gap={3}  p={2} >
                {
                    metricSections.map((section) =>(
                        <MetricsSection 
                            disabled={readOnly}
                            key={section.name}
                            name={section.name}
                            label={section.label}
                            fieldKey={section.fieldKey}
                            handleSave={handleSave}
                            handleDelete={handleDelete}
                            metrics={metrics}
                            formik={formik}
                        />
                    ))
                }

            </Box>
        </Box>
      )
    }

