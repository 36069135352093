import { useParams } from "react-router-dom"
import { useGetCasePerformanceStatsQuery } from "../../../../rosterAdminAPISlice"
import { useDispatch, useSelector } from "react-redux"
import { selectRosterMgmtDates, selectRosterMgmtStatSpotlightIndex, setRosterMgmtStat, setRosterMgmtStatSpotlightIndex } from "../../../../../admin/adminSlice"
import StatCard from "../../../../../globalComponents/Cards/StatCard/StatCard"
import { Box } from "@mui/material"



export function ClinicianStatCards() {

    const { clinicianId } = useParams()
    const dispatch = useDispatch()
    const dates = useSelector(selectRosterMgmtDates)
    const statIndex = useSelector(selectRosterMgmtStatSpotlightIndex)

    const { data, isLoading, isFetching } = useGetCasePerformanceStatsQuery({ dates, userId: clinicianId })
    const { requested, requested_change, assigned, assigned_change, completed, completed_change, day_period, session_duration, session_duration_change, compensation_points, compensation_points_change } = data || {}

    const selected = (label) => statIndex === label
    const setStat = (val, index) => (e) => {
        dispatch(setRosterMgmtStatSpotlightIndex(index))
        dispatch(setRosterMgmtStat(val))
    }


    return (
        <Box className='flex-row' gap={1} flexWrap='wrap'>
            <StatCard
                label='Case duration'
                stat={session_duration}
                percentDiff={session_duration_change}
                selected={selected(0)}
                setStat={setStat('Case duration', 0)}
                dayPeriod={day_period}
                dates={dates}
                loading={isLoading || isFetching} />
            <StatCard
                label='Requested'
                stat={requested}
                percentDiff={requested_change}
                selected={selected(1)}
                setStat={setStat('Requested', 1)}
                dayPeriod={day_period}
                dates={dates}
                loading={isLoading || isFetching} />
            <StatCard
                label='Assigned'
                stat={assigned}
                percentDiff={assigned_change}
                selected={selected(2)}
                setStat={setStat('Assigned', 2)}
                // endComponent={<AsgmtStatChart  dates={dates}/>}
                dayPeriod={day_period}
                dates={dates}
                loading={isLoading || isFetching}
            />
            <StatCard
                label='Completed'
                stat={completed}
                percentDiff={completed_change}
                selected={selected(3)}
                setStat={setStat('Completed', 3)}
                dayPeriod={day_period}
                dates={dates}
                loading={isLoading || isFetching} />
            <StatCard
                label='Compensation pts.'
                stat={compensation_points}
                percentDiff={compensation_points_change}
                selected={selected(4)}
                setStat={setStat('Compensation points', 4)}
                dayPeriod={day_period}
                dates={dates}
                loading={isLoading || isFetching} />
        </Box>
    )
}