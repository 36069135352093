import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import TrialInfecDisRecsContainer from "./TrialRecommendations/TrialInfecDisRecsContainer";


export default function TrialInfecDisSummaryContainer({ data, isLoading, allowEdits }) {

    const { encounterType, trialEncounterId } = useParams()

    const { trial_encounter } = data || {};
    const { patient } = trial_encounter || {};

const encounterInfo = {
        case_type: encounterType,
        case_id: trialEncounterId
    }


    return (
        <>
            <Box p={3} className='flex-col' gap={3}>
                <TrialInfecDisRecsContainer
                    encounter={trial_encounter}
                    readOnlyView={true}
                    allowEdits={allowEdits}
                    caseInfo={encounterInfo}
                    isLoading={isLoading}
                />
            </Box>
        </>
    );
}
