


import { Autocomplete, TextField } from "@mui/material"
import { useState } from "react";
import { useController } from "react-hook-form";



//it gets frustrating needing to customize object options
//will be derived form a Base HaloAutocomplete in the future, for now this will do 

export function HaloObjAutocomplete({ 
  control, 
  name, 
  label, 
  required, 
  options, // in [{}, {}, {}] format
  optionKey, // use to compare object values
  helperText,
  hideHelperText=false,
  useIdAsKey,
  placeholder='',
  isOptionEqualToValue,
  onChange,
  textFieldVariant,
  ...otherProps
}) {
    const {
      field,
      formState: {  errors },
    } = useController({
      name,
      control,
      rules: { required: required? 'This field is required' : false },
    })
    const [inputValue, setInputValue] = useState('')

    const defaultIsOptionEqualToValue = (option, val) => option[optionKey] === val[optionKey] || option[optionKey] === null
    const defaultOnChange = (event, newValue) => field.onChange(newValue)
    

      return(
        <Autocomplete
            {...otherProps}
            fullWidth
            openOnFocus
            autoComplete
            value={field.value || null}
            onChange={onChange || defaultOnChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            getOptionKey={useIdAsKey? (option) => option?.id : null}
            options={options || []}
            getOptionLabel={(option) => option[optionKey] || ''}
            isOptionEqualToValue={ isOptionEqualToValue || defaultIsOptionEqualToValue}
            renderInput={(params) => (
              <TextField 
                {...params} 
                required={required} 
                placeholder={placeholder}
                label={label} 
                size="small" 
                error={Boolean(errors[name])}
                helperText={hideHelperText? '' :helperText? helperText: errors[name]?.message}
                variant={textFieldVariant}
                />
            )}
        />
      )
  
  }
  
  