import { Box, Divider, IconButton, Typography } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';

function ScheduleItem({ day, indx }) {
    return(
        <Box key={indx} className='flex-row' gap={1} justifyContent={'space-between'}>
            <Typography variant="body2" color='text.disabled'>{day.day_name}</Typography>
            <Typography variant='subtitle2'>{day?.requested_cases >= 0? day.requested_cases : '-'}</Typography>
        </Box>
    );
}

const init = [
    { day_name: 'Sunday' },
    { day_name: 'Monday' },
    { day_name: 'Tuesday' },
    { day_name: 'Wednesday' },
    { day_name: 'Thursday' },
    { day_name: 'Friday' },
    { day_name: 'Saturday' },
];

const filterRemainingDays = (currentCaseLoad) => {
    const daysOfWeek = init.map((day) => day.day_name)
    const currentDayIndex = new Date().getDay()
    const remainingDays = daysOfWeek.slice(currentDayIndex)
    const filteredDays = currentCaseLoad.filter((dayObject)=> remainingDays.includes(dayObject.day_name))
    
    return filteredDays
}


export default function DashboardPreferences({ 
    currentWeekCaseLoad, 
    openModal,
    prefetchSchedules
}){

    return(
        <>
        <Box sx={(theme) =>({...theme.standardBox})}>
            <Box className='flex-row-between-center' p={'11px 16px'}>
                <Typography variant='subtitle2'>Current week caseload</Typography>
                <IconButton onClick={openModal} sx={{height:28, width:28, p:0.5}} onMouseEnter={prefetchSchedules} title='Edit schedule'>
                    <TuneIcon sx={{fontSize:20}} />
                </IconButton>
            </Box>
            <Divider/>
            <Box className='flex-col' p={2} gap={'10px'}>
            {(filterRemainingDays(currentWeekCaseLoad || init)).map( (day, indx) => <ScheduleItem key={indx} day={day} indx={indx} />)}
            </Box>
        </Box>


        </>
    );
}
