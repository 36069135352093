import dayjs from "dayjs";
import HaloModal from "../../../../../globalComponents/Modals/HaloModal";
import { useForm } from "react-hook-form";
import uploadModalGraphic from '../../../../../../assets/icons/upload-modal-graphic.svg'
import { Box } from "@mui/material";
import { HaloDatePicker } from "../../../../../globalComponents/ReactHookFormFields";
import HaloDropZone from "../../../../../globalComponents/ReactHookFormFields/FileUpload/HaloDropZone";
import { useEffect } from "react";
import HaloModalStickyFooter from "../../../../../globalComponents/Modals/HaloModalStickyFooter/HaloModalStickyFooter";
import { ActionButton } from "../../../../../globalComponents/Buttons/ActionButton/ActionButton";
import { useManagePatientDocsMutation } from "../../../../patientAPISlice";
import formatFileFormData from "../../../../../../utils/fileUtils/formatFileFormData";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../../../../../app/api/apiSlice";




const defaultValues =  {
            result_summary: '',
            result_date: dayjs(),
            documentation: [],
        }


export default function AddPatientDocsModal({patientId, open, handleClose}){

    const dispatch = useDispatch()
    const { control, handleSubmit, formState: { errors, isValid }, reset } = useForm({ defaultValues })
    const [ manageDocs ] = useManagePatientDocsMutation()

    useEffect(() => {

        if(!open){
            reset(defaultValues)
        }

    }, [open])


    const saveDocs =(values) => {
        values.patient_id = patientId
        values.result_date = values?.result_date?.format('YYYY-MM-DD')
        const formData = formatFileFormData(values, 'documentation')

        manageDocs({
            method:'POST',
            body: formData
        })
        .then(res => {
            //add the new docs to the patient profile cache
            dispatch(
                apiSlice.util.updateQueryData('getPatientProfile', {patientId: patientId?.toString()}, (draft) => {
                    draft.history.unshift(...res.data)
                  })
            )

            handleClose()
            enqueueSnackbar('Saved ' + res?.data?.length + ' file(s)', {variant:'success'})
        })
    }

    return(
        <HaloModal
            open={open}
            handleClose={handleClose}
            title='Upload patient documentation'>
            <Box component='form' onSubmit={handleSubmit(saveDocs)} noValidate>
                <Box className='flex-col-center' p={2} gap={2}> 
                    <img src={uploadModalGraphic} alt='upload graphic' width={66.39} height={72}/>
                    <HaloDatePicker 
                        name='result_date'
                        label='Date'
                        control={control}
                        required
                    />
                    <HaloDropZone
                        name='documentation'
                        control={control}
                        required
                        dropZoneSx={{
                            height:155
                        }}
                        maxNumFiles={5}
                    />
                
                </Box>
                <HaloModalStickyFooter p='8px 16px' className='flex-row' justifyContent='flex-end' gap={1}>
                        <ActionButton
                            color='inherit'
                            onClick={handleClose}>
                            Cancel
                        </ActionButton>
                        <ActionButton
                            type='submit'
                            variant='contained'>
                            Save
                        </ActionButton>
                </HaloModalStickyFooter>
            </Box>
        </HaloModal>
    )


}