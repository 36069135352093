import {  RosterDatePickingPopover} from "./RosterDatePickingPopover/RosterDatePickingPopover"
import { Box, Button, Divider, Menu } from "@mui/material";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { ActionButton } from "../../../../../globalComponents/Buttons/ActionButton/ActionButton";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { setRosterMgmtDates, setRosterMgmtPreset } from "../../../../../admin/adminSlice";

const getLabel = (datesArr) => { // ugly brother, let's clean this up

    datesArr = datesArr.filter((date) => date)

    if(datesArr?.length === 1 ) return datesArr[0].format('MMM D, YYYY')

    else if(datesArr?.length === 2 ) {

        const startDate = dayjs(datesArr[0])
        const endDate = dayjs(datesArr[1])

        if (startDate.isSame(endDate, 'year')){

            if(startDate.isSame(endDate, 'month')) {

                if(startDate.isSame(endDate, 'date')) return startDate.format('MMM D')

                else return startDate.format('MMM D') + ' - ' + endDate.format('D')
            }
            
            else return datesArr[0].format('MMM D') + ' - ' + datesArr[1].format('MMM D')
        }
        else return datesArr[0].format('MMM D, YYYY') + ' - ' + datesArr[1].format('MMM D, YYYY')
    }
}

const arrowBtnProps = {
    disableElevation:true,
    size:"small",
    variant:"contained"
}


//currently only being used for Roster mgmt
export default function RosterDateRangeButton({
    dateSelector, //needs to be a redux store selector and have values of [ date1 , date2 ]
    presetSelector //needs to be a redux store selector, will need to consolidate this to a single selector?
}){

    const dispatch = useDispatch()
    const [ anchorEl, setAnchorEl ] = useState(null);
    const storeDates = useSelector(dateSelector)?.map(date => date? dayjs(date): null);
    const storePreset = useSelector(presetSelector)

    const [dates, setDates] = useState(storeDates)
    const [selectedPreset, setPresetValue] = useState(storePreset)


    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);



    const handleArrowClick = (action) => (e) => {
        if(action === 'back'){
            dispatch(

                setRosterMgmtDates([dayjs(storeDates[0]).subtract(1, 'day').format('YYYY-MM-DD'), null])
            )
        }
        else{
            dispatch(

                setRosterMgmtDates([dayjs(storeDates[0]).add(1, 'day').format('YYYY-MM-DD'), null])
            )
        }
    }

    const handleCancel = () =>{
        setDates(storeDates)
        setPresetValue(storePreset)
        handleClose()
    }

    return(
        <Box className='flex-row-center' >
            <Button 
                {...arrowBtnProps}
                onClick={handleArrowClick('back')}
                sx={{borderRadius:'18px 0px 0px 18px', minWidth:40, height:32}}>
                <ChevronLeftIcon />
            </Button>
            <Divider orientation="vertical" sx={{height: 32}}/>
            <ActionButton
                onClick={handleClick}
                variant='contained' 
                sx={{borderRadius:0, height:32}}
                startIcon={<CalendarMonthOutlinedIcon />}>
                    {getLabel(storeDates)}
            </ActionButton>
            <Divider orientation="vertical" sx={{height: 32}}/>
            <Button 
                {...arrowBtnProps}
                onClick={handleArrowClick('forward')}
                sx={{borderRadius:'0px 18px 18px 0px', minWidth:40, height:32}}>
                <ChevronRightIcon />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                keepMounted
                onClose={handleCancel}
                sx={(theme) => ({ 
                    '& .MuiPopover-paper':{ boxShadow: theme.customShadows.elevation8, borderRadius:2 },
                    'ul': {p:0}
                })}>
                <RosterDatePickingPopover
                    handleClose={handleClose}
                    handleCancel={handleCancel}
                    dates={dates}
                    setDates={setDates} 
                    selectedPreset={selectedPreset} 
                    setPresetValue={setPresetValue}/>
            </Menu>
        </Box>
    )
}