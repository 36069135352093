import CustomTextFieldCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell";
import CRUDDataGrid from "../../../../../globalComponents/DataGrid/CRUDDataGrid";
import { useManageDrugInteractionMutation } from "../../../../pathologyAPISlice";
import TextFieldPortalCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/TextFieldPortalCell";
import D2DSyncModal from "./D2DSyncModal";
import { DrugInteractionIcon } from "../../../../../../assets/icons/MaterialDesignIcons";


export default function DrugInteractions({readOnly, drugInteractions}){

  const columns = [
      {
        field: 'drug1',
        headerName: 'Drugs',
        editable: !readOnly, 
        flex:1,
        headerClassName: readOnly? '' : 'required-col',
        renderEditCell: (params) => (
          <CustomTextFieldCell {...params} />
        ),
      },
      { field: 'clinical_relevance', 
        headerName: 'Clinical Relevance',
        editable: !readOnly ,
        flex:1.5,
        headerClassName: readOnly? '' : 'required-col',
        renderEditCell: (params) => (
          <TextFieldPortalCell {...params} />
        )
      },
    ]


  return(
    <CRUDDataGrid
      title={'Drug Interactions'}
      columns={columns} 
      initRows={drugInteractions} 
      mutation={useManageDrugInteractionMutation}
      GridIcon={DrugInteractionIcon}
      fieldToFocus='drug1'
      requiredFields={['drug1', 'clinical_relevance']}
      isReadOnly={readOnly}
      toolbarChildren={<D2DSyncModal readOnly={readOnly}/>}/>
  )
}
