import { useTheme } from "@emotion/react";
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";








export default function PatientTabs({tab, handleTabChange}){

    
    const theme = useTheme()


    return(
        <Box className='flex-col' gap={3}>
            <Box sx={{ background:theme.palette.primary.shades8, p:0.5}}>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    sx={{
                        '& .Mui-selected':{
                            background: theme.palette.primary.contrast,
                            color:theme.palette.text.primary
                        },
                        '& .MuiTabs-indicator':{
                            display:'none'
                        }
                    }}>
                    <Tab value={'Summary'} label='Summary' sx={{flex:1, maxWidth:1000}}/>
                    <Tab value={'IPC Consult'} label='IPC Consult' sx={{flex:1, maxWidth:1000}}/>
                </Tabs>
            </Box>
        </Box>
    )



}