import { SvgIcon } from "@mui/material"


export const CheckedIcon = (props) =>(
    <SvgIcon {...props} viewBox="0 0 22 22">
            <g >
                <path d="M9.50039 15.75L16.7254 8.525L15.3004 7.1L9.50039 12.9L6.62539 10.025L5.22539 11.45L9.50039 15.75ZM11.0004 21.35C9.58372 21.35 8.24622 21.0792 6.98789 20.5375C5.72956 19.9958 4.62956 19.2542 3.68789 18.3125C2.74622 17.3708 2.00456 16.275 1.46289 15.025C0.921224 13.775 0.650391 12.4333 0.650391 11C0.650391 9.56667 0.921224 8.22083 1.46289 6.9625C2.00456 5.70417 2.74206 4.60833 3.67539 3.675C4.60872 2.74167 5.70456 2 6.96289 1.45C8.22122 0.9 9.56706 0.625 11.0004 0.625C12.4337 0.625 13.7837 0.9 15.0504 1.45C16.3171 2 17.4129 2.7375 18.3379 3.6625C19.2629 4.5875 20.0004 5.67917 20.5504 6.9375C21.1004 8.19583 21.3754 9.55 21.3754 11C21.3754 12.4333 21.1004 13.7792 20.5504 15.0375C20.0004 16.2958 19.2587 17.3917 18.3254 18.325C17.3921 19.2583 16.3004 19.9958 15.0504 20.5375C13.8004 21.0792 12.4504 21.35 11.0004 21.35Z" fill="#00B295"/>
            </g>
    </SvgIcon>
)

export const UncheckedIcon = (props) =>(
    <SvgIcon {...props} viewBox="0 0 22 22">
            <g >
                <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z" fill="#D32F2F"/>
            </g>
    </SvgIcon>
)
