import CustomTextFieldCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell";
import CRUDDataGrid from "../../../../../globalComponents/DataGrid/CRUDDataGrid";
import TextFieldPortalCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/TextFieldPortalCell";
import { DrugInteractionIcon } from "../../../../../../assets/icons/MaterialDesignIcons";
import { useManageGxDrugInteractionsMutation } from "../../../../geneomicsAPISlice";


export default function DrugInteractions({readOnly, drugInteractions}){

  const columns = [
      {
        field: 'drug',
        headerName: 'Drugs',
        editable: !readOnly, 
        flex:1,
        headerClassName: readOnly? '' : 'required-col',
        renderEditCell: (params) => (
          <CustomTextFieldCell {...params} />
        ),
      },
      { field: 'relevance', 
        headerName: 'Clinical Relevance',
        editable: !readOnly ,
        flex:1.5,
        headerClassName: readOnly? '' : 'required-col',
        renderEditCell: (params) => (
          <TextFieldPortalCell {...params} />
        )
      },
    ]


  return(
    <CRUDDataGrid
      title={'Drug Interactions'}
      columns={columns} 
      initRows={drugInteractions} 
      mutation={useManageGxDrugInteractionsMutation}
      GridIcon={DrugInteractionIcon}
      fieldToFocus='drug'
      requiredFields={['drug', 'relevance']}
      isReadOnly={readOnly}
      />
  )
}
