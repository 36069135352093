import { Tabs } from "@mui/material";
import { styled } from '@mui/material/styles';



const HaloTabs = styled(Tabs)({
        height:46,
        '& .MuiTab-root':{
            minHeight:0,
            height:46
    }});

export default HaloTabs;





