import { Route } from "react-router-dom";
import Login from "./Login";
import NotFound404 from "../../app/layout/fallbackComponents/NotFound404";
import PasswordRecovery from "./pages/PasswordRecovery";
import PasswordReset from "./pages/PasswordReset";




export const authRoutes = [
    <Route path='/' key='authRoutes'>
        <Route path='/' element={<Login />} key='Base' />
        <Route path='/login' element={<Login />} key='Login' />
        <Route path='/password-recovery' element={<PasswordRecovery />} key='Forgot Password' />
        <Route path='/password-reset' element={<PasswordReset />} key='Password Reset' />
    </Route>,
    <Route path='*' key='404' element={<NotFound404 />} />

]
