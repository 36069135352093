import { memo } from "react"
import CRUDDataGrid from "../../../../../globalComponents/DataGrid/CRUDDataGrid"
import DiagnosesSearchCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/DiagnosesSearchCell"
import { useManageTrialICD10CodeMutation } from "../../../../trialUserAPISlice"
import { DiagnosisIcon } from "../../../../../../assets/icons/MaterialDesignIcons"


const isCellEditable = (params) => {
    return params?.row?.isNew
}


function TrialDiagnoses({ readOnly, diagnoses }) {

    const columns = [
        {
            field: 'joint_fields',
            headerName: 'Diagnoses',
            flex: 1,
            editable: !readOnly,
            headerClassName: readOnly ? '' : 'required-col',
            renderEditCell: (params) => (
                <DiagnosesSearchCell {...params} />
            ),
        },
    ]

    return (

        <CRUDDataGrid
            title={'Diagnoses'}
            columns={columns}
            initRows={diagnoses}
            mutation={useManageTrialICD10CodeMutation}
            GridIcon={DiagnosisIcon}
            fieldToFocus='joint_fields'
            requiredFields={['joint_fields']}
            isReadOnly={readOnly}
            isCellEditable={isCellEditable} //allows edit only when adding a row
            isTrialReview={true}
        />
    )

};

export default memo(TrialDiagnoses);
