
import TrialInfecDisRecsContainer from "./TrialRecommendations/TrialInfecDisRecsContainer"

function TrialInfecDisDetailsContainer({ encounter, isLoading, allowEdits = false, caseInfo }) {

    const { patient
    } = encounter || {};
    const { medications, allergies, icd10_references } = patient || {};

    return (
        <>
            <TrialInfecDisRecsContainer
                encounter={encounter}
                readOnlyView={true}
                allowEdits={allowEdits}
                caseInfo={caseInfo}
                isLoading={isLoading}
            />
        </>

    )

}

export default TrialInfecDisDetailsContainer
