import { Checkbox, FormControlLabel } from "@mui/material"
import { useController } from "react-hook-form"



export default function HaloCheckbox({
    name,
    control,
    label,
    labelPlacement='end',
    required,
    ...otherProps
}){

    const {field, formState:{errors}} = useController({
        name,
        control, 
        rules:{ required: required? 'This field is required' : false }
    })

    return(
        <FormControlLabel
            sx={(theme) => ({
                color: errors[name]? theme.palette.error.main : ''
            })}
            
            control={
                <Checkbox
                    checked={field.value || false}
                    onChange={(e) => field.onChange(e.target.checked)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            field.onChange(!field.value)}
                    }}
                    sx={(theme) =>({
                        color: errors[name]? theme.palette.error.main : ''
                    })}
                    {...otherProps}
                />
            }
            name={name}
            label={label}
            labelPlacement={labelPlacement}
        />
    )

}