import { Box, Typography, TextField } from "@mui/material"
import { ActionButton } from "../../globalComponents/Buttons/ActionButton/ActionButton"
import { HaloTextField } from "../../globalComponents/ReactHookFormFields"
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom"
import ModalWithHaloLogo from "../components/ModalWithHaloLogo";
import { useSearchParams } from "react-router-dom"
import { useResetPasswordMutation } from "../authApiSlice";
import { show400Error } from "../../../app/errors/genericErrors";
import StaticAlert from "../../globalComponents/Alert/StaticAlert";
import { useState } from "react";


function PasswordReset() {

    const { control, handleSubmit, formState: { errors, isValid }, reset, getValues, setValue, watch, register, trigger } = useForm({
        defaultValues: {
            new_password: '',
            password_confirmation: ''
        }
    })

    const navigate = useNavigate()
    const [resetPassword] = useResetPasswordMutation()
    let [searchParams, setSearchParams] = useSearchParams();
    const [showInvalidAlert, setShowInvalidAlert] = useState(false)


    const newPassword = watch('new_password')

    const handlePasswordReset = async () => {

        let valid = await trigger()
        if (!valid) return;

        if (isValid) {
            const formData = new FormData()
            const values = getValues()
            Object.keys(values).forEach((key) => formData.append(key, values[key]))
            formData.append('uid', searchParams.get('uid'))
            formData.append('token', searchParams.get('token'))
            await resetPassword(formData)
                .unwrap()
                .then(() => {
                    navigate('/login', { state: { fromPasswordReset: true } })
                })
                .catch((err) => setShowInvalidAlert(true))
        }
    }


    return (
        <ModalWithHaloLogo p={'48px'}>
            <Box className='flex-col' gap={3} width={'100%'} component='form' onSubmit={handleSubmit(handlePasswordReset)}>
                <Box className='flex-col' gap={1}>
                    {
                        showInvalidAlert && 
                        <StaticAlert 
                        severity={'error'} 
                        title={'Invalid link or expired token'} 
                        msg={'Please request a new reset link.'} 
                        optionalCTALink={'/password-recovery'} 
                        optionalCTATitle={'Request new reset link'}
                        />
                    }
                    <Typography variant='subtitle1'>Password Reset</Typography>
                    <Typography variant='body2'></Typography>
                </Box>

                <HaloTextField
                    name='new_password'
                    label='New password'
                    type={'password'}
                    variant={'password-sign-up'}
                    control={control}
                    required
                    inputLabelProps={{ sx: { fontSize: '14px' } }}
                    InputProps={{ sx: { fontSize: '14px' } }}
                />
                <TextField
                    required
                    fullWidth
                    label='Confirm password'
                    type='password'
                    {...register('password_confirmation', {
                        required: 'Please confirm your password',
                        validate: value => value === newPassword || 'The passwords do not match'
                    })}
                    error={!!errors.password_confirmation}
                    helperText={errors.password_confirmation ? errors.password_confirmation.message : ''}
                    InputLabelProps={{ sx: { fontSize: '14px' } }}
                    InputProps={{ sx: { fontSize: '14px' } }}
                    size='small'
                    height={100}
                />
                <Box className='flex-row'>
                    <ActionButton sx={{ flexGrow: 1 }} variant='contained' type='submit'>Reset password</ActionButton>
                </Box>
            </Box>
        </ModalWithHaloLogo>
    )
}

export default PasswordReset
