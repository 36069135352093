import { useParams } from "react-router-dom"
import { useGetTrialEncounterSubmittedDetailsQuery } from "../trialUserAPISlice"
import { Box, Typography } from "@mui/material"
import TrialPathologyDetailsContainer from "../components/TrialPathologyReview/TrialPathologyDetailsContainer"
import { useEffect } from "react"
import TrialEncounterSubmittedDetailsNav from "../components/Header/TrialEncounterSubmittedDetailsNav"
import { useDispatch } from "react-redux"
import { setTrialStaticMeds } from "../trialUserSlice"
import TrialInfecDisDetailsContainer from "../components/TrialInfectiousDiseaseReview/TrialInfecDisDetailsContainer"


function TrialEncounterSubmittedDetails() {
    let { encounterType, trialEncounterId } = useParams()

    const dispatch = useDispatch()
    const { data, isLoading, isFetching } = useGetTrialEncounterSubmittedDetailsQuery({ encounterType, trialEncounterId })
    const { trial_encounter } = data || {}

    const isInfecDis = encounterType.toLowerCase().includes('infectious');

    useEffect(() => {
        dispatch(setTrialStaticMeds(trial_encounter?.patient?.medications))
    }, [data, dispatch, trial_encounter?.patient?.medications])

    return (
        <>
            <TrialEncounterSubmittedDetailsNav encounter={trial_encounter} />
            <Box className='flex-col' p={3} gap={3} >
                <Typography variant="h6">
                    {isInfecDis ? 'Infectious disease encounter' : 'Pathology encounter'}
                </Typography>
                {
                    isInfecDis ?


                        <TrialInfecDisDetailsContainer
                            isLoading={isLoading || isFetching}
                            encounter={trial_encounter} />
                        :
                        <TrialPathologyDetailsContainer
                            isLoading={isLoading || isFetching}
                            encounter={trial_encounter}
                        />
                }
            </Box>
        </>
    )


}

export default TrialEncounterSubmittedDetails
