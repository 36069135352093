import { useTheme } from "@emotion/react"
import { Chip } from "@mui/material"
import { memo } from "react"

const ReferralStatusChip = ({trialUser}) =>{

    const theme = useTheme()

    let referralStatus;

    if(trialUser.passed_final_approval && trialUser.all_work_reviewed) {
        referralStatus = 'Completed'
    }

    else if(trialUser.is_work_completed) {
        referralStatus = 'Pending review'
    }

    else if (!trialUser.is_work_completed && trialUser.is_used) {
        referralStatus = 'On trial'
    }

    else {
        referralStatus = 'Invite sent'
    }

    const colorToStatus = {
        'Completed': theme.palette.success.main,
        'On trial': theme.palette.primary.main,
        'Pending review': theme.palette.warning.main,
        'Invite sent': theme.palette.mode === 'dark' ? theme.palette.grey[600] :  theme.palette.action.active,
    }

    return <Chip label={referralStatus} size='small' sx={{color:'#fff', background:colorToStatus[referralStatus]}}/>

}

export default memo(ReferralStatusChip)
