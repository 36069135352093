
import TrialInfecDisRecommendations from "./TrialInfecDisRecommendations";
import CaseAccordion from "../../../../globalComponents/Accordions/CaseAccordion";
import SummaryContainer from "../../../../globalComponents/Containers/SummaryContainer";
import { memo} from 'react';
import { Box } from '@mui/material';
import { useTutorial } from "../../../utils/TutorialContext";


function TrialInfecDisRecsContainer({ readOnlyView, allowEdits, caseInfo, isLoading, ...props }) {

    const { id, treating_provider,
        treatment_recommendation,
        diagnostic_recommendation } = props?.encounter || {}

    const {refs} = useTutorial()

    const content = [

        <Box className='flex-row' key={'recs'} ref={refs.current[2]}>
                <TrialInfecDisRecommendations
                    id={id}
                    readOnly={readOnlyView}
                    treating_provider={treating_provider}
                    treatment_recommendation={treatment_recommendation}
                    diagnostic_recommendation={diagnostic_recommendation}
                />
        </Box>

    ]

    return readOnlyView ? (
        <SummaryContainer number={1} title={'Recommendations'} disableEdits={!allowEdits} caseInfo={caseInfo} isLoading={isLoading} baseURL={'/trial-users/encounter-review/'}>
            {content}
        </SummaryContainer>
    ) : (
        <CaseAccordion number={1} title={'Recommendations'} initialOpenState={true}>
            {content}
        </CaseAccordion>
    );

}


export default memo(TrialInfecDisRecsContainer)
