import { Box, Typography } from "@mui/material"
import { getCurrentWeekStrings } from "../../../../../../../../utils/dateUtils/dateRangeToString"













const {weekRangeStr} = getCurrentWeekStrings()

export function WeeklySchedule({schedule}){


    return(
        <Box className='flex-col' gap={1} flex={1}>
            <Typography variant='body2'>{weekRangeStr}</Typography>
        {
            schedule?.map((day) =>(
                <Box key={day.day_name} className='flex-row' gap={1} justifyContent={'space-between'}>
                    <Typography variant="body2" color='text.disabled'>{day.day_name}</Typography>
                    <Typography variant='subtitle2'>{day?.requested_cases >= 0? day.requested_cases : '-'}</Typography>
                </Box>
            ))
        }
        </Box>
    )

}