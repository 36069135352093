import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import isLeapYear from 'dayjs/plugin/isLeapYear'
import { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch } from "react-redux";
import { ActionButton } from "../Buttons/ActionButton/ActionButton";
dayjs.extend(isLeapYear)


const datePresets = ['Today', 'Last month', 'Last bi-weekly']



function DatePickingPopover({ handleClose, start_date, end_date, setDateRangeReducer, setDisplayedDateRange, buttonTitle }) {

    const dispatch = useDispatch()

    const [dates, setDates] = useState(start_date ? [dayjs(start_date), dayjs(end_date)] : [])

    const [selectedPreset, setSelectedPreset] = useState(null)

    const updateStoreDates = () => {

        const date_range = {}

        if (dates[1] && !dates[0]) {
            date_range.start_date = dates[1].format('YYYY-MM-DD')
            date_range.end_date = ''
        }

        else {
            date_range.start_date = dates[0] ? dates[0].format('YYYY-MM-DD') : ''
            date_range.end_date = dates[1] ? dates[1].format('YYYY-MM-DD') : ''
        }

        dispatch(setDateRangeReducer(date_range))

        if (dates[0] || dates[1]) {
            setDisplayedDateRange(
                (dates[0] && dates[1]) && (dates[0].date() !== dates[1].date()) ?
                    dates?.map(date => date ? date?.format('LL') : '').join(' - ') :
                    dates[0].format('LL')
            )
        }

        handleClose()
    }

    const clearStoreDates = () => {
        dispatch(setDateRangeReducer({
            start_date: '',
            end_date: '',
        }))
        setDates([])
        setDisplayedDateRange(buttonTitle)
        setSelectedPreset(null)
    }

    const calculatePresetTimeRanges = (presetPeriod) => {
        const now = dayjs()

        switch (presetPeriod) {
            case 'Today':
                setDates([now, null])
                setSelectedPreset('Today')
                break;
            case 'Last month':
                const startOfPreviousMonth = now.subtract(1, 'month').startOf('month')
                const endOfPreviousMonth = now.subtract(1, 'month').endOf('month')
                setDates([startOfPreviousMonth, endOfPreviousMonth])
                setSelectedPreset('Last month')
                break;
            case 'Last bi-weekly':
                const currentDay = now.date()


                let startOfPreviousBiweekly
                let endOfPreviousBiweekly

                if (currentDay <= 15) {
                    const previousMonth = now.startOf('month').subtract(1, 'month')
                    startOfPreviousBiweekly = previousMonth.date(16)
                    endOfPreviousBiweekly = previousMonth.endOf('month')
                }

                else {
                    startOfPreviousBiweekly = now.startOf('month')
                    endOfPreviousBiweekly = now.date(15).endOf('day')
                }

                setDates([startOfPreviousBiweekly, endOfPreviousBiweekly])
                setSelectedPreset('Last bi-weekly')

                break;
            default:
                break;
        }

    }

    const DatePresetItem = ({ period }) => {

        return (
            <ListItem disablePadding>
                <ListItemButton
                    onClick={() => calculatePresetTimeRanges(period)}>
                    <ListItemIcon sx={{ fontSize: '14px' }}>
                        {selectedPreset === period ? <CheckIcon /> : null}
                    </ListItemIcon>
                    <ListItemText primary={period} primaryTypographyProps={{ fontSize: '14px' }} />
                </ListItemButton>
            </ListItem>
        )
    }

    return (
        <Box>
            <Box className='flex-row' >
                <DateRangeCalendar
                    value={dates}
                    onChange={(newValue) => {
                        !newValue[0] && newValue[1] ? setDates([newValue[1], newValue[0]]) : setDates(newValue)
                        setSelectedPreset(null)
                    }}
                />
                <Divider orientation="vertical" />
                <Box className='flex-col' width={200}>
                    <Box p={2}>
                        <Typography variant='subtitle2'>Date presets</Typography>
                    </Box>
                    <List >
                        {datePresets.map((preset) => <DatePresetItem period={preset} key={preset} />)}
                    </List>
                </Box>
            </Box>
            <Divider />
            <Box className='flex-row-between-center' p={1}>
                <ActionButton variant='text' onClick={clearStoreDates}>Reset</ActionButton>
                <Box className='flex-row-flex-end' gap={1}>
                    <ActionButton variant='outlined' onClick={handleClose}>Cancel</ActionButton>
                    <ActionButton variant='contained' onClick={updateStoreDates}>Update</ActionButton>
                </Box>
            </Box>
        </Box>
    )

}

export default DatePickingPopover
