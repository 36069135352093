import { Box, Typography } from "@mui/material";
import HaloModal from "../../../../../globalComponents/Modals/HaloModal";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TitleAndBodyBox } from "../../../../../globalComponents/TextGroups/TitleAndBodyBox";
import WarningIcon from '@mui/icons-material/Warning';






export default function ViewErrors ({viewError, handleClose, errors, csvColumns}) {

    const rows = errors?.rows.filter(item => item.columns.includes(viewError))
    const column = csvColumns.find(item => item?.haloField === viewError)
    const csvValues = column?.values 


    return(
        <HaloModal
            open={Boolean(viewError)}
            title={'Import errors'}
            handleClose={handleClose}>
            
            <Box className='flex-col' gap={2} p={2}>
                <Box className='flex-row-between'   sx={(theme) => ({...theme.standardBox, p:2, gap:1, alignItems:'center'})}>
                    <TitleAndBodyBox 
                        title={'Column'}
                        body={column?.csvHeader}
                    />
                    <TitleAndBodyBox 
                        title={'Halo field'}
                        body={viewError}
                    />

                    <Box className='flex-row' gap={1}>
                        <WarningIcon  color='warning'/>
                        <Typography>{rows?.length} {rows?.length > 1? 'errors' : 'error'} </Typography>
                    </Box>

                </Box>
                <TableContainer sx={(theme) => ({...theme.standardBox})}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{minWidth:200}}>Row #</TableCell>
                                <TableCell sx={{minWidth:200}}>File value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            rows?.map((row) => (
                                <TableRow
                                    key={row.row_number}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {row.row_number}
                                    </TableCell>
                                    <TableCell>
                                        {csvValues[row?.row_number -2 ] || <Typography variant="body2" color='error'>EMPTY</Typography>} {/* subtracting 2 to account for 0 indexing  */}
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </HaloModal>
    )



}