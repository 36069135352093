import { Autocomplete, TextField } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useLayoutEffect, useRef } from "react";


const sx={
    p:0, 
    '& .Mui-error':{
        outlineOffset:-2
    }
}
const inputSx={
    '& .MuiOutlinedInput-root': {
        borderRadius: '0px', 
        p:0
    },
    '& .MuiAutocomplete-input':{
        pb: '5.5px !important'
    },
    '& fieldset': {
        border: 'none',
    },
}

export default function AutoCompleteCell(props) {

    const { id, 
            value: valueProp,
            hasFocus, 
            options, //required
            optionKey, 
            field, 
            isOptionEqualToValue,
            openOnFocus=true, // sometimes false for autocomplete fields that are moving when focused on, (e.g first cell of data grid)  
            row } = props

    const inputRef = useRef()
    const apiRef = useGridApiContext()
    const error = row?.errors?.includes(field)


    useLayoutEffect(() => {
        if (hasFocus) {
            inputRef.current.focus()
        }
    }, [hasFocus])

    const handleValueChange = (event, newValue) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue })
    }  
    
    
    const defaultIsOptionEqualToValue = (option, value) => option[optionKey] === value[optionKey] || value === ""
    const defaultGetOptionLabel = (option) => option[optionKey] || ''

    return(
        <Autocomplete
            openOnFocus={openOnFocus}
            fullWidth
            disableClearable
            options={options || []}
            getOptionLabel={optionKey? defaultGetOptionLabel: (option) => option || ''}
            isOptionEqualToValue={isOptionEqualToValue || defaultIsOptionEqualToValue}
            value={valueProp || ''}
            onChange={handleValueChange}
            sx={sx}
            renderOption={(props, option) => (
                <li {...props} key={option?.id ? option.id : option}>
                  {optionKey? option[optionKey]: option}
                </li>
              )}
            renderInput={(params) => (
            <TextField 
                {...params} 
                error={error}
                inputRef={inputRef}
                sx={inputSx}
                />
            )}
        
        />
    )
}

