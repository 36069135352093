import { SvgIcon } from "@mui/material"


export const OnboardingKeyIcon = (props) =>(
    <SvgIcon {...props} viewBox="0 0 24 24">
            <g>
                <path d="M7.00005 18.325C5.25172 18.325 3.76047 17.7076 2.5263 16.4728C1.29213 15.238 0.675049 13.746 0.675049 11.9968C0.675049 10.2475 1.29213 8.75655 2.5263 7.52405C3.76047 6.29138 5.25172 5.67505 7.00005 5.67505C8.40372 5.67505 9.5773 6.01813 10.5208 6.7043C11.4643 7.39063 12.1432 8.24572 12.5575 9.26955H23.325V14.7305H20.675V18.1555H15.7V14.7305H12.5545C12.1422 15.7544 11.4643 16.6095 10.5208 17.2958C9.5773 17.982 8.40372 18.325 7.00005 18.325ZM7.00005 16.5C8.23055 16.5 9.2273 16.0848 9.9903 15.2543C10.7533 14.424 11.1816 13.6457 11.2753 12.9195H17.625V16.3445H18.85V12.9195H21.5V11.0805H11.2783C11.1826 10.3544 10.7533 9.57613 9.9903 8.7458C9.2273 7.9153 8.23055 7.50005 7.00005 7.50005C5.75005 7.50005 4.68755 7.93755 3.81255 8.81255C2.93755 9.68755 2.50005 10.75 2.50005 12C2.50005 13.25 2.93755 14.3125 3.81255 15.1875C4.68755 16.0625 5.75005 16.5 7.00005 16.5ZM6.99655 13.8555C7.52938 13.8555 7.97238 13.6777 8.32555 13.322C8.67888 12.9664 8.85555 12.5269 8.85555 12.0035C8.85555 11.4707 8.68005 11.0277 8.32905 10.6745C7.97822 10.3212 7.53638 10.1445 7.00355 10.1445C6.48022 10.1445 6.03955 10.32 5.68155 10.671C5.32355 11.0219 5.14455 11.4637 5.14455 11.9965C5.14455 12.5199 5.32238 12.9605 5.67805 13.3185C6.03372 13.6765 6.47322 13.8555 6.99655 13.8555Z" fill={props.color}/>
            </g>
    </SvgIcon>
)
