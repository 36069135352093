import { Typography } from "@mui/material";
import dayjs from "dayjs";




export const CaseBody = ({ type, data }) =>{

    return  (
        <>
            <Typography variant='caption' color='text.disabled'>
                Date submitted: {data?.associated_case?.date_submitted ? dayjs(data.associated_case.date_submitted).format('MMMM D, YYYY h:mm A') : '-'}
            </Typography>   
            <Typography variant='caption' color='text.disabled'>
                Clinician: {data?.compensation ? data.compensation.map(comp => comp?.user?.name)?.join(' + ') : data?.user?.name}
            </Typography>
        </>
    )
}
