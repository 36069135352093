import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material"






//for use with chip only
export function SAOFCAutocomplete({
    open, 
    query,
    optionKey,
    chipLabel,
    handleMenuItemClick
}){
    //TO DO:
    //focus on open
    //tab to option 


    const {data:options, isLoading, isFetching} = query(undefined, {skip: !open})

    return(
        <Autocomplete
            fullWidth
            open={open}
            size="small"
            sx={{ p:2, pt:1 }}
            isOptionEqualToValue={(option, value) => option[optionKey] === value[optionKey]}
            getOptionLabel={(option) => option[optionKey]}
            options={options || []}
            loading={isLoading || isFetching}
            disablePortal
            onChange={(e, newValue) => handleMenuItemClick(e, newValue)}
            renderInput={(params) => (
                <TextField
                {...params}
                label={'Search ' + chipLabel.toLowerCase() }
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <>
                        {isLoading || isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </>
                    ),
                }}
                />
            )}
            ListboxProps={{sx:{height:250}}}
            PopperComponent={({ children }) => (
                <Box sx={{
                    '& .MuiAutocomplete-paper':{
                        boxShadow:'none'
                    }
                }}>{children}</Box>
            )}
            />
    )
}