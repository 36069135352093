import { useState, useCallback, useEffect } from 'react'
import { TextField, Autocomplete, MenuItem, CircularProgress } from '@mui/material'
import debounce from 'lodash/debounce'
import axios from 'axios'
import { useController } from "react-hook-form"

//Radar docs 
//free up to 100K calls/month
//https://radar.com/documentation/maps/search

const HaloAddressAutocomplete = ({
    control,
    name,
    setValue,
    required = false,
    inputLabelProps,
    autoCompleteSXProps,
    textFieldLabel,
    hideHelperText = false,
    helperText

}) => {

    const { formState: { errors } } = useController({
        name,
        control,
        rules: {
            required: required ? 'This field is required' : false,
        },
    })

    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [loading, setLoading] = useState(false)

    const fetchSuggestions = async (query) => {

        setLoading(true)
        try {
            const response = await axios.get('https://api.radar.io/v1/search/autocomplete', {
                headers: {
                    Authorization: process.env.NODE_ENV === 'development' ? 
                    process.env.REACT_APP_TESTING_RADAR_AUTOCOMPLETE_API_KEY : 
                    process.env.REACT_APP_PROD_RADAR_AUTOCOMPLETE_API_KEY,
                },
                params: {
                    query: query,
                },
            })
            setOptions(response.data.addresses || [])
        }
        catch (error) {
            console.error('Error fetching suggestions:', error)
        }
        finally {
            setLoading(false)
        }
    }

    const debouncedFetchSuggestions = useCallback(

        debounce((query) => {
            if (query.length >= 4) {
                fetchSuggestions(query)
            }
        }, 1000),
        []
    )

    useEffect(() => {
        return () => {
            debouncedFetchSuggestions.cancel() //cancel any delayed function calls on unmount
        }
    }, [debouncedFetchSuggestions])

    const handleAutocompleteSelect = (event, option) => {
        if (option) {
            setValue('address', option.addressLabel || `${option.number} ${option.street}`)
            setValue('city', option.city)
            setValue('state', option.state)
            setValue('zip', option.postalCode)
            setInputValue(option.addressLabel || `${option.number} ${option.street}`)
        }
    }

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue)

        if (newInputValue === '') {
            setOptions([])
            setValue('city', '')
            setValue('state', '')
            setValue('zip', '')
        }
        else {
            debouncedFetchSuggestions(newInputValue)
        }
    }

    return (
        <Autocomplete
            name={name}
            inputValue={inputValue}
            fullWidth
            freeSolo
            options={options}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.addressLabel}
            onInputChange={handleInputChange}
            onChange={handleAutocompleteSelect}
            renderOption={(props, option) => (
                <MenuItem
                    {...props}
                    key={option.formattedAddress}
                    sx={{
                        fontSize: '14px',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                    }}>
                    {option.formattedAddress}
                </MenuItem>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    InputLabelProps={{ ...inputLabelProps }}
                    label={textFieldLabel || 'Address'}
                    variant="outlined"
                    fullWidth
                    size='small'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ?
                                    <CircularProgress color="inherit" size={20} />
                                    : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    error={Boolean(errors[name])}
                    helperText={hideHelperText ? '' : helperText ? helperText : errors[name]?.message}
                />
            )}
            sx={{ ...autoCompleteSXProps }}
        />
    )
}

export default HaloAddressAutocomplete