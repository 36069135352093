import { Box, IconButton, Typography, Button, Modal } from "@mui/material"
import { ActionButton } from "../../globalComponents/Buttons/ActionButton/ActionButton"
import { HaloTextField } from "../../globalComponents/ReactHookFormFields"
import { useForm } from "react-hook-form";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom"
import ModalWithHaloLogo from "../components/ModalWithHaloLogo";
import { useForgotPasswordMutation } from "../authApiSlice";
import DismissibleAlert from "../../globalComponents/Alert/DismissibleAlert";
import { useState } from "react";

function PasswordRecovery() {

    const { control, handleSubmit, formState: { isValid }, reset, getValues, trigger } = useForm({
        defaultValues: { email: '' }
    })

    const [showAlert, setShowAlert] = useState(false)
    const [alertObj, setAlertObj] = useState({})

    const navigate = useNavigate()
    const [forgotPassRequest] = useForgotPasswordMutation()

    const handleForgotPassRequest = async () => {

        const email = getValues('email')

        if (email.includes('aegishealthservices')) {
            setAlertObj({
                title: 'Reset password through Gmail.com',
                msg: 'If you are unable to log in with your Aegis Health Services Gmail account due to a forgotten password, please use Gmail.com to reset your password.',
                severity: 'error'
            })
            setShowAlert(true)
            return
        }

        let valid = await trigger()
        if (!valid) return

        if (isValid) {
            forgotPassRequest({ email })
            reset()
            setAlertObj({
                title: 'Email sent!',
                msg: 'If an account associated with this email exists, a reset link has been sent.',
                severity: 'success'
            })
            setShowAlert(true)
        }
    }

    return (
        <ModalWithHaloLogo p={'48px'}>
            <Box className='flex-col' gap={3} width={'100%'} component='form' onSubmit={handleSubmit(handleForgotPassRequest)} noValidate>
                <Box className='flex-col' gap={1}>
                    {showAlert
                        && <DismissibleAlert
                            severity={alertObj.severity}
                            title={alertObj.title}
                            msg={alertObj.msg}
                            optionalDismissLogic={() => setShowAlert(false)}
                        />}
                    <Typography variant='subtitle1'>Account recovery</Typography>
                    <Typography variant='body2'>Recover your BeyondMD account</Typography>
                </Box>
                <HaloTextField name='email' label='Email' variant={'email'} type='email' control={control} maxLength={300} required />
                <Box className='flex-row'>
                    <ActionButton sx={{ flexGrow: 1 }} variant='text' type='submit' onClick={() => { navigate('/login') }}>Back</ActionButton>
                    <ActionButton sx={{ flexGrow: 1 }} variant='contained' type='submit'>Submit</ActionButton>
                </Box>
            </Box>
        </ModalWithHaloLogo>
    )
}

export default PasswordRecovery
