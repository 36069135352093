
import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { ActionButton } from "../Buttons/ActionButton/ActionButton";

export const SmallHeaderBox = ({ children, justifyContent='space-between', gap, sx, ...otherProps }) => (
    <Box 
      className='flex-row'
      gap={gap || 1}
      sx={{
        ...sx,
        height:64, 
        p:'8px 24px', 
        background: useTheme().palette.background.paper, 
        justifyContent, 
        alignItems:'center', 
        borderBottom:`1px solid ${useTheme().palette.divider}`}}>
      {children}
    </Box>
  );
  

export const ProceedButton = ({ onClick, text }) => {
    return (
      <ActionButton
        variant='contained'
        color='success'
        onClick={onClick}>
        {text}
      </ActionButton>
    );
  };
  

export const TypographySubtitle = ({ children }) => (
    <Typography sx={{...useTheme().typography.subtitle1, fontWeight:700}}>
      {children}
    </Typography>
  );
