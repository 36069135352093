import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from '../api/apiSlice'
import authReducer from '../../features/auth/authSlice'
import pathologyReducer from '../../features/pathology/pathologySlice'
import infectiousDiseaseReducer from '../../features/infectiousDisease/infectiousDiseaseSlice'
import rosterReducer from '../../features/rosterManagement/rosterSlice'
import adminReducer from '../../features/admin/adminSlice'
import trialUserReducer from '../../features/trialUsers/trialUserSlice'
import qualityReducer from '../../features/qualityAssurance/qualitySlice'
import { setupListeners } from '@reduxjs/toolkit/query'

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        pathology: pathologyReducer,
        infectiousDisease: infectiousDiseaseReducer,
        roster: rosterReducer,
        admin: adminReducer,
        trialUser: trialUserReducer,
        quality: qualityReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
        devTools: process.env.NODE_ENV === 'development'
})

setupListeners(store.dispatch)