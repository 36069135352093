import TrialInfecDisRecsContainer from "./TrialRecommendations/TrialInfecDisRecsContainer"

function TrialInfecDisReviewContainer({ data }) {
    const { trial_encounter: encounter } = data || {}

    return (
        <>
            <TrialInfecDisRecsContainer
                metrics={data?.metrics}
                encounter={encounter}
            />
        </>
    )
}

export default TrialInfecDisReviewContainer
