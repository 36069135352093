import { Route } from "react-router-dom";
import RequireAuth from '../auth/RequireAuth'
import Caseload from "./pages/Caseload";
import CaseReview from "./pages/CaseReview";
import CaseSummary from "./pages/CaseSummary";
import CaseFinalization from "./pages/CaseFinalization";
import CaseDetails from "./pages/CaseDetails";
import RosterMgmtSite from "./pages/RosterMgmtSite";
import RosterMgmtClinician from "./pages/RosterMgmtClinician";

export const rosterRoutes = [
    <Route path='/caseload' element={<RequireAuth />} key='roster'>
        <Route path='/caseload' element={<Caseload />} />
        <Route path='/caseload/case-review/:caseType/:caseId' element={<CaseReview />} />
        <Route path='/caseload/case-summary/:caseType/:caseId' element={<CaseSummary />} />
        <Route path='/caseload/case-finalize/:caseType/:caseId' element={<CaseFinalization />} />
        <Route path='/caseload/case-details/:caseId' element={<CaseDetails />} />
    </Route>

]

export const rosterAdminRoutes = [
    <Route path='/admin' element={<RequireAuth />} key='roster'>
        <Route path='/admin/site/:siteId' element={<RosterMgmtSite />} />
        <Route path='/admin/clinician/:clinicianId' element={<RosterMgmtClinician />} />
    </Route>
]