import { useTheme } from "@emotion/react"
import { Box, Collapse, Divider } from "@mui/material"
import { useState } from "react"
import TrialPatientFrame from "./TrialPatientFrame"
import TrialClinicianFrame from "./TrialClinicianFrame"
import {useParams} from "react-router-dom"

function TrialClinicianPatientHeader({ trialPatient, encounter }) {

    const theme = useTheme()
    const {encounterType} = useParams()
    const isInfecDis = encounterType.toLowerCase().includes('infectious')


    return (
        <>
            <Box height={196} width={'100%'}></Box>
            <Box display={'flex'} mt={'64px'}

                sx={{
                    ...theme.stickyHeader,
                    zIndex: 1099,
                    background: theme.palette.background.paper
                }}>

                <Box className='flex-row' height={128} p={3} pt={2} gap={3} width={'100%'} sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`
                }}>
                    <TrialClinicianFrame encounter={encounter} />
                    <Divider orientation='vertical' flexItem />
                    <TrialPatientFrame isInfecDis={isInfecDis} trialPatient={trialPatient} />
                </Box>

            </Box>
        </>
    )
}

export default TrialClinicianPatientHeader
