import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"
import { memo } from "react";
import { useController } from "react-hook-form"






function HaloSelectField({
    control, 
    name, 
    label, 
    required, 
    onBlur,
    options, // [ {val:'', label:''}, {val:'', label:''}, {val:'', label:''},] format
    title,
    inputLabelProps,
    menuItemProps, 
    selectProps
   }) {
      const {
        field,
        formState: {  errors },
      } = useController({
        name,
        control,
        rules: { required: required? 'This field is required' : false },
      })

        return(
            <FormControl required={required} fullWidth size='small' error={Boolean(errors[name])}>
                <InputLabel sx={{...inputLabelProps}} id={name + 'Select'}>{label}</InputLabel>
                <Select 
                    label={label}
                    labelId={name + 'Select'}
                    onChange={field.onChange} 
                    value={field.value} 
                    name={field.name} 
                    inputRef={field.ref} 
                    onBlur={onBlur || field.onBlur}
                    title={title? field.value : ''}
                    sx={{...selectProps}}
                    >
                    {options?.map(opt => (
                        <MenuItem key={opt.val} value={opt?.val} sx={{...menuItemProps}}>{opt?.label}</MenuItem>
                    ))}                    
                </Select>   
                {     errors[name]?.message &&
                    <FormHelperText>{errors[name]?.message}</FormHelperText>
                }
          </FormControl>
        )
}  


export default memo(HaloSelectField)
