import { DataGridPro } from "@mui/x-data-grid-pro";
import { useGetUsersCaseStatsQuery } from "../../../../../../rosterAdminAPISlice"
import { Box, Typography, IconButton, Tooltip } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import KeepMountedTabPanel from "../../../../../../../globalComponents/Tabs/TabPanels/KeepMountedTabPanel"
import { hideSelectAllCheckBoxProps, linkedRowProps } from "../../../../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps"
import { CaseTypeQuickAddIcon } from "../../../../../../../../assets/icons/CaseTypeQuickAddIcon";
import { SiteQuickAddIcon } from "../../../../../../../../assets/icons/SiteQuickAddIcon"
import { AssignmentIcon } from "../../../../../../../../assets/icons/MaterialDesignIcons";
import ClinicianRosterSettingsModal from "../../../../ClinicianRosterSettingsModal/ClinicianRosterSettingsModal"
import { useGetSiteAssignmentsQuery, useModifySiteAssignmentsMutation } from "../../../../../../rosterAdminAPISlice";
import { useGetSitesQuery } from "../../../../../../../patients/patientAPISlice"
import { useGetCaseTypesQuery } from "../../../../../../rosterAPISlice";
import { useGetUserCaseTypePermissionsQuery, useModifyUserCaseTypePermissionsMutation } from "../../../../../../rosterAdminAPISlice";
import NumCasesDialog from "./NumCasesDialog";
import HaloUserAvatar from "../../../../../../../globalComponents/Avatar/HaloUserAvatar/HaloUserAvatar";



const columns = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: ({ value, row }) => (
          <Box className="flex-row" alignItems="center" gap={1} height="100%">
            
            <Tooltip title={row.activity_status.charAt(0).toUpperCase() + row.activity_status.slice(1)} arrow>
                <Box>
                    <HaloUserAvatar
                        imageUrl={row?.google_profile_photo}
                        activityStatus={row.activity_status}
                        slotProps={{ 
                            userAvatar: { 
                              sx: { bgcolor: 'success.main', height: 24, width: 24 } 
                            } 
                          }}
                    />
                </Box>
            </Tooltip>
            {/* User Name */}
            <Typography variant="body2" noWrap title={value}>
              {value}
            </Typography>
          </Box>
        ),
    },
    {
        field: 'requested',
        headerName: 'Requested',
        flex: 1
    },
    {
        field: 'assigned',
        headerName: 'Assigned',
        flex: 1
    },
    {
        field: 'completed',
        headerName: 'Completed',
        flex: 1
    },
    {
        field: 'completion_percentage',
        headerName: 'Comp. rate',
        flex: 1,
        valueFormatter: (value) => {

            if (!value) return '-'

            else {
                return value.toFixed(2) + '%'
            }
        }
    },
    {
        field: 'actions',
        headerName: '',
        width:130,
        sortable: false,
        renderCell: (params) => {
            return (
                <Box className='flex-row-center' gap={1} height={'100%'}>
                    <Tooltip title='Assign case(s)' arrow>
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation()
                                params.api.getRow(params.id).openModal('assignCase')
                            }}
                        >
                            <AssignmentIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Edit case type permission' arrow>
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation()
                                params.api.getRow(params.id).openModal('caseType')
                            }}
                        >
                            <CaseTypeQuickAddIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Edit site assignment' arrow>
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation()
                                params.api.getRow(params.id).openModal('site')
                            }}
                        >
                            <SiteQuickAddIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    }
]


const getRowId = (row) => row.user_id



export default function RMCliniciansGrid({
    gridProps,
    dates,
    pageSize,
    pageSizeOptions,
    setPageSize,
    apiRef,
    visible,
}) {

    const navigate = useNavigate()
    const [paginationModel, setPagination] = useState({ pageSize, page: 0 })
    const { data, isLoading, isFetching } = useGetUsersCaseStatsQuery(dates, { skip: !visible })
    const [selected, setSelected] = useState([])
    const [selectedRowData, setSelectedRowData] = useState([])
    const [open, setOpen] = useState(false)


    useEffect(() => {

        setPagination(prev => ({ ...prev, pageSize }))

    }, [pageSize])

    const handleCellClick = ({ field, row }) => {

        if (field.includes('__') || field === 'actions') return

        else {
            navigate('/admin/clinician/' + row.user_id)
        }
    }

    const handlePaginationChange = (pagination) => {
        setPagination(pagination)
        setPageSize(pagination?.pageSize)
    }

    const handleRowSelection = (selectedRowsModel) => {
        setSelected(selectedRowsModel)
    }

    const enhancedColumns = data?.map((row) => ({
        ...row,
        openModal: (action) => {
            if (selected.length === 0) {
                setSelected([row.user_id])

                const selectedRowData = data?.find((user) => row.user_id === user.user_id)
                setSelectedRowData([selectedRowData])
            }
            else {
                const selectedRowData = selected.map((id) => data?.find((row) => row.user_id === id))
                setSelectedRowData(selectedRowData)
            }
            setOpen(action)
        }
    }))

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') return
        setOpen('')
        setSelected([])
        setSelectedRowData([])
    }

    return (
        <>
            <KeepMountedTabPanel className={visible ? 'visible' : ''}>
                <DataGridPro
                    apiRef={apiRef}
                    rows={enhancedColumns || []}
                    columns={columns}
                    loading={isLoading || isFetching}
                    getRowId={getRowId}
                    pagination
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationChange}
                    pageSizeOptions={pageSizeOptions}
                    // checkboxSelection
                    disableRowSelectionOnClick
                    rowSelectionModel={selected}
                    onRowSelectionModelChange={handleRowSelection}
                    onCellClick={handleCellClick}
                    {...gridProps}
                    sx={{
                        ...gridProps.sx,
                        ...hideSelectAllCheckBoxProps,
                        ...linkedRowProps,
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none'
                        }
                    }}
                />
            </KeepMountedTabPanel>
            {open === 'assignCase' &&
                <NumCasesDialog
                    open={Boolean(open)}
                    handleClose={handleClose}
                    selected={selected}
                />
            }
            {open === 'site' &&
                <ClinicianRosterSettingsModal
                    open={open}
                    handleClose={handleClose}
                    rowData={selectedRowData}
                    userIds={selected}
                    allSettingOptionsQuery={useGetSitesQuery}
                    assignedSettingOptionsQuery={useGetSiteAssignmentsQuery}
                    settingsMutation={useModifySiteAssignmentsMutation}
                    title={'Edit site assignments'}
                    settingType='sites'
                    name={selectedRowData[0]?.name}
                />}
            {open === 'caseType' &&
                <ClinicianRosterSettingsModal
                    open={open}
                    handleClose={handleClose}
                    rowData={selectedRowData}
                    userIds={selected}
                    allSettingOptionsQuery={useGetCaseTypesQuery}
                    assignedSettingOptionsQuery={useGetUserCaseTypePermissionsQuery}
                    settingsMutation={useModifyUserCaseTypePermissionsMutation}
                    title={'Edit case type permissions'}
                    settingType='caseTypes'
                    name={selectedRowData[0]?.name}
                />}
        </>
    )
}