import { Route } from 'react-router-dom'
import RequireAuth from '../auth/RequireAuth'
import TrialUserProfile from './pages/TrialUserProfile'
import TrialReview from './pages/TrialReview'


export const trialAdminRoutes = [
    <Route path='/admin/trial-users' element={<RequireAuth />} key='Trial Users'>
        <Route path='profile/:trialUserId' element={<TrialUserProfile />} key={'Trial User Profile'} />
        <Route path='review/:encounterType/:trialEncounterId' element={<TrialReview />} key={'Trial Review'} />
    </Route>

]
