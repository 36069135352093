import CRUDDataGrid from '../../../../../globalComponents/DataGrid/CRUDDataGrid'
import CustomTextFieldCell from '../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell'
import { useManageD2DInteractionsMutation } from '../../../../infectiousDiseaseAPISlice'
import { DrugInteractionIcon } from "../../../../../../assets/icons/MaterialDesignIcons";




export default function DrugInteractions({drugInteractions, readOnly}){

    const columns = [
        {
            field:'drug1',
            headerName:'Drugs',
            flex:1,
            headerClassName: readOnly? '' : 'required-col',
            editable: !readOnly,
            renderEditCell: (params) => (
                <CustomTextFieldCell {...params} />
            ),
        },
        {
            field:'clinical_relevance',
            headerName:'Clinical relevance',
            flex:1,
            editable: !readOnly,
            headerClassName: readOnly? '' : 'required-col',
            renderEditCell: (params) => (
                <CustomTextFieldCell {...params} />
            ),
        },
    ]


    return(
        <CRUDDataGrid
            title={'Drug Interactions'}
            columns={columns}
            initRows={drugInteractions}
            GridIcon={DrugInteractionIcon}
            mutation={useManageD2DInteractionsMutation}
            fieldToFocus={'drug1'}
            requiredFields={['drug1', 'clinical_relevance']}
            isReadOnly={readOnly}
        />
    )
}