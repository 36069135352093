import { useGradeFeedbackSectionMutation } from "../../trialAdminAPISlice"
import { useState } from "react"
import { Box, Checkbox, Typography } from "@mui/material"
import { checkboxClasses } from "@mui/material/Checkbox"
import { CheckedIcon, UncheckedIcon } from "../../../../assets/icons/CheckedIcon"

function AnswerKeyChoice({ isAssignedReviewer, feedbackSubmitted, sx, section, trialEncounterId, setScore, theme }) {

    const [toggleCheck] = useGradeFeedbackSectionMutation()

    const [checked, setChecked] = useState(+section.points === 0 ? false : true)

    const handleToggleCheck = (e) => {
        setChecked(() => {
            toggleCheck({
                trialEncounterId,
                payload: {
                    action: 'grading',
                    sectionId: section.id,
                    isChecked: e.target.checked
                }
            })
                .then((res) => setScore(res.data.score_percentage))
            return e.target.checked
        })
    }

    return (
        <Box sx={{ p: '8px 16px' }}>
            <Box
                className='flex-row'
                gap={2}
                sx={{
                    borderRadius: '8px',
                    p: '8px 0px',
                    backgroundColor: checked ? theme.palette.success.shades4 : feedbackSubmitted ? theme.palette.error.shades4 : ''
                }}>
                <Box className='flex-row' gap={'4px'}>
                    <Box width={42} height={42} className='flex-row-center'>
                        {!feedbackSubmitted ?
                            <Checkbox
                                sx={{
                                    [`&.${checkboxClasses.checked}`]: {
                                        color: theme.palette.success.main,
                                    },
                                }}
                                checked={checked}
                                onChange={handleToggleCheck}
                                disabled={!isAssignedReviewer}
                            /> :
                            checked ? <CheckedIcon /> : <UncheckedIcon />}
                    </Box>
                    <Box className='flex-col' pr={'4px'} width={'87%'}>
                        <Typography variant='subtitle2'>{section.title}</Typography>
                        <Typography variant='body2' sx={sx}>{section.answer}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AnswerKeyChoice
