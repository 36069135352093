import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { RuleSettingsIcon } from "../../../../assets/icons/MaterialDesignIcons";
import { useTheme } from '@emotion/react';
import CaseLoadPrefsDialog from './CaseLoadPrefsDialog/CaseLoadPrefsDialog';
import { dateRangeToString } from '../../../../utils/dateUtils/dateRangeToString';

function CSModalHeader({ 
    mode, 
    unconfirmedDateRange,
    weekRangeStr, 
    handleDialog,
    open,
    prefs,
    setPrefs,
    prefsRef,
    tutorialState }){

  const theme = useTheme()
  weekRangeStr = unconfirmedDateRange?.endDate ? dateRangeToString(unconfirmedDateRange?.startDate, unconfirmedDateRange?.endDate) : weekRangeStr
  
  const getTitle = () => {
      return mode === 'confirming' ? 'Confirm upcoming caseload' : 'Edit caseload';
  };

  const getSubtitle = () =>{
    return mode === 'confirming' ? 'Take a moment to review upcoming caseload for the week of ' : 'Viewing current week caseload for '
  };



  return (
    <Box className='flex-row-between-center' p={'8px 16px'} height={64}>
      <Box className='flex-col'>
        <Typography variant="subtitle2">{getTitle()}</Typography>
        <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
          {
            mode === ''
          }
         {getSubtitle() + weekRangeStr}
        </Typography>
      </Box>
      <IconButton 
        onClick={() => handleDialog(true)} 
        ref={prefsRef}
        sx={{
          border:'3px solid' + (tutorialState?.step === 2 ? theme.palette.success.main : ' transparent')
        }}>
        <RuleSettingsIcon sx={{ color: theme.palette.action.active }} />
      </IconButton>

      <CaseLoadPrefsDialog
                    open={open}  
                    handleDialog={handleDialog} 
                    prefs={prefs}
                    setPrefs={setPrefs}
                    />
    </Box>
  );
};

export default CSModalHeader;