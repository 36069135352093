import { Box } from "@mui/material";
import UserAvatar from "../UserAvatar";
import UserActivityIndicator from "./UserActivityIndicator";












export default function HaloUserAvatar({
    name,
    isImpersonating,
    imageUrl,
    activityStatus,
    pulse,
    slotProps = {
        userAvatar: {},
    }
}) {

   return(
        <Box>
            <UserActivityIndicator
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
                pulse={pulse}
                activityStatus={isImpersonating? 'impersonating' : activityStatus}>
                <UserAvatar
                    firstName={name} 
                    imgUrl={imageUrl}    
                    sx={(theme) => ({ background: theme.palette.success.main, height:32, width:32, fontWeight:400 })}
                    {...slotProps?.userAvatar}
                    />
            </UserActivityIndicator>
        </Box>
   )

}