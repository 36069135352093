import { Chip, Typography } from "@mui/material";






export default function HaloTabLabel({label, count=null, element=null}){


    return(
        <>
        <Typography
            color='text.primary'
            variant='subtitle2'>
                    {label}
        </Typography>
        {count && <Chip label={count} size='small' variant='outlined' sx={{p:'3px 4px', ml:1, fontSize:12, height:18}}/> }
        {element && element}

        </>

    )
}