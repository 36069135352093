import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ActionButton } from '../../../../../../globalComponents/Buttons/ActionButton/ActionButton';
import AddIcon from '@mui/icons-material/Add';
import { Chip } from '@mui/material';
import { SiteQuickAddIcon } from '../../../../../../../assets/icons/SiteQuickAddIcon'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import ClinicianRosterSettingsModal from '../../../ClinicianRosterSettingsModal/ClinicianRosterSettingsModal';
import { useGetSitesQuery } from '../../../../../../patients/patientAPISlice';
import { useGetSiteAssignmentsQuery, useModifySiteAssignmentsMutation } from '../../../../../rosterAdminAPISlice';





export function AllowedSites({ allowedSites, clinicianInfo }) {

  const [open, setOpen] = useState(false)
  let { clinicianId } = useParams()


  return (
    <>
      <TableContainer sx={(theme) => ({ ...theme.standardBox, height: 340, flex: 1, 'th': { background: theme.palette.background.paper }, minWidth: 'fit-content' })}>
        <Table size='small' stickyHeader >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Sites</TableCell>
              <TableCell colSpan={1} align='right'>
                <ActionButton
                  startIcon={<SiteQuickAddIcon />}
                  sx={(theme) => ({ 'span': { mr: 0.5 }})}
                  onClick={() => setOpen(true)}
                >
                  Edit site assignment
                </ActionButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allowedSites?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell >
                  {row.site_name}
                </TableCell>
                <TableCell>
                  {/* <Chip label={row.user.employment_type} variant='outlined' color='primary' size='small'/> */}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {open &&
        <ClinicianRosterSettingsModal
          open={open}
          handleClose={() => setOpen(false)}
          rowData={clinicianInfo}
          userIds={[clinicianId]}
          allSettingOptionsQuery={useGetSitesQuery}
          assignedSettingOptionsQuery={useGetSiteAssignmentsQuery}
          settingsMutation={useModifySiteAssignmentsMutation}
          title={'Edit site assignments'}
          settingType='sites'
          name={clinicianInfo?.name}
        />
      }
    </>
  )


}