
import { useRemoveCasesFromBatchMutation } from '../../../rosterAdminAPISlice';
import { Box, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { ActionButton } from "../../../../globalComponents/Buttons/ActionButton/ActionButton"
import { useTheme } from '@emotion/react';
import { show400Error } from "../../../../../app/errors/genericErrors";




function RemoveFromBatchDialog({
    open,
    handleClose,
    selected,
    setSelected,
    resetParentCallback = () => null, //handle any state in the parent component like clearing a form
}) {

    const theme = useTheme()
    const [removeFromBatch, { isLoading: isRemoving }] = useRemoveCasesFromBatchMutation()

    const removeCasesFromBatchHandler = () => {
        removeFromBatch({ case_ids: selected })
            .unwrap()
            .then((res) => {

                setSelected([])
                handleClose()

                enqueueSnackbar(res, {variant:'success'})
            })
            .catch(err => show400Error(err))
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle>
                Confirm removal of case(s) from batch
            </DialogTitle>
            <DialogContent>
                <Box className='flex-col' gap={2}>
                    <DialogContentText color={'inherit'}>
                        You have selected  <span style={{ color: theme.palette.primary.main }}>{selected?.length}</span> case(s) to be removed from today's batch.
                    </DialogContentText>
                    <DialogActions>
                        <ActionButton
                            variant='outlined'
                            onClick={() => {
                                resetParentCallback()
                                handleClose()
                            }}
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            onClick={removeCasesFromBatchHandler}
                            variant='contained'
                            loading={isRemoving}
                        >
                            Remove
                        </ActionButton>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>

    )
}

export default RemoveFromBatchDialog