import { useParams } from "react-router-dom"
import { useGetTrialEncounterQuery } from "../trialUserAPISlice"
import TrialPathologySummaryContainer from "../components/TrialPathologyReview/TrialPathologySummaryContainer"
import TrialInfecDisSummaryContainer from "../components/TrialInfectiousDiseaseReview/TrialInfecDisSummaryContainer"
import TrialEncounterSummaryNav from "../components/Header/TrialEncounterSummaryNav"

function TrialEncounterSummary() {

    let { encounterType, trialEncounterId } = useParams()
    const { data, isLoading, isFetching } = useGetTrialEncounterQuery({encounterType, trialEncounterId}, { refetchOnMountOrArgChange: true })


    return (
        <>
            <TrialEncounterSummaryNav
                trialEncounterId={trialEncounterId}
                encounterType={encounterType}
                isLoading={isLoading || isFetching}
                patientName={data?.trial_encounter?.patient?.name}
            />

            {
                encounterType === 'Infectious Disease' ?
                    <TrialInfecDisSummaryContainer data={data} isLoading={isLoading || isFetching} allowEdits /> 
                    
                    :
                    <TrialPathologySummaryContainer data={data} isLoading={isLoading || isFetching} allowEdits />
            }

        </>
    )
}

export default TrialEncounterSummary
