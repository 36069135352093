import { useTheme } from "@emotion/react";
import { Box, Skeleton } from "@mui/material";












export default function PatientVerticalCardLoader(){

    const theme = useTheme()

    return(
        <Box className='flex-col' gap={2} sx={{...theme.standardBox}} width={272} p={3} height={'100%'} >
            <Box className='flex-col-center' gap={1}>
                <Skeleton animation='wave' width={56} height={56} variant="circular"/>
                <Skeleton animation='wave' width={'50%'} height={20}/>
            </Box>
            <Skeleton animation='wave' width={'100%'} height={50}/>
            <Skeleton animation='wave' width={'100%'} height={50}/>
            <Skeleton animation='wave' width={'100%'} height={50}/>
            <Skeleton animation='wave' width={'100%'} height={50}/>
            <Skeleton animation='wave' width={'100%'} height={50}/>
            <Skeleton animation='wave' width={'100%'} height={50}/>
            <Skeleton animation='wave' width={'100%'} height={50}/>
            <Skeleton animation='wave' width={'100%'} height={50}/>

        </Box>
    )
}