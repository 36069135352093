import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux";
import { setIsForbidden } from "../../auth/authSlice";


export default function ForbiddenAccess() {


    const navigate = useNavigate()
    const dispatch = useDispatch()

    const backHandler = () => {
        dispatch(setIsForbidden(false))
        navigate('/trial-users/dashboard')
    }

    return (

        <Box className='flex-col-center' sx={{ width: '100%', height: '100vh' }}>
            <Box className='flex-row-center' sx={{ m: '0 auto', height: '100%' }}>
                <Box className='flex-col-center' sx={{ height: '80%' }} gap={3}>
                    <Box className='flex-col-center' sx={{ minHeight: 0 }}>
                        <img style={{ display: 'block', width: '100%', height: '80%', objectFit: 'cover' }} src="/images/forbidden_access.svg" alt='sinking boat' />
                    </Box>
                    <Box className='flex-col-center' maxWidth={598} gap={2}>
                        <Typography variant='h5'>You do not have access to this page</Typography>
                        <Typography sx={{ textAlign: 'center' }}>If you believe this is an error, please contact support at support@beyondmd.care</Typography>
                        <Button variant='contained' disableElevation onClick={backHandler} sx={(theme) => ({ color: theme.palette.primary.contrast, backgroundColor: theme.palette.primary.main, borderRadius: '21px', p: '8px 22px' })}>Go back home</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )


}
