import OutlinedToolbarChip from "../../../../../globalComponents/Chips/OutlinedToolbarChip/OutlinedToolbarChip";
import StickyHeaderToolbar from "../../../../../globalComponents/DataGrid/subComponents/toolBars/StickyHeaderToolbar";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useState } from "react";
import ConfirmCloseCaseDialog from "./ConfirmCloseCaseDialog";
import { useGridApiContext } from "@mui/x-data-grid-pro";




export default function HistoryToolbar({ selected, setSelected }) {
    
  const apiRef = useGridApiContext();
  const [ open, setOpen ] = useState(false  )

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)


  return(
      <StickyHeaderToolbar sx={{height:55}}>
              <OutlinedToolbarChip
                  onClick={handleOpen}
                  startIcon={<DoneOutlinedIcon sx={{height:18, width:18}}/>}>
                  Mark complete
              </OutlinedToolbarChip>
              <ConfirmCloseCaseDialog 
                open={open} handleClose={handleClose} 
                selected={selected}
                setSelected={setSelected} 
                patient={apiRef?.current?.getRow(selected)?.patient}/>
      </StickyHeaderToolbar>
  )

}



