import { IconButton, styled } from '@mui/material';
import { MaterialDesignContent, useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close';
//snackbar specific theme

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(({theme}) => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main,
    
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main,
  },
  '&.notistack-MuiContent':{
    boxShadow: 'none',
    borderRadius: '8px',
    p:'6px 16px',
    ...theme.typography.body2,
    
  }
}));


export function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton size='small' onClick={() => closeSnackbar(snackbarKey)} color='inherit'>
      <CloseIcon fontSize='inherit'/>
    </IconButton>
  );
}

