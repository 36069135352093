import { Box } from "@mui/material";
import { ActionButton } from "../../../../globalComponents/Buttons/ActionButton/ActionButton";
import HaloModalStickyFooter from "../../../../globalComponents/Modals/HaloModalStickyFooter/HaloModalStickyFooter";






export default function StepButtons({
    hideBackBtn, 
    handleBack, 
    handleNext,
    disableNext, 
    loading,
    secondaryNextBtn = null
}){




    return(
        <HaloModalStickyFooter >
        <Box className='flex-row-between' alignItems='center' p={2}>    

            <ActionButton
                variant='outlined' 
                sx={{
                    width:120,
                    visibility: hideBackBtn ? 'hidden':'visible'
                }}
                onClick={handleBack}>
                    Back
            </ActionButton>

            <Box className='flex-row' gap={1}>
                {secondaryNextBtn}
                <ActionButton 
                    variant='contained' 
                    sx={{ width:120}}
                    loading={loading}
                    disabled={disableNext}
                    onClick={handleNext}>
                        Next
                </ActionButton>
            </Box>
        </Box>
        </HaloModalStickyFooter>
    )
}