import { useRef } from "react";
import { Box, IconButton } from "@mui/material"
import SignaturePad from "react-signature-pad-wrapper"
import { ActionButton } from "../Buttons/ActionButton/ActionButton";
import { useTheme } from "@emotion/react"
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useNavigate } from "react-router-dom"
import { enqueueSnackbar } from 'notistack';
import { useDispatch } from "react-redux";

const Signature = ({ apiCallback, optionalSignBtnDisable, optionalSuccessURL, optionalReducer, denyHandler }) => {

    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const sigPad = useRef(null)

    const clearSig = () => {
        sigPad.current.clear()
    }

    const saveSig = (e) => {
        e.preventDefault()
        if (sigPad.current.isEmpty()) {
            enqueueSnackbar('Please provide a signature first', { variant: 'error' })
            return

        }

        const signatureData = sigPad.current.toData()

        const signatureJSON = { x: [], y: [] }
        signatureData[0].points.forEach((point) => {
            signatureJSON.x.push(point.x)
            signatureJSON.y.push(point.y)
        })

        apiCallback([signatureJSON])
            .then((res) => {
                if (optionalReducer) {
                    dispatch(optionalReducer())
                }

                if (optionalSuccessURL) {
                    navigate(optionalSuccessURL, { state: { fromSigning: true } })
                }
            })
            .catch((err) => {
                enqueueSnackbar('Error saving signature:', { variant: 'error' })
            })
    }



    return (
        <Box className='flex-col' gap={4} component='form' noValidate onSubmit={saveSig}>
            <Box sx={{ border: `1px dotted ${theme.palette.divider}`, height: 150 }}>
                <Box className='flex-row-center' m={'0 5px'} gap={1} sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
                    <Box>
                        <SignaturePad
                            redrawOnResize
                            width={420}
                            ref={sigPad}
                            options={{ penColor: 'black', }}
                        />
                    </Box>
                    <IconButton onClick={clearSig}>
                        <RestartAltIcon color={theme.palette.action.active} />
                    </IconButton>

                </Box>
            </Box>
            <Box className='flex-row' width={'100%'} gap={4}>
                <ActionButton onClick={denyHandler} variant='contained' sx={{ backgroundColor: theme.palette.error.main, flex: 1 }}>Deny</ActionButton>
                <ActionButton type='submit' disabled={!optionalSignBtnDisable} variant='contained' sx={{ backgroundColor: theme.palette.success.main, flex: 1 }}>Consent & Sign</ActionButton>
            </Box>
        </Box>
    )
}

export default Signature
