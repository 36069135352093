import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { DateCalendar } from '@mui/x-date-pickers-pro';
import SchedulingChip from './SchedulingChip/SchedulingChip';
import dayjs from 'dayjs';
import { Collapse} from '@mui/material';
import { HaloNumberField } from '../../../globalComponents/ReactHookFormFields/NumberFields/HaloNumberField';
import HaloSwitch from '../../../globalComponents/ReactHookFormFields/Switch/HaloSwitch';
import { useWatch } from 'react-hook-form';
import HaloSkeleton from '../../../globalComponents/Skeletons/HaloSkeleton';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';




const inputSx={
    width:145,
    alignSelf:'center',
    'input':{
        pt:'7px',
        pb:'7px',
        fontSize:24,
        textAlign:'center'
    }
}
const calendarStyles = {
    width:530,
    height:'auto',
    maxHeight:'none',
    '& .MuiDayCalendar-header':{
        gap:0,
        justifyContent:'center'
    },
    '& .MuiDayCalendar-weekDayLabel':{
        height:48,
        width:68,
    },
    '& .MuiDayCalendar-weekContainer':{
        gap:0
    },
    '& .MuiDayCalendar-slideTransition':{
        minHeight:'60vh',
        '.MuiDayCalendar-monthContainer':{
            position:'relative'
        }
    },
    '& .MuiMonthCalendar-root':{
        width:'100%'
    }
}


function CSModalBody({
    tutorialState, 
    theme, 
    pointsRef, 
    control,
    isLoading, 
    schedulingRef, 
    handleMonthChange, 
    startDate, 
    prefs, 
    handleDailyScheduleDialog, 
    setDialogData, 
    unconfirmedDateRange, 
    calendarState}){

    
    const enable_goal = useWatch({control, name:'enable_goal'})

    return(
        <OverlayScrollbarsComponent defer>
            <Box 
                className='flex-col' 
                gap={1} 
                m={2}
                ref={pointsRef}
                border={'3px solid' + (tutorialState?.step === 1 ? theme.palette.success.main : ' transparent')} >
                    <Box className='flex-row-between' alignItems={'center'}>
                        <Box>
                        <HaloSkeleton loading={isLoading}>
                                <Typography variant='subtitle2'>Goal settings</Typography>
                                <Typography variant='caption' color={theme.palette.text.secondary}>Set and track your goals to stay on course</Typography>
                        </HaloSkeleton>
                        </Box>
                        <HaloSkeleton loading={isLoading}>
                            <HaloSwitch 
                                name='enable_goal'
                                control={control}
                            />
                        </HaloSkeleton>

                    </Box>
                    <Box className='flex-row-center' >
                    <Collapse in={enable_goal} >
                        <HaloSkeleton loading={isLoading}>
                            <HaloNumberField
                                name='points_goal'
                                control={control}
                                required={enable_goal}
                                sx={inputSx}
                            />
                        </HaloSkeleton>
                    </Collapse>

                    </Box>

            </Box>
            <Divider/>
            <Box className='flex-col' gap={1} p="8px 16px">
                <HaloSkeleton height={50} width={'100%'} loading={isLoading}/>
                <HaloSkeleton height={50} width={'100%'} loading={isLoading}/>
                <HaloSkeleton height={50} width={'100%'} loading={isLoading}/>
                <HaloSkeleton loading={isLoading} width={'100%'} height={400}>
                    <DateCalendar
                        ref={schedulingRef}
                        disablePast
                        loading={isLoading}
                        onMonthChange={handleMonthChange}
                        slots={{
                            day:SchedulingChip,
                        }}
                        views={['month', 'day']}
                        referenceDate={dayjs(startDate)}
                        slotProps={{
                            day:{   
                                calendarState, 
                                prefs, 
                                handleDailyScheduleDialog,
                                setDialogData,
                                unconfirmedDateRange,
                                isLoading
                            }
                        }}
                        sx={{...calendarStyles, border:'3px solid' + (tutorialState?.step === 3 ? theme.palette.success.main : ' transparent')}}
                        />

                </HaloSkeleton>

            </Box>         
        </OverlayScrollbarsComponent>
    )
}


export default CSModalBody;