import { useForm } from "react-hook-form";
import HaloModal from "../../../../../../features/globalComponents/Modals/HaloModal";
import { useGetHaloUsersQuery } from "../../../../../../features/rosterManagement/rosterAdminAPISlice";
import { Alert, Box, Typography } from "@mui/material";
import { HaloAsyncObjAutocomplete } from "../../../../../../features/globalComponents/ReactHookFormFields/AutocompleteFields/HaloAsyncObjAutocomplete";
import CatchingPokemonTwoToneIcon from '@mui/icons-material/CatchingPokemonTwoTone';
import UserActivityIndicator from "../../../../../../features/globalComponents/Avatar/HaloUserAvatar/UserActivityIndicator";
import UserAvatar from "../../../../../../features/globalComponents/Avatar/UserAvatar";
import HaloModalStickyFooter from "../../../../../../features/globalComponents/Modals/HaloModalStickyFooter/HaloModalStickyFooter";
import { ActionButton } from "../../../../../../features/globalComponents/Buttons/ActionButton/ActionButton";
import { useManageUserImpersonationMutation } from "../../../../../../features/auth/authApiSlice";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';



export default function DittoModal({open, handleClose}){


    const { control,  reset, handleSubmit } = useForm({ user:'' })
    const [impersonate, {isLoading}] = useManageUserImpersonationMutation()

    const impersonateUser = (values) => {
        impersonate({
            userId: values.user.id,
            method:'POST'
        })
        .unwrap()
        .then((res) => {
            window.location.reload()
        })
        .catch((err => {}))
    }

    return(
        <HaloModal 
            title={'Impersonate user'}
            open={open}
            handleClose={handleClose}>
            <Box className='flex-col' p={2} gap={2} component='form' onSubmit={handleSubmit(impersonateUser)} noValidate>
                <Box className='flex-col-center' gap={0.5}>
                    <CatchingPokemonTwoToneIcon sx={{fontSize:40}}/>
                    <Typography variant="h6">Ditto</Typography>
                    <Typography variant="h4">~ ( •ᴗ• ) ~</Typography>

                </Box>
                <HaloAsyncObjAutocomplete
                    label='Select user'
                    name='user'
                    query={useGetHaloUsersQuery}
                    optionKey='name'
                    control={control}
                    autoHighlight
                    required
                    renderOption={(props, option) => {
                        const { key, ...optionProps} = props || {}
                        return(
                            <Box key={option?.id} className='flex-row' component='li' {...optionProps}  sx={{ gap:2, p:2 }}>  
                                <UserActivityIndicator
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                    activityStatus={option?.activity_status}>
                                </UserActivityIndicator>
                                <Typography variant="body2" noWrap title={option?.name}>
                                    {option?.name}
                                </Typography>
                            </Box>
                        )
                    }}
                />
                <Alert severity="warning">
                    In prod: <br />
                    - Ditto can only GET <br />
                    <br />
                    In dev (locally): <br />
                    - Ditto can fully CRUD
                </Alert>
                <HaloModalStickyFooter className='flex-row' sx={{justifyContent:'flex-end', gap:2, p:1}}>
                    <ActionButton color='inherit' onClick={handleClose}>
                        Cancel
                    </ActionButton>
                    <ActionButton type='submit' variant='contained'>
                        Impersonate
                    </ActionButton>
                </HaloModalStickyFooter>
            </Box>
        </HaloModal>
    )
}