import dayjs from "dayjs";
import { useTheme } from "@emotion/react";
import { Box, Divider, Fade, Modal } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { getCurrentWeekStrings } from "../../../../utils/dateUtils/dateRangeToString";
import { useGetMonthSchedulesQuery, useUpsertDailySchedulesMutation } from "../../rosterAPISlice";
import useMonthlySchedules from "./useMonthlySchedules";
import { setScheduleConfirmation } from "../../rosterSlice";
import TutorialDialog from "./TutorialDialog/TutorialDialog";
import SchedulingSingleDayDialog from "./SchedulingSingleDayDialog/SchedulingSingleDayDialog";
import CSModalFooter from "./CSModalFooter";
import CSModalHeader from "./CSModalHeader";
import CSModalBody from "./CSModalBody";
import { useForm } from "react-hook-form";
import { apiSlice } from "../../../../app/api/apiSlice";




const {weekRangeStr, weekStartIso} = getCurrentWeekStrings()

// !!this component is bloated like a #!(&#^$ , but works. Will revisit to simplify it at some point
export default function CaseSchedulingModal({
    unconfirmedSchedule,
    closeModal,
    open,
    goBackFunc = () => null,
    mode='scheduling',
}){
    const {startDate, endDate, ptGoalObjId} = unconfirmedSchedule || {} 
    const unconfirmedDateRange = useMemo(()=> ( {startDate, endDate} ), [unconfirmedSchedule])

    const theme = useTheme() 
    const dispatch = useDispatch()

    const [month, setMonth] = useState(null) //used for month navigation 
    const {data, isLoading, isFetching} = useGetMonthSchedulesQuery(month, {skip: !open || !month})  
    const {needs_tutorial, schedules, preferences, current_week_point_goal} = data || {}    
    const {default_schedule} = preferences || {}    

    const pointsRef = useRef(null)
    const prefsRef = useRef(null)
    const schedulingRef = useRef(null)
    const [tutorialState, setTutorialState] = useState(needs_tutorial? {step:1, ref: pointsRef}: false)

    const [upsertSchedules, {isLoading: isSettingSchedule}] = useUpsertDailySchedulesMutation()

    const [calendarState, setCalendarState] = useMonthlySchedules(schedules) // holds exsisting DailyCaseLoadSchedules, clears any duplicates

    const [dailyScheduleOpenDialog , setDailyDialogOpen] = useState(false) //states for 'dialog' pop up...
    const [dialogData, setDialogData] =  useState({})    //used for modifying calendar state

    const [prefs, setPrefs] = useState(default_schedule)//holds state for caselaod prefs 
    const [prefsOpen, setPrefsOpen] = useState(false)   //opens 'settings' dialog

    const {handleSubmit,control, setValue, getValues } = useForm({
        defaultValue:{
            points_goal: current_week_point_goal?.points_goal?.toString(),
            enable_goal: !current_week_point_goal?.opt_out
        }
    })

     //initializing state variables after query comes in
     useEffect(() => {   

        if(default_schedule && !prefs){
            setPrefs(default_schedule)
        }

        if(pointsRef && open && needs_tutorial) setTutorialState({ 
            step:1,
            ref:pointsRef.current
        })

        if(current_week_point_goal){
            setValue('points_goal', current_week_point_goal?.points_goal?.toString())
            setValue('enable_goal', !current_week_point_goal?.opt_out)
        }




    }, [data, pointsRef, open, month])

    useEffect(() => {
        if(mode === 'scheduling'){
            setMonth(dayjs().format('YYYY-MM-DD'))
        }else{
            setMonth(dayjs(startDate).format('YYYY-MM-DD'))
        }

    },[])
    


    const handleDailyScheduleDialog = useCallback((bool) => setDailyDialogOpen(bool), [])
    const handlePrefsDialog = (bool) => setPrefsOpen(bool)
    const handleMonthChange = (month) => setMonth(month.format('YYYY-MM-DD'))

    const resetModal = () => {
        closeModal()
        setCalendarState(schedules) //reset schedules to initial query data
        setMonth(dayjs().format('YYYY-MM-DD')) //reset to current month   
    }

    const submit = () => {

            const values = getValues()

            //only send schedules that have been modified/are new
            //maybe let the server do this? will allow for faster optimistic updates...
            //...especially if there are alot of schedules modified
            const editedSchedules = calendarState.filter( (entry) => entry.is_edited || entry.is_new);

            let payload ={
                'schedules': editedSchedules ,
                'preferences': prefs,
                'points_goal': {
                    points: values.points_goal,
                    opt_out: !values.enable_goal,
                    week_start_date: weekStartIso
                }
            }   

            //server will decide what biling point goal is being modified depending on if the...
            // ...'confirming_upcoming_caseload' key is attached'
            if (mode === 'confirming'){
                payload.confirming_upcoming_caseload = {start_date: startDate}
            }

            upsertSchedules(payload)
            .unwrap()
            .then(res => {
                setCalendarState([])
                closeModal()
                setMonth(dayjs().format('YYYY-MM-DD'))

                if (mode === 'confirming'){
                    dispatch(setScheduleConfirmation({
                        isConfirmed: true,
                        startDate: null,
                        endDate: null
                    }))
                    dispatch(
                        apiSlice.util.invalidateTags(['Dashboard Charts'])
                    )
                }

                enqueueSnackbar('Schedule settings saved', {variant:'success'})
            })
            .catch(err => {
                if(err?.status === 400){
                    enqueueSnackbar(err?.data?.detail, {variant:'error'})
                }
            })
    }

   
    return(
        <Modal open={open} disableEnforceFocus closeAfterTransition disableAutoFocus keepMounted={mode === 'confirming'? false :true}>
            <Fade in={open} unmountOnExit>
            <Box 
                className='flex-col' 
                sx={{
                        ...theme.standardBox, 
                    maxWidth:600, 
                    maxHeight:'91vh', 
                    ...theme.modal.centered
                    }}
                component={'form'}
                noValidate
                onSubmit={handleSubmit(submit)}
                >
                <CSModalHeader
                    mode={mode}
                    unconfirmedDateRange={unconfirmedDateRange}
                    weekRangeStr={weekRangeStr}
                    handleDialog={handlePrefsDialog}
                    open={prefsOpen}  
                    prefs={prefs}
                    prefsRef={prefsRef}
                    setPrefs={setPrefs}
                    tutorialState={tutorialState}/>
                <Divider/>
                <CSModalBody  
                    tutorialState={tutorialState}
                    theme={theme}
                    pointsRef={pointsRef}
                    control={control}
                    getValues={getValues}
                    isLoading={isLoading}
                    schedulingRef={schedulingRef}
                    handleMonthChange={handleMonthChange}
                    startDate={startDate}
                    prefs={prefs}
                    handleDailyScheduleDialog={handleDailyScheduleDialog}
                    setDialogData={setDialogData}
                    unconfirmedDateRange={unconfirmedDateRange}
                    calendarState={calendarState}/>


                <CSModalFooter
                    ptGoalObjId={ptGoalObjId}
                    closeModal={closeModal}
                    mode={mode}
                    goBackFunc={goBackFunc}
                    resetModal={resetModal}
                    isLoading={isLoading || isFetching}
                    isSettingSchedule={isSettingSchedule}
                    />

                <SchedulingSingleDayDialog
                    dialogData={dialogData}
                    calendarState={calendarState} 
                    setCalendarState={setCalendarState} 
                    open={dailyScheduleOpenDialog}
                    handleDailyScheduleDialog={handleDailyScheduleDialog}/>
                
                <TutorialDialog 
                    tutorialState={tutorialState} 
                    setTutorialState={setTutorialState} 
                    schedulingRef={schedulingRef} 
                    prefsRef={prefsRef}
                    isLoading={isLoading}/>
            </Box>
            </Fade>
        </Modal>


    )


}
