import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import {useNavigate} from 'react-router-dom'
import { ActionButton } from "../../../features/globalComponents/Buttons/ActionButton/ActionButton";
import { useLogErrorMutation } from "../../../features/auth/authApiSlice";
import { useEffect } from "react";

export default function RenderError({error, resetErrorBoundary}){
    const theme = useTheme()
    const navigate = useNavigate()
    const [logError] = useLogErrorMutation()
    const navBack = () => { 
        //navigate(-1) seems to be more intensive than navigating to a specific path, 
        //thus the setTimeout is needed to ensure the route is retrieved and set before resetting the error state
        //how can this be improved?
        navigate(-1)
        setTimeout(() => {
            resetErrorBoundary();  
          }, 100);
    }

    const navHome = () => {
        navigate('/dashboard')
        resetErrorBoundary()
    }

    useEffect(() => {

        if(error?.message){
            logError({
                message: error?.message,
                stack: error?.stack,
            })
        }

    },[])

return(
    <Box className='flex-col-center' sx={{width: '100%', height: '100vh', background:theme.palette.background.paper}}>
        <Box className='flex-row-center' sx={{ m: '0 auto', height: '100%' }}>
            <Box className='flex-col-center' sx={{height:'80%'}} gap={3}>
                <Box className='flex-col-center' sx={{minHeight: 0}}>
                        <img style={{display: 'block', width: 288, height: 336, objectFit:'cover'} }src="/images/something-went-wrong.svg"/>
                </Box>
                <Box className='flex-col-center' maxWidth={598} gap={2}>
                    <Typography textAlign='center' variant='h5' color={theme.palette.text.primary}>Oops! Something went wrong.</Typography>
                    <Typography textAlign='center' color={theme.palette.text.primary}>Reloading Halo may fix this. For help, contact support@beyondmd.care </Typography>
                    <Box className='flex-row' gap={1}>
                        <ActionButton variant='contained' onClick={navBack}>
                            Go back
                        </ActionButton>
                        <ActionButton variant='contained' onClick={navHome}>
                            Go home
                        </ActionButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>

    )
}
