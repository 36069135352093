import { useTheme } from "@emotion/react";
import { Box, Skeleton, Divider } from "@mui/material";




export default function TrialReviewHistoryLoader() {

    const theme = useTheme()

    return (
        <Box className='flex-col-space-around' gap={2} sx={{ ...theme.standardBox }} width={'100%'} height={215} >
            <Box className='flex-row-between-center'>
                <Skeleton animation='wave' width={80} height={20} sx={{ marginLeft: 1 }} />
                <Skeleton animation='wave' width={80} height={20} />
                <Skeleton animation='wave' width={80} height={20} />
                <Skeleton animation='wave' width={80} height={20} />
                <Skeleton animation='wave' width={80} height={20} sx={{ marginRight: 1 }} />
            </Box>
            <Divider />
            <Box className='flex-row-between-center'>
                <Skeleton animation='wave' width={80} height={20} sx={{ marginLeft: 1 }} />
                <Skeleton animation='wave' width={80} height={20} />
                <Skeleton animation='wave' width={80} height={20} />
                <Skeleton animation='wave' width={80} height={20} />
                <Skeleton animation='wave' width={80} height={20} sx={{ marginRight: 1 }} />
            </Box>
            <Divider />
            <Box className='flex-row-between-center'>
                <Skeleton animation='wave' width={80} height={20} sx={{ marginLeft: 1 }} />
                <Skeleton animation='wave' width={80} height={20} />
                <Skeleton animation='wave' width={80} height={20} />
                <Skeleton animation='wave' width={80} height={20} />
                <Skeleton animation='wave' width={80} height={20} sx={{ marginRight: 1 }} />
            </Box>
            <Divider />
            <Box className='flex-row-flex-end'>
                <Skeleton animation='wave' width={56} height={20} sx={{ marginRight: 1 }} />
            </Box>
        </Box>
    )
}
