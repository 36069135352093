

import { Box, ClickAwayListener, Grow, ListItemIcon, MenuItem, MenuList, Paper, Popper, TextField } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { apiSlice } from '../../../../../../../app/api/apiSlice';
import { useParams } from 'react-router-dom';
import { useAddMetricsMutation } from '../../../../../pathologyAPISlice';
import { showGenericValidationError } from '../../../../../../../app/errors/genericErrors';






export default function FindingMetricsProvider({children, encounterId}){


    const dispatch = useDispatch()
    const { caseId } = useParams()
    const [ open, setOpen ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState(null)
    const inputRef = useRef(null)
    const [ metric, setMetric ] = useState('')
    const [ saveMetricTrigger ] = useAddMetricsMutation()


    const handleClose = () => setOpen(false)
    
    const handleContextMenu = (e) => {

        if (e.ctrlKey && e.button === 2) {

            e.preventDefault()
            setAnchorEl({
                getBoundingClientRect: () => ({
                top: e.clientY,
                left: e.clientX,
                width: 0,
                height: 0,
                right: e.clientX,
                bottom: e.clientY,
              })
            })
    
            const selection = window.getSelection();
            setMetric(selection.toString())
            setOpen(true)

        }

    };

    //allows for default closing of popper (esc/enter)
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpen(false);
        } else if (event.key === 'Escape') {
          setOpen(false);
        }
    }


    const addMetric = (metricType) => {
        
        let value = metric.trim()
        if(value !== ''){
            saveMetricTrigger({ 
                encounter_id: encounterId, 
                metric_type: metricType,
                metric_item: value  
            })
            .unwrap()
            .then(res=> {
                dispatch(
                    apiSlice.util.updateQueryData('getCase', caseId, (draft) =>{
                        draft.encounter.findingsmetrics = res.metrics
                    })
                )
                handleClose()
            })
            .catch(err => {
                showGenericValidationError(err)
            })

        }
        handleClose()
    }
    

    useEffect(() => {
        
        if(inputRef?.current && open){
            inputRef.current.focus()
        }
    },[inputRef?.current, open])


    return(
        <Box onContextMenu={handleContextMenu}>
            {children}
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="top-start"
                transition  
                disablePortal
                sx={{zIndex:1100}}
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                    >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                            autoFocusItem={true} //keeps focus on 
                            onKeyDown={handleListKeyDown}
                        >
                            <MenuItem  sx={{opacity:"1 !important"}}>
                                <TextField 
                                    id="metrics" 
                                    label="Metric" 
                                    variant="standard"
                                    inputRef={inputRef} 
                                    value={metric}
                                    onKeyDown={(e) => e.stopPropagation()}
                                    onChange={(e) => {
                                        setMetric(e.target.value);
                                      }}/>
                            </MenuItem>
                            <MenuItem onClick={() => addMetric('missing_labs')}><ListItemIcon><AddIcon fontSize='20px'/></ListItemIcon>Missing labs</MenuItem>
                            <MenuItem onClick={() => addMetric('outdated_labs')}><ListItemIcon><AddIcon fontSize='20px'/></ListItemIcon>Outdated labs</MenuItem>
                            <MenuItem onClick={() => addMetric('missing_drugs')}><ListItemIcon><AddIcon fontSize='20px'/></ListItemIcon>Missing drugs</MenuItem>
                            <MenuItem onClick={() => addMetric('drug_changes')}><ListItemIcon><AddIcon fontSize='20px'/></ListItemIcon>Drug changes</MenuItem>
                            <MenuItem onClick={() => addMetric('cultured_pathogens')}><ListItemIcon><AddIcon fontSize='20px'/></ListItemIcon>Cultured pathogens</MenuItem>
                            <MenuItem onClick={() => addMetric('resistances_found')}><ListItemIcon><AddIcon fontSize='20px'/></ListItemIcon>Resistances found</MenuItem>
                            <MenuItem onClick={() => addMetric('duplicate_therapies')}><ListItemIcon><AddIcon fontSize='20px'/></ListItemIcon>Duplicate therapies</MenuItem>
                        </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                    )}
            </Popper>
        </Box>
    )


}