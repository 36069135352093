import { Box, Breadcrumbs, Divider, Typography } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const getStyledCrumbs = (crumbsArr) => {

    let styledCrumbs = []

    for (let i=0; i< crumbsArr.length; i++){
        let variant='body2'
        let color = 'text.disabled'
        let value = crumbsArr[i]

        if(i === crumbsArr.length -1) color = 'text.primary'

        styledCrumbs.push(
            <Typography key={i} variant={variant} color={color}>{value}</Typography>
        )

    }

    return styledCrumbs
}



export default function BreadCrumbHeader({ 
    title, 
    helperText,
    crumbs = [],
    endComponent=null,
    startComponent = null,
    startCrumb
}){


    return(
        <>
        <Box className='flex-row-between' sx={(theme) => ({ p:'16px 24px', background:theme.palette.background.paper })}>
            
            <Box className='flex-col' gap={1}>
                <Breadcrumbs separator={<ChevronRightIcon fontSize="small" color={'disabled'}/>} aria-label="breadcrumb">
                    {getStyledCrumbs( [(startCrumb || title),...crumbs] )}
                </Breadcrumbs>
                
                <Box className='flex-col'>
                    <Box className='flex-row' sx={{alignItems:'center'}} gap={1}>
                        {startComponent}
                        <Typography noWrap variant='h5' >
                            {title}
                        </Typography>
                    </Box>
                    <Typography noWrap variant="body2" color='text.secondary' >
                        {helperText}
                    </Typography>
                </Box>

            </Box>

                {endComponent}
        </Box>
        <Divider/>
        </>
    )



}
