import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { stringToTitleCase } from "../../../../../utils/stringUtils/titleCase"
import { memo } from "react"



const LinkedPatientCell = ({patientName, patientId}) => {

    return(
        <Box className='flex-row' 
            sx={{ 
                height:'100%', 
                width:'100%', 
                }}>
            <Link className='flex-row' style={{ textDecoration: 'none', height:'100%', width:'100%', alignItems:'center' }} to={'/patients/profile/' + patientId} title={patientName}>
                <Typography variant='body2' noWrap color={'primary'}>{stringToTitleCase(patientName)}</Typography>
            </Link>
        </Box>
    )
}

export default memo(LinkedPatientCell);