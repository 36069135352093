import { Box } from "@mui/material";
import { Info } from "./Info";
import { AllowedUsers } from "./AllowedUsers";






export default function SiteInfoAndSettings({data}){

    const {info, allowed_users} = data || {}




    return(
        <Box className='flex-row' sx={{height:340, gap:2}}>
            <Info info={info}/>
            <AllowedUsers allowedUsers={allowed_users}/>
        </Box>
    )
}