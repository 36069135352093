import { Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard"
import RequireAuth from '../auth/RequireAuth'


export const dashboardRoutes = [
    <Route path='/dashboard' element={<RequireAuth/>} key='dashboardRoutes'>
        <Route path='/dashboard' element={<Dashboard/>} key='dashboard' />
    </Route>
]
