import { enqueueSnackbar } from 'notistack';

const showGenericValidationError = (err) => {
  if (err?.status === 400) {
    enqueueSnackbar('Validation error: please check your inputs and try again', { variant: 'error' });
  }
};

const showAndIterateValidationError = (messageStr='You are missing the following fields: ', err ) => {
  if(err?.status === 400) {
    enqueueSnackbar( messageStr + err.data.join(', '), {variant:'error'} )
  }
}

const show400Error = (err) => {
  if(err?.status === 400){
    enqueueSnackbar(err?.data?.detail, {variant:'error'})
  }
}

const showGenericNotFoundError = (err) => {
  if (err?.status === 404) {
    enqueueSnackbar('Resource not found', { variant: 'error' });
  }
};

export { showGenericValidationError, showGenericNotFoundError, show400Error, showAndIterateValidationError};
