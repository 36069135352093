import { Box, Tab } from "@mui/material";
import HaloTabs from "../../../../../../globalComponents/Tabs/HaloTabs";
import { DoctorIcon, HospitalIcon } from "../../../../../../../assets/icons/MaterialDesignIcons";
import HaloTabLabel from "../../../../../../globalComponents/Tabs/TabLabels/HaloTabLabel";
import TodayIcon from '@mui/icons-material/Today';
import GridAPISearchBar from "../../../../../../globalComponents/DataGrid/subComponents/searchBars/GridAPISearchBar/GridAPISearchBar";
import ReduxStoreSearchBar from "../../../../../../globalComponents/SearchBars/ReduxStoreSearchBar/ReduxStoreSearchBar";
import { setDailyCasesFilters } from "../../../../../../admin/adminSlice";


const getKey = (tabValue) => {
    if(tabValue === 'Clinicians') return 'name'

    if(tabValue === 'Sites' ) return 'site_name'
}


export default function RosterGridTabs({
    tabValue, 
    handleTabChange,
    apiRef
}){

    return(
        <Box className='flex-row-between' >
            <HaloTabs 
                value={tabValue} 
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile>
                <Tab
                    label={ <HaloTabLabel label={'Clinicians'} />}
                    value="Clinicians"
                    icon={<DoctorIcon color='primary' sx={{height:20, width:20}}/>}
                    iconPosition='start'/>
                <Tab
                    label={ <HaloTabLabel label={'Sites'} />}
                    value="Sites"
                    icon={<HospitalIcon color='primary' sx={{height:20, width:20}}/>}
                    iconPosition='start'/>
                <Tab
                    label={ <HaloTabLabel label={"Today's cases"} />}
                    value="Today's cases"
                    icon={<TodayIcon color='primary' sx={{height:20, width:20}}/>}
                    iconPosition='start'/>
            </HaloTabs>
            {
                tabValue === "Today's cases" ?

                    <ReduxStoreSearchBar 
                        selectorPath='admin.rosterMgmtGrids.dailyCasesFilters.search'
                        setter={(val) => setDailyCasesFilters({search:val})}
                        placeholder='Search cases'
                        />
                    :
                    <GridAPISearchBar 
                        apiRef={apiRef} 
                        searchField={getKey(tabValue)}
                        placeholder={'Search ' + tabValue?.toLowerCase()}/>
                }
        </Box>
    )
}