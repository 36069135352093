





import { memo } from "react";
import { useManageGxGenesMutation } from "../../../geneomicsAPISlice";
import { GeneticsIcon } from "../../../../../assets/icons/MaterialDesignIcons";
import CRUDDataGrid from "../../../../globalComponents/DataGrid/CRUDDataGrid";
import CustomTextFieldCell from "../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell";

function Genes({readOnly,  genes}){


  let columns = [
      {
        field: 'name',
        headerName: 'Gene',
        flex:1.5, 
        editable: !readOnly, 
        headerClassName: readOnly? '' : 'required-col',
        renderEditCell: (params) => (
          <CustomTextFieldCell {...params}/>
        ),
      },
      { 
        field: 'description', 
        headerName: 'Description',
        flex:1,
        editable: !readOnly,
        renderEditCell: (params) => (
          <CustomTextFieldCell {...params}/>
        ),
      }
  ]

    return(

      <CRUDDataGrid
        title={'Genes'}
        columns={columns} 
        initRows={genes} 
        mutation={useManageGxGenesMutation}
        fieldToFocus='name'
        requiredFields={['name']}
        isReadOnly={readOnly}
        GridIcon={GeneticsIcon}
        gridParentSx={{
          flex:1.5
        }}/>
    )
  
}

export default memo(Genes);