import Papa from 'papaparse';
import { useCallback, useEffect, useState } from 'react';




export const useGetCSVColumns = ({template, file}) => {

    const [csvColumns , setCsvColumns] = useState([])
   
    //get custome field instances
    const {template_objects} = template || {}
    
    const handleParse = useCallback(() => {

        if (file?.length < 1) return null;
    
        const reader = new FileReader();
    
        reader.onload = async ({ target }) => {

            const csv = Papa.parse(target.result, {
                header: true,
            });
    
            const parsedData = csv?.data; //gets rows where keys are the csv columns headers     
            const columns = Object.keys(parsedData[0]); //  csv column headers arr

            // build array where each item object contains column info and ordred columns values
            const formattedColumns = columns.map((header, indx) => ({ 
                id: indx,
                csvHeader: header,
                haloField: template_objects?.[indx]?.database_field,
                values: parsedData.map(row => row[header]), 
            }));
            
            setCsvColumns(formattedColumns);
        };
    
        reader.readAsText(file[0]);
        
    }, [setCsvColumns, template, file]);


    useEffect(()=> { handleParse() }, [ handleParse ])


    return csvColumns
}