import { Box, Typography, Divider } from "@mui/material";
import { useUpdateTrialRecsAndCommentsMutation } from "../../../../trialUserAPISlice";
import { useTheme } from "@emotion/react";
import { showGenericValidationError } from "../../../../../../app/errors/genericErrors";
import { getToolbarStatusIcon } from "../../../../../globalComponents/DataGrid/utils/getToolbarStatusIcon";
import { memo } from "react";
import { useForm } from "react-hook-form";
import { HaloTextField } from "../../../../../globalComponents/ReactHookFormFields";
import { ClinicalNotesIcon } from "../../../../../../assets/icons/MaterialDesignIcons";

function TrialRecsAndComments({ readOnly = false, id, ...initialValues }) {
    const theme = useTheme()

    const [updateRecsAndComments,] = useUpdateTrialRecsAndCommentsMutation();
    const { control, handleSubmit, formState: { isDirty, isValid }, reset } = useForm({
        defaultValues: initialValues,
        shouldFocusError: false
    })


    const handleRecs = async (values) => {

        if (isDirty) {
            updateRecsAndComments({ id, ...values })
                .unwrap()
                .then(res => reset({ ...values }, { keepDirtyValues: true, keepDirty: false, }))
                .catch(err => showGenericValidationError(err))
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', ...theme.standardBox, flex: 1.5, }}>
            <Box sx={{ display: 'flex', alignItems: 'center', p: '12px 16px 8px 16px', gap: 1, height: 48, background: theme.palette.primary.shades4 }}>
                <Box className='flex-row' gap={0.5} alignItems='center'>
                    <ClinicalNotesIcon color='primary' style={{ fontSize: 22 }} />
                    <Typography>Recommendations & Comments</Typography>
                </Box>
                {!readOnly ? getToolbarStatusIcon(!isValid) : null}
            </Box>
            <Divider />
            <Box
                className='flex-col'
                p={2}
                gap={2}
                onBlur={handleSubmit(handleRecs)}
            >
                <HaloTextField
                    name='treating_provider'
                    label='Treating provider'
                    disabled={readOnly}
                    control={control}
                />
                <HaloTextField
                    name='medication_review'
                    label='Medication review'
                    disabled={readOnly}
                    control={control}
                    multiline
                    minRows={5}
                    maxRows={20}
                    maxLength={5000}
                />
                <HaloTextField
                    name='diagnostic_recommendation'
                    label='Diagnostics'
                    disabled={readOnly}
                    control={control}
                    multiline
                    minRows={5}
                    maxRows={20}
                    maxLength={5000}
                />
                <HaloTextField
                    name='treatment_recommendation'
                    label='Treatment'
                    disabled={readOnly}
                    control={control}
                    multiline
                    minRows={5}
                    maxRows={20}
                    maxLength={5000}
                />
                <HaloTextField
                    name='comments'
                    label='Comments'
                    disabled={readOnly}
                    control={control}
                    multiline
                    minRows={5}
                    maxRows={20}
                    maxLength={5000}
                />
            </Box>
        </Box>
    );
}

export default memo(TrialRecsAndComments);
