import { Box } from "@mui/material";
import StatCard from "../../../../../globalComponents/Cards/StatCard/StatCard";
import { useDispatch, useSelector } from "react-redux";
import { selectRosterMgmtStatSpotlightIndex, setRosterMgmtStat, setRosterMgmtStatSpotlightIndex, selectRosterMgmtStatSpotlight } from "../../../../../admin/adminSlice";
import { useGetCasePerformanceStatsQuery} from "../../../../rosterAdminAPISlice";
import AsgmtStatChart from "../AsgmtStatChart/AsgmtStatChart";
import StatSpotlightCharts from "../StatSpotlightChart/StatSpotlightCharts";
import { useEffect } from "react";






export default function OverviewCaseStats({dates}){

    const dispatch = useDispatch()
    const statIndex = useSelector(selectRosterMgmtStatSpotlightIndex)
    const stat = useSelector(selectRosterMgmtStatSpotlight)
    
    const { data, isLoading, isFetching } = useGetCasePerformanceStatsQuery({dates})
    const { requested, requested_change, assigned, assigned_change, completed, completed_change, patients, patients_change, day_period } = data || {}


    const selected = (index) => statIndex === index
    const setStat = (val, index) => (e) => {
        dispatch(setRosterMgmtStatSpotlightIndex(index))
        dispatch(setRosterMgmtStat(val))
    }

    useEffect(() => {
        //janky solution, will revisit if additional cards are added 
        if(statIndex > 3) {
            setStat('Completed', 3)()
        }
    }, [statIndex])


    return(
        <>
            <Box className='flex-row' gap={1} flexWrap={'wrap'}>
                <StatCard 
                    label='Patients'
                    stat={patients}
                    percentDiff={patients_change}
                    selected={selected(0)}
                    setStat={setStat('Patients', 0)}
                    dayPeriod={day_period}
                    dates={dates}
                    loading={isLoading || isFetching}/>
                <StatCard 
                    label='Requested'
                    stat={requested}
                    percentDiff={requested_change}
                    selected={selected(1)}
                    setStat={setStat('Requested', 1)}
                    dayPeriod={day_period}
                    dates={dates}
                    loading={isLoading || isFetching}/>
                <StatCard 
                    label='Assigned'
                    stat={assigned}
                    percentDiff={assigned_change}
                    selected={selected(2)}
                    setStat={setStat('Assigned', 2)}
                    endComponent={<AsgmtStatChart  dates={dates}/>}
                    dayPeriod={day_period}
                    dates={dates}
                    loading={isLoading || isFetching}
                    />
                <StatCard 
                    label='Completed'
                    stat={completed}
                    percentDiff={completed_change}
                    selected={selected(3)}
                    setStat={setStat('Completed', 3)}
                    dayPeriod={day_period}
                    dates={dates}
                    loading={isLoading || isFetching}/>
            </Box>
            <StatSpotlightCharts stat={stat} dates={dates}/>
        </>
    )
}