import { Step,  StepLabel, Stepper } from "@mui/material";
import HaloModal from "../../../../globalComponents/Modals/HaloModal";
import { useState } from "react";
import { CPModalBody } from "./CPModalBody";
import { useGetSitesQuery } from "../../../patientAPISlice";





const steps = ['Personal info', 'Health info']


export default function CreatePatientModal({open, handleClose, openAssignment}){


    const [activeStep, setActiveStep] = useState(0);
    const {data:sites } = useGetSitesQuery(undefined, {skip:!open})

    const handleNext = () => {  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  


    return(
        <HaloModal 
            open={open} 
            handleClose={handleClose}
            title={'Create a new patient'}
            modalMaxWidth={1200}>
                <Stepper activeStep={activeStep} sx={{p:'16px 48px'}}>
                    {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                <CPModalBody 
                  open={open}
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleReset={handleReset}
                  sites={sites}
                  steps={steps}
                  handleClose={handleClose}
                  openAssignment={openAssignment}/>
        </HaloModal>
    )
}