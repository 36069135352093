import HaloModal from "../../../globalComponents/Modals/HaloModal"
import { Alert, AlertTitle, Box, Typography } from "@mui/material"
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton"
import { useGetSitesQuery } from "../../../patients/patientAPISlice"
import { useGrantFullAccessMutation } from "../../trialAdminAPISlice"
import { useForm } from "react-hook-form"
import { SelectMultipleAutocomplete } from "../../../globalComponents/ReactHookFormFields/AutocompleteFields/SelectMultipleAutocomplete"
import { enqueueSnackbar } from "notistack"



export default function TrialOnboardingModal({ open, handleClose, rowData }) {


    const [grantAccess, { isLoading: isGrantingAccess }] = useGrantFullAccessMutation()

    const { handleSubmit, control } = useForm({
        defaultValues: {
            authorized_sites: []
        }
    })

    const onboardHandler = ( values) => {
        const { authorized_sites } = values

        grantAccess({ 
            authorized_sites: authorized_sites.map(site => site.id), //only send ids of the sites
            trial_user_id: rowData.id 
        })
        .unwrap()
        .then((res) => {
            
            if (res?.redirect_url){
                window.location.href = res.redirect_url     
            }else{
                enqueueSnackbar(res, {variant:'success'})
                handleClose()
            }
        })
    }       

    return (
        <HaloModal open={open} title={'Trial user onboarding'} handleClose={handleClose}>
            <Box component={'form'} onSubmit={handleSubmit(onboardHandler)} noValidate>
                <Box className='flex-col' p={2} gap={2} >
                    <Box className='flex-col' gap={2}>
                        <Box>
                            <Typography variant='body2' color='text.disabled' >{`By proceeding you will grant trial user ${rowData?.name}: `}</Typography>
                            <Typography variant='body2' color='text.disabled' sx={{  p: 0, pl: '5px' }}>• Full access to Halo </Typography>
                            <Typography variant='body2' color='text.disabled' sx={{  p: 0, pl: '5px' }}>• An @aegishealthservices.com email</Typography>
                        </Box>

                        <Alert severity="info" sx={{ borderRadius: 2 }}>
                            <AlertTitle>Confirm the following: </AlertTitle>
                            • User has received proper HALO training.<br />
                            • Vicky has received all required documents.<br />
                        </Alert>
                    </Box>

                    <Box>    
                        <SelectMultipleAutocomplete 
                            name='authorized_sites'
                            label='Select user-associated sites'
                            query={useGetSitesQuery}
                            optionKey='site_name'
                            required
                            control={control}
                        />
                    </Box>
                </Box>
                <Box className='flex-row' gap={1} sx={{ justifyContent: 'flex-end', alignItems: 'center', p: '8px 16px 16px 16px' }}>
                    <ActionButton 
                        sx={{ p: '6px 8px' }} 
                        color='primary'
                        disabled={isGrantingAccess}
                        onClick={handleClose}>
                        Cancel
                    </ActionButton>
                    <ActionButton 
                        loading={isGrantingAccess} 
                        variant='contained' 
                        type='submit' >
                        Grant full access
                    </ActionButton>
                </Box>
            </Box>
        </HaloModal>
    )
}


