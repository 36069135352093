import { Box, Typography, Divider, Alert} from "@mui/material";
import { useUpdateRecsAndCommentsMutation } from "../../../../pathologyAPISlice";
import { useTheme } from "@emotion/react";
import QACommentsContainer from "../../../../../qualityAssurance/components/QAFeedback/QACommentsContainer";
import { showGenericValidationError } from "../../../../../../app/errors/genericErrors";
import { getToolbarStatusIcon } from "../../../../../globalComponents/DataGrid/utils/getToolbarStatusIcon";
import { memo, useEffect} from "react";
import { useForm } from "react-hook-form";
import { HaloTextField } from "../../../../../globalComponents/ReactHookFormFields";
import { ClinicalNotesIcon } from "../../../../../../assets/icons/MaterialDesignIcons";
import Under18Alert from "../../../../../rosterManagement/components/Under18Alert/Under18Alert";



function RecsAndComments({ readOnly=false,id,qaObj, isUnder18, ...initialValues }) {
    const theme = useTheme()
    
    const [updateRecsAndComments,] = useUpdateRecsAndCommentsMutation();
    const { control, handleSubmit, formState:{isDirty, isValid}, reset} = useForm({
        defaultValues: initialValues,
        shouldFocusError:false
    })
    

    const handleRecs = async (values) => {
        if (isDirty){
            updateRecsAndComments( { id, ...values } )
                .unwrap()
                .then(res => reset( {...values}, {keepDirtyValues:true, keepDirty:false, }))
                .catch(err => showGenericValidationError(err) )
        }
    };  
 

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', ...theme.standardBox, flex:1.5, }}>
            <Box sx={{ display: 'flex', alignItems: 'center', p: '12px 16px 8px 16px', gap:1, height:48, background:theme.palette.primary.shades4 }}>
                <Box className='flex-row' gap={0.5} alignItems='center'>
                    <ClinicalNotesIcon color='primary' style={{fontSize:22}}/>
                    <Typography>Recommendations & Comments</Typography>
                </Box>
                {getToolbarStatusIcon(!isValid)}
            </Box>
            <Divider />
            <Box 
                className='flex-col' 
                p={2} 
                gap={2} 
                onBlur={handleSubmit(handleRecs)}                 
                >
                <HaloTextField 
                    name='treating_provider'
                    label='Treating provider'
                    disabled={readOnly}
                    //required *commeneted this as it prevented the other fields from being saved...
                    //...I could see users loosing their work because of this
                    control={control}    
                    maxLength={300}                
                />
                <HaloTextField 
                    name='medication_review'
                    label='Medication review'
                    disabled={readOnly}
                    control={control}
                    multiline
                    minRows={5}
                    maxRows={20}
                    maxLength={7000}
                />
                { qaObj && <QACommentsContainer fieldReview={qaObj?.field_reviews["Medication Review"]} /> }
                <HaloTextField
                    name='diagnostic_recommendation'
                    label='Diagnostics'
                    disabled={readOnly}
                    control={control}
                    multiline
                    minRows={5}
                    maxRows={20}
                    maxLength={7000}
                />
                {
                    isUnder18 &&
                    <Under18Alert />
                }
                <HaloTextField
                    name='treatment_recommendation'
                    label='Treatment'
                    disabled={readOnly}
                    control={control}
                    multiline
                    minRows={5}
                    maxRows={20}
                    maxLength={7000}
                />
                { qaObj && <QACommentsContainer fieldReview={qaObj?.field_reviews["Recommendations & Treatment Plan"]} /> }
                <HaloTextField
                    name='comments'
                    label='Comments'
                    disabled={readOnly}
                    control={control}
                    multiline
                    minRows={5}
                    maxRows={20}
                    maxLength={7000}
                />
            </Box>
        </Box>
    );
}

export default memo(RecsAndComments);
