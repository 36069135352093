import { useTheme } from "@emotion/react";
import { Box, Skeleton } from "@mui/material";



export function AccordionLoader(){

    const theme = useTheme()

    return(
        <Box className='flex-row'
            sx={{
                height:56,
                p:1,
                background: theme.palette.background.paper,
                gap:2,
                boxShadow: theme.customShadows.shadow2 ,
                alignItems:'center'
            }}>
            <Skeleton animation='wave' variant="circular"  height={35} width={35}/>
            <Skeleton width={100} height={'50%'}/>

        </Box>
    )

}