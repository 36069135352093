import { DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { useController } from "react-hook-form";







export default function HaloDateRangePickers({
    control, 
    name, 
    label, 
    format="MMM D YYYY",
    slotProps,
    required=false, 
    helperText,
    hideHelperText=false,
    minDate = dayjs('1901-01-01'),  // min and max dates should always be passed in 'YYYY-MM-DD' format
    maxDate = dayjs(), //today
    title,
    disablePast = true,
    ...otherProps 
}){


    const { field, formState: { errors }, } = useController({
        name,
        control,
        rules: { 
          required: required? 'This field is required' : false ,
          validate: (dates) => {
            if(! (minDate <= dates[0] && dates[1] <= maxDate) ) return `Date is out of range: ${minDate.format('MM/DD/YYYY')}-${maxDate.format('MM/DD/YYYY')}`

            if(dates.filter(x => x).length !== 2) return  'Missing required dates'
          }
        },
    })


    return(
        <DateRangePicker
            label={label}
            size='small'
            {...field}
            {...otherProps}
            format={format}
            title={title? field.value : ''}
            disablePast={disablePast}
            slotProps={{
                textField:{
                  size:'small',
                  fullWidth:true,
                  error:Boolean(errors[name]),
                  helperText:hideHelperText? '' :helperText? helperText: errors[name]?.message,
                  required:required,
                },
            }}
          />
    )



}