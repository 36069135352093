import CaseAccordion from "../../../../globalComponents/Accordions/CaseAccordion";
import SummaryContainer from "../../../../globalComponents/Containers/SummaryContainer";
import Allergies from "./Allergies/Allergies";
import Diagnoses from "./Diagnoses/Diagnoses";
import General from "./General/General";






export default function DataInput(props){

    const {allergies, diagnoses, readOnlyView,  allowEdits, caseInfo, isLoading, general} = props

    const content = [
        <General general={general}  key={'general'}readOnly={readOnlyView} />,
        <Allergies allergies={allergies} key={'allergies'} readOnly={readOnlyView}/>,
        <Diagnoses diagnoses={diagnoses} key={'diags'} readOnly={readOnlyView}/>
    ]



    return readOnlyView ? (
        <SummaryContainer number={1} title={'Data Input'} disableEdits={!allowEdits} caseInfo={caseInfo} isLoading={isLoading}>
            {content}
        </SummaryContainer>
    ) : (
        <CaseAccordion number={1} title={'Data Input'}>
            {content}
        </CaseAccordion>
    );
}