


import { Autocomplete, TextField, CircularProgress, Checkbox, Box } from "@mui/material"
import { useState } from "react";
import { useController } from "react-hook-form";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


// Also takes an RTK query and the options are always showing rather than needing to click on the input 
export function SelectMultipleAutocomplete({ 
  control, 
  name, 
  label, 
  required, 
  query, //needs to be an RTK query e.g. useGetSites
  queryParams=undefined,
  optionKey, // use to compare object values
  ...otherProps
}) {
    const {
      field,
      formState: {  errors },
    } = useController({
      name,
      control,
      rules: { required: required? 'This field is required' : false },
    })

    const [inputValue, setInputValue] = useState('')
    const { data:options, isLoading } = query(queryParams);

    return(
        <Autocomplete
            {...otherProps}
            fullWidth
            autoComplete
            multiple
            open={true}
            disablePortal
            disableCloseOnSelect
            value={field.value || null}
            onChange={(event, newValue) => {
              field.onChange(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={options || []}
            ListboxProps={{sx:{minHeight:250, border: 1,borderTop: 0, borderColor:'divider'}}}
            getOptionLabel={(option) => option[optionKey] || ''}
            isOptionEqualToValue={(option, val) => option[optionKey] === val[optionKey] || option[optionKey] === null}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
                {option.site_name}
                </li>
            )}
            PopperComponent={({ children }) => (
              <Box sx={{
                  '& .MuiAutocomplete-paper':{
                      boxShadow:'none'
                  }
              }}>{children}</Box>
            )}
            renderInput={(params) => (
              <TextField 
                {...params} 
                required={required} 
                label={label} 
                size="small" 
                error={Boolean(errors[name])}
                helperText={errors[name]?.message}
                sx={{borderRadius:0}}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                      <>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                      </>
                  ),
              }}/>
            )}
        />
      )
  
  }
  
  