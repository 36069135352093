import { useTheme } from "@emotion/react"
import { Box, IconButton, Typography } from "@mui/material"






export default function HaloIconAndTextButton({
  isActive, 
  label, 
  description,
  Icon,
  sx={},
  ...otherProps
}){


    const theme = useTheme()


    return(
      <IconButton
          {...otherProps}
          className='flex-col'
          sx={{
            borderRadius: 2,
            boxShadow: `inset 0px 0px 0px ${isActive ? '2px ' + theme.palette.primary.main : '1px ' + theme.palette.divider}`,
            boxSizing: 'border-box',
            p: '16px 32px',
            alignItems: 'center',
            background: isActive ? theme.palette.primary.shades4 : '',
            ...sx
          }}>
          <Box>
            <Icon color={isActive ? 'primary' : theme.palette.action.active} />
            <Typography variant="subtitle1" color={theme.palette.text.primary}>
              {label}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.primary}>
              {description}
            </Typography>
          </Box>
      </IconButton>
    )


}