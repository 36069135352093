import { Box, Tooltip, Typography } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import DefaultNoRowsOverlay from '../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay'
import { useCallback, useState, } from "react";
import { useGetCompletedCaseListQuery } from "../../../rosterAPISlice";
import dayjs from "dayjs";
import HistoryFilters from "./HistoryFilters";
import { useDispatch, useSelector } from "react-redux";
import { linkedRowProps } from "../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";
import usePaginatedRowCount from "../../../../globalComponents/DataGrid/hooks/commonUseEffects/usePaginatedRowCount";
import MDApprovedStatusChip from "../../../utils/StatusChips/MDApprovedStatusChip";
import  LinkedPatientCell  from "../../../../globalComponents/DataGrid/subComponents/customCells/LinkedPatientCell";
import { resetCaseHistoryPagination, selectCaseHistoryFilters, setCaseHistoryFilters } from "../../../rosterSlice";
import HistoryToolbar from "./HistoryToolbar/HistoryToolbar";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

let columns =[
  {
      field: 'case_type',
      headerName: 'Case type',
      flex:1,
      renderCell:(params) =>{
         return <>{params?.formattedValue?.split(' ')[0]}</>
      },
      sortable:false
  },
  {
      field: 'patient',
      headerName: 'Patient',
      flex:1,
      renderCell:({value,row}) => (
          <LinkedPatientCell
            patientName={value}
            patientId={row?.patient_id}
          />
        )
  },
  {
      field: 'site_name',
      headerName: 'Site',
      flex:1,
      valueGetter: (value, row) => row?.site || '-'
  },
  {
      field: 'patient_dob',
      headerName: 'DOB',
      flex:1,
      valueGetter: (value, row) =>{
        if(!value) return ''
        return dayjs(value).format('LL')
      },
      sortable:false
  },
  {
    field:'date_submitted',
    headerName: 'Date submitted',
    flex:1,
    valueFormatter: (value) => {
      if(!value) return ''
      return dayjs(value).format('LL')
  }
  },
  {
    field:'md_review',
    headerName: 'MD approved',
    flex:0.75,  
    sortable:false,
    minWidth:150,
    renderCell: ({value, row}) => {
      return(
        <MDApprovedStatusChip mdApproved={value?.is_approved} qaObj={row?.qa_feedback} mdReviewObj={row?.md_review}/>
      )
    }
  },
  {
    field:'compensation_points',
    headerName: 'Compensation pts.',
    flex:0.75,
    renderCell:({row}) => {
      const {qa_feedback, review_metadata} = row || {}
      const {first_pass_user, second_pass_user} = review_metadata || {}
      const {review_state_string:qa_status} = qa_feedback || {}
      let pending = qa_status === 'pending' || qa_status === 'in-progress'
      let isShared = false
      
      if(second_pass_user && (first_pass_user !== second_pass_user)){
        isShared = true
      }

      return(
        <Tooltip title={first_pass_user + ' + ' + second_pass_user}>
          <Box display='flex' alignItems='center' height='100%' gap={1}>
            <Typography variant='body2' noWrap sx={(theme) => ({color:pending? theme.palette.warning.main :''})}>{pending? 'QA pending' : row?.compensation_points}</Typography>
            {isShared && <ShareOutlinedIcon color="action"/>}
          </Box>
        </Tooltip>
      )


    },
    sortable:false
  },
]


export default function CaseHistoryTabPanel() {

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const queryOptions = useSelector(selectCaseHistoryFilters)
  
  const { data, isLoading, isFetching } = useGetCompletedCaseListQuery({ ...queryOptions, page: queryOptions.pagination.page + 1, search: queryOptions.searched });
  const rowCountState = usePaginatedRowCount(data?.count)
  const [ selected, setSelected ] = useState([])
  const isSelected = selected?.length > 0

  const resetPagination = () => dispatch(resetCaseHistoryPagination)
  const setParams = useCallback((state) => { 
      if( !('pagination' in state) ){
        state = {...state, pagination: { page: 0, pageSize: 10 }}
      }

      dispatch(setCaseHistoryFilters(state)); 
    }, [dispatch]);
  
  const handleSortModelChange = (sortModel) => {

    const {field, sort} = sortModel[0] || {}

    let order = field

    if(field === 'patient'){
        order = 'patient__first_name'
    }else if (field === 'site_name'){
        order = 'patient__site__site_name'
    }   

    if(sort === 'desc'){
        order = '-' + order
    }
    
    resetPagination()
    setParams({ordering:order})

  };

  const handlePaginationChange = (pageModel) => setParams({pagination:pageModel}) 


  const handleCellClick = ({field, row}) => {
    if (field.includes('__') || field === 'patient') return

    else{
      navigate(`/caseload/case-details/${row.id}`)
    }
  }

  const handleRowSelection = useCallback((newSelectionModel) => {               

    setSelected(newSelectionModel)
    
  } ,[setSelected, data])

  return (
    <>
      <Box className="flex-row-between">
        <HistoryFilters 
            queryOptions={queryOptions}
            setParams={setParams}
        />
      </Box>
      <Box sx={(theme) => ({ ...theme.standardBox})}>
        <DataGridPro
          pagination
          columns={columns}
          rows={data?.results || []}
          columnHeaderHeight={isSelected? 0 : 56}
          rowCount={rowCountState}
          onCellClick={handleCellClick}
          loading={isLoading || isFetching}
          sortingMode="server"
          paginationMode="server"
          pageSizeOptions={[5, 10, 15, 20, 50]}
          initialState={{
            sorting: {
              sortModel: [{ field: 'date_submitted', sort: 'desc' }],
            },
          }}
          onSortModelChange={handleSortModelChange}
          paginationModel={queryOptions.pagination}
          onPaginationModelChange={handlePaginationChange}
          disableColumnMenu
          slots={{
            noRowsOverlay: DefaultNoRowsOverlay,
            toolbar: isSelected? HistoryToolbar : null,
          }}
          slotProps={{
            noRowsOverlay: { message: 'No cases' },
            toolbar: { selected, setSelected }
          }}
          sx={{
            border:'1px solid rgba(255,255,255,0)',
            ...linkedRowProps,
          }}
          checkboxSelection
          disableMultipleRowSelection
          isRowSelectable={() => queryOptions?.resolution_status === 'pending_results'}
          rowSelectionModel={selected}
          onRowSelectionModelChange={handleRowSelection}
        />
      </Box>
    </>
  );
}
