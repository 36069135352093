import { useEffect, useState } from "react";





const useCountDown = (totalTime = 10) => {
    const updateInterval = 100; //ms, looks smoother
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const newProgress = prevProgress - (updateInterval / 1000) * (100 / totalTime);
                return newProgress >= 0 ? newProgress : 0; // Ensure progress does not go below 0
            });
        }, updateInterval);

        if (progress <= 0) {
            clearInterval(interval);
          }

        return () => clearInterval(interval);
    }, []);

    return progress
}


export default useCountDown;