import { styled, Switch } from "@mui/material";

const StyledWarningSwitch = styled(Switch)(({ theme: { palette } }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: palette.warning.main,
      '& + .MuiSwitch-track': {
        backgroundColor: palette.warning.main,
      },
      '&.Mui-disabled': {
        color: '#e651008a', 
        '& + .MuiSwitch-track': {
          backgroundColor: palette.warning.light, 
        },
      },
    },
    '&:not(.Mui-checked)': {
      color: palette.primary.main,
      '& + .MuiSwitch-track': {
        backgroundColor: palette.primary.main,
      },
      '&.Mui-disabled': {
        color: 'rgb(167, 202, 237)', 
        '& + .MuiSwitch-track': {
          backgroundColor: palette.primary.light, 
        },
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: palette.primary.main,
  },
}));

export default StyledWarningSwitch;
