import { useLogoutMutation, useManageUserImpersonationMutation } from "../../../../../features/auth/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { Menu, ListItem, ListItemButton, ListItemIcon, ListItemText, Chip } from "@mui/material";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import { logOut, selectIsImpersonating } from "../../../../../features/auth/authSlice";
import { useState } from "react";
import ImpersonateModal from "./ImpersonateModal/ImpersonateModal";
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';


const ExpandableListItem = ({ open, label, title, handleClick, icon, endComponent }) => (
  <ListItem disablePadding title={title}>
    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 }} onClick={handleClick}>
      <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }} >
        {icon}
      </ListItemIcon>
      <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
      {open && endComponent}
    </ListItemButton>
  </ListItem>
)






export default function UserMenu({
  open,
  isMenuOpen,
  anchorEl,
  handleClose,
  handleDarkMode,
  user
}) {

  const isImpersonating = useSelector(selectIsImpersonating)
  const [logoutMutation, { isLoading: isLoggingOut }] = useLogoutMutation()
  const [handleImpersonate, { isLoading }] = useManageUserImpersonationMutation()
  const [openDitto, setOpenDitto] = useState(false)
  const dispatch = useDispatch();


  const logUserOut = async () => {
    logoutMutation()//logout request to the server
      .unwrap()
      .then(res => dispatch(logOut()))
  }

  const handleDarkModeClick = () => {
    handleDarkMode()
    handleClose()
  }


  const handleImpersonateClick = () => {
    setOpenDitto(prev => !prev)
    handleClose()
  }

  const handleStopImpersonate = () => {
    handleImpersonate({
      userId: user?.id,
      method: 'DELETE'
    })
    .unwrap()
    .then(res => window.location.reload())
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={isMenuOpen}
        onClose={handleClose}
        sx={{ width: !open ? '100px' : '400px' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {
          user?.groups?.Developer &&
              <ExpandableListItem
                open={open}
                label={'Impersonate'}
                title='Impersonate a user'
                handleClick={handleImpersonateClick}
                icon={<FaceRetouchingNaturalIcon />}
                endComponent={<Chip color="warning" label='Dev only' size="small" sx={{ ml: 3 }} />} />
        }
        {
          isImpersonating &&
            <ExpandableListItem
              open={open}
              label={'Stop impersonate'}
              title='Stop impersonating a user'
              handleClick={handleStopImpersonate}
              icon={<FaceRetouchingOffIcon />}
              endComponent={<Chip color="warning" label='Dev only' size="small" sx={{ ml: 3 }} />} />
        }
        <ExpandableListItem
          open={open}
          label={'Dark mode'}
          title='Dark mode'
          handleClick={handleDarkModeClick}
          icon={<DarkModeOutlinedIcon />} />

          <ExpandableListItem
            open={open}
            label={'Logout'}
            title='Logout'
            handleClick={logUserOut}
            icon={<LogoutOutlinedIcon />} />

      </Menu>
      {
        user?.groups?.Developer &&
        <ImpersonateModal open={openDitto} handleClose={() => setOpenDitto(false)} />
      }
    </>
  )
}