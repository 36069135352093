import { Button, Box, ButtonGroup,  Typography } from "@mui/material";
import { useState } from "react";
import { dateRangeToString } from "../../../../../../utils/dateUtils/dateRangeToString";
import { getIsDateRange } from "../../../../../globalComponents/DateRange/utils/getIsDateRange";
import SpotlightBarChart from "./ChartViews/SpotlightBarChart";
import SpotlightDoughnutChart from "./ChartViews/SpotlightDoughnutChart";









export default function StatSpotlighCharts({stat, dates}){

    const isDateRange = getIsDateRange(dates)
    const [view , setView] = useState('Trend')
    
    const handleClick = (val) => (e) => setView(val)
    const getVariant = (val) => val === view? 'contained' : 'outlined'

    return(
        <Box sx={(theme) => ({ ...theme.standardBox, height:346, p:2})}>
            <Box className='flex-row-between'>
                <Box className='flex-col'>
                    {
                    isDateRange &&
                        <>
                            <Typography variant="subtitle2">{stat}</Typography>
                            <Typography variant="body2">{dateRangeToString(dates[0], dates[1])}</Typography>
                        </>
                    }
                </Box>
                <ButtonGroup size="small" sx={{height:34}}>
                    <Button 
                        sx={{borderRadius:'30px 0px 0px 30px'}} 
                        onClick={handleClick('Trend')}
                        disableElevation variant={getVariant('Trend')}
                        >Trend</Button>
                    <Button 
                        sx={{borderRadius:'0px 30px 30px 0px'}} 
                        onClick={handleClick('Site')}
                        disableElevation variant={getVariant('Site')}
                        >Sites</Button>
                </ButtonGroup>
            </Box>     
            {
                view === 'Trend'?
                    <SpotlightBarChart dates={dates} stat={stat} isDateRange={isDateRange}/>  
                :
                view === 'Site'?
                    <SpotlightDoughnutChart dates={dates} stat={stat}/>
                : 
                    null
                
            }
        </Box>
    )
}