import dayjs from 'dayjs';
import CustomTextFieldCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell";
import CRUDDataGrid from '../../../../../globalComponents/DataGrid/CRUDDataGrid';
import { useManageAllergyMutation } from '../../../../../patients/patientAPISlice';
import { useState } from 'react';
import CustomDateFieldCell from '../../../../../globalComponents/DataGrid/subComponents/customCells/CustomDateFieldCell';
import { AllergyIcon } from '../../../../../../assets/icons/MaterialDesignIcons';



export default function Allergies({readOnly, allergies}){



    const columns = [
      {
        field: 'allergy_name',
        headerName: 'Allergy',
        flex:1,
        editable:!readOnly,
        headerClassName: readOnly? '' : 'required-col',
        renderEditCell: (params) => (
          <CustomTextFieldCell {...params} />
        ),
      },
      { 
        field: 'date_onset', 
        headerName: 'Date Onset',
        flex:1,
        editable:!readOnly,
        type: 'date',
        valueFormatter: (value) => value? dayjs(value).format('MMM DD, YYYY') : null,
        renderEditCell: (params) => (
          <CustomDateFieldCell {...params} />
        )
      },
      { 
        field: 'reactions', 
        headerName: 'Reactions',
        flex:2,
        editable:!readOnly 
      },
      ]



    return (
        <CRUDDataGrid
          title={'Allergies'}
          columns={columns} 
          initRows={allergies} 
          mutation={useManageAllergyMutation}
          GridIcon={AllergyIcon}
          fieldToFocus='allergy_name'
          requiredFields={['allergy_name']}
          isReadOnly={readOnly}
          />     
  )
}

