import AddIcon from '@mui/icons-material/Add';
import { randomId, } from '@mui/x-data-grid-generator';
import { Box, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetAddingItemStatus } from '../hooks/subscriptions';
import { useRef } from 'react';




export default function BaseFooter({ 
  apiRef, 
  isReadOnly, 
  handleAddRow
  }) {

  const isAddingItem = useGetAddingItemStatus(apiRef);


  
  return (
    <>
      <Divider/>
      <Box sx={(theme) =>({ borderTop: `1px solid ${theme.divider}`, display:isReadOnly? 'none':''})}>
        <LoadingButton 
          onClick={handleAddRow} 
          startIcon={<AddIcon />}
          disabled={ isAddingItem }
          loadingPosition="start">
          Add
        </LoadingButton>
      </Box>
    </>
  );
}
  