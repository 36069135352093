import React from 'react'
import BreadCrumbHeader from '../../../globalComponents/Headers/BreadCrumbHeader'
import { stringToTitleCase } from '../../../../utils/stringUtils/titleCase'
import { Box, Button } from '@mui/material'

const ProfileHeader = ({user, tabValue}) => {


return (
    <BreadCrumbHeader
    title={'Profile'}
    helperText={`Welcome to your profile dashboard, ${user.firstName ? stringToTitleCase(user.firstName) : ''}`}
    crumbs={[tabValue]}
    endComponent={
        tabValue === 'My payslips' ? 
        <Box className='flex-row-center'>
        <Button 
            sx={{borderRadius:'18px'}}
            variant="outlined"
            disableElevation
            >
                Go to QuickBooks
        </Button>
    </Box>
    :
    null
    }
    />
)
}

export default ProfileHeader
