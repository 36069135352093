



export default function snakeCaseToSentence(text) {
    return text
        .split('_') 
        .map((word, index) =>
            index === 0
                ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() 
                : word.toLowerCase() 
        )
        .join(' '); 
}
