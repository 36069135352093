import {useTheme} from "@emotion/react"
import { Box, Button, Typography, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import { useEffect, useState } from "react";
import { useSendFaxRecordMutation, useGetFaxRecordsQuery} from "../../rosterAPISlice";
import faxModalGraphic from '../../../../assets/icons/fax-graphic.svg'
import {  useDispatch } from "react-redux";
import {enqueueSnackbar} from 'notistack';
import { useParams } from "react-router-dom";
import HaloModal from "../../../globalComponents/Modals/HaloModal";
import { useForm } from "react-hook-form";
import { HaloNumberField } from "../../../globalComponents/ReactHookFormFields";
import { formatDateTimeField } from "../../../../utils/dateUtils/formatDateTimeField";
import { apiSlice } from "../../../../app/api/apiSlice";
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton";


const FaxModal = ({open, setOpen, patientId}) => {

const theme = useTheme()
const [sendFaxRecord, {isLoading:isFaxing}] = useSendFaxRecordMutation()
const params = useParams()
const {data} = useGetFaxRecordsQuery(patientId, {skip:!open})
const dispatch = useDispatch()

const [faxRecords, setFaxRecords] = useState(data)

useEffect(() => {
    setFaxRecords(data)
},[data])

const defaultValues = {
    fax_number: null,
}

const {control, getValues, trigger, reset} = useForm({defaultValues})

const handleClose = () => {
    setOpen(false)
    reset()
}

const sendFaxHandler = async (event) => {
    event.preventDefault()
    let valid = await trigger()
    if(!valid) return;

    let {fax_number} = getValues()

    let caseType = params.caseType || params.encounterType

    const payload = {
        case_type: caseType.toLowerCase().includes('pathology') ? 'PATHOLOGY' : 'INFECTIOUS DISEASE',
        fax_number: fax_number,
        case_id: params.caseId || null,
        encounter_id: params.encounterId || null
    }

    sendFaxRecord(payload)
    .unwrap()
    .then(res => {
        reset({fax_number: ''})
        setFaxRecords((prevFaxRecords) => ([...prevFaxRecords, res.new_fax]))
        dispatch(apiSlice.util.updateQueryData('getFaxRecords', patientId, (faxData) => {faxData.push(res.new_fax)}))
        enqueueSnackbar(res?.message, {variant:'success'})
    })
    .catch((err) => {
        enqueueSnackbar('Error faxing case, please try again', {variant:'error'})
    })
}

return (
    <HaloModal title='Fax document to provider' handleClose={handleClose} modalMaxWidth={560} open={open} >
        <Box className='flex-col' gap={2} p={2}>
            <Box className='flex-row-center' gap={2} p={'16px 0px'}>
                <img src={faxModalGraphic} alt='upload graphic' width={75} height={64}/>
            </Box>

            <Box className='flex-col' gap={2} component={'form'} noValidate onSubmit={sendFaxHandler}>
                    <Typography sx={{...theme.typography.body2}}>Compose fax details</Typography>
                    <HaloNumberField
                        required
                        control={control}
                        name='fax_number'
                        variant='phone'
                        label='Fax number'
                        InputProps={{sx: {fontSize: '14px'}}}
                        InputLabelProps={{sx:{fontSize: "14px"}}} 
                    />
            </Box>

            <Box sx={{...theme.standardBox,}}>
                <Box sx={{
                    p:'8px 16px',
                    justifyContent:'space-between',
                    height:36,
                }}>
                    <Typography variant="body2" sx={{ color: theme.palette.text.primary}}>Fax records</Typography>
                </Box>
                <Divider/>
            {/* look into making this table a global reusable component */}
            <TableContainer sx={{ maxHeight:208 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow sx={{'& .MuiTableCell-root': {height: 40, padding: '8px 16px', background:theme.palette.background.paper}}}>
                            <TableCell sx={{width: 184}}>Time</TableCell>
                            <TableCell sx={{width: 128}}>Fax number</TableCell>
                            <TableCell>User</TableCell>
                        </TableRow> 
                    </TableHead>
                    <TableBody>
                        {faxRecords?.map((fax, index) => 
                            <TableRow 
                                key={fax.id} 
                                sx={{ 
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    '& .MuiTableCell-root': {height: 40, padding: '12px 16px'},
                                    backgroundColor: index % 2 === 0 ? theme.palette.primary.shades4 : 'inherit',
                                }}
                            >
                                <TableCell sx={{maxWidth: 184}}>{formatDateTimeField(fax.created_at)}</TableCell>
                                <TableCell sx={{maxWidth:128}}>{fax.number}</TableCell>
                                <TableCell>{fax.user.name}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>    
            </Box>                
        </Box>
        <Box className='flex-row' gap={1} sx={{ justifyContent: 'flex-end', alignItems: 'center', p: '8px 16px 16px 16px' }}>
            <ActionButton sx={{color: theme.palette.text.primary, p: '6px 8px'}} onClick={handleClose}>Cancel</ActionButton>
            <ActionButton loading={isFaxing} variant='contained' type='submit' onClick={sendFaxHandler}>Send fax</ActionButton>
        </Box>
        
    </HaloModal>

)}

export default FaxModal
