import { Box, Divider } from "@mui/material";
import { selectCurrentUser } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../components/AdminHeader/AdminHeader";
import PatientListPanel from "../../patients/admin/components/PatientListPanel/PatientListPanel";
import QAReviewPanel from "../../qualityAssurance/components/QAReviewPanel/QAReviewPanel";
import TrialUserListPanel from "../../trialUsers/components/TrialUserPanel/TrialUserListPanel";
import { selectCurrentAdminTab, setAdminTab } from "../adminSlice";
import RosterAdminPanel from "../../rosterManagement/admin/components/RosterAdminPanel/RosterAdminPanel";
import AdminTabs from "../components/Tabs/AdminTabs";
import DataIntakePanel from "../../dataIntake/components/DataIntakePanel/DataIntakePanel";
import MDReviewPanel from "../../MDReview/components/MDReviewPanel/MDReviewPanel";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";



export default function AdminHome(){

    const dispatch = useDispatch()
    const [searchParam] = useSearchParams()
    const user = useSelector(selectCurrentUser)
    const tabValue = useSelector(selectCurrentAdminTab) 
    const navigate = useNavigate()
    
    useEffect(() => {   
        
        const tabParam = searchParam?.get('tab')
        const isAllowed = user?.groups?.adminGroupTabs?.includes(tabParam) || user?.groups?.Developer
        
        if (!user?.groups?.adminGroupTabs) return

        if (tabParam && isAllowed){
            dispatch(setAdminTab(tabParam))
        }
        else if (!isAllowed && tabParam){
            enqueueSnackbar(`Not allowed access to the "${tabParam}" tab`, {variant:'error'})
            navigate('/admin?tab=' + tabValue)
        }
    },[user?.userGroups?.adminGroupTabs])

    const handleTabChange = (event, newValue) => {
        navigate('/admin?tab=' + newValue )
        dispatch(setAdminTab(newValue))
    }
    
    return(
        <>
        <AdminHeader user={user} tabValue={tabValue}/>
        <Box sx={(theme) => ({background:theme.palette.background.paper})}>

            <AdminTabs
                tabValue={tabValue}
                handleTabChange={handleTabChange}
                userGroups ={user.groups}
                />
            <Divider/>
        </Box>

        <Box className='flex-col' p={3} gap={2} >

        {
            tabValue === 'Roster mgmt.'?
                <RosterAdminPanel user={user}/>
            :
            tabValue === 'Patients'?
                <PatientListPanel user={user}/>
            : 
            tabValue === 'Trial users' ? 
                <TrialUserListPanel user={user}/> 
            :        
            tabValue === 'QA cases' ?
                <QAReviewPanel user={user}/>
            :
            tabValue === 'Data intake' ?
                <DataIntakePanel user={user}/>
            :
            tabValue === 'MD Review'?
                <MDReviewPanel />
            :
                null
        }
        </Box>



        </>

    )
}
