import IconButton from '@mui/material/IconButton';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import HaloLogo from '../../../assets/icons/BeyondMD/haloLogo.png'
import BeyondMDLogo from '../../../assets/icons/BeyondMD/beyondMD.png'
import BeyondMDDarkLogo from '../../../assets/images/beyondMD/beyondMDDark.png'
import { Box } from '@mui/material';
import SideBarDrawer from './SideBarDrawer/SideBarDrawer';
import SideBarNavigation from './SideBarNavigation/SideBarNavigation';




function SideBar({ darkMode, setDarkMode, open, setOpen }) {
  const theme = useTheme();
  const location = useLocation()
  const isAtLogin = location.pathname === '/' || location.pathname.includes('/login') || location.pathname.includes('/password')
  const isAtBAAOrTrialSignUp = location.pathname.includes('referral/sign-up') || location.pathname.includes('baa-consent')


  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode
      localStorage.setItem('dark_mode', newMode)
      return newMode
    })
  }

  //hide side bar when logged out
  if (isAtLogin || isAtBAAOrTrialSignUp) return null;

  const iconButtonProps = {
    className: open ? 'flex-row' : 'flex-row-center',
    onClick: open ? handleDrawerClose : handleDrawerOpen,
    sx: {
      p: 0,
      pl: open ? 1 : 0,
      height: '100%',
      width: '100%',
      justifyContent: open ? 'flex-start' : 'center',
      borderRadius: open ? 2 : '50%'
    }
  };

  return (

    <SideBarDrawer
      variant="permanent"
      open={open}
      sx={{
        background: theme.palette.background.paper,
        p: 1
      }}>
      <Box
        height={64}
        p={'8px 8px 0px 8px'}
      >
        <IconButton {...iconButtonProps}>
          {/* Need to get proer PNG images, going to have to deal with bootleg styling in the meantime */}
          <img
            src={open ? (darkMode ? BeyondMDDarkLogo : BeyondMDLogo) : HaloLogo}
            height={open ? (darkMode ? 32 : 41) : '32px'}
            width={open ? (darkMode ? 181 : 235) : '32px'}
            style={{ marginLeft: darkMode ? 0 : open ? -7 : -2 }}
            alt={open ? 'BeyondMD Logo' : 'Halo Logo'}
          />
        </IconButton>
      </Box>
      <SideBarNavigation
        open={open}
        darkMode={darkMode}
        handleDarkMode={handleDarkMode} />
    </SideBarDrawer>
  );
}

export default memo(SideBar)
