import { Box, Divider } from "@mui/material";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro";
import { useEffect, useState } from "react";
import { useDispatch} from "react-redux";
import { setRosterMgmtDates, setRosterMgmtPreset } from "../../../../../../admin/adminSlice";
import { ActionButton } from "../../../../../../globalComponents/Buttons/ActionButton/ActionButton";
import { PresetDateList } from "./PreSelectDateMenu/PresetDateList";



export function RosterDatePickingPopover({
    handleClose,
    handleCancel,
    dates,
    setDates,
    selectedPreset,
    setPresetValue,
}){

    const dispatch = useDispatch()

    
    const updateStoreDates = () => {
        dispatch(
            setRosterMgmtDates(
            dates?.map(date => date? date?.format('YYYY-MM-DD'): null)
            )
        )
        dispatch(
            setRosterMgmtPreset(selectedPreset)
        )
        handleClose()
    }

  


    return(
        <Box>
            <Box className='flex-row' >
                <DateRangeCalendar
                    value={dates}
                    onChange={(newValue) => {
                        setDates(newValue)
                        setPresetValue('')
                    }}
                />
                <Divider orientation="vertical" flexItem/>
                <PresetDateList 
                    setDates={setDates} 
                    selectedPreset={selectedPreset} 
                    setPresetValue={setPresetValue}/>
            </Box>
            <Divider/>
            <Box className='flex-row' gap={1} justifyContent='flex-end' alignItems='center' p={1}>
                <ActionButton variant='outlined' onClick={handleCancel}>Cancel</ActionButton>
                <ActionButton variant='contained' onClick={updateStoreDates}>Update</ActionButton>
            </Box>
        </Box>
    )


}