import { Box, Typography, Divider } from "@mui/material"
import { TitleAndBodyBox } from "../../../globalComponents/TextGroups/TitleAndBodyBox"

function TrialClinicianInfoBox({ theme, trialUserInfo }) {

    const { name, email, address, city, state, zip, phone, personal_email, trial_type } = trialUserInfo

    return (

        <Box sx={{ ...theme.standardBox, width: '65%' }}>
            <Typography sx={{ ...theme.typography.subtitle2, padding: '12px 16px' }}>Contact</Typography>
            <Divider />
            <Box className='flex-row' gap={3} p={2}>
                <Box className='flex-col' gap={2}>
                    <TitleAndBodyBox title={'Name'} body={name} />
                    <TitleAndBodyBox title={'Personal email'} body={personal_email} />
                    <TitleAndBodyBox title={'Address'} body={`${address} ${city}, ${state} ${zip}`.includes('null' || 'undefined') ? '-' : `${address} ${city}, ${state} ${zip}`} />
                </Box>
                <Box className='flex-col' gap={2}>
                    <TitleAndBodyBox title={'Phone number'} body={phone} />
                    <TitleAndBodyBox title={'Work email'} body={email} />
                </Box>
                <Box className='flex-col' gap={2}>
                    <TitleAndBodyBox title={'Trial type'} body={trial_type} />
                </Box>
            </Box>
        </Box>
    )
}

export default TrialClinicianInfoBox
