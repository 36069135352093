import { Box, IconButton, Typography, Link } from "@mui/material"
import { ActionButton } from "../../globalComponents/Buttons/ActionButton/ActionButton"
import { HaloTextField } from "../../globalComponents/ReactHookFormFields"
import { useForm } from "react-hook-form";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom"
import axiosAuth from "../../../utils/apiUtils/axiosAuth";
import DismissibleAlert from "../../globalComponents/Alert/DismissibleAlert";
import { useState } from "react";
import { show400Error } from "../../../app/errors/genericErrors";


function StandardLogin({ setIsStandardLogin }) {

    const { control, handleSubmit, getValues, reset } = useForm({
        defaultValues: { username: '', password: '' }
    })

    const [invalidCreds, setInvalidCreds] = useState(false)
    const [errorObj, setErrorObj] = useState({})

    const navigate = useNavigate()

    const handleLogin = () => {

        const { username, password } = getValues()

        axiosAuth.post('login', { username, password } )
        .then(() => {
            navigate('/trial-users/dashboard')
        })
            .catch((err) => {
                if (err.response.status === 400) {
                    setErrorObj({
                        title: 'Login error',
                        message: err.response.data.detail
                    })
                }
                else {
                    setErrorObj({
                        title: 'Invalid credentials',
                        message: 'You have entered an invalid username or password'
                    })
                }
                setInvalidCreds(true)
            })
    }

    return (
        <Box
            className='flex-col'
            gap={3}
            width={'100%'}
            component='form'
            noValidate
            onSubmit={handleSubmit(handleLogin)}>
            <Box className='flex-row-flex-start' sx={{ alignItems: 'center' }}>
                <IconButton onClick={() => setIsStandardLogin(false)} >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant='subtitle1'>Back to Employee login</Typography>
            </Box>
            {invalidCreds
                && <DismissibleAlert
                    severity={'error'}
                    title={errorObj.title}
                    msg={errorObj.message}
                    optionalDismissLogic={() => setInvalidCreds(false)}
                />}
            <Typography variant='subtitle1'>Referral account sign in</Typography>
            <HaloTextField name='username' label='Username' control={control} maxLength={300} required />
            <HaloTextField name='password' label='Password' type={'password'} variant={'password-login'} control={control} maxLength={300} required />
            <Link href='#' variant='subtitle2' onClick={() => navigate('/password-recovery')}>Forgot your password?</Link>
            <Box className='flex-row-flex-end'>
                <ActionButton variant='contained' type='submit' sx={{ width: '40%' }}>Sign in</ActionButton>
            </Box>
        </Box>
    )
}

export default StandardLogin
