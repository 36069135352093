import { useState } from "react";
import { HaloAsyncObjAutocomplete } from "../../../../../globalComponents/ReactHookFormFields/AutocompleteFields/HaloAsyncObjAutocomplete";
import { useGetCustomTemplatesQuery } from "../../../../dataIntakeAPISlice";
import { HaloTextField } from "../../../../../globalComponents/ReactHookFormFields/TextFields/HaloTextField";
import { Box, Switch, Typography } from "@mui/material";
import Collapse from '@mui/material/Collapse';








export default function DemographicsUpload({
    control,
    templateTypes
}){



    const [ isNew, setIsNew ] = useState(false)
    const handleSwitch = (e) => setIsNew(e.target.checked)

    

    return(
        <>  

            <HaloAsyncObjAutocomplete 
                name='template'
                label='Template'
                control={control}
                query={useGetCustomTemplatesQuery}
                queryParams={{
                    type:templateTypes?.find(type => type.name === 'demographics')?.id
                }}
                required={!isNew}
                disabled={isNew}
                optionKey='template_name' 
                freeSolo
                disablePortal
                />
                    <Box className='flex-col' gap={1}>
                        <Box className='flex-row-between' >
                            <Typography variant="body1" color='text.secondary'>Create new demographics template</Typography>
                            <Switch size='small' checked={isNew} onClick={handleSwitch}/>
                        </Box>
                        <Collapse in={isNew}>
                            <HaloTextField 
                                name='template_name'
                                label='Template name'
                                required={isNew}
                                control={control}

                            />
                        </Collapse>
                        
                    </Box>

        </>
    )
}