import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import SuccessImg from "../../../../assets/images/generic/pathologySuccess.png"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from "react-router-dom";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { useEffect, useState } from "react";
import Confetti from 'react-dom-confetti';
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton";


const config = {
    angle: "100",
    spread: "150",
    startVelocity: "30",
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "844px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

export default function TrialSuccessMessage({ patient, encounterType }) {

    const theme = useTheme()
    const [confetti, setConfetti] = useState({ one: true, two: true });
    const [count, setCount] = useState(0); //tracks how many times confetti pops
    const navigate = useNavigate()

    useEffect(() => {
        const interval = setInterval(() => {
            if (count >= 2) {// limit of 8 means each confetti component 'explodes' 4x
                clearInterval(interval);
            } else {
                setConfetti(prevConfetti => ({
                    one: !prevConfetti.one,
                    two: !prevConfetti.two
                }));
                setCount(prevCount => prevCount + 1);
            }
        }, 400);

        return () => {
            clearInterval(interval);
        }
    }, [count]);

    return (
        <Box className='flex-col-center' sx={{ ...theme.shadowedBox, gap: 3, overflow: 'hidden' }} >
            <Box className='flex-col-center' >
                <Box p={'22px 16px'}>
                    <CheckCircleIcon sx={{ color: theme.palette.success.main, height: 35, width: 35, }} />
                </Box>
                <Box className='flex-col-center' gap={1}>
                    <Typography variant='h5' sx={{ fontWeight: 600 }}>Success!</Typography>
                    <Typography variant='body1'> {`Trial ${encounterType} Review has been submitted for ${stringToTitleCase(patient?.name)}`}</Typography>
                </Box>
            </Box>
            <Box className='flex-row' >
                <Confetti active={confetti.one} config={config} />
                <img src={SuccessImg} alt='Success' height={288} width={239} />
                <Confetti active={confetti.two} config={config} />
            </Box>
            <Box className='flex-row-center' gap={2} marginBottom={3}>
                <ActionButton
                    variant='contained'
                    color='primary'
                    size='medium'
                    onClick={() => navigate('/trial-users/dashboard', { state: { fromEncounterSubmit: true } })}
                    startIcon={<AssignmentOutlinedIcon />}>
                    Back to dashboard
                </ActionButton>
            </Box>
        </Box>
    )
}
