import Analysis from "./Analysis/Analysis"
import DataInput from "./DataInput/DataInput"
import Recommendations from "./Recommendations/Recommendations"







export default function GenomicsReviewContainer({ data, isUnder18 }){


    const { encounter, qa_obj } = data || {}
    const { patient } = encounter || {}

    return(
        <>
            <DataInput 
                meds={patient?.medications}
                diagnoses={patient?.icd10_references}
                genes={encounter?.genes}
                allergies={patient?.allergies}
            />
            <Analysis 
                labValues={encounter?.lab_values}
                imagingValues={encounter?.imaging_values}
                drugInteractions={encounter?.drug_interactions}
                qaObj={qa_obj}
            />
            <Recommendations 
                isUnder18={isUnder18}
                encounter={encounter}
                qaObj={qa_obj}/>
            
        </>
    )

}