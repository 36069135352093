import React, { useEffect, useState } from 'react'
import { Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useTheme } from "@emotion/react";
import { ActionButton } from '../../globalComponents/Buttons/ActionButton/ActionButton';
import ModalWithHaloLogo from '../../auth/components/ModalWithHaloLogo';
import Signature from '../../globalComponents/Signature/Signature';
import { useSignBAADocMutation } from '../trialUserAPISlice';
import openDocInNewTab from '../../../utils/apiUtils/openDocInNewTab';
import { useLogoutMutation } from '../../auth/authApiSlice';
import { logOut } from '../../auth/authSlice';
import { useDispatch } from 'react-redux';
import { setBAAConsent } from '../../auth/authSlice';


const ConfirmDenyDialog = ({ open, denyHandler, handleClose, theme }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Logout warning</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You will be logged out as you need a signed Business Associate Agreement (BAA) to continue using our software program.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    onClick={denyHandler}
                    variant='contained'
                    sx={{ backgroundColor: theme.palette.error.main }}>
                    Confirm deny and logout
                </ActionButton>
                <ActionButton
                    onClick={handleClose}
                    variant='contained'
                    sx={{ backgroundColor: theme.palette.success.main }}>
                    Close and sign BAA
                </ActionButton>
            </DialogActions>
        </Dialog>)

}

function BAAConsent({ }) {

    const theme = useTheme()
    const dispatch = useDispatch()

    const [logoutMutation] = useLogoutMutation()
    const [BAAViewed, setBAAViewed] = useState(false)
    const handleBAAClick = () => openDocInNewTab(`trial-users/baa`, () => setBAAViewed(true))
    const [consentAndSign] = useSignBAADocMutation()

    const [open, setOpen] = useState(false)
    const handleCloseDialog = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false)
    }
    const handleOpenDialog = () => setOpen(true)
    const denyHandler = () => {
        logoutMutation()
            .then(() => dispatch(logOut()))
    }

    
    return (
        <>
            <ModalWithHaloLogo sx={{maxWidth: 560}}>
                <Box className='flex-col' width={'100%'} gap={2} >
                    <Typography sx={{ ...theme.typography.subtitle1, fontWeight: 500 }}>Disclaimer</Typography>
                    <Typography sx={{ ...theme.typography.body2 }}>
                        In order to continue using our software program, you must sign a Business Associate Agreement (BAA) with us. This agreement is required by law under the Health Insurance Portability and Accountability Act (HIPAA) to ensure that we protect the privacy and security of patient protected health information (PHI).
                        <br />
                        <br />
                        Please note that failure to sign the BAA will result in your inability to access the software program.
                    </Typography>

                    <ActionButton
                        width={'100%'}
                        variant='contained'
                        onClick={handleBAAClick}>
                        View BAA PDF
                    </ActionButton>

                    <Typography sx={{ ...theme.typography.subtitle1, fontWeight: 500 }}>Electronic signature consent</Typography>
                    <Typography sx={{ ...theme.typography.body2 }}>We offer you the option to provide your electronic signature for any relevant documents that require your signature. By consenting to use an electronic signature, you acknowledge that you have read and understood the terms and conditions of using an electronic signature and agree to be legally bound by them.
                        <br />
                        <br />
                        If you choose to provide your electronic signature, you will be able to sign documents quickly and easily without the need for printing, scanning, or mailing physical copies. Your electronic signature will be considered as valid and binding as your physical signature.
                        <br />
                        <br />
                        The BAA PDF must be viewed prior to consenting and signing.
                    </Typography>
                    <Signature apiCallback={consentAndSign} optionalSignBtnDisable={BAAViewed} optionalSuccessURL='/trial-users/dashboard' optionalReducer={setBAAConsent} denyHandler={handleOpenDialog} />
                </Box>
            </ModalWithHaloLogo>
            <ConfirmDenyDialog open={open} denyHandler={denyHandler} handleClose={handleCloseDialog} theme={theme} />
        </>
    )
}

export default BAAConsent
