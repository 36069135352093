import { Box, Typography, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import {useNavigate} from "react-router-dom"








export default function NotFound404(){

    const theme = useTheme()
    const navigate = useNavigate()


return(

    <Box className='flex-col-center' sx={{width: '100%', height: '100vh'}}>
        <Box className='flex-row-center' sx={{ m:'0 auto', height: '100%' }}>
            <Box className='flex-col-center' sx={{height:'80%'}} gap={3}>
                <Box className='flex-col-center' sx={{minHeight: 0}}>
                    <img style={{display: 'block', width: '100%', height: '80%', objectFit:'cover'} }src="/images/404.svg" alt='sad cat'/>
                </Box>
                <Box className='flex-col-center' maxWidth={598} gap={2}>
                    <Typography variant='h5'>Page Not Found</Typography>
                    <Typography sx={{textAlign:'center'}}>Oops! The page you're looking for doesn't exist. Please check the URL or go back to the homepage. </Typography>
                    <Button variant='contained' disableElevation onClick={() => navigate('/dashboard')} sx={{color: theme.palette.primary.contrast, backgroundColor: theme.palette.primary.main, borderRadius: '21px', p: '8px 22px'}}>Go back home</Button>
                </Box>
            </Box>
        </Box>
    </Box>
)


}
