import { Box, Menu } from "@mui/material";
import { ActionButton } from "../Buttons/ActionButton/ActionButton";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useState } from "react";
import DatePickingPopover from "./DatePickingPopover";
import { useSelector } from "react-redux";
import dayjs from "dayjs"

function DateRangeButton({ storedDateRangeSelector, setDateRangeReducer, buttonTitle }) {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const { start_date, end_date } = useSelector(storedDateRangeSelector)

    const [displayedDateRange, setDisplayedDateRange] = useState(
        !start_date && !end_date ?
            buttonTitle :
            (start_date && end_date) && (dayjs(start_date).date() !== dayjs(end_date).date()) ?
                [dayjs(start_date).format('LL'), dayjs(end_date).format('LL')].join(' - ') :
                dayjs(start_date).format('LL'))

    return (
        <Box>
            <ActionButton
                onClick={handleClick}
                variant='contained'
                startIcon={<CalendarMonthOutlinedIcon />}
            >{displayedDateRange}</ActionButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                keepMounted
                onClose={handleClose}
                sx={(theme) => ({
                    '& .MuiPopover-paper': { boxShadow: theme.customShadows.elevation8, borderRadius: 2 },
                    'ul': { p: 0 }
                })}>
                <DatePickingPopover
                    start_date={start_date}
                    end_date={end_date}
                    setDateRangeReducer={setDateRangeReducer}
                    setDisplayedDateRange={setDisplayedDateRange}
                    handleClose={handleClose}
                    buttonTitle={buttonTitle}
                />
            </Menu>
        </Box>
    )

}

export default DateRangeButton
