import { apiSlice } from "../../app/api/apiSlice";

export const qualityAPISlice = apiSlice
.enhanceEndpoints({addTagTypes:['QA List', 'QA Review']})
.injectEndpoints({
    endpoints: builder => ({
        getQAReviewerList: builder.query({
            query: ({pagination, ordering, search, review_state}) => {
                let url = `qa/reviewer/list/`
                let queryParams = `?page=${pagination?.page + 1}&ordering=${ordering}&size=${pagination.pageSize}&search=${search}&review_state=${review_state}`

                return url + queryParams
            },
            providesTags:['QA List'],
        }),
        getQAReview: builder.query({
            query:( qualityId) => `qa/review/${qualityId}`,
            providesTags: ['QA Review']
        }),
        editQAReviewField: builder.mutation({
            query:({fieldId, payload, qualityId}) => ({
                url: 'qa/review/field/' + fieldId,
                method:'PUT',
                body: payload
            }),
            onQueryStarted({ payload, qualityId, fieldId}, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    apiSlice.util.updateQueryData('getQAReview', qualityId?.toString(), (draft) => {
                        const index = draft.qa_data.field_reviews.findIndex( item => item?.id === fieldId)
                        draft.qa_data.field_reviews[index] = {
                            ...draft.qa_data.field_reviews[index],
                            score: payload.score,
                            feedback: payload.feedback
                        } 
                    })
                )
                queryFulfilled.catch(patchResult.undo)
            },
        }),
        editQAObj: builder.mutation({
            query:({ qualityId, payload}) => ({
                url:`qa/review/${qualityId}`,
                method:'PUT',
                body: payload
            }),
            onQueryStarted({ qualityId, payload}, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    apiSlice.util.updateQueryData('getQAReview', qualityId?.toString(), (draft) => {
                        draft.qa_data.final_feedback = payload?.final_feedback
                    })
                )
                queryFulfilled.catch(patchResult.undo)
            },
        }),
        submitQAReview: builder.mutation({
            query:({payload, qualityId}) => ({
                url: `qa/review/submit/${qualityId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags:['QA List', 'Dashboard', 'Charts', 'Compensation Stats', 'QA Review']
        })

    })
})


export const {
    useGetQAReviewerListQuery, useGetQAReviewQuery, useEditQAReviewFieldMutation, useSubmitQAReviewMutation, useEditQAObjMutation
} = qualityAPISlice
