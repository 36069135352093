import { useTheme } from "@emotion/react";
import { Box, Divider, Modal, TextField } from "@mui/material";
import { useFormik } from "formik";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../auth/authSlice";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import DRModalHeader from "./DRModalHeader";
import DRModalMessage from "./DRModalMessage";
import DRModalFooter from "./DRModalFooter";
import { useDelayCaseMutation, useRemoveCaseMutation } from "../../../../rosterAPISlice";
import { show400Error } from "../../../../../../app/errors/genericErrors";
import { enqueueSnackbar } from "notistack";
import dayjs from 'dayjs'
import HaloModal from "../../../../../globalComponents/Modals/HaloModal";
import { apiSlice } from "../../../../../../app/api/apiSlice";
import { useForm } from "react-hook-form";


function getActionText(modalAction){
    const modalTitle =  `Request ${modalAction === 'remove' ? 'removal' : modalAction}`
    const actionVerb = modalAction === 'delay'? 'delaying' : 'removing'

    return { modalTitle, actionVerb }
}

const initValues ={
    identifier:'',
    reason: ''
}

const today = dayjs().format('YYYY-MM-DD')


function DelayRemoveCaseModal({
    open, 
    closeModal, 
    modalAction, 
    selected, 
    setSelected,
    }){

    const theme = useTheme()
    const apiRef = useGridApiContext()
    const userEmail = useSelector(selectCurrentUser)?.email
    const [delayCase] = useDelayCaseMutation()
    const [removeCase, {isLoading: isRemoving}] = useRemoveCaseMutation()
    const formik = useFormik({
        initialValues:initValues,
        validate : (values) =>{
            let errors = {}
            let {identifier} = values || {}
            if (identifier !== userEmail && !identifier) {
                errors.identifier = 'This is a required field'
            }
            else if (identifier !== userEmail && identifier){
                errors.identifier = 'This does not match your email'
            }
            if (values?.reason?.trim().length < 10) errors.reason = 'This field is required and must be at least 10 characters long'
            return errors
        },
        validateOnMount:false,
        validateOnBlur:false,
        validateOnChange:false,
        onSubmit: values => {
            const {reason} = formik.values

            if(modalAction === 'delay') {
                enqueueSnackbar('Case successfully delayed', {variant:'success'}) // show 'optimistic' feedback
                setSelected([])
            } 
 
            manageCase({
                action: modalAction,
                patch: {
                    ...selectedRow,
                    status:'delayed',
                    case_resolution_status:'delayed',
                    case_resolution_notes:reason
                },
                today,
                payload:{ 
                    case_id: selectedRow.case,
                    reason
                }
            })
            .unwrap()
            .then(res => {
                if(modalAction === 'remove'){
                    enqueueSnackbar(res, {variant:'success'}) 
                    setSelected([])
                }
            })
            .catch(err => show400Error(err) )

            
        }
    })


    const manageCase = modalAction === 'delay' ? delayCase : removeCase
    const { modalTitle, actionVerb } = getActionText(modalAction);
    const selectedRow = apiRef?.current?.getRow(selected)

    

    const clearModalAction = () => {
        formik.resetForm()
        closeModal()
    }


    return(
        <HaloModal 
            open={open}
            title={modalTitle} 
            handleClose={clearModalAction}>
                <Box >
                    <Box className='flex-col' gap={2} p={2}>
                        <DRModalMessage 
                            modalAction={modalAction} 
                            selectedRow={selectedRow} 
                            theme={theme} />
                        <Box className='flex-col' gap={3}>
                            <TextField
                                required
                                size="small"
                                name='identifier'
                                label='Confirm with email'
                                value={formik.values.identifier}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.identifier)}
                                helperText={formik.errors.identifier}
                                disabled={isRemoving}
                            />
                            <TextField
                                required
                                multiline
                                rows={3}
                                name='reason'
                                autoComplete="reason"
                                label={'Reason for ' + actionVerb + ' case'}
                                value={formik.values.reason}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.reason)}
                                helperText={formik.errors.reason}
                                disabled={isRemoving}
                            />
                        </Box>
                    </Box>
                    <DRModalFooter
                        clearModalAction={clearModalAction} 
                        formik={formik} 
                        modalAction={modalAction} 
                        isRemoving={isRemoving}
                    />
                </Box>
        </HaloModal>
    )
}

export default memo(DelayRemoveCaseModal)
