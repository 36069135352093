
export const getIsDateRange = (datesArr) => {


    const hasTwoItems = datesArr.filter(d => d).length === 2 //filter out null values
    if(!hasTwoItems) return false


    const isSameDay = datesArr[0] === datesArr[1];
    return !isSameDay

}