export const stringToTitleCase = (str) => {
    if (!str) return ''

    if (typeof str !== 'string') throw new Error("Input must be a string")

    let words;

    
    if (str.includes('_')){
        words = str.toLowerCase().split('_')
    }
    else {
        words = str.toLowerCase().split(' ')
        
    }

    let formattedWords = []

    for (let word of words) {
        if (word) formattedWords.push(word[0]?.toUpperCase() + word?.slice(1))
    }

    return formattedWords.join(' ')
}
