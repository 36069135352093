import { Box, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ActionButton } from '../../../../../../../../../globalComponents/Buttons/ActionButton/ActionButton';
import { useReopenCasesMutation } from '../../../../../../../../rosterAdminAPISlice';
import { useTheme } from '@emotion/react';
import { enqueueSnackbar } from 'notistack';
import { show400Error } from '../../../../../../../../../../app/errors/genericErrors';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { apiSlice } from '../../../../../../../../../../app/api/apiSlice';




export default function ReopenDialog({
    open,
    handleClose,
    selected,
    setSelected = () => null,
}) {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [manageCases, { isLoading }] = useReopenCasesMutation()
    const patientId  = useParams().patientId || ''
    const location = useLocation()
    const isOnPatientProfile = location.pathname.includes('patients/profile')



    const reopenCases = (e) => {
        e.preventDefault()
        manageCases({ case_ids: selected })
            .unwrap()
            .then((res) => {

                setSelected([])
                handleClose()
                enqueueSnackbar(res, { variant: 'success' })

                if (isOnPatientProfile) {
                    dispatch(
                        apiSlice.util.updateQueryData('getPatientProfile', { patientId: patientId?.toString() }, (draft) => {
                            selected.forEach(caseId => {
                                const selectedCase = draft.history.find(obj => obj.type.includes('Encounter' || 'encounter') && obj.data.case.id === caseId)
                                selectedCase.data.case.status = 'assigned'
                            })
                        })
                    )
                }
            })
            .catch((err) =>
                show400Error(err)
            )
    }


    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'sm'} component='form' fullWidth onSubmit={reopenCases} noValidate onClick={(e) =>  e?.stopPropagation()}>
            <DialogTitle >
                Reopen case(s) today
            </DialogTitle>
            <DialogContent>
                <Box className='flex-col' gap={2} >
                    <DialogContentText color={'inherit'}>
                        <span style={{ color: theme.palette.primary.main }}>{selected?.length}</span> case(s) will be reopened today.
                    </DialogContentText>

                </Box>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    variant='outlined'
                    onClick={() => {
                        setSelected([])
                        handleClose()
                    }}
                    disabled={isLoading}>
                    Cancel
                </ActionButton>
                <DialogActions>
                    <ActionButton
                        type='submit'
                        variant='contained'
                        loading={isLoading}
                    >
                        Reopen
                    </ActionButton>
                </DialogActions>
            </DialogActions>
        </Dialog>
    )
}