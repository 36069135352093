
import { useArchivePatientMutation } from "../../../../patients/patientAPISlice"
import { Box, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material'
import { useForm } from 'react-hook-form'
import { enqueueSnackbar } from 'notistack'
import { show400Error } from "../../../../../app/errors/genericErrors"
import { HaloTextField } from "../../../../globalComponents/ReactHookFormFields/TextFields/HaloTextField"
import { ActionButton } from "../../../../globalComponents/Buttons/ActionButton/ActionButton"
import DetailItem from "../../../../globalComponents/TextGroups/DetailItem"
import StaticAlert from "../../../../globalComponents/Alert/StaticAlert"
import { stringToTitleCase } from "../../../../../utils/stringUtils/titleCase"

function ArchiveDialog({
    open,
    handleClose,
    patientId,
    patientObj,
    resetParentCallback = () => null, //handle any state in the parent component like clearing a form

}) {

    const [archivePatient, { isLoading: isArchiving }] = useArchivePatientMutation()
    const { has_prior_reviews_or_docs, name } = patientObj || {}
    const { control, handleSubmit } = useForm({
        defaultValues: {
            patient_id: patientId,
            archival_reason: ''
        }
    })


    const archivePatientHandler = (values) => {
        archivePatient(values)
            .unwrap()
            .then((res) => {
                handleClose()
                enqueueSnackbar(res, { variant: 'success' })
                resetParentCallback()
            })
            .catch(err => show400Error(err))
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={'sm'}
            fullWidth
            component='form'
            noValidate
            onSubmit={handleSubmit(archivePatientHandler)}
        >
            <DialogTitle>
                Archive patient
            </DialogTitle>
            <DialogContent>
                <Box className='flex-col' gap={2}>
                    {has_prior_reviews_or_docs &&
                        <StaticAlert
                            severity={'warning'}
                            title={'Archival warning '}
                            msg={'The following patient has prior reviews and/or documentation. Please confirm that the following patient should be archived.'}
                        />}
                    <DetailItem
                        label={'Patient name'}
                        value={stringToTitleCase(name)}
                    />
                    <HaloTextField
                        control={control}
                        name='archival_reason'
                        required
                        label='Archival reason'
                    />
                    <DialogActions>
                        <ActionButton
                            variant='outlined'
                            onClick={() => {
                                resetParentCallback()
                                handleClose()
                            }}
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            type='submit'
                            variant='contained'
                            loading={isArchiving}
                        >
                            Archive
                        </ActionButton>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ArchiveDialog