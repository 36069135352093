import { useGridApiContext } from '@mui/x-data-grid-pro';
import { AssignmentIcon } from '../../../../../../../../../assets/icons/MaterialDesignIcons';
import OutlinedToolbarChip from '../../../../../../../../globalComponents/Chips/OutlinedToolbarChip/OutlinedToolbarChip';
import { useCallback, useState } from 'react';
import TargetUserDialog from './Dialogs/TargetUserDialog';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import StickyHeaderToolbar from '../../../../../../../../globalComponents/DataGrid/subComponents/toolBars/StickyHeaderToolbar';
import UnassignDialog from './Dialogs/UnassignDialog';
import RemoveFromBatchDialog from '../../../../../RemoveFromBatchDialog/RemoveFromBatchDialog';

export default function CaseMgmtToolbar({
    isSelected,
    selected,
    setSelected,
    selectedRowData
}) {
    const apiRef = useGridApiContext()
    const [open, setOpen] = useState('')

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen('')
    }
    const handleOpen = (action) => (e) => setOpen(action)


    const showUnassign = useCallback(() => {
        const rowsMap = apiRef?.current?.getSelectedRows()
        for (let [key, { user }] of rowsMap) {
            if (user) {
                return true
            }
        }
        return false
    }, [apiRef])


    return (
        isSelected &&
        <>

            <StickyHeaderToolbar sx={{ height: 52 }}>
                <OutlinedToolbarChip
                    onClick={handleOpen('Assign')}
                    startIcon={<AssignmentIcon sx={{ height: 18, width: 18 }} />}>
                    Assign
                </OutlinedToolbarChip>
                {
                    showUnassign() &&
                    <OutlinedToolbarChip
                        onClick={handleOpen('Unassign')}
                        startIcon={<AssignmentReturnOutlinedIcon sx={{ height: 18, width: 18 }} />}
                    >
                        Unassign
                    </OutlinedToolbarChip>
                }
                <OutlinedToolbarChip
                    onClick={handleOpen('RemoveFromBatch')}
                    startIcon={<PersonRemoveOutlinedIcon sx={{ height: 18, width: 18 }} />}>
                    Remove from batch
                </OutlinedToolbarChip>
            </StickyHeaderToolbar>
            <TargetUserDialog
                open={open === 'Assign'}
                handleClose={handleClose}
                selected={selected}
                setSelected={setSelected}
                selectedRowData={selectedRowData}
            />
            <UnassignDialog
                open={open === 'Unassign'}
                handleClose={handleClose}
                selected={selected}
                setSelected={setSelected}
            />
            <RemoveFromBatchDialog
                open={open === 'RemoveFromBatch'}
                handleClose={handleClose}
                selected={selected}
                setSelected={setSelected}
            />
        </>

    )
}