import {  Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { memo} from "react";



export const NavListItem = ({ 
    label, 
    link, 
    open,  
    isSelected,
    handleNav,
    icon,
    prefetch,
    handlePrefetch,
  }) => {

  const theme = useTheme()
    
  return (
    <Button
      title={label}
      onClick={() => handleNav(link)}
      onMouseEnter={prefetch && (() => handlePrefetch(prefetch))}
      sx={{
        height:48,
        width:open? '100%': 48,
        minWidth:48,
        p: open? '12px': 0,
        color:isSelected? theme.palette.primary.contrast : theme.palette.text.secondary,
        background: isSelected? theme.palette.primary.light : '',
        ':hover ': {
          background: isSelected? theme.palette.primary.light: ''
        },
        '.MuiButton-startIcon' :{
          m: open? '' : 0
        },
        justifyContent: open? 'flex-start' : 'center',
        borderRadius:open? 2 : '50%'
      }}
      startIcon={icon}>
      {open? label : ''}
    </Button>
  );
};


export default memo(NavListItem)

