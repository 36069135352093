import TrialMedications from "./Medications/TrialMedications"
import TrialAllergies from "./Allergies/TrialAllergies"
import TrialDiagnoses from "./Diagnoses/TrialDiagnoses"
import CaseAccordion from "../../../../globalComponents/Accordions/CaseAccordion";
import SummaryContainer from "../../../../globalComponents/Containers/SummaryContainer";
import { memo } from 'react';
import { Box } from '@mui/material';
import { useTutorial } from "../../../utils/TutorialContext";

function TrialDataInput(props) {
    const { meds, allergies, diagnoses, readOnlyView, allowEdits, caseInfo, isLoading } = props
    const { refs } = useTutorial()

    const content = [
        <Box className='flex-row' gap={2} key={'meds-and-diags'} id='tutorial-medications'>
            <div ref={refs.current[2]} style={{width:'60%' }}>
                <TrialMedications meds={meds} key={'meds'} readOnly={readOnlyView} />
            </div>
            <TrialDiagnoses diagnoses={diagnoses} key={'diags'} readOnly={readOnlyView} />
        </Box>,
        <TrialAllergies allergies={allergies} key={'allergies'} readOnly={readOnlyView} />,
    ]


    return readOnlyView ? (
        <SummaryContainer number={1} title={'Data Input'} disableEdits={!allowEdits} caseInfo={caseInfo} isLoading={isLoading} baseURL={'/trial-users/encounter-review/'}>
            {content}
        </SummaryContainer>
    ) : (
        <CaseAccordion number={1} title={'Data Input'} initialOpenState={true}>
            {content}
        </CaseAccordion>
    );
}


export default memo(TrialDataInput)
