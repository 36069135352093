import {useManageAntibioticResistanceGenesMutation, useManageSamplesMutation} from '../../../../../../infectiousDiseaseAPISlice'
import CRUDDataGrid from "../../../../../../../globalComponents/DataGrid/CRUDDataGrid"
import AutoCompleteCell from '../../../../../../../globalComponents/DataGrid/subComponents/customCells/AutoCompleteCell'
import { PillIcon } from '../../../../../../../../assets/icons/MaterialDesignIcons';

// const preValidation = (params, event) => {
//     const arr = ['gene'];
//     const enterKeyHit = params.reason === 'enterKeyDown'
//     const selectIsOpen = event.target.ariaExpanded === 'true'

    
//     if (enterKeyHit && arr.includes(params.field) && selectIsOpen) {
//       event.defaultMuiPrevented = true;
//       return true; 
//     }
//     return false; 
//   };


export default function AntibioticResistances({readOnly,sample, gene_options}){


    const columns = [
        {
            field:'gene',
            headerName:'Gene',
            flex:1,
            editable: !readOnly,
            headerClassName: readOnly? '' : 'required-col',
            valueFormatter: value => value?.name,
            renderEditCell: (params) =>(
                <AutoCompleteCell 
                    {...params}
                    openOnFocus={false}
                    options={gene_options}
                    optionKey={'name'}
                />
            )
        },
        {
            field:'description',
            headerName:'Description - read only',
            flex:2,
            editable:false,
            valueGetter:(value, row) => {
                return row?.gene?.description || '-'
             },
        }
    ]

    return(
        <CRUDDataGrid
            title={'Antibiotic resistance'}
            columns={columns} 
            initRows={sample?.antibiotic_resistance_genes} 
            mutation={useManageAntibioticResistanceGenesMutation}
            GridIcon={PillIcon}
            fieldToFocus='gene'
            requiredFields={['gene']}
            parentId={sample?.id}
            isReadOnly={readOnly}
            // preValidation={preValidation}
            toggleProps={{
                value: sample?.no_genes_provided,
                mutation: useManageSamplesMutation,
                mutationKey: 'no_genes_provided',
                helperText: 'no resistance genes provided in this sample'
              }}/> 
    )
}
