import { SvgIcon } from "@mui/material"


export const EmptyChartIcon = (props) =>(
    <SvgIcon {...props} viewBox="0 0 41 40">
        <g >
            <path d="M12.044 10.5955L13.0058 11.5573C15.1341 9.42906 14.3019 8.03752 13.6948 7.02115C13.4356 6.58459 13.2105 6.20941 13.2105 5.81378C13.2105 4.36085 14.4792 3.84243 14.5338 3.82197L14.0427 2.54639C13.954 2.58049 11.853 3.41269 11.853 5.81378C11.853 6.58459 12.2146 7.19168 12.5352 7.72374C13.0945 8.65143 13.4287 9.2176 12.0508 10.5955H12.044Z" fill="black" fillOpacity="0.26"/>
            <path d="M16.628 8.54911L17.5898 9.51091C19.7181 7.38267 18.8859 5.99113 18.2788 4.97476C18.0196 4.5382 17.7944 4.16303 17.7944 3.76739C17.7944 2.31446 19.0632 1.79604 19.1178 1.77558L18.6266 0.5C18.538 0.534106 16.437 1.3663 16.437 3.76739C16.437 4.5382 16.7985 5.14529 17.1191 5.67735C17.6785 6.60505 18.0127 7.17121 16.6348 8.54911H16.628Z" fill="black" fillOpacity="0.26"/>
            <path d="M27.2691 27.5054L28.9608 26.6664C31.8462 25.2339 33.8175 22.3554 34.1108 19.1425C34.1927 18.2694 33.8926 17.3963 33.2991 16.7415C32.7057 16.0866 31.8598 15.7183 30.9731 15.7183H29.0631V13.6719C29.0631 13.2967 28.7561 12.9897 28.381 12.9897H2.4601C2.08492 12.9897 1.77797 13.2967 1.77797 13.6719V20.8342C1.77797 25.0498 3.74932 28.9174 7.03035 31.4072H0.932128C0.556958 31.4072 0.25 31.7142 0.25 32.0893C0.25 33.972 1.77797 35.5 3.66064 35.5H26.853C28.7357 35.5 30.2636 33.972 30.2636 32.0893C30.2636 31.7142 29.9567 31.4072 29.5815 31.4072H23.8107C25.2295 30.3363 26.396 29.0061 27.2691 27.5054ZM29.0631 20.8342V17.0825H30.9731C31.4778 17.0825 31.9485 17.2872 32.2896 17.6623C32.6306 18.0375 32.7943 18.515 32.7466 19.0198C32.501 21.7619 30.8162 24.2176 28.3537 25.4386L28.2104 25.5068C28.763 24.0402 29.0563 22.4577 29.0563 20.8274L29.0631 20.8342ZM13.572 19.0675C14.2405 19.0675 14.8407 19.3608 15.2636 19.8315C15.6866 19.3608 16.2868 19.0675 16.9553 19.0675C18.2445 19.0675 19.2882 20.1589 19.2882 21.5095C19.2882 24.6269 15.2636 26.3936 15.2636 26.3936C15.2636 26.3936 11.2391 24.6269 11.2391 21.5095C11.2391 20.1589 12.2827 19.0675 13.572 19.0675ZM28.7834 32.7715C28.5038 33.5627 27.7466 34.1357 26.853 34.1357H3.66064C2.77387 34.1357 2.00989 33.5627 1.73022 32.7715H28.7834Z" fill="black" fillOpacity="0.26"/>
        </g>
    </SvgIcon>
)
