import { createTheme } from "@mui/material";

export const  initTheme = (darkMode) => {
    let theme =  createTheme({
      
        typography:{
          subtitle1:{
            fontWeight:500
          }
        },
        palette: {
            mode: darkMode ? 'dark' : 'light',
            background:{
              default : darkMode? 'rgb(23,31,35)' : '#f6fcfe'
            },
            primary:{ //might have to move this to modify after the Theme objects is initialized
              main:'#10A5E7',
              dark:'#006FA9',
              shades4: 'rgba(16, 165, 231, 0.04)',
              shades8: 'rgba(16, 165, 231, 0.08)',
              shades12: 'rgba(16, 165, 231, 0.12)',
              contrast: darkMode? 'rgba(0,0,0,0.87)' : '#fff',
              contrastText: darkMode? 'rgba(0,0,0,0.87)' : '#fff'
            },
        },
        components: {
          MuiFormLabel: {
            styleOverrides: {
              asterisk: {color:darkMode? '#F44336' : '#D32F2f'},
            },
          },
          MuiButton:{
            styleOverrides:{
              root:{
                textTransform:'none'
              }
            }
          },
          MuiTab: {
            styleOverrides: {
              root: {
                textTransform: 'none',  // Set text transform to none
              },
            },
          },
        },
        customShadows:{
          shadow2:'0px 1px 5px 0px rgba(0, 0, 0, 0.06), 0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 3px 1px -2px rgba(0, 0, 0, 0.10)',
          elevation4: '0px 1px 10px 0px rgba(0, 0, 0, 0.06),  0px 4px 5px 0px rgba(0, 0, 0, 0.07),0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
          elevation8: '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)'
        },
        avatar:{
          primary:'#00B295',
          secondary:'hsla(170, 100%, 35%, 1)'
        },
        dangerBox:{
          p:1,
          borderRadius: 1,
          background: 'var(--light-error-shades-190-p, linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #D32F2F)',
        },
        stickyHeader:{
          position:'fixed',
                top:0,
                zIndex:1100,
                width:'-webkit-fill-available'
        },
        buttons:{
          primary:{
              bgcolor: '#10A5E7',
              borderRadius: '18px',
              p:'6px 16px',
              color: darkMode? 'rgba(0,0,0,0.87)' : '#fff'
          }
        },
        modal:{
          centered:{
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            width: '100%' 
          }
        }
    });

    //When the value for a theme option is dependent on another theme option, you should compose the theme in steps.
    //https://mui.com/material-ui/customization/theming/#api
    return theme = createTheme(theme, {
      palette:{
        error:{
          ...theme.palette.error,
          shades4: darkMode ? 'hsla(0, 65%, 51%, 0.08)' :'hsla(0, 65%, 51%, 0.04)',
          shades12 : 'hsla(0, 65%, 51%, 0.12)',
          shades160: darkMode ? 'rgba(255, 255, 255, 1)' : 'hsla(0, 0%, 0%, 0.6)',
          shades190: 'hsla(0, 65%, 51%, 1)'
        },
        success:{
          ...theme.palette.success,
          main:'#00B295',
          shades4: !darkMode ? '#00B2950A' : 'hsla(117, 67%, 45%, 0.08)',
          shades8: '#00B29514'
        }
      },
      typography:{
        ...theme.palette.typography,
        body3:{
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '130%',
          letterSpacing: '0.048px',
        }
      },
      components:{
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                width: 10,
              },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                background: 'transparent',
              },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                backgroundColor: theme.palette.divider,
                opacity:0.5
              },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                background: theme.palette.grey[400],
              },
              '& ::-webkit-scrollbar': {
                width: 10,
              },
              '& ::-webkit-scrollbar-track': {
                background: 'transparent',
              },
              '& ::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                backgroundColor: theme.palette.divider,
              },
              '& ::-webkit-scrollbar-thumb:hover': {
                background: theme.palette.grey[400],
              },
              '.os-theme-dark, .os-theme-light': {
                '--os-size': '14px !important',
              },
              '.os-theme-dark .os-scrollbar-handle': {
                backgroundColor: theme.palette.grey[400],
                background:theme.palette.divider
              },
              '.os-theme-light .os-scrollbar-handle': {
                backgroundColor: theme.palette.grey[400],
                background:theme.palette.divider
              },
              // Data grid
              " .MuiDataGrid-container--top [role=row]": {
                backgroundColor: theme.palette.background.paper + ' !important',
              },
              // Neutralize the hover colour (causing a flash)
              " .MuiDataGrid-hide-hover.MuiDataGrid-row.Mui-hovered": {
                backgroundColor: "transparent !important",
              },
              // Take out the hover colour
              " .MuiDataGrid-hide-hover.MuiDataGrid-row:hover": {
                backgroundColor: "transparent !important",
                cursor:'default'
              },
            },
          },
        },
      },
      shadowedBox:{
        borderRadius:4,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.customShadows.shadow2
      },
      standardBox:{
        background: theme.palette.background.paper,
        borderRadius:2, 
        boxShadow:'none', 
        border:'1px solid',
        borderColor: theme.palette.divider
      },
    })

}
