import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro"
import { useGetSitesQuery } from "../../../../../../patients/patientAPISlice"
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import PatientIntakeModal from "../../../../../../dataIntake/components/PatientIntakeModal/PatientIntakeModal";
import { useNavigate } from "react-router-dom";
import KeepMountedTabPanel from "../../../../../../globalComponents/Tabs/TabPanels/KeepMountedTabPanel";
import { SMBShareIcon } from "../../../../../../../assets/icons/MaterialDesignIcons";
import { linkedRowProps } from "../../../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";




export default function RMSitesGrid({
    gridProps,
    pageSizeOptions, 
    pageSize,
    setPageSize,
    apiRef,
    visible
}){ 


    const navigate = useNavigate()
    const { data, isLoading, isFetching } = useGetSitesQuery({include:'templates'}, {skip: !visible})
    const [ intakeSite, setIntakeSite ] = useState({})
    const [paginationModel, setPagination] = useState({ pageSize, page:0 })
    const [ open, setOpen ] = useState(false)


    const columns =[
        {
            field: 'site_name',
            headerName: 'Site',
            width:250,
        },
        {
            field: 'emr',
            headerName: 'Emr',
            width:150,
            flex:1,
            valueGetter: (row) => row?.name || '-'
        },
    ]

    const handleCellClick = ({field, row}) =>{
        
        if (field.includes('__') || field === 'actions') return

        else{
            navigate('/admin/site/' + row.id)
        }
    }


    const handlePaginationChange = (pagination) => {
        setPagination(pagination)
        setPageSize(pagination?.pageSize)
    }   

    useEffect(() => {

        setPagination(prev => ({...prev, pageSize}))

    },[ pageSize])

    useEffect(() => {
        if (data) {
          setIntakeSite(prev => data.find((site) => site.id === prev.id) || {} )
        }
    }, [data]);


    
    return (
        <>
            <KeepMountedTabPanel className={visible ? 'visible' : ''}>
                <DataGridPro
                    rows={data || []}
                    columns={columns}
                    apiRef={apiRef}
                    disableRowSelectionOnClick
                    pagination
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationChange}
                    pageSizeOptions={pageSizeOptions}
                    onCellClick={handleCellClick}
                    {...gridProps}
                    sx={{
                        ...gridProps.sx,
                        ...linkedRowProps
                    }}
                />
            </KeepMountedTabPanel>
        </>
    )
}