import { stickyHeaderProps } from "../../gridStyleProps/gridStyleProps";
import { GridToolbarContainer } from '@mui/x-data-grid-pro';








export default function StickyHeaderToolbar({children,sx, ...props}){



    return(
        <GridToolbarContainer
            {...props}
            className='flex-row align-center'
            sx={(theme) => ({
                background:theme.palette.background.paper,
                borderBottom:1,
                borderColor:theme.palette.divider,
                p:'8px 16px',
                ...stickyHeaderProps,
                ...sx
            })}>
            {children}
        </GridToolbarContainer>
    )


}