import { IconButton, InputAdornment } from "@mui/material";
import { LabeledBox } from "../../../../../globalComponents/Formik/QuickEditFields";
import { useState } from "react";
import { CallPatientModal } from "./CallPatientModal";
import { HaloNumberField, } from "../../../../../globalComponents/ReactHookFormFields";
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { useWatch } from "react-hook-form";






export function CallPatientField({
    patient,
    control,
    ...other
}){

    const [open, setOpen] = useState(false)

    const watchedPhone = useWatch({control, name:'phone_mobile'})

    const closeModal = () => setOpen(false)
    const openModal = () => setOpen(true)

    return(
        <LabeledBox label='Phone mobile'>


            {
                <HaloNumberField
                    {...other}
                    control={control}
                    sx={{
                        '.MuiOutlinedInput-root':{
                            pr:0
                        }
                    }}
                    variant='phone'
                    InputProps={{
                        endAdornment: 
                            <InputAdornment position="end">
                                <IconButton 
                                    disabled={watchedPhone?.length !== 15 && watchedPhone?.length !== 12} //12 for initial render, 15 for masking render
                                    sx={{p:'2px', borderRadius:1}}
                                    onClick={openModal}>
                                    <PhoneEnabledIcon sx={{fontSize:20}}/>
                                </IconButton>
                            </InputAdornment>,
                      }}
                />
            }
            <CallPatientModal open={open} closeModal={closeModal} patient={patient}/>
        </LabeledBox>
    )



}