import { createSlice } from "@reduxjs/toolkit"

const qualitySlice = createSlice({
    name: 'quality',
    initialState:{
        currentScores:{
            score:0, //adding this to get "live" score from Form values
            max_possible_score:'',
            fields:{}
        }
    },
    reducers: {
        setCurrentScores:(state, action) => {
            state.currentScores = {
                ...action.payload
            }
        },
        updateFieldScore:(state, {payload}) => {

            
            state.currentScores.fields= {
                    ...state.currentScores.fields,
                    ...payload
            }
            const valuesArray = Object.values(state.currentScores.fields).map(value => value? value : 0);
            const sum = valuesArray.reduce((acc, currentValue) => acc + parseFloat(currentValue), 0);
            const average = sum / state.currentScores.max_possible_score;

            state.currentScores.score = (average * 100)?.toFixed(1) ;
        }
    }
})


export const {
    setCurrentScores, updateFieldScore
} = qualitySlice.actions


export const selectScorePercentage = (state) => state?.quality?.currentScores?.score 

export default qualitySlice.reducer;
