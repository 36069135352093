import { useTheme } from "@emotion/react";
import { Box, Skeleton, Divider } from "@mui/material";
import { TextTitleAndBodyLoader } from "../../../globalComponents/Loaders/commonComponent/TextTitleAndBodyLoader";


export default function TrialStatusBoxLoader() {

    const theme = useTheme()

    return (
        <Box className='flex-col' gap={2} sx={{ ...theme.standardBox }} width={'35%'} height={246} >
            <Box className='flex-col' gap={1}>
                <Skeleton animation='wave' width={56} height={20} sx={{ marginLeft: 1, marginTop: 1 }} />
                <Divider />
                <Box className='flex-row' gap={3} p={2}>
                    <Box className='flex-col' gap={2}>
                        <TextTitleAndBodyLoader titleWidth={100} bodyWidth={302} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
