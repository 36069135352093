import { Box } from "@mui/material";
import FormikTextField from "../../../../../globalComponents/Formik/TextFields/FormikTextField";
import { memo } from "react";
import MetricsChips from "./MetricsChips";



function MetricsSection({name, label, fieldKey, handleSave, handleDelete, metrics, formik, disabled}){


    return(

        <Box className='flex-col' gap={1}>
            <FormikTextField 
                value={formik.values[name]} 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={ Boolean(formik.errors[name])}
                name={name}
                label={label}
                disabled={disabled}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSave(e, name);
                    }
                }}/>
            <MetricsChips 
                metrics={metrics}
                name={name}
                fieldKey={fieldKey}
                handleDelete={handleDelete}
                disabled={disabled}/>    
        </Box>
    )


}

export default memo(MetricsSection)