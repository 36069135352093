import { useTheme } from "@emotion/react";
import { Box, Skeleton, Divider } from "@mui/material";
import { TextTitleAndBodyLoader } from "../../../globalComponents/Loaders/commonComponent/TextTitleAndBodyLoader";

export default function TrialClinicianInfoBoxLoader(){

    const theme = useTheme()

    return(
        <Box className='flex-col' gap={2} sx={{...theme.standardBox}} width={'65%'} height={246} >
            <Box className='flex-col' gap={1}>
                <Skeleton animation='wave' width={56} height={20} sx={{marginLeft: 1, marginTop: 1}}/>
                <Divider/>
                <Box className='flex-row' gap={3} p={2}>
                    <Box className='flex-col' gap={2}>
                        <TextTitleAndBodyLoader/>
                        <TextTitleAndBodyLoader/>
                        <TextTitleAndBodyLoader bodyWidth={318}/>
                    </Box>
                    <Box className='flex-col' gap={2}>
                        <TextTitleAndBodyLoader/>
                        <TextTitleAndBodyLoader/>            
                    </Box>
                </Box> 
            </Box>
        </Box>
    )
}
