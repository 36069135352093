
import CaseAccordion from '../../../../globalComponents/Accordions/CaseAccordion';
import SummaryContainer from '../../../../globalComponents/Containers/SummaryContainer'
import { memo } from 'react';
import { Box } from '@mui/material';
import Medications from '../../../../pathology/components/PathologyReview/DataInput/Medications/Medications';
import Diagnoses from '../../../../pathology/components/PathologyReview/DataInput/Diagnoses/Diagnoses';
import Genes from './Genes';
import Allergies from '../../../../pathology/components/PathologyReview/DataInput/Allergies/Allergies';

function DataInput(props){
    const {meds, diagnoses, allergies, genes, readOnlyView, allowEdits, caseInfo, isLoading} = props

    const content = [
        <Box className='flex-row' gap={2} key={'meds-and-diags'}> 
                <Medications meds={meds} key={'meds'} readOnly={readOnlyView}/>
                <Diagnoses diagnoses={diagnoses} key={'diags'} readOnly={readOnlyView}/>
        </Box>,       
        <Allergies allergies={allergies} key='allergies'/>,
        <Genes genes={genes} key='genes' readOnlyView={readOnlyView}/>
    ]
                    

    return readOnlyView ? (
        <SummaryContainer number={1} title={'Data Input'} disableEdits={!allowEdits} caseInfo={caseInfo} isLoading={isLoading}>
            {content}
        </SummaryContainer>
    ) : (
        <CaseAccordion number={1} title={'Data Input'}>
            {content}
        </CaseAccordion>
    );
}


export default memo(DataInput)