import { Chip, Tab } from "@mui/material";
import { PatientListFillIcon } from "../../../../assets/icons/MaterialDesignIcons";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { ContentPaste } from "@mui/icons-material";
import { usePrefetchEndpoint } from "../../../../utils/apiUtils/usePrefetchEndpoint";
import HaloTabs from "../../../globalComponents/Tabs/HaloTabs";
import HaloTabLabel from "../../../globalComponents/Tabs/TabLabels/HaloTabLabel";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';

export default function AdminTabs({tabValue, handleTabChange, userGroups}){


    return(
        <HaloTabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            >
            {
                userGroups?.['patient list'] &&
                    <Tab 
                        label={<HaloTabLabel label='Patients'/>}
                        value='Patients'
                        icon={<PatientListFillIcon color='primary' sx={{height:20, width:20}}/>}
                        iconPosition="start"
                        
                    />
            }
            {
                userGroups?.['QA | Reviewer'] &&
                    <Tab 
                        label={<HaloTabLabel label='QA cases'/>}
                        value={'QA cases'}
                        icon={<DescriptionOutlinedIcon color="warning" sx={{fontSize:20}}/>}
                        iconPosition="start"
                    />
            }
            {   (userGroups?.['Trial User | Reviewer'] || userGroups?.['Trial User | Admin'] || userGroups?.['Developer']) &&
                    <Tab
                        label={<HaloTabLabel label='Trial users'/>}
                        value='Trial users'
                        icon={<ContentPaste color='primary' sx={{height:20, width:20}}/>}
                        iconPosition="start"
                        
                    />
            }
            {
                (userGroups?.['Developer'] || (userGroups?.['Internal Staff'] && userGroups?.['Roster Management | Admin']) )&&
                    <Tab 
                        label={<HaloTabLabel label='Roster mgmt.' element={
                            <Chip label='Dev Beta' color="warning" size='small' sx={{ml:1}}/>
                        }/>}
                        value='Roster mgmt.'
                        icon={<DashboardOutlinedIcon color='primary' sx={{height:20, width:20}}/>}
                        iconPosition="start"    
                        
                    />
            }
            {
                (userGroups?.['Developer'] || userGroups?.['Data Intake'] ) &&
                    <Tab 
                        label={<HaloTabLabel label='Data intake' />}
                        value='Data intake'
                        icon={<UploadFileIcon color='primary' sx={{height:20, width:20}}/>}
                        iconPosition="start"    
                        
                    />
            }
            {
                (userGroups?.['Developer'] || userGroups?.['Pathology MD'] ) &&
                    <Tab 
                        label={<HaloTabLabel label='MD Review'/>}
                        value='MD Review'
                        icon={<RateReviewOutlinedIcon color='primary' sx={{height:20, width:20}}/>}
                        iconPosition="start"    
                        
                    />
            }
        </HaloTabs>
    )
}
