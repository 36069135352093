


const downloadDoc = (res) => {

    const contentType = res.headers['content-type']; 
    const contentDisposition = res.headers['content-disposition']; 
    const filename = contentDisposition.match(/filename="([^"]+)"/); 
    const blob = new Blob([res.data], { type: contentType }); 
    const fileURL = URL.createObjectURL(blob);


    const downloadLink = document.createElement('a');
    downloadLink.href = fileURL
    downloadLink.download = filename[1];
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

}

export default downloadDoc;