import LabValues from './LabValues/LabValues';
import DrugInteractions from './DrugInteractions/DrugInteractions'
import CaseAccordion from '../../../../globalComponents/Accordions/CaseAccordion';
import ImagingValues from './ImagingValues/ImagingValues';
import SummaryContainer from '../../../../globalComponents/Containers/SummaryContainer';
import { memo } from 'react';
import ResistanceGenes from '../DataInput/ResistanceGenes/ResistanceGenes';


function Analysis(props){
    const {labValues, resistanceGenes, imagingValues, drugInteractions, qaObj, readOnlyView, allowEdits, caseInfo, isLoading} = props
    const { field_reviews } = qaObj || {}
 

    const content = [
        <LabValues labValues={labValues} qaObj={qaObj} key={'LAB'}/>,
        <ImagingValues imagingValues={imagingValues} qaObj={qaObj} key={'IMG'}/>,
        <ResistanceGenes resistanceGenes={resistanceGenes} key={'genes'} readOnly={readOnlyView}/>,
        <DrugInteractions drugInteractions={drugInteractions} key={'d2d'}/>
    ]

    const hasQAComments = field_reviews?.['Lab Values']?.score_percentage < 100 || field_reviews?.['Imaging Values']?.score_percentage < 100

    return readOnlyView ? (
        <SummaryContainer number={2} title={'Analysis'} disableEdits={!allowEdits} caseInfo={caseInfo} isLoading={isLoading}>
            {content}
        </SummaryContainer>
    ) : (
        <CaseAccordion number={2} title={'Analysis'} iconVariant={hasQAComments? 'error' : null}>
            {content}
        </CaseAccordion>
    );


   
}

export default memo(Analysis)
