import { HaloObjAutocomplete } from "../../../../../globalComponents/ReactHookFormFields/AutocompleteFields/HaloObjAutocomplete";
import { HaloTextField } from "../../../../../globalComponents/ReactHookFormFields/TextFields/HaloTextField";
import { HaloAsyncObjAutocomplete } from "../../../../../globalComponents/ReactHookFormFields/AutocompleteFields/HaloAsyncObjAutocomplete";
import { useGetSitesQuery } from "../../../../../patients/patientAPISlice";
import Collapse from '@mui/material/Collapse';
import { Box, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";







export default function PatientUpload({
    site,
    control,
    setValue
}){

    const { emr } = site || {}
    const [ isNewEhr, setIsNew ] = useState(false)

    const handleSwitch = (e) => setIsNew(e.target.checked)
    
    useEffect(() => {
        if(site?.data_templates?.length > 0 && !isNewEhr) {
            setValue('template', site?.data_templates[0])
        }else{
            setValue('template', {})
        }

    },[site, isNewEhr])
    



    return(
        <>
            <HaloAsyncObjAutocomplete 
                name='site'
                label='Site'
                control={control}
                required
                disablePortal
                query={useGetSitesQuery}
                queryParams={{
                    include:'templates_and_emr_templates'
                }}
                optionKey={'site_name'} 
                />
                <Collapse in={site?.data_templates?.length > 0} >
                    <HaloObjAutocomplete 
                        options={site?.data_templates}
                        optionKey='template_name'
                        label='Choose related template'
                        name='template'
                        control={control}   
                        disabled={isNewEhr}
                        freeSolo
                    />
                </Collapse>
                <Collapse in={Boolean(emr)}>
                    <Box className='flex-col' gap={1}>
                        <Box className='flex-row-between' >
                            <Typography variant="body1" color='text.secondary'>Create new <i>{emr?.name}</i> template</Typography>
                            <Switch size='small' checked={isNewEhr} onClick={handleSwitch}/>
                        </Box>
                        <Collapse in={isNewEhr}>
                            <HaloTextField 
                                name='template_name'
                                label='EMR template name'
                                required={isNewEhr}
                                control={control}
                            />
                        </Collapse>
                        
                    </Box>
                </Collapse>

        </>
    )
}