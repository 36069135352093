import { Typography, Box, Button, IconButton } from "@mui/material"
import FileDropzone from "../../../rosterManagement/components/LabDocUpload/FileDropzone"
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton"
import { useState, useCallback } from "react"
import fileAttachedIcon from "../../../../assets/icons/file-attached-icon.svg"
import uploadIcon from "../../../../assets/icons/upload-icon.svg"
import { useTheme } from "@emotion/react"
import { useDropzone } from 'react-dropzone'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';



const ResumeDropZone = ({ setValue, getValues }) => {

    const [fileAttached, setFileAttached] = useState(false)
    const theme = useTheme()

    const handleFileDetach = () => {
        setValue('resume_file', null)
        setFileAttached(false)
    }

    const onDrop = useCallback((acceptedFiles) => {
        setFileAttached(true)
        setValue('resume_file', acceptedFiles[0])
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'application/pdf': ['.pdf'] },
        maxFiles: 1
    });

    const resume = getValues('resume_file')

    const dropZone = (
        <div {...getRootProps()} >
            <input  {...getInputProps({ name: 'resume_file', type: 'file' })} />
            <Box className='flex-col-center' gap={1} sx={{
                borderRadius: '4px', p: '24px 16px',
                border: '1px dashed #10A5E780'
            }}>
                <img src={uploadIcon} height={48} width={48} alt='upload icon' />
                <Typography sx={{ fontSize: '14px' }}>
                    Drag your file here or <Button variant="text" color="primary" sx={{ p: 0, textDecoration: 'underline' }}>
                        click to upload
                    </Button>
                </Typography>
                <Typography sx={{ color: theme.palette.text.disabled, fontSize: '14px' }}>PDF (max. 3MB)</Typography>
            </Box>
        </div>)

    const attached = (
        <Box className='flex-col' gap={1}>
            <Box className='flex-row' gap={1} sx={{ borderRadius: '4px', p: '24px 16px', border: '1px dashed #00B29580' }}>
                <Box className='flex-row-center' gap={2} >
                    <img src={fileAttachedIcon} alt='attached icon' height={48} width={48} />
                    <Typography variant="subtitle2">Resume added successfully</Typography>
                </Box>
            </Box>
            <Box className='flex-row-between'>
                <Box className='flex-row-center'>
                    <AttachFileIcon />
                    <Typography variant="body2">{resume?.name}</Typography>
                </Box>
                <IconButton size="small" onClick={handleFileDetach}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
    )


    return (
        !fileAttached ? dropZone : attached
    );
}

function ResumeUpload({setValue, getValues}) {

    return (
        <>
            <Typography sx={(theme) => ({ ...theme.typography.subtitle1, fontWeight: 500 })}>Upload your resume (optional)</Typography>
            <ResumeDropZone setValue={setValue} getValues={getValues} />
        </>

    )
}

export default ResumeUpload
