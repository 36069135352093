import { Box, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { LabPanelIcon } from '../../../../../../../../assets/icons/MaterialDesignIcons'

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { show400Error } from "../../../../../../../../app/errors/genericErrors";
import { ActionButton } from "../../../../../../../globalComponents/Buttons/ActionButton/ActionButton";
import { useWatch } from "react-hook-form";
import { getToolbarStatusIcon } from "../../../../../../../globalComponents/DataGrid/utils/getToolbarStatusIcon";


const getSx = (theme, readOnly) => ({
    p:'8px 16px',
    justifyContent:'space-between',
    alignItems:'center',
    gap:0.5,
    height:48,
    backgroundColor: 'primary.shades4',
    '&:hover':{
        backgroundColor: !readOnly && theme.palette.action.hover ,
        cursor: !readOnly && 'pointer'
        }

})




export default function FormToolbar({
    readOnly,
    manageSample,
    sample,
    setOpen,
    setSamplesState,
    open,
    control,
    errors
}){

    const watchedSampleType = useWatch({control, name:'sample_type'})

    const handleDelete = async() =>{

        manageSample({
            method:'DELETE',
            body:{id:sample.id}
        }).unwrap()
        .then(res =>{
            //remove sample from state
            setSamplesState(prev => prev.filter(obj => obj.id !== sample.id))
        })
        .catch(err => show400Error(err))
    }
    
    return(
        <Box 
            className='flex-row'
            onClick={() => setOpen(prev => !prev)}
            sx={(theme) => getSx(theme, readOnly)}>
                    <Box className='flex-row align-center' gap={0.5}>
                        <LabPanelIcon color='primary' style={{fontSize:22}}/>
                        <Typography variant="body1" > Sample </Typography>
                        <Typography variant="body1">{ watchedSampleType ? ' - ' + watchedSampleType?.name : "" }</Typography>
                        {!readOnly && getToolbarStatusIcon(Object.keys(errors).length > 0)}
                    </Box>
                    <Box className='flex-row align-center' >                    
                        <ActionButton
                            onClick={handleDelete}
                            sx={{ 
                                display: readOnly? 'none' : '',
                                '& .MuiButton-startIcon':{
                                    margin: '0px !important', 
                                }}}
                            startIcon={
                            <DeleteIcon sx={(theme) =>({
                                height:24, 
                                width:24, 
                                color:theme.palette.action.active,
                                })}/>
                            }>
                        </ActionButton>
                        {
                            !open ?
                                <ExpandLessIcon size='small' color='action'/>
                            :
                                <ExpandMoreIcon size='small' color='action'/>
                        }
                    </Box>   
            </Box>
    )
}