import { Box, Modal, Typography } from "@mui/material"
import beyondMDLogo from "../../../../assets/images/beyondMD/beyondMD.png"
import beyondMDDarkLogo from "../../../../assets/images/beyondMD/beyondMDDark.png"
import { useTheme } from '@emotion/react';
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton";
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../auth/authSlice";



function TrialTutorialModal({ open, onClose, onOpenPopover }) {
    const theme = useTheme()
    const location = useLocation()
    const isDashboard = location.pathname.includes('dashboard')
    const {firstName} = useSelector(selectCurrentUser)

    const dashboardModalMessage = () => (
        <>
            <Typography sx={{ ...theme.typography.h6 }}>{`Welcome to Halo (BeyondMD's Clinical Tool), ${firstName}!`}</Typography>
            <Typography>In continuation of our selection process, we invite you to partake in a simulation assessment designed to gauge your expertise and alignment with the specific requirements of the role. This evaluation mirrors a task you would frequently undertake.</Typography>
            <Typography>Your performance will be appraised based on precision and comprehensive analysis. You have a duration of two weeks from today's date to finalize and submit your assessment. We look forward to your submission!
            </Typography>
        </>
    )

    const reviewModalMessage = () => (
        <>
            <Typography sx={{ ...theme.typography.h6 }}>{`Welcome to the pathology review page, ${firstName}!`}</Typography>
            <Typography> This review closely resembles a real word case that you will encounter in your daily workflow. This is where you will perform your test. </Typography>
            <Typography>Feel free to refer back to the instructions on the dashboard at any point by clicking on the house icon on the sidebar. Your work will automatically save after editing any of the fields.</Typography>
            <Typography>Best of luck on your assessment!</Typography>
        </>
    )

    return (
        <Modal open={open} disableEnforceFocus>
            <Box className='flex-col-center' sx={{ ...theme.modal.centered, ...theme.standardBox, maxWidth: 680, height: 479, border: 'none', '&:focus-visible': { outline: 'none' } }}>
                <Box className='flex-row-center' sx={{ backgroundColor: theme.palette.primary.shades12, height: 227, width: '100%' }}>
                    <img src={theme.palette.mode === 'dark' ? beyondMDDarkLogo : beyondMDLogo} alt='BeyondMD Logo' height={40} width={227} />
                </Box>
                <Box p={3} className='flex-col-flex-start' gap={1}>
                    {isDashboard ? dashboardModalMessage() : reviewModalMessage()}
                </Box>
                <Box className='flex-row-flex-end' width={'100%'} gap={1} p={'12px'}>
                    <ActionButton onClick={onClose} variant='contained' sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[600] :  theme.palette.action.active, color: '#fff' }}>Get started</ActionButton>
                    <ActionButton onClick={onOpenPopover} variant='contained' sx={{ backgroundColor: theme.palette.primary.main }}>Show me around</ActionButton>
                </Box>
            </Box>
        </Modal>
    )
}

export default TrialTutorialModal
