import { Alert, Box, LinearProgress } from "@mui/material";



export default function EmptyContentAlert({ progress = null, emptyContent = [] }) {
    return (
        <Box>
            {progress !== null && (
                <LinearProgress 
                    color="warning" 
                    variant="determinate" 
                    value={progress} 
                    sx={{ borderRadius: 1 }} 
                />
            )}
            <Alert severity="warning">
                <div>The following sections are currently empty:</div>
                <ul style={{ margin: '8px 0', paddingLeft: '20px' }}>
                    {emptyContent.map((section, index) => (
                        <li key={index}>{section}</li>
                    ))}
                </ul>
                <div>If these sections were intentionally left blank and do not require any content, please submit your review.</div>
            </Alert>
        </Box>
    );
}