import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';









export function FilterChipTitle({chipLabel, handleChipClose}){

    return(

        <>
        <Box className='flex-row-between' alignItems={'flex-end'} pl={2} pb={1} pr={1} >
            <Typography variant='subtitle2'>
                {chipLabel}
            </Typography>
            <IconButton onClick={handleChipClose} size="small" sx={{p:0}}>
                <CloseIcon />
            </IconButton>
        </Box>
        </>

    )
}