import dayjs from 'dayjs';
import CustomTextFieldCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell";
import CRUDDataGrid from '../../../../../globalComponents/DataGrid/CRUDDataGrid';
import CustomDateFieldCell from '../../../../../globalComponents/DataGrid/subComponents/customCells/CustomDateFieldCell';
import QACommentsContainer from '../../../../../qualityAssurance/components/QAFeedback/QACommentsContainer';
import TextFieldPortalCell from '../../../../../globalComponents/DataGrid/subComponents/customCells/TextFieldPortalCell';
import { LabIcon } from '../../../../../../assets/icons/MaterialDesignIcons';
import { useManageGxLabValuesMutation } from '../../../../geneomicsAPISlice';


export default function LabValues({readOnly, labValues, qaObj}){


  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      flex:1.5,
      headerClassName: readOnly? '' : 'required-col',
      editable: true, 
      renderEditCell: (params) => (
        <CustomTextFieldCell {...params} />
      ),
    },
    { 
      field: 'date', 
      headerName: 'Date',
      type:'date',
      width:120,
      editable: true,
      headerClassName: readOnly? '' : 'required-col',
      valueFormatter: (value) => value? dayjs(value).format('MMM DD, YYYY') : null,
      renderEditCell: (params) => (
        <CustomDateFieldCell {...params} />
      )
    },
    { 
      field: 'renal_function', 
      headerName: 'Renal Function',
      flex:1,
      editable: true
    },
    { 
      field: 'description', 
      headerName: 'Description',
      flex:2,
      headerClassName: readOnly? '' : 'required-col',
      editable: true ,
      type:'string',
      renderEditCell: (params) => (
        <TextFieldPortalCell {...params} />
      )
    },
  
    
  ]
  


  return(
    <>
      <CRUDDataGrid
        title={'Lab Values'}
        columns={columns} 
        initRows={labValues} 
        mutation={useManageGxLabValuesMutation}
        GridIcon={LabIcon}
        fieldToFocus='type'
        requiredFields={ ['type','date', 'description'] }
        isReadOnly={readOnly}/>
      {
        qaObj && 
        <QACommentsContainer fieldReview={qaObj?.field_reviews["Lab Values"]} /> 
      }
    </>
  )
}
  
