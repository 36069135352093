import { apiSlice } from '../../app/api/apiSlice'

export const dashboardApiSlice = apiSlice
.enhanceEndpoints({addTagTypes:['Dashboard', 'Dashboard Charts']})
.injectEndpoints({
    endpoints: builder => ({
        getDashboardData: builder.query({
            query: () => '/dashboard',
            providesTags: ['Dashboard']
        }),
        getDashboardChartsData: builder.query({
            query: ({chartType, today, chartPeriod}) => `/dashboard/charts/${chartType}/${today}/${chartPeriod}`,
            providesTags: ['Dashboard Charts']
        })
    })
})

export const {
    useGetDashboardDataQuery,
    useGetDashboardChartsDataQuery
} = dashboardApiSlice 
