import dayjs from 'dayjs';

export const calculateAge = (birthday) => {
    if(!birthday) return 0

    const today = dayjs();
    const birthDate = dayjs(birthday);
    const age = today.diff(birthDate, 'year');
    
    return isNaN(age) ? '-' : age.toString();
}




