import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import BaseSearchBar from "../BaseSearchBar"
import { debounce } from "lodash";




const getNestedStateValue = (state, path) => {
    const keys = path.split('.'); // Split the string into an array of keys
    return keys.reduce((acc, key) => acc?.[key], state); // Traverse the state object
  };
  
  



export default function ReduxStoreSearchBar({
    selectorPath,
    setter,
    ...props
}){ 
    const dispatch = useDispatch()
    const storeValue = useSelector((state) => getNestedStateValue(state, selectorPath))
    const [search, setSearch] = useState(storeValue)
    

    const handleChange = (e) => setSearch(e.target.value)
    const handleClear = (e) => setSearch('')

    const debouncedSearch = useCallback(
        debounce((value) => {
          dispatch(setter(value))
        }, 300),
        [setter, dispatch]
    );

    useEffect(() => {
        debouncedSearch(search)
    }, [search]) 
    // including debouncedSearch in dependencies results continous rerenders...


    return(
        <BaseSearchBar 
            value={search}
            onChange={handleChange}
            handleClear={handleClear}
            {...props}
        />
    )



}