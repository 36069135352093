import React from 'react'
import BreadCrumbHeader from '../../../globalComponents/Headers/BreadCrumbHeader'
import BackButton from '../../../globalComponents/SubHeader/Backbutton'

function EditUserInfoHeader({infoType}){


return (
    <BreadCrumbHeader
    title={'Profile'}
    crumbs={['My info', infoType]}
    startComponent={<BackButton/>}
    />
)
}

export default EditUserInfoHeader
