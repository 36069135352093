import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { enqueueSnackbar } from 'notistack'
import LeftIcon from '../../../../../assets/icons/ReferralIcons/Left.svg'
import RightIcon from '../../../../../assets/icons/ReferralIcons/Right.svg'
import MiddleIcon from '../../../../../assets/icons/ReferralIcons/Middle.svg'
import CurveUp from '../../../../../assets/icons/ReferralIcons/CurveUp.svg'
import CurveDown from '../../../../../assets/icons/ReferralIcons/CurveDown.svg'
import { show400Error } from "../../../../../app/errors/genericErrors";
import { useForm } from "react-hook-form";
import { ActionButton } from "../../../../globalComponents/Buttons/ActionButton/ActionButton";
import { useState } from "react";
import { HaloAsyncObjAutocomplete } from "../../../../globalComponents/ReactHookFormFields/AutocompleteFields/HaloAsyncObjAutocomplete";
import { useGetReferralLinkTypesQuery, useGetReferralListQuery, useSendReferralMutation } from "../../../../trialUsers/trialAdminAPISlice";
import { useGetRosterUsersQuery } from "../../../../rosterManagement/rosterAPISlice";
import HaloChipsInput from "../../../../globalComponents/ReactHookFormFields/ChipsInput/HaloChipsInput";
import { DataGridPro } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import ReferralStatusChip from "../../../utils/referralStatuses/ReferralStatusChip";
import DefaultNoRowsOverlay from "../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay";
import HaloSelectField from "../../../../globalComponents/ReactHookFormFields/SelectFields/HaloSelectField"


const HeroImage = () => (
    <Box className='flex-row-center' gap={'10px'} sx={{ padding: '8px 0px' }}>
        <img src={LeftIcon} alt='laptop' />
        <img src={CurveUp} alt='line up' />
        <img src={MiddleIcon} alt='briefcase' />
        <img src={CurveDown} alt='line down' />
        <img src={RightIcon} alt='notebook' />
    </Box>
)


const defaultValues = { email: '', referral_type: '' }
const defaultQueryParams = {
    pagination: { page: 0, pageSize: 5 },
    ordering: '-date_issued',
    search: ''
}

const columns = [
    {
        field: 'date_issued',
        headerName: 'Date sent',
        flex:1,
        valueGetter:(value,row) => value? dayjs(value).format('MMM D, YYYY') : '-'
    },
    {
        field: 'recipient_email',
        headerName: 'Email',
        flex:1
    },
    {
        field: 'trial_user',
        headerName:'Status',
        flex:1,
        renderCell: ( {row} ) => <ReferralStatusChip trialUser={row}/>

    }

]

export default function ReferralPanel({userGroups}) {
    const [ queryParams , setQueryParams ] = useState(defaultQueryParams)
    const { data, isLoading, isFetching } = useGetReferralListQuery(queryParams)
    const { control,  reset, handleSubmit } = useForm({ defaultValues })
    const [sendReferral, { data:referralRes, isLoading: isSendingReferralEmail }] = useSendReferralMutation()
    
    const isTrialReviewerOrAdmin = userGroups?.['Trial User | Reviewer'] || userGroups?.['Trial User | Admin']

    const sendReferralHandler = ({email, referral_type}) => {
        sendReferral({ email: email.join(','), referral_type: referral_type.id })
        .unwrap()
        .then((res) => {
            if(res?.sent_emails) enqueueSnackbar(res?.sent_emails, { variant: 'success'})
        
            reset({defaultValues})
        })
        .catch((err) => {
            show400Error(err)
        })
    }


    const handlePagination = (paginationModel) => setQueryParams((prev) => ({...prev, pagination: paginationModel}))

    return (
        <Box className='flex-col-center' gap={3}>
            <Box className='flex-col-center' sx={(theme) =>({ gap: 3, ...theme.standardBox, p: '24px 0px 40px 0px', borderRadius: 2, width:'100%' })}>
                <Box className='flex-col-center' gap={1} >
                    <Typography variant="h6">Invite your friends. </Typography>
                    <Typography variant="body2" color='text.secondary'>Invite your friends to earn with Halo!</Typography>
                    {
                        !isTrialReviewerOrAdmin &&
                        <HeroImage />
                    }
                </Box>
                <Box 
                    className='flex-col-center' 
                    gap={4} 
                    component={'form'} 
                    onSubmit={handleSubmit(sendReferralHandler)} 
                    noValidate>
                    <Box className='flex-col-center' sx={(theme) =>({...theme.standardBox, p:'56px 56px 48px 56px', gap:2.5, width:752})}>
                        {
                            referralRes?.not_sent_emails && 
                            <Alert severity="error">
                                <AlertTitle>Duplicate referral(s)</AlertTitle>
                                {referralRes?.not_sent_emails}
                            </Alert>
                        }
                        <HaloChipsInput 
                            required
                            label={'Email(s)'}
                            name='email'
                            control={control}
                        />
                        {
                            isTrialReviewerOrAdmin &&
                            <>
                            <HaloAsyncObjAutocomplete 
                                label='Referrer (optional)'
                                name='referrer'
                                query={useGetRosterUsersQuery}
                                optionKey='name'
                                control={control}
                            />
                            <HaloAsyncObjAutocomplete 
                                label='Type'
                                name='referral_type'
                                query={useGetReferralLinkTypesQuery}
                                optionKey='name'
                                required
                                control={control}
                            />
                            </>
                        }
                        <Box className='flex-col' sx={(theme) =>({'li::marker':{color:theme.palette.text.secondary}})} mt={'-10px'}>
                            <ul style={{ 
                                paddingLeft: 24, 
                                margin: 0, 
                            }}>
                                <li style={{ marginBottom: '0px' }}>
                                <Typography variant='caption' color='text.secondary'>
                                    Email link is valid for 1 week and is one time use.
                                </Typography>
                                </li>
                                <li style={{ marginBottom: '0px' }}>
                                <Typography variant='caption' color='text.secondary'>
                                    The clinician you've referred is on a two-week trial. They'll undergo a swift evaluation by submitting a pathology review.
                                </Typography>
                                </li>
                            </ul>
                        </Box>
                        <ActionButton
                            type='submit'
                            variant='contained'
                            sx={{ width: 104, mt: 0.5, }}
                            loading={isSendingReferralEmail}
                        >Send
                        </ActionButton>
                    </Box>
                </Box>
            </Box>
            <Box className='flex-col-center' sx={(theme) =>({ gap: 3, ...theme.standardBox, p: '24px 0px 40px 0px', borderRadius: 2, width:'100%' })}>
                <Box className='flex-col-center' gap={1} >
                    <Typography variant="h6">Your referrals</Typography>
                    <Typography variant="body2" color='text.secondary'>Users who have registred via your referral or have passed initial approval will appear here</Typography>
                    {
                        isTrialReviewerOrAdmin &&
                        <HeroImage />
                    }
                </Box>
                <Box 
                    className='flex-col-center' 
                    gap={4} >
                        <DataGridPro
                            columns={columns}
                            rows={data?.results || []}
                            rowCount={data?.count || 0}
                            sx={(theme) => ({...theme.standardBox, width:752 })}
                            disableRowSelectionOnClick
                            disableColumnMenu
                            autoHeight
                            pagination
                            loading={isLoading || isFetching}
                            paginationMode="server"
                            paginationModel={queryParams?.pagination}
                            onPaginationModelChange={handlePagination}
                            pageSizeOptions={[5, 10, 15, 20, 50]}
                            slots={{
                                noRowsOverlay:DefaultNoRowsOverlay
                            }}
                            slotProps={{
                                noRowsOverlay: { message: 'No sent referrals' },
                            }}
                        />
                </Box>
            </Box>
        </Box>
    )
}
