import HaloModal from "../../../../globalComponents/Modals/HaloModal";
import { useGetIntakeToBatchAnalysisQuery, useIntakeToBatchMutation } from "../../../rosterAdminAPISlice";
import GroupContainer from "../../../../globalComponents/Containers/GroupContainer";
import { Box} from "@mui/material";
import { useForm } from "react-hook-form";
import { HaloAsyncObjAutocomplete } from "../../../../globalComponents/ReactHookFormFields/AutocompleteFields/HaloAsyncObjAutocomplete";
import { useGetCaseTypesQuery } from "../../../rosterAPISlice";
import TypographyCard from "../../../../globalComponents/Cards/TypographyCard/TypographyCard";
import ResponsiveDoughtnutChart from "../../../../globalComponents/NivoCharts/Doughnut/ResponsiveDoughtnutChart";
import CustomNivoTooltip from "../../../../globalComponents/NivoCharts/Tooltips/CustomNivoTooltip";
import { useTheme } from "@emotion/react";
import HaloDateRangePickers from "../../../../globalComponents/ReactHookFormFields/DateFields/HaloDateRangePickers";
import dayjs from "dayjs";
import HaloModalStickyFooter from "../../../../globalComponents/Modals/HaloModalStickyFooter/HaloModalStickyFooter";
import { ActionButton } from "../../../../globalComponents/Buttons/ActionButton/ActionButton";
import { useEffect } from "react";


const defaultValues = {
    batch_dates: [dayjs(), dayjs()],
    case_type: '',
} 


export default function BulkPatientsToBatchModal({
    recordId = '',
    site,
    handleClose
}) {
    const theme = useTheme()
    const [ intakeToBatch, {isLoading:batchingPatients} ] = useIntakeToBatchMutation()
    const {data, isLoading, isFetching} = useGetIntakeToBatchAnalysisQuery(recordId, {skip: !recordId})
    const { patients_not_in_batch, patients_in_batch, eligible, ineligible, review_interval } = data || {}
    const { handleSubmit, control, reset } = useForm({ 
        defaultValues
    })

    const disabled = isFetching || isLoading || data?.patients_not_in_batch === 0

    const batchPatients = ({case_type, batch_dates}) => {

        intakeToBatch({
            record_id:recordId,
            body:{
                case_type: case_type.id,
                start_date: batch_dates[0].format('YYYY-MM-DD'),
                end_date: batch_dates[1].format('YYYY-MM-DD')
            }
        })
        .then(res => handleClose())
        .catch(err => {})

    }

    const stats = [
        {
            title: 'Site',
            value: site?.site_name
        },
        {
            title: 'Review interval',
            value: review_interval
        },
        {
            title: 'Eligible for review',
            value: eligible,
            endComponent: () => {
                
                const chartData = eligible || ineligible ?
                        [
                            {
                                id:0,
                                label:'Eligible',
                                value: eligible,
                                color: theme.palette.success.main
                            },
                            {
                                id:1,
                                label:'Ineligible',
                                value: ineligible ,
                                color: theme.palette.warning.light
                            }
                        ]
                    : 
                        [
                            {
                                id:0,
                                label:'No data available',
                                value: eligible + ineligible,
                                color: theme.palette.grey[300]
                            }
                        ]

                return (
                    <Box height={84} width={84}>
                        <ResponsiveDoughtnutChart
                            colors={{ datum: 'data.color' }} 
                            tooltip={obj => (
                                <CustomNivoTooltip
                                    stats={chartData}
                                />
                            )}
                            data={chartData}
                        />
                    </Box>
                )
            }
        },
        {
            title: 'Not yet in batch',
            value: patients_not_in_batch,
            endComponent: () => {
                const chartData = patients_in_batch || patients_not_in_batch ?
                [
                    {
                        id:0,
                        label:'Not yet batched',
                        value: patients_not_in_batch,
                        color: theme.palette.success.main
                    },
                    {
                        id:1,
                        label:'Batched',
                        value: patients_in_batch,
                        color: theme.palette.warning.light
                    }
                ]
            : 
                [
                    {
                        id:0,
                        label:'No data available',
                        value: patients_in_batch + patients_not_in_batch,
                        color: theme.palette.grey[300]
                    }
                ]

        return (
            <Box height={84} width={84}>
                <ResponsiveDoughtnutChart
                    colors={{ datum: 'data.color' }} 
                    tooltip={obj => (
                        <CustomNivoTooltip
                            stats={chartData}
                        />
                    )}
                    data={chartData}
                />
            </Box>
        )
            }
        },
 
    ]

    useEffect(() => {

        if(!recordId) reset(defaultValues)

    }, [recordId])  

    return(
        <HaloModal 
            open={Boolean(recordId)}
            handleClose={handleClose}
            title='Add patient(s) to batch'
            modalMaxWidth={880}
        >
            <Box component={'form'} noValidate onSubmit={handleSubmit(batchPatients)}>
                <Box className='flex-col' gap={2} p={2}>

                    <Box className='flex-row' gap={1} height={96} >
                        {
                            stats?.slice(0,2)?.map(({...props}) => (
                                <TypographyCard {...props} key={props?.title} loading={isLoading || isFetching}/>
                            ))
                        }
                    </Box>
                    <Box className='flex-row' gap={1} height={96} >
                        {
                            stats?.slice(2, 4)?.map(({...props}) => (
                                <TypographyCard {...props} key={props?.title} loading={isLoading || isFetching}/>
                            ))
                        }
                    </Box>
                    <GroupContainer title={'Batch distribution'}>
                        <Box className='flex-col' gap={3}>
                            <HaloDateRangePickers 
                                label='Batch date(s)'
                                name='batch_dates'
                                required
                                control={control}
                                minDate={dayjs().subtract(1, 'day')}
                                maxDate={dayjs().add(1, 'month')}
                                disabled={disabled}
                            />
                            <HaloAsyncObjAutocomplete
                                label='Case type'
                                name='case_type'
                                query={useGetCaseTypesQuery}
                                queryParams={{site:site?.id}}
                                optionKey={'name'}
                                required
                                control={control}
                                disabled={disabled}
                            />
                        </Box>
                    </GroupContainer>
                </Box>
                <HaloModalStickyFooter className='flex-row' justifyContent='flex-end' p={2} gap={2}>
                    <ActionButton 
                        variant='outlined' 
                        onClick={handleClose} 
                        disabled={batchingPatients}>
                        Cancel
                    </ActionButton>
                    <ActionButton 
                        type='submit' 
                        variant='contained' 
                        loading={batchingPatients} 
                        disabled={disabled}>
                        Batch patients
                    </ActionButton>
                </HaloModalStickyFooter>
            </Box>
        </HaloModal>
    )
}