import { useCallback, useState } from "react";
import StyledFilterChip from "../StyledFilterChip";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, IconButton, Menu, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FilterChipTitle } from "../FilterChipTitle";
import { SAOFCAutocomplete } from "./SAOFCAutocomplete";
import { ActionButton } from "../../../Buttons/ActionButton/ActionButton";







export default function SingleAsyncObjFilterChip({
    query, //must be a RTK Query
    chipLabel, 
    filterState, 
    setFilterState, 
    disableClear,
    optionKey
}){
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const isActive = Boolean(filterState?.[optionKey] != '')

    const handleChipClick = (event) => setAnchorEl(event.currentTarget);
    const handleChipClose = () => setAnchorEl(null); 

    const getLabel = useCallback(() => {

        if(!filterState?.[optionKey]) return chipLabel
        return filterState[optionKey]

    }, [filterState])

    const handleMenuItemClick = (event, value) => {
        setFilterState(value);
        setAnchorEl(null);
    };

    const handleClearFilter = useCallback((e) => {
        handleMenuItemClick(e, {id:'', name:'' });
    }, [handleMenuItemClick, ]);


    return(
        <>
        <StyledFilterChip 
            isActive={isActive}
            label={getLabel()}
            onClick={handleChipClick}
            onDelete={isActive && !disableClear? handleClearFilter : null}
            color={isActive ? 'primary' : 'default'}
            variant={isActive ? 'filled' : 'outlined'}
            icon={isActive? null:  <ArrowDropDownIcon />}
            />
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleChipClose}
            sx={(theme) => ({
                '& .MuiPopover-paper':{
                    boxShadow: theme.customShadows.elevation8,
                    borderRadius:2
                }
            })}
            >
                <Box className='flex-col'  width={288}  justifyContent={'space-between'}>
                        <FilterChipTitle chipLabel={chipLabel} handleChipClose={handleChipClose}/>
                        <SAOFCAutocomplete 
                            open={open} 
                            query={query} 
                            optionKey={optionKey}
                            chipLabel={chipLabel}
                            handleMenuItemClick={handleMenuItemClick}/>
                        <Box display="flex" justifyContent="flex-end" p={1} >
                            <ActionButton size='small' onClick={(e) => handleClearFilter(e)}>
                                Clear
                            </ActionButton>
                        </Box>
                </Box>
        </Menu>
        </>
    )
}