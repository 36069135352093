import {  useParams } from "react-router-dom"
import { useGetCaseDetailsQuery} from "../rosterAPISlice"
import { Box, Typography} from "@mui/material"
import PathologyDetailsContainer from "../../pathology/components/PathologyReview/PathologyDetailsContainer"
import InfectiousDiseaseDetailsContainer from "../../infectiousDisease/components/infectiousDiseaseReview/InfectiousDiseaseDetailsContainer"
import MainQAFeedbackContainer from "../../qualityAssurance/components/QAFeedback/MainQAFeedbackContainer"
import { useCallback, useEffect, useState } from "react"
import FaxModal from "../components/FaxModal/FaxModal"
import CaseDetailsNav from "../components/CaseNavHeaders/CaseDetailsNav"
import { useDispatch } from "react-redux"
import { setStaticMeds } from "../../pathology/pathologySlice"
import MDFeedbackContainer from "../../MDReview/components/MDFeedback/MDFeebackContainer"
import GenomicsDetailsContainer from "../../genomics/components/GeneomicsReview/GenomicsDetailsContainer"
import SummaryContainer from "../../globalComponents/Containers/SummaryContainer"
import { CaseHeaderLoader, SubHeaderLoader } from "../../globalComponents/Loaders"

export default function CaseDetails(){

    let { caseId } = useParams()
    const dispatch = useDispatch()
    const {data, isLoading, isFetching} = useGetCaseDetailsQuery(caseId)
    const {encounter, billable_event, is_same_day_editable, case_info, qa_obj, compensation_objs, md_review } = data || {}
    const caseType = case_info?.case_type || ''

    const [open, setOpen] = useState(false)
    
    useEffect(() => {
        
        dispatch(setStaticMeds(encounter?.patient?.medications))
        
    }, [data])

    const openFaxModal = useCallback(() =>{ setOpen(true) }, []) 
    
    const sharedProps = {
        isLoading: isLoading || isFetching,
        encounter: encounter,
        allowEdits: is_same_day_editable,
        caseInfo: case_info,
        qaObj: (qa_obj?.review_state_string === 'approved' || qa_obj?.review_state_string === 'rejected') ? qa_obj : null
    };

    if(isLoading || isFetching) {

        return(
            <>
            <SubHeaderLoader />
            <CaseHeaderLoader />
            <Box className= 'flex-col' p={3} gap={3}>
                <SummaryContainer isLoading={true}/>
                <SummaryContainer isLoading={true}/>
                <SummaryContainer isLoading={true}/>
            </Box>
            </>
            
        )

    }

    return(
        <>
            <CaseDetailsNav
                encounter={encounter}
                caseId={caseId}
                caseInfo={case_info}
                billableEvent={billable_event}
                compensationObjs={compensation_objs}
                qaObj={qa_obj}
                openFaxModal={openFaxModal}
                isLoading={isFetching || isLoading}
                caseType={caseType}
            />
                
            <Box className='flex-col' p={3} gap={3} >
                    <Typography variant="h6">
                        {caseType + ' case'}
                    </Typography>
                {
                    md_review?.feedback &&
                    <MDFeedbackContainer review={md_review}/>
                }
                {
                    (qa_obj?.review_state_string === 'approved' || qa_obj?.review_state_string === 'rejected') &&
                    <MainQAFeedbackContainer qaObj={qa_obj}/>
                }
                {
                    caseType.toLocaleLowerCase()?.includes('infectious disease') ?
                        <InfectiousDiseaseDetailsContainer {...sharedProps} />
                        :
                    caseType.toLocaleLowerCase()?.includes('pathology') ?
                        <PathologyDetailsContainer {...sharedProps} />
                        :        
                    caseType.toLocaleLowerCase()?.includes('gx') ?
                        <GenomicsDetailsContainer {...sharedProps}/>
                        :
                        null
                }
            </Box>
            <FaxModal patientId={encounter?.patient?.id} open={open} setOpen={setOpen}/>
        </>
    )


}
