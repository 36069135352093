import { Autocomplete, Box, CircularProgress,Popper,TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useGetSitesQuery } from "../../patients/patientAPISlice";
import { useTheme } from "@emotion/react";




export default function AsyncSiteAutoComplete({
    value, 
    setValue, 
    error, 
    onBlur, 
    forceOpen=false,
    disablePortal=false,
    required,
    key,
    ...props}){

    const theme = useTheme()
    const [isOpen, setOpen] = useState(forceOpen);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null)
    const { data:sites, isLoading } = useGetSitesQuery(undefined, { skip: !isOpen });


    useEffect(() => {
        if(isOpen && inputRef?.current) inputRef.current.focus()
    }, [inputRef, isOpen])


    return (
        <Autocomplete
            {...props}
            fullWidth
            freeSolo
            size="small"
            sx={{ p:2, pt:1 }}
            open={forceOpen ? true : isOpen}
            disablePortal={disablePortal}
            onOpen={() => setOpen(true)}
            onClose={() => forceOpen? null : setOpen(false)}
            onKeyDown={(e) => e.stopPropagation()}
            loading={isLoading}
            options={sites || []}
            getOptionLabel={(option) => option.site_name || ''}
            isOptionEqualToValue={(option, val) => option.site_name === val.site_name}
            value={value || ''}
            onChange={(e, newValue) => setValue('site', newValue) }
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    onBlur={onBlur}
                    required={required}
                    inputRef={inputRef}
                    label="Search site"
                    sx={{ 
                        '& .Mui-disabled': {
                            WebkitTextFillColor: `${theme.palette.text.primary} !important`,
                          },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),}}/>
            )}/>
    );
}