import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@emotion/react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { HaloNumberField } from '../../../../globalComponents/ReactHookFormFields/NumberFields/HaloNumberField';
import { ActionButton } from '../../../../globalComponents/Buttons/ActionButton/ActionButton';

const days = ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];

function CaseLoadPrefsDialog({open, handleDialog, prefs, setPrefs}){

    const theme = useTheme()
    const {control, reset, trigger, getValues} = useForm({
        defaultValues:prefs
    })

    const close = () => handleDialog(false)

    const confirmPrefs = async() =>{
        let valid = await trigger()
        if(!valid) return;

        let values = getValues()

        setPrefs({
            ...values,
            isEdited:true //lets the parent component know if prefs has been edited
        })
        close()
    }

    useEffect(() =>{
        if(prefs) reset(prefs)
    },[prefs])

    return(

        <Dialog open={open} >
            <Box component={'form'} noValidate > 
                <DialogTitle component='div' className='flex-row-between-center' sx={{width:280, p:'8px 16px'}}>
                    <Typography variant='subtitle2'>
                        Default Caseload 
                    </Typography>
                    <IconButton onClick={close}>
                        <ClearIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent 
                    className='flex-col' 
                    sx={{
                        p:0
                    }} >
                    <Box className='flex-row-between-center'  p={'12px 16px'}>
                        <CalendarMonthOutlinedIcon color='primary' sx={{height:25, width:25}}/>
                        <Typography sx={{...theme.typography.subtitle2}}>
                            Caseload
                        </Typography>
                    </Box>
                        {days?.map( (day, indx) =>(
                            <Box key={day} p={'5px 16px'} className='flex-row-between-center'>
                                <Typography sx={{...theme.typography.subtitle2, color:theme.palette.primary.main, textTransform:'uppercase'}}>
                                    {day.slice(0, 3)}
                                </Typography>
                                <HaloNumberField 
                                    sx={{width:68}}
                                    required
                                    hideHelperText
                                    name={day}
                                    maxValue={20}
                                    control={control}
                                />
                            </Box>
                        ))}
                </DialogContent>
                <DialogActions>
                    <ActionButton  onClick={close}>
                        Cancel
                    </ActionButton>
                    <ActionButton 
                        variant='contained' 
                        color='primary' 
                        onClick={(e) =>confirmPrefs()} 
                        size='small'
                        >
                        Confirm
                    </ActionButton>
                </DialogActions>
            </Box>
        </Dialog>


    )
}

export default memo(CaseLoadPrefsDialog)
