import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


//because cases are distributed at 3AM EST
// we have to handle the dates to be sent in EST 
// no timezone defaults this to UTC, causing early cutoffs for users
export const todayEST = () => dayjs().tz("America/New_York").format('YYYY-MM-DD');
