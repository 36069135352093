import { ResponsivePie } from '@nivo/pie'



export default function ResponsiveDoughtnutChart({data, ...otherProps}){

    /* 
        data comes   in an array of objects 
        e.g. [ {id, label, value, color}, {id, label, value, color}]
    */


    return(
        <ResponsivePie
        {...otherProps}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        data={data || [ ]}
        margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
        innerRadius={0.6}
        padAngle={0.7}
        activeOuterRadiusOffset={8}
        
    />
    )
}