import { useTheme } from "@emotion/react"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../auth/authSlice"
import { useState } from "react"
import ProfileHeader from "../components/ProfileHeader/ProfileHeader"
import { Box, Divider } from "@mui/material"
import ProfileTabs from "../components/TabsAndPanels/ProfileTabs"
import MyProfilePanel from "../components/TabsAndPanels/Panels/MyProfilePanel"
import MyPayslipsPanel from "../components/TabsAndPanels/Panels/MyPayslipsPanel"
import ProfileSettingsPanel from "../components/TabsAndPanels/Panels/ProfileSettingsPanel"
import ReferralPanel from "../components/TabsAndPanels/Panels/ReferralPanel"

function Profile(){

    const theme = useTheme()
    const user = useSelector(selectCurrentUser)
    const [tabValue, setTabValue] = useState('My info')

    const handleTabChange = (event, newValue) => setTabValue(newValue)

return (
    <>
        <ProfileHeader user={user} tabValue={tabValue}/>
        <Box sx={{background:theme.palette.background.paper}}>
        <ProfileTabs
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            userGroups ={user?.groups}
        />
        <Divider/>
        </Box>

        <Box className='flex-col' p={3} gap={2}>
            {
            tabValue === 'My info' ? 
                <MyProfilePanel /> :
            tabValue === 'My payslips'?
                <MyPayslipsPanel /> :
            tabValue === 'Referrals' ? 
                <ReferralPanel userGroups={user?.groups}/>:
            tabValue === 'Settings'?
                <ProfileSettingsPanel /> 
            : null
            }
        </Box>
    </> 


)
}

export default Profile
