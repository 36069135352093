import CaseloadTabs from "../components/Tabs/CaseloadTabs";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import CaseloadTabPanel from "../components/Tabs/CaseloadTabPanel/CaseloadTabPanel";
import { useGetCaseloadQuery} from "../rosterAPISlice";

import CaseLoadHeader from "../components/CaseloadHeader/CaseLoadHeader";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentCaseloadTab, setCurrentCaseloadTab } from "../rosterSlice";
import { todayEST } from "../../../utils/dateUtils/dayJsEST";
import CaseHistoryTabPanel from "../components/Tabs/CaseHistoryTabPanel/CaseHistoryTabPanel";

const today = todayEST()

export default function Caseload(){

    const dispatch = useDispatch()
    const theme = useTheme()
    const tabValue = useSelector(selectCurrentCaseloadTab).activeCaseloadTab
    const {data, isLoading} = useGetCaseloadQuery(today)
    const {daily_schedule,history_count, assignments} = data || {}
    
    
    const handleTabChange = (event, newValue) => dispatch(setCurrentCaseloadTab(newValue))

    return (
        <>
            <CaseLoadHeader dailySchedule={daily_schedule} tabValue={tabValue}/>
            <Box sx={{background:theme.palette.background.paper}}>
                <CaseloadTabs
                    caseLoadCount={assignments?.length}
                    historyCount={history_count}
                    tabValue={tabValue}
                    handleTabChange={handleTabChange} />
            </Box>
            <Box className='flex-col' p={3} gap={2}>
                {
                    tabValue === 'Caseload'?
                        <CaseloadTabPanel
                            assignments={assignments}
                            isLoading={isLoading}/>:
                    tabValue === 'History'?
                        <CaseHistoryTabPanel md_review={false}/>
                    :
                        null //future tabs will be added here
                }
            </Box>
        </>
    )
}
