import { createContext, useContext, useState, useRef, useEffect, createRef } from 'react';

const TutorialContext = createContext();

export const TutorialProvider = ({ children }) => {
    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);

    const refs = useRef([]);

    const addStep = (stepDescription) => {
        const newRef = createRef();
        refs.current.push(newRef);
        setSteps((prevSteps) => [...prevSteps, { description: stepDescription, ref: newRef }]);
    };

    const nextStep = () => {
        setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    };

    const prevStep = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    //clears all refs to prevent previous refs from carrying over
    const clearRefs = () => {
        refs.current = [];
        setSteps([]);
        setCurrentStep(0);
    };

    return (
        <TutorialContext.Provider
            value={{
                steps,
                currentStep,
                currentRef: steps[currentStep]?.ref,
                description: steps[currentStep]?.description,
                addStep,
                nextStep,
                prevStep,
                clearRefs,
                refs
            }}
        >
            {children}
        </TutorialContext.Provider>
    );
};

export const useTutorial = () => useContext(TutorialContext);
