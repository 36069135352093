import { useRef, useMemo } from 'react';

function usePaginatedRowCount(count) {
  //prevents undefined row count error 
  //https://mui.com/x/react-data-grid/pagination/#server-side-pagination

  const rowCountRef = useRef(count || 0);

  const rowCount = useMemo(() => {
    if (count !== undefined) {
      rowCountRef.current = count;
    }
    return rowCountRef.current;
  }, [count]);

  return rowCount

}

export default usePaginatedRowCount;
