import { createSlice } from '@reduxjs/toolkit'

const trialUserSlice = createSlice({
    name: 'trial users',
    initialState: {
        currentTrialEncounter: {
            patient: {
                id: null,
                name: ''
            },
            encounterId: null,
            caseType: ''
        },
        trialStaticMeds: []
    },
    reducers: {
        setCurrentTrialEncounter: (state, action) => {
            state.currentTrialEncounter = {
                ...state.currentTrialEncounter,
                ...action.payload
            }
        },
        setTrialStaticMeds: (state, { payload }) => {
            state.trialStaticMeds = payload
        },
        manageTrialStaticMeds: (state, { payload }) => {
            if (payload.method === 'POST') {
                state.trialStaticMeds.push(payload.data)
            }
            else if (payload.method === 'PUT') {
                const index = state.trialStaticMeds.findIndex(med => med.id === payload.data.id)
                state.trialStaticMeds[index] = payload.data
            }
            else if (payload.method === 'DELETE') {
                state.trialStaticMeds = state.trialStaticMeds.filter(med => !payload.body.ids.includes(med.id))
            }
        }

    }
})

export const { setCurrentTrialEncounter, setTrialStaticMeds, manageTrialStaticMeds } = trialUserSlice.actions

export const selectCurrentTrialEncounter = (state) => state.trialUser.currentTrialEncounter
export const selectTrialStaticMeds = (state) => state.trialUser.trialStaticMeds


export default trialUserSlice.reducer
