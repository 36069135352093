import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, IconButton } from '@mui/material';





export default function HeaderCollapseBtn({show, showShadow, toggleShow}){


    return(
        <Box
            sx={(theme)=>({
                background: theme.palette.background.paper,
                position: 'absolute' ,
                zIndex:1200,
                left:'50%',
                right:'50%',
                borderRadius: '50%',
                height: 32,
                width: 32,
                bottom:-15,
                boxShadow: (showShadow || show ) ? '0px 4px 5px 0px rgba(0, 0, 0, 0.07),0px 2px 4px -1px rgba(0, 0, 0, 0.1)':'',
            })}>
                <IconButton 
                    onClick={toggleShow}
                    sx={(theme)=>({
                        height:32,
                        width:32,
                        borderRadius: '50%',
                        border:1,
                        borderColor: theme.palette.divider,
                        background:theme.palette.background.paper,
                    })}>
                {
                    show? <ExpandLessIcon /> : <ExpandMoreIcon/>
                }
            </IconButton>

        </Box>
    )


    if(show){
        return(
        <Box sx={(theme) =>({ 
            position: 'absolute', 
            bottom: 0, 
            left: '50%', 
            transform: 'translateX(-50%)',
            borderRadius:'8px 8px 0px 0px',
            background:theme.palette.background.paper,
            })}>
            <IconButton 
                onClick={toggleShow} 
                sx={(theme) => ({
                    height:24,
                    width:56,
                    borderRadius:'8px 8px 0px 0px',
                    
                    border: 1,
                    borderBottom:0,
                    borderColor: theme.palette.divider,
                })}
                >
                <ExpandLessIcon/>
            </IconButton>
        </Box>
        )
    }


    return(
        <Box
            sx={(theme) => ({
                position:'fixed',
                zIndex:1200,
                left:'50%',
                right:'50%',
                top:62,
                width:'fit-content',
                borderRadius:'0px 0px 8px 8px',
                boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.07),0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
                background:theme.palette.background.paper,

            })}>
            <IconButton
                onClick={toggleShow} 
                sx={(theme) => ({
                    borderRadius:'0px 0px 8px 8px',
                    height:24,
                    width:56,
                    border: 1,
                    borderTop:0,
                    borderColor: theme.palette.divider,
                    zIndex:1300,
                })}
                >
                <ExpandMoreIcon/>
            </IconButton>
        </Box>
    )
}