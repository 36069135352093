import { Route } from "react-router-dom";
import PatientProfile from "./pages/PatientProfile";
import RequireAuth from '../auth/RequireAuth'


export const patientRoutes = [
    <Route path='/patients' element={<RequireAuth/>} key='patients'>
        <Route path='profile/:patientId' element={<PatientProfile/>}/>
    </Route>
    
]
