import React from 'react';
import { Box, Typography } from '@mui/material';
import { stringToTitleCase } from '../../../../../../utils/stringUtils/titleCase';

const getWarningText = action => action === 'delay' ?
  ' Please only delay if you are certain the case can wait until tomorrow.' :
  ' Please only remove a case if it 100% requires no action or clinical review.';

const DRModalMessage = ({ modalAction, selectedRow, theme }) => {
    return (
        <Box className='flex-col' sx={{ gap: 3 }}>
            <Typography variant="body2">
                Are you sure you want to {modalAction + ' ' + stringToTitleCase(selectedRow?.patient?.name)}'s • {selectedRow?.case_type} case?
                <span style={{ color: theme.palette.error.main, fontWeight: 500 }}>{getWarningText(modalAction)}</span>
            </Typography>
            
            <Typography variant='body2'>Type your email to confirm</Typography>

            {modalAction === 'remove' &&
                <Typography variant="body2" color="text.disabled"> * You will automatically have another case added if one is available</Typography>
            }
        </Box>
    );
};

export default DRModalMessage;
