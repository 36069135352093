import { TextField } from "@mui/material"
import { useGridApiContext } from "@mui/x-data-grid-pro"
import { useEffect, useLayoutEffect, useRef, useState } from "react"


const inputSx ={
    width:'100%',
    height:'100%',
    '& .MuiOutlinedInput-root': {
        borderRadius: '0px', 
    },
    '& fieldset': {
        border: 'none',
    },
}


export default function CustomTextFieldCell(props){
    const { 
        id,
        value,
        hasFocus, 
        field,
        row,
    } = props
    const apiRef = useGridApiContext()
    const ref = useRef()
    const error = row?.errors?.includes(field)
    
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus()
        }
    }, [hasFocus])


    const handleValueChange = (event) => {
        const newValue = event.target.value
        apiRef.current.setEditCellValue({ id, field, value: newValue})
    }



    return <TextField
                tabIndex={props.tabIndex}
                error={error}
                inputRef={ref}
                autoComplete={field}
                name={field}
                value={value || ''}
                onChange={handleValueChange}
                sx={inputSx}/>
    }