import { createSlice } from "@reduxjs/toolkit"
import dayjs from "dayjs"

const rosterSlice = createSlice({
    name: 'roster',
    initialState:{
        scheduleConfirmation:{
            isConfirmed:true, // false will trigger schedule confirmation modal
            startDate:null,
            endDate:null,
        },
        currentCase:{ //used for infec/path workflows
            patient:{
                id:null,
                name:''
            },
            encounterId: null,
            caseId: null,
            caseType:'',
            totalSessionDurationInSeconds: null
        },
        caseHistoryFilters:{
            case_type: '',
            resolution_status:'completed',
            sites: [],
            ordering: '-date_submitted',
            direction: 'desc',
            md_review: '' ,
            searched:'',
            pagination: { page: 0, pageSize: 10 },
            start_date: null,
            end_date: null
        },
        currentTab: {
            activeCaseloadTab: 'Caseload'
        },
        isReqCaseDialogOpen: false,
    },
    reducers: {
        //pass the keys you want updated
        setCurrentCase :(state, action) =>{
            state.currentCase = {
                ...state.currentCase,
                ...action.payload
            }
        },
        setScheduleConfirmation: (state, action) =>{
            state.scheduleConfirmation = {
                ...state.scheduleConfirmation,
                ...action.payload
            }
        },
        setCaseHistoryFilters: (state, action) => {
            state.caseHistoryFilters = {
                ...state.caseHistoryFilters,
                ...action.payload,
            }
        },
        resetCaseHistoryPagination: (state) => {
            state.caseHistoryFilters.pagination = { page: 0, pageSize: 10 }
        },
        handleCaseHistoryFiltersPagination: (state, action) => {
            state.caseHistoryFilters.pagination = {...action.payload}
        },
        setCurrentCaseloadTab: (state, action) => {
            state.currentTab.activeCaseloadTab = action.payload
        },
        setIsReqCaseDialogOpen: (state, action) => {
            state.isReqCaseDialogOpen = action.payload
        },
    }
})


export const {
    setCurrentCase, setScheduleConfirmation, setCaseHistoryFilters, resetCaseHistoryPagination,
    handleCaseHistoryFiltersPagination, setCurrentCaseloadTab, setIsReqCaseDialogOpen
} = rosterSlice.actions

export const selectCurrentCase = (state) => state.roster.currentCase
export const selectScheuleConfirmation = (state) => state.roster.scheduleConfirmation
export const selectCaseHistoryFilters = (state) =>state.roster.caseHistoryFilters
export const selectCurrentCaseloadTab = (state) => state.roster.currentTab
export const selectIsReqCaseDialogOpen = (state) => state.roster.isReqCaseDialogOpen


export default rosterSlice.reducer;
