import { Box, Divider } from "@mui/material";








// use only within a HaloModal component
//has a fixed 66px height maybe we can pass a prop for that height if needed?
export default function HaloModalStickyFooter({children, sx={}, ...otherProps}) {


    return(
        <>  
        <Box height={66}></Box> {/* Spacer */}
        <Box {...otherProps} 
            sx={(theme) => ({
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                background: theme.palette.background.paper,
                borderTop: `1px solid ${theme.palette.divider}`,
                maxHeight:66,
                borderRadius:2 ,
                ...sx
            })}>
            {children}
        </Box>
        </>
    )


}