import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";




export const QABody = ({ data }) => {




    return(
        <Box className='flex-col' gap={0.5}>
            <Typography sx={(theme) => ({ ...theme.typography.caption, color: theme.palette.error.main })}>
                Rejected on {dayjs(data.qa_feedback.created_at).format("MMM DD, YYYY h:mm A")}
            </Typography>
            <Box className='flex-col' sx={(theme) => ({ p: 1, borderRadius: 1, background: theme.palette.error.shades4 })}>
                <Typography variant='caption' color='text.disabled'>
                    Score: {data.qa_feedback?.score_percentage}%
                </Typography>
                <Typography variant='caption' color='text.disabled'>
                    Reviewer: {data.qa_feedback?.qa_reviewer_name}
                </Typography>
                <Typography variant='caption' color='text.disabled'>
                    General feedback: {data.qa_feedback?.final_feedback}
                </Typography>

            </Box>
        </Box>
    )
}