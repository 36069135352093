import { Box, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ActionButton } from '../../../../../../../../../globalComponents/Buttons/ActionButton/ActionButton';
import { useUnassignCasesMutation } from '../../../../../../../../rosterAdminAPISlice';
import { useTheme } from '@emotion/react';
import { enqueueSnackbar } from 'notistack';
import { show400Error } from '../../../../../../../../../../app/errors/genericErrors';






export default function UnassignDialog({
    open,
    handleClose,
    selected,
    setSelected,
}){
    const theme = useTheme()
    const [manageCases, {isLoading}] = useUnassignCasesMutation()

    const unassignCases = (e) => {
        e.preventDefault()
        manageCases({ case_ids: selected })
        .unwrap()
        .then((res) => {
            setSelected([])
            handleClose()
            enqueueSnackbar(res, {variant:'success'})

        })
        .catch((err) => 
            show400Error(err)
        )   
    }


    return(
        <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth component={'form'} onSubmit={unassignCases} noValidate>
                <DialogTitle >
                    Unassign cases
                </DialogTitle>
                <DialogContent>
                    <Box className='flex-col' gap={2} >
                    <DialogContentText color={'inherit'}>
                        <span style={{color:theme.palette.primary.main}}>{selected?.length}</span> cases(s) will be unassigned.
                    </DialogContentText>
                        
                    </Box>
                </DialogContent>
                <DialogActions>
                    <ActionButton
                        variant='outlined' 
                        onClick={() => {
                            setSelected([])
                            handleClose()
                        }} 
                        disabled={isLoading}>
                            Cancel
                    </ActionButton>
                    <DialogActions>
                        <ActionButton
                            type='submit'
                            variant='contained'
                            loading={isLoading}
                            >
                            Unassign
                        </ActionButton>
                    </DialogActions>
                </DialogActions>
            </Dialog>
    )
}