import { gridClasses } from '@mui/x-data-grid';






//hides blue cell outline 
//shows mouse pointer as element is linked
export const linkedRowProps = {
    border:'1px solid rgba(255,255,255,0)',
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
        },
    '& .MuiDataGrid-row:hover': {
        cursor: 'pointer'
        }
}

export const stickyHeaderProps = {
    position: "sticky", 
    top: 0, 
    zIndex: 1, 
    "& .MuiDataGrid-columnHeaders": {
        position: "sticky",
        top: 0,
        zIndex: 1,
    },
    [`.${gridClasses.virtualScroller}, .${gridClasses.main}`]: {
        overflow: 'unset !important',
      },
    // "& .MuiDataGrid-virtualScroller": {
    //   // remove the space left for the header
    //   marginTop: "0!important"
    // }
}

export const hideSelectAllCheckBoxProps ={
    '& .MuiDataGrid-columnHeaderTitleContainerContent': { '& .MuiCheckbox-root' : { visibility:'hidden' } },
}


