
import { useCallback, useLayoutEffect, useState } from 'react';




export const useSaveGridState = (stateKeyName, apiRef, defaultState={}) => {

    const [initialState, setInitialState] = useState();



    const saveSnapshot = useCallback(() => {
        
        if (apiRef?.current?.exportState && localStorage) {
          const currentState = apiRef.current.exportState();
          localStorage.setItem(stateKeyName, JSON.stringify(currentState));
        }
      }, [apiRef]);
    
    useLayoutEffect(() => {
        const stateFromLocalStorage = localStorage?.getItem(stateKeyName);
        setInitialState( stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : defaultState );

        // handle refresh and navigating away/refreshing
        window.addEventListener('beforeunload', saveSnapshot);

        return () => {
            // clean up
            window.removeEventListener('beforeunload', saveSnapshot);
            saveSnapshot();
        };
    }, [saveSnapshot]);


    return initialState

}