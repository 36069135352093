import { apiSlice } from "../../app/api/apiSlice";


export const rosterAdminApiSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ['Roster Admin | All', 'Roster Admin | Stats', 'Clinicians Grid', 'Intake Analysis', 'Site Assignments', 'Case Type Permissions', 'Roster Admin | Clinician Case History', 'Clinician Settings'] })
    .injectEndpoints({
        endpoints: builder => ({
            getCasePerformanceStats: builder.query({
                query: ({ dates, userId = false }) => {
                    let url = `roster/case-stats/performance/${dates?.join(',')}`
                    if (userId) url += `/?user_id=${userId}`

                    return url
                },
                providesTags: ['Roster Admin | Stats']
            }),
            getCaseAsgmtStats: builder.query({
                query: (dates) => `roster/case-stats/assignment/${dates?.join(',')}`,
                providesTags: ['Roster Admin | Stats']
            }),
            getCaseStatsBarCharts: builder.query({
                query: ({ dates, statType, userId = false }) => {
                    let url = `roster/case-stats/bar-chart/${dates?.join(',')}/${statType}/`
                    if (userId) url += `?user_id=${userId}`

                    return url
                },
                providesTags: ['Roster Admin | Stats']
            }),
            getCaseDoughnutCharts: builder.query({
                query: ({ dates, statType }) => `roster/case-stats/doughnut-chart/${dates?.join(',')}/${statType}`,
                providesTags: ['Roster Admin | Stats']

            }),
            getUsersCaseStats: builder.query({
                query: (dates) => `roster/case-stats/users/${dates?.join(',')}`,
                providesTags: ['Roster Admin | All']

            }),
            getCaseObjects: builder.query({
                query: ({ pagination, ordering, search, resolution_status, case_type, sites, user }) => {
                    let url = `roster/cases/today/?page=${pagination.page + 1}&ordering=${ordering}&size=${pagination.pageSize}&search=${search}&resolution_status=${resolution_status}&case_type=${case_type}&user=${user?.id}`

                    if (sites?.length) url += `&site=${sites?.map((site) => site?.id).join(',')}`

                    return url
                },
                providesTags: ['Roster Admin | All']
            }),
            getSiteAndSettings: builder.query({
                query: (siteId) => `roster/distribution/site/${siteId}`
            }),
            getClinicianAndSettings: builder.query({
                query: (clinicianId) => `roster/distribution/clinician/${clinicianId}`,
                providesTags:['Clinician Settings']
            }),
            getClinicianCaseAsgmtHistory: builder.query({
                query: ({ dates, clinicianId, queryParams }) => {
                    const {pagination, ordering, status, sites, case_type, search} = queryParams
                    let url = `roster/case-asmgts/history/${dates?.join(',')}/${clinicianId}/?page=${pagination?.page + 1}&size=${pagination?.pageSize}`

                    if(ordering) url += `&ordering=${ordering}`
                    if(status) url += `&status=${status}`
                    if(sites) url += `&site=${sites?.map((site) => site?.id).join(',')}`
                    if(case_type) url += `&case_type=${case_type}`
                    if(search) url += `&search=${search}`

                    return url
                },
                providesTags: ['Roster Admin | Clinician Case History']
            }),
            manageCases: builder.mutation({
                query: ({ method, body, type }) => ({
                    url: 'roster/cases/asgmt/' + type,
                    method,
                    body
                }),
                invalidatesTags: ['Roster Admin | All', 'Roster Admin | Stats']
            }),
            unassignCases: builder.mutation({
                query: (payload) => ({
                    url: 'roster/cases/unasgmt',
                    method: 'PUT',
                    body: payload
                }),
                invalidatesTags: ['Roster Admin | All', 'Roster Admin | Stats', 'Roster Admin | Clinician Case History']
            }),
            reopenCases: builder.mutation({
                query: (payload) => ({
                    url: 'roster/cases/reopen',
                    method: 'PUT',
                    body: payload
                }),
                invalidatesTags: ['Roster Admin | All', 'Roster Admin | Stats', 'Roster Admin | Clinician Case History', 'Patient Profile']
            }),
            removeCasesFromBatch: builder.mutation({
                query: (payload) => ({
                    url: 'roster/cases/batch-remove',
                    method: 'PUT',
                    body: payload
                }),
                invalidatesTags: ['Roster Admin | All', 'Roster Admin | Stats']
            }),
            getSiteAssignments: builder.query({
                query: (userIds) => ({
                    url: 'roster/users/settings/site-assignments',
                    params: { user_ids: userIds },
                    paramsSerializer: (params) => {
                        return Object.entries(params)
                            .map(([key, value]) => `${key}=${value}`)
                            .join('&')
                    }
                }),
                providesTags: ['Site Assignments']
            }),
            modifySiteAssignments: builder.mutation({
                query: (payload) => ({
                    url: 'roster/users/settings/site-assignments',
                    method: 'PUT',
                    body: payload
                }),
                invalidatesTags: ['Site Assignments', 'Clinician Settings']
            }),
            getUserCaseTypePermissions: builder.query({
                query: (userIds) => ({
                    url: 'roster/users/settings/case-type-permissions',
                    params: {user_ids: userIds},
                    paramsSerializer: (params) => {
                        return Object.entries(params)
                            .map(([key,value]) => `${key}=${value}`)
                            .join('&')
                    }   
                }),
                providesTags: ['Case Type Permissions']
            }),
            modifyUserCaseTypePermissions: builder.mutation({
                query: (payload) => ({
                    url: 'roster/users/settings/case-type-permissions',
                    method: 'PUT',
                    body: payload
                }),
                invalidatesTags: ['Case Type Permissions', 'Clinician Settings']
            }),
            getIntakeToBatchAnalysis: builder.query({
                query: (record_id) => 'roster/cases/intake_to_batch/' + record_id,
                providesTags: ['Intake Analysis']
            }),
            intakeToBatch: builder.mutation({
                query: ({ record_id, body }) => ({
                    url: 'roster/cases/intake_to_batch/' + record_id,
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['Intake Records', 'Intake Analysis']
            }),
            modifyUserEmploymentType: builder.mutation({
                query: (payload) => ({
                    url: 'roster/users/settings/employment-type',
                    method: 'PUT',
                    body: payload
                }),
                invalidatesTags: ['Clinician Settings']
            }),
            modifyUserTrialReviewerPermission: builder.mutation({
                query: (payload) => ({
                    url: 'roster/users/settings/trial-reviewer-permission',
                    method: 'PUT',
                    body: payload
                }),
                invalidatesTags: ['Clinician Settings']
            }),
            getDeleteCaseRelatedObjects: builder.query({
                query: (caseId) => `roster/case/delete/${caseId}`
            }),
            deleteCase: builder.mutation({
                query: (caseId) => ({
                    url:`roster/case/delete/${caseId}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['Patient Profile', 'Patient List']
            }),
            getHaloUsers: builder.query({
                query: () => 'users/list/'
            }),
            getCaseHistory: builder.query({
                query: (caseId) => 'roster/case/history/' + caseId 
            })
        })
    })


export const {
    useGetCasePerformanceStatsQuery, useGetCaseAsgmtStatsQuery, useGetCaseStatsBarChartsQuery,
    useGetCaseDoughnutChartsQuery, useGetUsersCaseStatsQuery,
    useGetCaseObjectsQuery, useGetSiteAndSettingsQuery, useGetClinicianAndSettingsQuery,
    useGetClinicianCaseAsgmtHistoryQuery, useManageCasesMutation,
    useUnassignCasesMutation, useRemoveCasesFromBatchMutation, useGetSiteAssignmentsQuery,
    useModifySiteAssignmentsMutation, useGetIntakeToBatchAnalysisQuery, useIntakeToBatchMutation,
    useGetUserCaseTypePermissionsQuery, useModifyUserCaseTypePermissionsMutation,
    useGetDeleteCaseRelatedObjectsQuery, useDeleteCaseMutation, useGetHaloUsersQuery,
    useGetCaseHistoryQuery, useReopenCasesMutation, useModifyUserEmploymentTypeMutation, useModifyUserTrialReviewerPermissionMutation
} = rosterAdminApiSlice
