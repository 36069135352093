import { Box, Skeleton } from "@mui/material";





export default function GSLoader(){



    return(
        <Box className='flex-col'>
            <Box className='flex-row' gap={1}>
                <Skeleton width={96}/>
                <Skeleton width={30}/>
            </Box>
            <Skeleton height={100}/>
        </Box>
    )
}