import Analysis from "./Analysis/Analysis"
import DataInput from "./DataInput/DataInput"
import Recommendations from "./Recommendations/Recommendations"







export default function GenomicsDetailsContainer({ encounter, isLoading, allowEdits = false, caseInfo, qaObj }){


    const { patient } = encounter || {}

    const sharedProps = {
        readOnlyView: true,
        allowEdits: allowEdits,
        caseInfo: caseInfo,
        qaObj: qaObj,
        isLoading: isLoading
    };

    return(
        <>
            <DataInput 
                meds={patient?.medications}
                diagnoses={patient?.icd10_references}
                genes={encounter?.genes}
                allergies={patient?.allergies}
                {...sharedProps}
            />
            <Analysis 
                labValues={encounter?.lab_values}
                imagingValues={encounter?.imaging_values}
                drugInteractions={encounter?.drug_interactions}
                {...sharedProps}
            />
            <Recommendations 
                encounter={encounter}
                {...sharedProps}
            />
        </>
    )

}