import { Box, Typography, Divider } from "@mui/material"
import Header from "../../dashboard/components/Header/Header"
import TrialToDoList from "../components/TrialDashboard/TrialToDoList"
import TrialInfo from "../components/TrialDashboard/TrialInfo"
import { useTheme } from "@emotion/react";
import { useGetTrialDashboardDataQuery, useSetTutorialStatusMutation } from "../trialUserAPISlice";
import TrialUserStepper from "../components/TrialUserProfile/TrialUserStepper";
import { useLocation } from "react-router-dom";
import TrialTutorialModal from "../components/Tutorial/TrialTutorialModal";
import { useEffect, useState } from "react";
import { useGetTutorialStatusQuery } from "../trialUserAPISlice";
import TrialTutorialPopover from "../components/Tutorial/TrialTutorialPopover";
import DismissibleAlert from "../../globalComponents/Alert/DismissibleAlert";
import StaticAlert from "../../globalComponents/Alert/StaticAlert";
import { useTutorial } from "../utils/TutorialContext";


const dashboardTutorialSteps = [
    "Both test encounters must be submitted prior to the trial end date stated here.",
    "Review the instructions provided prior to beginning your test encounters.",
    "View your assigned test encounters here and select a test encounter to begin your review.",
    "Track the progress of your trial account here."
]


function TrialDashboard() {
    const theme = useTheme()

    const { data } = useGetTrialDashboardDataQuery()


    const { trial_encounters, trial_info } = data || {}

    const { review_state, referrer, trial_end } = trial_info || {}

    const location = useLocation()
    const [openAlert, setOpenAlert] = useState(false)

    const isInfectious = trial_info?.trial_type?.toLowerCase().includes('infectious')
    

    const instructions = () => (
        <>
            We require our trial clinicians to submit two case reviews.
            <br />
            <br />
            For each patient you will find a PDF with the patient's test results. Address each consult note to 'Dear Provider'. 
            <br />
            <br />
            {
                isInfectious ?
                " Using the lab results provided, identify an appropriate First line and Alternative treatment plan. Explain your rationale about what you judge to be the contributing pathogen(s) you are targeting based on the limited information given and include any disclaimers or follow-up recommended for the treating provider. "
                :
                "Using the portions of the chart provided, please compose a consult note for the patient's prescriber. Be sure to include actionable drug interactions, disease-drug interactions, related lab abnormalities to medications where possible, and any therapeutic drug monitoring that is missing and needs follow-up.  Outline in a to-do list format what you would like to see changed for this patient. "
            }
            <br />
        </>)

    //tutorial related state and query 
    const { data: tutorialStatus } = useGetTutorialStatusQuery()
    const [setTutorialStatus] = useSetTutorialStatusMutation()


    const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false)
    const [isHomeTutorialPopoverOpen, setIsHomeTutorialPopoverOpen] = useState(false)

    const closeTutorialModalHandler = () => setIsWelcomeModalOpen(false)

    const openTutorialPopoverHandler = () => {
        setIsWelcomeModalOpen(false)
        setIsHomeTutorialPopoverOpen(true)
    }
    const closeTutorialPopoverHandler = () => setIsHomeTutorialPopoverOpen(false)

    const { refs } = useTutorial()

    useEffect(() => {
        if (tutorialStatus) {
            setIsWelcomeModalOpen(!tutorialStatus?.is_dashboard_tutorial_completed)
        }
    }, [tutorialStatus, setIsWelcomeModalOpen])

    const [dismissibleAlertObj, setDismissibleAlertObj] = useState({})

    useEffect(() => {
        if (location.state?.fromSigning) {
            setDismissibleAlertObj({
                title: 'Update',
                msg: 'You have successfully signed your BAA. A copy will be emailed to you and accessible on your profile page.'
            })
            setOpenAlert(true)
        }

        if (location.state?.fromEncounterSubmit && trial_info?.is_work_completed) {
            setDismissibleAlertObj({
                title: 'Test encounters completed!',
                msg: "Congratulations on successfully completing and submitting all your test cases! Each submission is currently undergoing a thorough review by our dedicated team. We strive to ensure that all assessments meet our standards of excellence. We truly appreciate the effort and diligence you've shown throughout this process. Thank you for collaborating with us."
            })
            setOpenAlert(true)
        }
    }, [location, trial_info])

    return (
        <>
            <TrialTutorialPopover
                open={isHomeTutorialPopoverOpen}
                onClose={closeTutorialPopoverHandler}
                optionalAPICallback={() => setTutorialStatus({ tutorial_category: 'trial-dashboard' })}
                stepsArray={dashboardTutorialSteps}
            />
            <TrialTutorialModal
                open={isWelcomeModalOpen}
                onClose={closeTutorialModalHandler}
                onOpenPopover={openTutorialPopoverHandler} />
            <Box className='flex-col' p={3} gap={2}>
                <div ref={refs.current[3]} >
                    <Box sx={{ ...theme.standardBox, p: 2 }} >
                        <TrialUserStepper review_state={review_state} />
                    </Box>
                </div>
                <Header />

                <Box className='flex-row' gap={3}>
                    <Box className='flex-col' gap={3} flexGrow={1} flex={1} minWidth={0}>
                        {trial_info?.is_trial_over &&
                            <StaticAlert
                                title={'Your trial period has expired'}
                                msg={"You won't be able to submit any incomplete test encounters, but you can still log in to monitor your review process. For an extension, please contact kate@beyondmd.care."}
                                severity={'error'} />}
                        {openAlert &&
                            <DismissibleAlert
                                title={dismissibleAlertObj.title}
                                msg={dismissibleAlertObj.msg}
                                severity='success'
                            />}
                        <TrialToDoList assignments={trial_encounters} />
                        <Box sx={{ ...theme.standardBox }}>
                            <div ref={refs.current[1]}>
                                <Typography sx={{ ...theme.typography.subtitle2, p: 2, pb: 1 }}>Instructions</Typography>
                                <Divider />
                                <Typography sx={{ ...theme.typography.body2, p: 2 }}>{instructions()}</Typography>
                            </div>
                        </Box>
                    </Box>
                    <Box className='flex-col' gap={2} width={300}>
                        <TrialInfo referrer={referrer} trialEnd={trial_end} />
                    </Box>

                </Box>
            </Box>
        </>
    )
}

export default TrialDashboard
