import { useState, useEffect } from 'react';


const iterateAndReturnError = (rows) =>{
  rows.reverse();
  let foundError = false;
  for (const row of rows) {
    if (row?.errors?.length > 0) {
      foundError = true;
      break;
    }
  }

  return foundError
}



export function useErrorTracking(apiRef) {
    const [error, setError] = useState(false);

    //handles adding error state
    useEffect(() => {
      const getRowErrors = () => {
        let rows = apiRef.current.getSortedRows();
        if(iterateAndReturnError(rows)) setError(true)
        
      };
      
      return apiRef.current.subscribeEvent( 'rowEditStop', getRowErrors );
    }, [apiRef]);


    //handles clearing error state
    useEffect(() =>{
      const getRowErrors = () => {
        let rows = apiRef.current.getSortedRows();
        if(!iterateAndReturnError(rows)) setError(false)
      };

      return apiRef.current.subscribeEvent( 'rowModesModelChange', getRowErrors );
    }, [apiRef])

  
    return error;
  }

  //rowEditStop mode for getting error

  //rowModesModelChange



