import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { useState, memo, useCallback} from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from "@emotion/react";
import StyledFilterChip from "../StyledFilterChip";
import { ActionButton } from "../../../Buttons/ActionButton/ActionButton";
import { FilterChipTitle } from "../FilterChipTitle";




function SingleFilterChip({ 
    chipLabel, 
    options=[], //need to be in [{ value, label }, { value, label }] format
    filterState, 
    setFilterState, 
    disableClear 
}) {

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const isActive = Boolean(filterState != '')


   
    const getLabel = useCallback(() => {

        if(!filterState) return chipLabel

        let optionIndx = options.findIndex(opt => opt.value === filterState)

        return options[optionIndx]?.label

    }, [filterState, options])

    const handleChipClick = (event) => setAnchorEl(event.currentTarget);
    const handleChipClose = () => setAnchorEl(null);
    const handleMenuItemClick = useCallback((event, option) => {
        setFilterState(option.value);
        setAnchorEl(null);
    }, [ setFilterState, setAnchorEl]);
    
    const handleClearFilter = useCallback((e) => {
        handleMenuItemClick(e, {value:'', label:chipLabel });
    }, [handleMenuItemClick, chipLabel]);
    

    return (
        <>
            <StyledFilterChip
                isActive={isActive}
                label={getLabel()}
                onClick={handleChipClick}
                onDelete={isActive && !disableClear? handleClearFilter : null}
                color={isActive ? 'primary' : 'default'}
                variant={isActive ? 'filled' : 'outlined'}
                icon={isActive? null:  <ArrowDropDownIcon />}/>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleChipClose}
                sx={{
                    '& .MuiPopover-paper':{
                        boxShadow: theme.customShadows.elevation8,
                        borderRadius:2
                    }
                }}
                >
                <FilterChipTitle chipLabel={chipLabel} handleChipClose={handleChipClose} />

                {options?.map((option, indx) => (
                    <MenuItem
                        key={indx}
                        selected={filterState === option.value}
                        onClick={(event) => handleMenuItemClick(event, option)}>
                            <Typography variant='body2'>
                                {option.label}
                            </Typography>
                    </MenuItem>
                ))}
                <Box display="flex" justifyContent="flex-end" p={1} >
                    { !disableClear &&
                    <ActionButton size="small" onClick={(event) => handleMenuItemClick(event, {value:'', label:chipLabel })}>
                        Clear
                    </ActionButton>
                    }
                </Box>
            </Menu>
        </>
    );
}

export default memo(SingleFilterChip);
