import Typography from '@mui/material/Typography';
import { Box, IconButton, Modal, Divider, Button, Link, Alert } from '@mui/material';
import axiosAuth from '../../utils/apiUtils/axiosAuth';
import googleIconColor from '../../assets/icons/googleIconColor.png'
import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import useAuthStatus from './useAuthStatus';
import { useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import StandardLogin from './components/StandardLogin';
import ModalWithHaloLogo from './components/ModalWithHaloLogo';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from './authSlice';




export default function Login() {
  const theme = useTheme()
  const location = useLocation()
  const { isAuth } = useAuthStatus()
  const navigate = useNavigate()
  const [isStandardLogin, setIsStandardLogin] = useState(false)
  const user = useSelector(selectCurrentUser)


  useEffect(() => {
    if (isAuth) {
      enqueueSnackbar('Welcome!', { variant: 'success' })
      if (user.frontEndUserType === 'Trial user' && !user.groups?.['Roster Management | User']) {
        navigate('/trial-users/dashboard')
      }
      else {
        navigate('/dashboard')
      }
    }
  }, [isAuth])


  const getGoogleConsentScreen = async () => {
    const res = await axiosAuth.get(`${process.env.REACT_APP_API_URL}google/login/raw/redirect`, { params: { next_url: location?.state?.from?.pathname } })
    window.location.href = res.data.redirect_url;

  }

  useEffect(() => {
    if (location.state?.fromSignUp) {
      enqueueSnackbar('Sign up complete! An email with login information has been sent.', { variant: 'success' })
    }

    if (location.state?.fromPasswordReset) {
      enqueueSnackbar('Password reset! Please login with your new password.', { variant: 'success' })
    }
  }, [location])



  return (
    <>
      <ModalWithHaloLogo>
        {!isStandardLogin ?
          <>
            <Typography sx={{ ...theme.typography.subtitle1, fontWeight: 500 }}>Sign in</Typography>
            <IconButton className='flex-row' onClick={getGoogleConsentScreen} sx={{ borderRadius: '84px', border: `1px solid ${theme.palette.divider}`, alignSelf: 'stretch', justifyContent: 'space-between' }}>
              <img src={googleIconColor} height={20} width={20} alt='Google icon' />
              <Typography sx={{ ...theme.typography.subtitle2, fontWeight: 500, color: theme.palette.text.primary }}>
                Continue with google
              </Typography>
              <Box height={20} width={20} visibility={'hidden'}></Box>
            </IconButton>
            <Divider flexItem />
            <Box className='flex-row-center' gap={1}>
              <Typography sx={{ ...theme.typography.body2, color: theme.palette.text.disabled, textAlign: 'center' }}>
                I have a referral account
              </Typography>
              <Link href='#' variant='subtitle2' onClick={() => setIsStandardLogin(true)} sx={{ textDecoration: 'none' }}> Sign in</Link>
            </Box>

          </>
          : <StandardLogin setIsStandardLogin={setIsStandardLogin} />
        }

      </ModalWithHaloLogo>
    </>
  );
}
