import { Box, Typography, Chip } from '@mui/material'
import { useTheme } from "@emotion/react"
import openDocInNewTab from "../../../../utils/apiUtils/openDocInNewTab"
import { TitleAndBodyBox } from "../../../globalComponents/TextGroups/TitleAndBodyBox"
import { useTutorial } from '../../utils/TutorialContext'

function ChiefComplaintAndEHR({ chiefComplaint, name, encounterType }) {

    const theme = useTheme()
    const {refs} = useTutorial()

    return (
        <Box className='flex-row' gap={4} flexGrow={1} ref={refs.current[1]}>
            {!encounterType.toLowerCase().includes('infectious') ? <TitleAndBodyBox title={'Chief complaint'} body={chiefComplaint} width={'60%'} /> : null
            }
            <Box className='flex-col' width={'40%'} gap={1}>
                <Typography variant="body2" sx={{ color: theme.palette.text.disabled }}>Documents</Typography>
                <Chip
                    size="small"
                    title={'Test patient EHR'}
                    label={'Test patient EHR'}
                    onClick={() => openDocInNewTab(`trial-users/trial-case/ehr/${name}`)}
                    sx={{ ...theme.typography.body2, height: 26, width: 150 }}
                />
            </Box>
        </Box>
    )
}

export default ChiefComplaintAndEHR
