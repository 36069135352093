import { Chip } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const StyledFilterChip = ({ isActive, ...props }) => {
  return (
    <Chip
    //look into transitions as to why the color chnages before the background
      {...props}
      deleteIcon={<ClearIcon/>}
      sx={{
        border:1,
        backgroundColor: isActive ? 'primary.main' : 'background.paper',
        color: isActive ? 'primary.contrast' : 'text.primary',
        borderColor: isActive? 'primary.main' : 'divider',
        transition: 'color 0ms ease-in', 
        flexDirection: isActive ? 'row' :'row-reverse',
        padding: '6px 12px 6px 16px',
        gap:1,
        '& .MuiChip-label': {
          typography: 'body2',
          p:0
        },
        '& .MuiChip-deleteIcon': {
          m: '0 5px 0 0px',
          //color: 'background.paper'
          color: 'primary.contrast'
        },
        '& .MuiChip-icon': {
          ml:0,
        }
    }}
      
    />
  );
};

export default StyledFilterChip;