import { Box, Skeleton } from "@mui/material";

export function TextTitleAndBodyLoader({ gap = '4px', titleWidth = 56, titleHeight = 20, bodyWidth = 56, bodyHeight = 20 }) {

    return (
        <Box className='flex-col' gap={gap}>
            <Skeleton animation='wave' width={titleWidth} height={titleHeight} />
            <Skeleton animation='wave' width={bodyWidth} height={bodyHeight} />
        </Box>
    )

}
