import { useTheme } from "@emotion/react";
import { useState, useCallback, useEffect } from "react";
import {useDropzone} from 'react-dropzone'
import uploadIcon from '../../../../assets/icons/upload-icon.svg'
import { Typography, Button, Box, IconButton} from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import fileAttachedIcon from '../../../../assets/icons/file-attached-icon.svg'


const FileDropzone = ({ onFileUpload, setFieldValue, file, docError, docErrorMsg }) => {

    const [fileAttached, setFileAttached] = useState(false)


    const theme = useTheme()

    const handleFileDetach = () => {
        setFieldValue('documentation', null)
        setFileAttached(false)
    }

    const onDrop = useCallback((acceptedFiles) => {
        setFileAttached(true)
        onFileUpload(acceptedFiles);
    }, [onFileUpload]);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {'application/pdf': ['.pdf'] }, 
        maxFiles: 1
    });

    useEffect(()=>{
        if(file?.name){
            setFieldValue('result_summary', file.name)
        }


    }, [file?.name])

    const dropZone = (
    <div {...getRootProps()} >
        <input  {...getInputProps({name: 'documentation', type:'file'})}/>
        <Box className='flex-col-center' gap={1} sx={{borderRadius: '4px', p: '24px 16px', 
        border: docError ? '1px dashed #D32F2F' : '1px dashed #10A5E780'}}>
            <img src={uploadIcon} height={48} width={48} alt='upload icon'/>
            <Typography sx={{fontSize:'14px'}}>
                Drag your file here or <Button variant="text" color="primary" sx={{p: 0, textDecoration:'underline'}}>
                    click to upload
                </Button>
                <span style={{color:'#D32F2F'}}>*</span>
            </Typography>
            <Typography sx={{color: theme.palette.text.disabled, fontSize:'14px'}}>PDF (max. 3MB)</Typography>
        </Box>
        {docError ? <Typography sx={{margin: '4px 14px 0px 14px', fontSize: '12px', color: '#d32f2f'}}>{docErrorMsg}</Typography> : ''}
    </div>)

    const attached = (
        <Box className='flex-col' gap={1}>
            <Box className='flex-row' gap={1} sx={{borderRadius: '4px', p: '24px 16px', border: '1px dashed #00B29580'}}>
                <Box className='flex-row-center' gap={2} >
                    <img src={fileAttachedIcon} alt='attached icon' height={48} width={48}/>
                    <Typography variant="subtitle2">Document added successfully</Typography>
                </Box>
            </Box>
            <Box className='flex-row-between'>
                <Box className='flex-row-center'>
                    <AttachFileIcon/>
                    <Typography variant="body2">{file?.name}</Typography>
                </Box>
                <IconButton size="small" onClick={handleFileDetach}>
                        <CloseIcon />
                </IconButton>
            </Box>
        </Box>
    )

    return (
        !fileAttached ? dropZone : attached
    )};


export default FileDropzone
