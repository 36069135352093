import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { ActionButton } from '../../../globalComponents/Buttons/ActionButton/ActionButton';
import PatientIntakeModal from '../PatientIntakeModal/PatientIntakeModal';
import { useState } from 'react';




export default function PatientIntakeButton() {

    const [open, setOpen] = useState(false)

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    return(
        <>
        <ActionButton
            sx={{alignItems:'flex-start'}}
            variant='outlined'
            onClick={handleOpen}
            startIcon={<UploadFileOutlinedIcon />}>
                Intake
        </ActionButton>
        <PatientIntakeModal 
            open={open}
            handleClose={handleClose}
        />
        </>
    )

}