

import { useFinalizeCaseMutation } from "../../../rosterManagement/rosterAPISlice"
import {  Box, Divider, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton";
import { HaloTextField } from "../../../globalComponents/ReactHookFormFields/TextFields/HaloTextField";
import { HaloNumberField } from "../../../globalComponents/ReactHookFormFields/NumberFields/HaloNumberField";
import HaloCheckbox from "../../../globalComponents/ReactHookFormFields/CheckBox/HaloCheckbox";
import { useEffect } from "react";
import HaloSkeleton from "../../../globalComponents/Skeletons/HaloSkeleton";
import useCountDown from "../../../globalComponents/hooks/useCountDown";
import Under18Alert from "../../../rosterManagement/components/Under18Alert/Under18Alert";

export default function GenomicsFinalizeContainer({
    setSubmitted, 
    treatingProvider, 
    sessionDuration, 
    isLoading,
    isUnder18
}){

    const {caseId} = useParams()
    const [finalizeReview, {isLoading: isSubmitting}] = useFinalizeCaseMutation()
    const progress = useCountDown(10)
    const disabled = isSubmitting || (progress > 0 && isUnder18)

    const {handleSubmit, control, reset} = useForm({
        defaultValues:{
            time_spent: sessionDuration? sessionDuration?.toString() : '0',
            treating_provider: treatingProvider || '',
            checked: false
        }
    })

    const submitCase = (values) => {
        finalizeReview({
            caseId,
            payload:{
                treating_provider: values.treating_provider,
                time_spent: values.time_spent
            }
        }).unwrap()
        .then(res => {
            setSubmitted(true) //show success
        })
        .catch(err =>{
            
        })
    }


    useEffect(() =>{

        reset({
            time_spent: sessionDuration? sessionDuration?.toString() : '0',
            treating_provider: treatingProvider,
            checked:false
        })


    }, [treatingProvider, sessionDuration, reset])


    return(
        <Box sx={(theme) => ({...theme.shadowedBox})}>
            {/* Header */}
            <Box className='flex-row' alignItems={'center'} gap={2} p={'22px 16px'}>
                <HaloSkeleton loading={isLoading}>
                    <CheckCircleOutlineIcon sx={{color:'#00B295', height:32, width:32}} />
                </HaloSkeleton>
                <HaloSkeleton loading={isLoading}>
                    <Typography variant='subtitle1' sx={{fontWeight:600}}>Submit</Typography>
                </HaloSkeleton>
            </Box>
            {/* Form */}
            <Box 
                sx={{ p:'0px 16px 24px 16px', gap:3}} 
                className='flex-col' 
                component={'form'} 
                noValidate 
                onSubmit={handleSubmit(submitCase)}> 
                {
                    isUnder18 &&
                    <Under18Alert progress={progress}/>
                }
                <Box sx={(theme) => ({...theme.standardBox})}>
                        <Box p={'8px 16px'}>
                            <HaloSkeleton loading={isLoading}>
                                <Typography variant='body1' >Confirmation</Typography>
                            </HaloSkeleton>
                        </Box>
                    <Divider/>
                    {/*Form Fields */}
                    <Box className='flex-col' p={'8px 16px'} gap={2}>
                        <HaloSkeleton loading={isLoading}>
                            <Box className='flex-row' gap={1}>
                                <Typography variant='body2' color='text.disabled'>Session time:</Typography>
                                <Typography variant='body2' color='text.primary'>{sessionDuration}</Typography>
                            </Box>
                        </HaloSkeleton>
                        <HaloSkeleton loading={isLoading} width={'100%'}>
                            <HaloNumberField
                                name='time_spent'
                                label='Time spent'
                                control={control}
                                required
                                disabled={disabled}
                                helperText="Enter estimated time (min) spent on case."
                                />
                        </HaloSkeleton>
                        <HaloSkeleton loading={isLoading} width={'100%'}>
                            <HaloTextField
                                name='treating_provider'
                                label='Treating provider'
                                control={control}
                                required
                                disabled={disabled}
                                helperText="Please confirm the patient's treating provider is correct."
                                />
                        </HaloSkeleton>
                        <HaloSkeleton loading={isLoading} >
                            <HaloCheckbox 
                                name='checked'
                                label="I confirm that I have reviewed the relevant documentation and acknowledge that past this point, I will no longer be able to edit this review."
                                control={control}
                                required
                                disabled={disabled}
                            />
                        </HaloSkeleton>
                    </Box>
                </Box>
                <HaloSkeleton loading={isLoading}>
                    <ActionButton 
                        type='submit'
                        variant='contained'
                        color='success'
                        startIcon={isSubmitting ? null : <CheckIcon/>}
                        disabled={disabled}
                        loading={isSubmitting}
                        sx={{
                            width:200,
                        }}>
                        Submit
                    </ActionButton>
                </HaloSkeleton>
            </Box>

        </Box>
        
    )
}
