import { Box, DialogTitle, Slider, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useState } from 'react';
import { useRequestCasesMutation } from '../../rosterAPISlice';
import { apiSlice } from '../../../../app/api/apiSlice';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { show400Error } from '../../../../app/errors/genericErrors';
import dayjs from 'dayjs';
import { ActionButton } from '../../../globalComponents/Buttons/ActionButton/ActionButton';



const MAX_CASES = 20
const today = dayjs().format('YYYY-MM-DD')


export default function RequestCasesDialog({open, handleClose}){

    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()
    const [cases, setCases] = useState(0)
    const [requestCase, {isLoading:isRequesting}] = useRequestCasesMutation()


    const handleSliderChange = (e, newValue) => setCases(newValue)
    const handleInputChange = (e) => {

        const input = e.target.value

        if(input > 20 ) {
            setCases(20)
            return;
        }

        setCases(e.target.value === '' ? 0 : Number(e.target.value));

    }

    const handleRequestCases = () =>{

        requestCase({
            payload:{requestedCases : cases},
            today
        })
        .unwrap()
        .then((res) => {
            const {message, warning} = res || {}
            if(message) enqueueSnackbar(message, {variant: 'success'})
            else if (warning) enqueueSnackbar(warning, {variant:'warning'})
            
            handleClose()
            setCases(0)
            dispatch(apiSlice.util.invalidateTags(['Caseload']))
        })
        .catch((err) => {
            show400Error(err)
        })

    }




    return(
        <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
                <DialogTitle>Request cases</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Request cases for today only (max 20)
                    </DialogContentText>
                    <Box className='flex-row' gap={2} alignItems={'center'}>
                        <Slider
                            value={cases} //delay in state being intitialized so a fallback number prevents component from breaking
                            onChange={handleSliderChange}
                            max={MAX_CASES}
                            disabled={isRequesting}
                            />
                        <TextField
                            value={cases}
                            onChange={handleInputChange}
                            size="small"
                            variant='outlined'
                            disabled={isRequesting}
                            inputProps={{
                                step:1,
                                min: 0,
                                max: MAX_CASES,
                                type: 'number',
                                }}
                            />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <ActionButton variant='outlined' onClick={handleClose} disabled={isRequesting}>Cancel</ActionButton>
                    <DialogActions>
                        <ActionButton
                            variant='contained'
                            onClick={handleRequestCases}
                            disabled={cases === 0}
                            // loading={isRequesting}
                            >
                            Request
                        </ActionButton>
                    </DialogActions>
                </DialogActions>
            </Dialog>
    )
}
