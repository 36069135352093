import AutoCompleteCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/AutoCompleteCell"
import CustomTextFieldCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/CustomTextFieldCell"
import { useManageGeneralEncounterMutation, useManageMedInstructionsMutation } from "../../../../infectiousDiseaseAPISlice"
import MedNotePanel from "./MedNotePanel/MedNote"
import CRUDDataGrid from "../../../../../globalComponents/DataGrid/CRUDDataGrid"
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro"
import { AdminMedsIcon } from "../../../../../../assets/icons/MaterialDesignIcons"
import AutoCompleteCreateCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/AutoCompleteCreateCell"
import Under18Alert from "../../../../../rosterManagement/components/Under18Alert/Under18Alert"
import QACommentsContainer from "../../../../../qualityAssurance/components/QAFeedback/QACommentsContainer"


const getDetailPanelHeight = () => 'auto';
const initialState = {
    columns: {
        columnVisibilityModel: {
            [GRID_DETAIL_PANEL_TOGGLE_FIELD]: true
        }
    }
};

//allows for selection of dropdown cells with arrow and enter keys
//default behavior on enter is stop edit mode and proccessRowUpdate
// NOTE: Could porbably add this as a prop to the cells themselves
const preValidation = (params, event) => {
    const arr = ['logic_group', 'category', 'medication', 'route', 'frequency'];
    const enterKeyHit = params.reason === 'enterKeyDown'
    const selectIsOpen = event.target.ariaExpanded === 'true'

    if (enterKeyHit && arr.includes(params.field) && selectIsOpen) {
        event.defaultMuiPrevented = true;
        return true;
    }
    return false;
};



export default function MedicationRecs({ medInstructions, med_recs_options = {}, general, isUnder18, readOnly, qaObj }) {

    const { med_category, medication, med_route, dose_duration } = med_recs_options

    const columns = [
        {
            field: 'logic_group',
            headerName: 'Rule',
            flex: 1,
            editable: !readOnly,
            headerClassName: readOnly ? '' : 'required-col',
            renderEditCell: (params) => (
                <AutoCompleteCell
                    {...params}
                    options={['Or', 'And']}
                    openOnFocus={false}
                    isOptionEqualToValue={(option, value) => option?.toLowerCase() === value || option === value || value === ''} />
            ),
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 1,
            editable: !readOnly,
            headerClassName: readOnly ? '' : 'required-col',
            valueFormatter: value => value?.name,
            renderEditCell: (params) => (
                <AutoCompleteCell {...params} options={med_category} optionKey={'name'} />
            ),
        },
        {
            field: 'medication',
            headerName: 'Medication',
            flex: 1,
            editable: !readOnly,
            headerClassName: readOnly ? '' : 'required-col',
            valueFormatter: value => value?.name,
            renderEditCell: (params) => (
                <AutoCompleteCell {...params} options={medication} optionKey={'name'} />
            ),
        },
        {
            field: 'route',
            headerName: 'Route',
            flex: 1,
            editable: !readOnly,
            headerClassName: readOnly ? '' : 'required-col',
            valueFormatter: value => value?.name,
            renderEditCell: (params) => (
                <AutoCompleteCell {...params} options={med_route} optionKey={'name'} />
            ),
        },
        {
            field: 'dose',
            headerName: 'Dose',
            flex: 1,
            editable: !readOnly,
            headerClassName: readOnly ? '' : 'required-col',
            renderEditCell: (params) => (
                <CustomTextFieldCell {...params} />
            ),
        },
        {
            field: 'frequency',
            headerName: 'Frequency',
            flex: 1,
            editable: !readOnly,
            headerClassName: readOnly ? '' : 'required-col',
            renderEditCell: (params) => (
                <AutoCompleteCreateCell
                    {...params}
                    options={[
                        'Daily',
                        'Twice daily',
                        '3 times daily',
                        '4 times daily',
                        'Divided twice daily',
                        'Divided 3 times daily'
                    ]}
                    isOptionEqualToValue={(option, value) => option === value || value === ''} />
            ),
        },
        {
            field: 'duration',
            headerName: 'Duration',
            flex: 1,
            editable: !readOnly,
            headerClassName: readOnly ? '' : 'required-col',
            valueFormatter: value => value?.label,
            renderEditCell: (params) => (
                <AutoCompleteCell {...params} options={dose_duration} optionKey={'label'} />
            ),
        }
    ]

    const getDetailPanelContent = (row) => row.row.isNew ? null : <MedNotePanel row={row} isReadOnly={readOnly} />;


    return (
        <>
            {isUnder18 && <Under18Alert />}
            <CRUDDataGrid
                title={'Medication recommendations'}
                columns={columns}
                initRows={medInstructions}
                mutation={useManageMedInstructionsMutation}
                GridIcon={AdminMedsIcon}
                fieldToFocus='logic_group'
                requiredFields={['logic_group', 'category', 'medication', 'route', 'dose', 'duration']}
                defaultValues={{
                    logic_group: 'Or',
                    route: med_route?.find(item => item.name === 'PO')
                }}
                isReadOnly={readOnly}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                initialState={initialState}
                toggleProps={{
                    value: general?.no_therapy_recommendation_provided,
                    mutation: useManageGeneralEncounterMutation,
                    mutationKey: 'no_therapy_recommendation_provided',
                    helperText: 'lab results indicate antibiotic therapy is not needed at this time'
                }}
                preValidation={preValidation}
            />
            {qaObj && <QACommentsContainer fieldReview={qaObj?.field_reviews["Medication Review"]} />}
        </>

    )
}