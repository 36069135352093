import { Box, Skeleton, Divider } from "@mui/material";

const generateSkeletons = (count) => {
    const answerKeySkeletons = []
    for (let i = 0; i < count; i++) {
        answerKeySkeletons.push(<Skeleton key={i} animation='wave' width={'100%'} height={58} />)
    }
    return answerKeySkeletons
}
export default function TrialClinicianInfoBoxLoader() {




    return (
        <Box className='flex-col' sx={(theme) => ({ ...theme.standardBox, m: 3, mb: 1, })} width={'90%'} height={1500} >
            <Skeleton animation='wave' width={56} height={20} sx={{ m:2}} />
            <Divider />
            <Box className='flex-col-center' p={2} gap={3} >
                {generateSkeletons(16)}
            </Box>
        </Box>
    )
}
