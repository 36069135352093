import { HaloNumberField } from '../../globalComponents/ReactHookFormFields'
import { useForm } from "react-hook-form";
import axiosAuth from '../../../utils/apiUtils/axiosAuth';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo } from '../../auth/authSlice';
import { enqueueSnackbar } from 'notistack';
import { selectCurrentUser } from '../../auth/authSlice';
import EditUserInfoSkeleton from '../components/EditUserInfoSkeleton';

function EditPhoneNumber() {

    const dispatch = useDispatch()
    const {phone} = useSelector(selectCurrentUser)
    const defaultValues = {phone_mobile: phone}
    const {control, getValues, trigger} = useForm({ defaultValues })

    const updateUserInfoHandler = async(event) => {
        event.preventDefault()
        let valid = await trigger()
        if(!valid) return; 

        let userInfo = getValues()

        axiosAuth({
            url: 'users/update-user-info',
            method: 'PUT',
            data: {'phone': userInfo.phone_mobile}
        }).then(() => {
            dispatch(updateUserInfo({infoType: 'phone', updatedValue: userInfo.phone_mobile}))
            enqueueSnackbar('Phone number successfully updated', {variant: 'success'})
        })
        .catch(() => {
            enqueueSnackbar('Phone number failed to update, please try again', {variant:'error'})
        })
    }

    return (
        <EditUserInfoSkeleton infoType='Phone number' height={192} updateHandler={updateUserInfoHandler}>
            <HaloNumberField
                label={'Phone number'}
                name='phone_mobile'
                variant="phone"
                control={control}
            />
        </EditUserInfoSkeleton>
    
    )
}

export default EditPhoneNumber
