import { useEffect, useRef, useState } from "react"


export function useMinLoadTime(ms, isLoading) {
    const [hasElapsed, setHasElapsed] = useState(false)
    const timeout = useRef()
  
    useEffect(() => {
      if (isLoading) {
        timeout.current && clearTimeout(timeout.current)
        setHasElapsed(false)
        timeout.current = setTimeout(() => {
          setHasElapsed(true)
        }, ms)
      }
    }, [ms, isLoading])
  
    useEffect(() => timeout.current && clearTimeout(timeout.current), [])
  
    return hasElapsed
}

/* 
  e.g. usage 

  const [scanForErrros, {data:errors, isLoading:scanningCSV}] = useScanForErrorsMutation()
  const hasScanned = useMinLoadTime(3000, scanningCSV)
  
*/