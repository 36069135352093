import { Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { calculateAge } from "../../../../../../utils/dateUtils/calculateAge";





//moved here to prevent rerenders

export default function WatchedAge({control}){

    const watchedDOB = useWatch({control, name:'date_of_birth'})

    return(
        <Typography variant="body2">{calculateAge(watchedDOB)}</Typography>
        )



}