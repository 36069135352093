import {  useSelector } from "react-redux";
import OverviewCaseStats from "./Overview/OverviewCaseStats";
import { selectRosterMgmtDates } from "../../../../admin/adminSlice";
import { Alert, Box } from "@mui/material";
import RosterGrids from "./RosterGrids/RosterGrids";







export default function RosterAdminPanel() {
    const dates = useSelector(selectRosterMgmtDates)

    return(
        <Box className='flex-col' gap={1}>
            <Alert severity="warning">Beta - still in transition from Legacy Halo</Alert>
            <OverviewCaseStats dates={dates}/>
            <RosterGrids dates={dates}/>
        </Box>
    )


}