import { DataGridPro, useGridApiRef, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import  DefaultNoRowsOverlay  from '../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay'
import { hideSelectAllCheckBoxProps, linkedRowProps, stickyHeaderProps } from '../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps';
import { stringToSentenceCase } from '../../../../../utils/stringUtils/sentenceCase';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CaseloadToolbar from './CaseloadToolbar';
import {MemoCell} from '../../../../globalComponents/DataGrid/performance/MemoGridItems'
import CaseDetailPanel from './CaseDetailPanel/CaseDetailPanel';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import  LinkedPatientCell  from '../../../../globalComponents/DataGrid/subComponents/customCells/LinkedPatientCell';
import { AssignmentStatusChipCell } from '../../../../globalComponents/DataGrid/subComponents/customCells/AssignmentStatusChipCell';
import { useSaveGridState } from '../../../../globalComponents/DataGrid/hooks/toLocalStorage/useSaveGridState';
import CaseLoadFilters from './CaseLoadFilters';

const pageSizeOptions = [5,10,15,20,30]

const sxProps = {
    border:'1px solid rgba(255,255,255,0);' ,
    "& .MuiDataGrid-virtualScroller": { overflow:  "hidden" },
    '.MuiDataGrid-detailPanel': {
        backgroundColor:'rgba(0, 0, 0, 0.02)'
    },
    ...hideSelectAllCheckBoxProps,
    ...stickyHeaderProps,
    ...linkedRowProps
}

const columns =  [
    {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        cellClassName: () => 'detailPanelCol',
        minWidth:45,
        
        renderCell: ({ value: isExpanded }) => (
            <IconButton tabIndex={-1} size='small' sx={{p:0}}>
                {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
        )
    },
    {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        cellClassName: () => 'checkBoxCol',
        minWidth: 35,
    },
    {
        field: 'case_type',
        headerName: 'Case type',
        width: 130,
    },
    {
        field: 'patient',
        headerName: 'Patient',
        minWidth: 180,
        valueGetter: (row) => row?.name,
        renderCell: (params) => (
            <LinkedPatientCell
                patientName={params?.value}
                patientId={params?.row?.patient?.id}
            />
        )
    },
    {
        field: 'site_name',
        headerName: 'Site',
        minWidth: 180,
    },
    {
        field: 'case_resolution_status',
        headerName: 'Status',
        minWidth: 180,
        renderCell: (params) =>  <AssignmentStatusChipCell {...params} />
    },
    {
        field: 'case_resolution_notes',
        headerName: 'Notes',
        flex: 2,
        valueGetter: (value, row) => {
            if(row?.qa_status === 'Rejected') return value || '-'
            return value || '-'
        }
    },

]

const getIsRowSelectable = ({row:{case_resolution_status:status}}) => {
    if(status === 'completed' || status === 'delayed' || status === 'no_action' || status === 'Rejected' || status === 'pending results') return false

    return true
}

const defaultGridState ={
    "sorting": {
        "sortModel": [
            {
                "field": "case_resolution_status",
                "sort": "asc"
            }
        ]
    },
    "pagination": {
        "paginationModel": {
            "page": 0,
            "pageSize": 10
        }
    },
}

export default function CaseloadTabPanel({assignments, isLoading}){

    const theme = useTheme()
    const navigate = useNavigate()
    const apiRef = useGridApiRef()
    const initialState = useSaveGridState('caseloadGridState', apiRef, defaultGridState)
    const [selected, setSelected] = useState([])
    const isSelected = selected?.length > 0
    
    //1 row max selection
    const handleRowSelection = useCallback((ids) => {               
        let id = ids[ids.length-1]
        if(id) {
            setSelected([id])
        }
        else{
            setSelected([])
        }
    },[setSelected])


    const handleCellClick = ({field, row}) =>{
        const {case_resolution_status:status} = row || {} 
        if(status === 'no_action' || status === 'delayed' ) return
        if (field.includes('__') || field === 'patient') return

        else{
            if(status==='completed' || status === 'pending_results'){ 
                navigate(`/caseload/case-details/${row.case}`) //needs to use the pathology or infectious disease id 
            }
            else{
                navigate('/caseload/case-review/'  + row.case_type + '/' + row.case) 
            }
        }
    }

    const getPanelContent = useCallback( (params) => <CaseDetailPanel {...params}/>, [] )
    const getPanelHeight = useCallback(() => 'auto', []);
    const memoizedRows = useMemo(() => assignments || [], [assignments]);

    const getRowClassName = ({row:{case_resolution_status:status}}) => {
        if(status === 'delayed' || status === 'no_action') return "MuiDataGrid-hide-hover"
    }
    
    if (!initialState) {
        return null;
    }

        
    return(
        <>
        <CaseLoadFilters apiRef={apiRef} initialGridState={initialState}/>
        <Box sx={{...theme.standardBox} }>
            <DataGridPro
                apiRef={apiRef}
                initialState={{
                    ...initialState,
                }}
                columns={columns}
                columnHeaderHeight={isSelected? 0 : 56}
                rows={memoizedRows}
                loading={isLoading}
                pagination
                autoHeight
                rowThreshold={0}
                pageSizeOptions={pageSizeOptions}
                disableRowSelectionOnClick
                checkboxSelection
                rowSelectionModel={selected}
                onRowSelectionModelChange={handleRowSelection}
                onCellClick={handleCellClick}
                getDetailPanelHeight={getPanelHeight}
                getDetailPanelContent={getPanelContent}
                isRowSelectable={getIsRowSelectable}
                getRowClassName={getRowClassName}
                slots={{
                    noRowsOverlay:DefaultNoRowsOverlay,
                    toolbar: isSelected? CaseloadToolbar : null,
                    cell: MemoCell,
                    detailPanelExpandIcon: KeyboardArrowRightIcon,
                    detailPanelCollapseIcon: KeyboardArrowDownIcon,
                }}
                slotProps={{ 
                    noRowsOverlay:{ message:'No assigned cases' }, 
                    toolbar:{ selected, setSelected } 
                }}
                sx={sxProps}/>
        </Box>

        </>
    )
}
