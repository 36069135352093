import { useTheme } from "@emotion/react";
import { Box, Chip, Typography, Button, IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import axiosAuth from "../../../../../utils/apiUtils/axiosAuth";
import {EmptyLabIcon} from '../../../../../assets/icons/EmptyLabIcon'
import { useState } from "react";
import LabDocUploadModal from "../../../../rosterManagement/components/LabDocUpload/LabDocUploadModal";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentCase } from "../../../../rosterManagement/rosterSlice";
import { EmptyEncountersIcon } from "../../../../../assets/icons/EmptyEncountersIcon";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


function openInNewTab(fileURL){
    const newTab = window.open();
    if (newTab) {newTab.location.href = fileURL}
}

function downloadDoc(fileURL, filename){
    const downloadLink = document.createElement('a');
    downloadLink.href = fileURL
    downloadLink.download = filename[1];
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}


export default function PatientDocs({
    isExpanded, 
    paginated_lab_history, 
    paginated_encounter_history,
    readOnly
}){

    const navigate = useNavigate()
    const theme = useTheme()
    const {patient} = useSelector(selectCurrentCase)
    const [allLabs, setAllLabs] = useState(paginated_lab_history)
    
    const handleDocumentClick = ({id, type}) => {
        
        axiosAuth({
            url:`roster/download/doc/${type}/${id}`,
            method:'GET',
            responseType: "blob" // needed for handling file responses, 
        })
            .then(res=> {
                const contentType = res?.headers['content-type']; 
                const contentDisposition = res?.headers['content-disposition']; 
                const filename = contentDisposition.match(/filename="([^"]+)"/); 
                const blob = new Blob([res?.data], { type: contentType }); 
                const fileURL = URL.createObjectURL(blob);
        
                if(contentType === 'application/pdf'){
                    openInNewTab(fileURL)
                }else{
                    downloadDoc(fileURL,filename)
                }
            })
            .catch(err => {
                if(err?.response?.status === 404) enqueueSnackbar('File not found', {variant:'error'})
                if(err?.response?.status === 500) enqueueSnackbar("Server error, please try again later",  {variant:'error'})
            })   
    }


    const handleViewPatientProfile = (patientId) => {
        navigate(`/patients/profile/${patientId}`)
    }

    
    let DOC_MAX_CHIP = isExpanded? 11 : 5 
    let ENCOUNTER_MAX_CHIP = isExpanded? 12 : 6 

    const hiddenDocCnt = allLabs?.total_items - DOC_MAX_CHIP
    const hiddenCaseCnt = paginated_encounter_history?.total_items - ENCOUNTER_MAX_CHIP

    if(hiddenDocCnt > 0) {
        DOC_MAX_CHIP = isExpanded ? 10 : 4
    }

    if(hiddenCaseCnt > 0) {
        ENCOUNTER_MAX_CHIP = isExpanded ? 11 : 5
    }

    const labDocIsEmpty = allLabs?.total_items === 0
    const encountersIsEmpty = paginated_encounter_history?.total_items === 0

    const [open, setOpen] = useState(false)

    const handleModalOpen = () => setOpen(true)


    return(
    <>
        <Box className='flex-row' gap={3}>
            <Box className='flex-col' gap={1} width={'50%'}>
                <Box className='flex-row-between' alignItems={'flex-start'}>
                    <Typography variant="body2" sx={{ color:theme.palette.text.disabled}}>Documents</Typography>
                    <IconButton sx={{height:24, width:24, visibility: readOnly? 'hidden':'visible'}} onClick={handleModalOpen} title="Upload document">
                        <AddCircleOutlineIcon sx={{fontSize:20}}/>
                    </IconButton>
                </Box>
                <Box className={labDocIsEmpty ? 'flex-row-center' : 'flex-row'} gap={1} flexWrap={'wrap'} sx={{maxWidth:350}}>
                    {
                    labDocIsEmpty && 
                        <Box className='flex-col-center' gap={1}> 
                            <EmptyLabIcon style={{fontSize:40}} color='disabled'/>
                        </Box> 
                    }
                    {allLabs?.data?.slice(0,DOC_MAX_CHIP).map((lab, indx) => {
                        return(
                                <Tooltip title={lab?.data?.summary} key={indx} enterDelay={0} >
                                    <Chip 
                                        size="small" 
                                        label={dayjs(lab.data.created_at).format('MMM D YYYY')} 
                                        onClick={() => handleDocumentClick({id:lab.data.id, type:lab.type})} 
                                        sx={{...theme.typography.body2, height:26, width:100}} />                                
                                </Tooltip>
                        )
                    })}

                    {hiddenDocCnt > 0?
                        <Button 
                            size="small"
                            variant="text" 
                            color="primary"
                            sx={{border:'none'}}
                            onClick={() => handleViewPatientProfile(patient.id)} 
                            >
                                { "+" + hiddenDocCnt + ' more'}
                            </Button>
                        :null
                    }
                </Box>
            </Box> 

            <Box className='flex-col' gap={1} width={'50%'}>
                <Typography sx={{...theme.typography.body2, color:theme.palette.text.disabled}}>Previous Reviews</Typography>
                <Box className={encountersIsEmpty ? 'flex-row-center' : 'flex-row'} gap={1} flexWrap={'wrap'}>
                    {encountersIsEmpty && <EmptyEncountersIcon style={{fontSize:40}} color={theme.palette.action.disabled}/> }
                    {paginated_encounter_history?.data?.slice(0,ENCOUNTER_MAX_CHIP).map((encounter, indx) => {
                        return(
                            <Tooltip  
                                key={indx} 
                                enterDelay={0}
                                title={dayjs(encounter?.data?.date_submitted).format('MMM D YYYY') + ' - ' + encounter?.type  }>
                                    
                                <Link to={`/caseload/case-details/${encounter?.data?.case_id}`} target="_blank">
                                    <Chip
                                        size='small'
                                        label={dayjs(encounter?.data?.date_submitted).format('MMM D YYYY')}
                                        sx={{...theme.typography.body2, width:100}} />
                                </Link>
                            </Tooltip>
                        )
                    })}
                    {hiddenCaseCnt > 0?
                        <Button 
                            size="small"
                            color="primary"
                            sx={{border:'none'}}
                            onClick={() => () => handleViewPatientProfile(patient.id)}
                            >
                                { "+" + hiddenCaseCnt + ' more'}
                        </Button>
                            :null
                    }
                </Box>
            </Box>
        </Box>
        <LabDocUploadModal open={open} setOpen={setOpen} allLabs={allLabs} setAllLabs={setAllLabs}/>
    </>
    )
}
