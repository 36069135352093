import {ResponsiveLine} from '@nivo/line'

export default function ResponsiveLineChart({data, margin, padding, theme}) {
    return (
        <ResponsiveLine
            data={data}
            theme={{
                "axis": {
                    "ticks": {
                        "text": {
                            "fill": theme.palette.text.secondary,
                            "outlineWidth": 0,
                            "outlineColor": "transparent"
                        }
                    },
                },
                "legends": {
                    "text": {                                        
                        "fill": theme.palette.text.primary,
                    }
                },
                "tooltip": {
                    "container": {
                        'background': theme.palette.mode == 'dark'? theme.palette.grey[900] : theme.palette.background.paper,
                        "color": theme.palette.text.secondary,
                        "fontSize": 14
                        },
                    }
            }}
            margin={margin || {  right: 100, bottom: 35, left: 20 }}
            xScale= {{ type: 'point' }}
            yScale= {{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false
            }}
            yFormat= " >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom= {{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={null}
            colors= {{ scheme: 'paired' }}
            enablePoints= {false}
            enableGridX= {false}
            gridYValues={5}
            enableSlices='x'
            pointSize={10}
            pointColor= {{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor= {{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',                
                }
            ]}
        />
    )
}
