import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import HaloTabs from '../../../globalComponents/Tabs/HaloTabs';
import HaloTabLabel from '../../../globalComponents/Tabs/TabLabels/HaloTabLabel';









export default function CaseloadTabs({ tabValue, handleTabChange, caseLoadCount, historyCount}){

    return(
        <>
        <HaloTabs
            sx={{pl:3}}
            value={tabValue}
            onChange={handleTabChange}>
            <Tab
                label={ <HaloTabLabel label={'Caseload'}  count={caseLoadCount || '-'}/>}
                value="Caseload"
                icon={<TaskOutlinedIcon color='primary' sx={{height:20, width:20}}/>}
                iconPosition='start'/>

            <Tab 
                label={<HaloTabLabel label={'History'} count={historyCount || '-'} />} 
                value="History" 
                icon={<WatchLaterOutlinedIcon color='disabled'/>} iconPosition='start'/>
        </HaloTabs>
        <Divider />
        </>
    )
}
