import { useTheme } from "@emotion/react"
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import dayjs from "dayjs";
import { calculateAge } from "../../../../../utils/dateUtils/calculateAge";
import HeaderFrameLayout from "../../../../globalComponents/Headers/HeaderFrames/FrameLayout/FrameLayout";
import { usePrefetchEndpoint } from "../../../../../utils/apiUtils/usePrefetchEndpoint";







export default function PatientFrame({patient}){

    const { date_of_birth, sex, vitals } = patient || {}
    const { height, weight, bmi, systolic, diastolic, pulse, respiration_rate } = vitals || {}

    const theme = useTheme()
    const prefetch = usePrefetchEndpoint()

    const firstRow = [ 
        { label:'DOB', value: dayjs(date_of_birth).format('LL') }, 
        { label:'Age', value: calculateAge(date_of_birth)}, 
        { label:'Sex', value: sex }, 
        { label:'Hgt', value: height }, 
        { label:'Wgt', value: weight }, 
    ]
    const secondRow = [ 
        { label:'BMI', value: bmi }, 
        { label:'SBP', value: systolic }, 
        { label:'DBP', value: diastolic }, 
        { label:'Pulse', value: pulse }, 
        { label:'RR', value: respiration_rate },
    ]
    

    return(
        <HeaderFrameLayout
            avatarColor={theme.palette.primary.main}
            name={patient?.name}
            icon={<AccountCircleOutlinedIcon sx={{height:20, width:20}}/>}
            iconLinkPath ={'/patients/profile/' + patient?.id}
            iconButtonProps={{
                onMouseEnter: () => prefetch('getPatientProfile', {patientId: patient?.id?.toString() })
            }}
            firstRow={firstRow}
            secondRow={secondRow}
        />
    )
}