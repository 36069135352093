import { Badge, styled } from '@mui/material';


const UserActivityIndicator = styled(Badge, { shouldForwardProp: (prop) => prop !== 'activityStatus' && prop !== 'pulse' })(({ theme, activityStatus, pulse}) => ({
    '& .MuiBadge-badge': {
        backgroundColor: 
        activityStatus === 'active'
            ? theme.palette.success.main
            : activityStatus === 'idle'
                ? theme.palette.warning.main
                : activityStatus === 'impersonating'
                    ? theme.palette.error.main
                    : 'grey',
        color: 
            activityStatus === 'active'
                ? theme.palette.success.light
                : activityStatus === 'idle'
                    ? theme.palette.warning.main
                    : activityStatus === 'impersonating'
                        ? theme.palette.error.main
                        : 'grey',
        width: 10,
        height: 10,
        borderRadius: '50%',
        border: `0.5px solid ${theme.palette.background.paper}`,
        boxShadow: `0 0 0 0.5px ${theme.palette.background.paper}`,
        ...(pulse ? {
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
                }
            }: {}
        )
    },
    '@keyframes ripple': {
        '0%': {
          transform: 'scale(.8)',
          opacity: 1,
        },
        '100%': {
          transform: 'scale(2.4)',
          opacity: 0,
        },
      },
}));

export default UserActivityIndicator;
