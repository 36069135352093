import {  DatePicker } from "@mui/x-date-pickers-pro";
import { useController } from "react-hook-form";
import dayjs from "dayjs";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';



export function HaloDatePicker({ 
  control, 
  name, 
  label, 
  format="MMM D YYYY",
  slotProps,
  required=false, 
  helperText,
  hideHelperText=false,
  minDate = dayjs('1901-01-01'),  // min and max dates should always be passed in 'YYYY-MM-DD' format
  maxDate = dayjs(), //today
  onBlur,
  title,
  InputProps = {},
  inputLabelProps = {},
  ...otherProps 
}){

    const { field, formState: { errors }, } = useController({
        name,
        control,
        rules: { 
          required: required? 'This field is required' : false ,
          validate: (date) => (minDate <= date && date <= maxDate) || 'Date is out of range' 
        },
      })

    return(

        <DatePicker
            label={label}
            size="small" //app wide default
            {...field}
            format={format}
            slots={{
              openPickerIcon:CalendarMonthOutlinedIcon
            }}
            title={title? field.value : ''}
            slotProps={{
              textField:{
                size:'small',
                fullWidth:true,
                error:Boolean(errors[name]),
                helperText:hideHelperText? '' :helperText? helperText: errors[name]?.message,
                required:required,
                InputLabelProps: {...inputLabelProps},
                InputProps: {...InputProps}
              },
              ...slotProps
            }}
            {...otherProps}
        /> 
    )
}