import dayjs from "dayjs";




//inputs need to be in 'yyyy-mm-dd' formats OR dayjs objs
export const dateRangeToString = (startDate, endDate) =>{

    const weekStart= typeof startDate === 'string' ? dayjs(startDate) : startDate;
    const weekEnd = typeof endDate === 'string' ? dayjs(endDate) : endDate;

    if(!startDate || !endDate) return ''

    if (!dayjs.isDayjs(weekStart) || !dayjs.isDayjs(weekEnd)) {
        throw new Error("Inputs must be valid dayjs objects or strings in 'YYYY-MM-DD' format");
    }
    
    
    const isSameMonth = weekStart.month() === weekEnd.month();
  
  
    let dateRange;
    if (isSameMonth) {
        let startDay = weekStart.format('DD')
        let endDay =   weekEnd.format('DD')
        dateRange = `${weekStart.format('MMM')} ${startDay} - ${endDay}`;
    } else {
      
      dateRange = `${weekStart.format('MMM D')} - ${weekEnd.format('MMM D')}`
    }
  
    return dateRange;
}

export const getCurrentWeekStrings = () => {
  const now = dayjs();
  const weekStart = now.startOf('week')
  const weekEnd = weekStart.add(6, 'day');

  const weekRangeStr = dateRangeToString(weekStart, weekEnd)
  const weekStartIso = weekStart.format('YYYY-MM-DD')

  return {weekRangeStr, weekStartIso}
}
  
  