import { SvgIcon } from "@mui/material";


export const TargetIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 40 40">
        <g clipPath="url(#clip0_3849_164407)">
            <path d="M32.7841 15.1802C33.7659 17.2743 34.2809 19.5194 34.3289 21.8538C34.4937 30.9372 27.2366 38.4621 18.1532 38.6269C18.0502 38.6269 17.9541 38.6269 17.8511 38.6269C8.90496 38.6269 1.54485 31.4315 1.38007 22.4511C1.21529 13.3677 8.47242 5.84278 17.5558 5.678C20.0344 5.62994 22.4443 6.13115 24.6894 7.15415L25.2592 5.90457C22.8288 4.79232 20.2335 4.25679 17.5284 4.30485C7.68285 4.48336 -0.178459 12.633 5.12784e-05 22.4786C0.178561 32.2074 8.14972 40.0069 17.8442 40.0069C17.9541 40.0069 18.0639 40.0069 18.1738 40.0069C28.0124 39.8284 35.8737 31.6718 35.6952 21.8332C35.6472 19.3066 35.0842 16.8692 34.0268 14.6035L32.7841 15.1871V15.1802Z" fill={props.color || 'black'}/>
            <path d="M39.993 6.41265C39.9586 6.17921 39.8007 5.9801 39.581 5.88398L36.4296 4.53829L37.6517 3.31618L36.6837 2.34811L35.4615 3.57022L34.1159 0.418827C34.0197 0.199122 33.8206 0.048075 33.5872 0.00688038C33.3537 -0.0274485 33.1134 0.0618066 32.9624 0.240317L27.4217 6.75594C27.3119 6.88639 27.2501 7.05803 27.2569 7.22968L27.4423 11.5826L19.2309 19.794C18.8258 19.5537 18.352 19.4027 17.844 19.4027C16.3266 19.4027 15.0977 20.6317 15.0977 22.149C15.0977 23.6663 16.3266 24.8953 17.844 24.8953C19.3613 24.8953 20.5903 23.6663 20.5903 22.149C20.5903 21.6409 20.4392 21.1672 20.1989 20.7621L28.4104 12.5507L32.7633 12.736C32.7633 12.736 32.7839 12.736 32.7908 12.736C32.9555 12.736 33.1134 12.6811 33.237 12.5712L39.7527 7.03057C39.9312 6.87952 40.0204 6.63922 39.9861 6.40578L39.993 6.41265ZM28.6438 7.44251L33.292 1.98422L34.4179 4.62068L28.7674 10.2712L28.6438 7.44251ZM32.5642 11.3629L29.7355 11.2393L35.386 5.58876L38.0225 6.71474L32.5642 11.3629Z" fill={props.color || 'black'}/>
            <path d="M17.851 14.5966C19.3889 14.5966 20.817 15.0566 22.0117 15.8462L25.4034 12.4545C23.2956 10.793 20.6454 9.79059 17.7549 9.79059C10.9303 9.79059 5.39648 15.3244 5.39648 22.149C5.39648 28.9736 10.9303 34.5074 17.7549 34.5074C24.5795 34.5074 30.1133 28.9736 30.1133 22.149C30.1133 19.2997 29.1383 16.677 27.5111 14.5829L24.1332 17.9609C24.9296 19.1624 25.4034 20.5973 25.4034 22.149C25.4034 26.3234 22.0254 29.7013 17.851 29.7013C13.6766 29.7013 10.2986 26.3234 10.2986 22.149C10.2986 17.9746 13.6766 14.5966 17.851 14.5966Z" fill={props.color || 'black'}/>
        </g>
        <defs>
            <clipPath id="clip0_3849_164407">
            <rect width="40" height="40" fill="white"/>
            </clipPath>
        </defs>
    </SvgIcon>
)