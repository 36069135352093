import { useParams } from "react-router-dom"
import Analysis from "./Analysis/Analysis"
import DataInput from "./DataInput/DataInput"
import Recommendations from "./Recommendations/Recommendations"
import { Box } from "@mui/material"







export default function GenomicsSummaryContainer({ data, isLoading, allowEdits }){

    const {caseType, caseId} = useParams()
    const { encounter, qa_obj } = data || {}
    const { patient } = encounter || {}
    const sharedProps = {
        readOnlyView: true,
        allowEdits,
        isLoading,
        caseInfo: { 
            case_type: caseType,
            case_id: caseId
        }
      }


    return(
        <>
        <Box p={3} className='flex-col' gap={3}>
            <DataInput 
                meds={patient?.medications}
                diagnoses={patient?.icd10_references}
                genes={encounter?.genes}
                allergies={patient?.allergies}
                {...sharedProps}
            />
            <Analysis 
                labValues={encounter?.lab_values}
                imagingValues={encounter?.imaging_values}
                drugInteractions={encounter?.drug_interactions}
                qaObj={qa_obj}
                {...sharedProps}
            />
            <Recommendations 
                encounter={encounter}
                qaObj={qa_obj}
                {...sharedProps}
                />
        </Box>
        </>
    )

}